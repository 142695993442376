import React from 'react'

function Input({ name, type, onChange, required, placeholder, value }) {
  if(value)
  return (
    <div className='relative w-full px-8 pt-3 sm:px-6 sm:pt-2 rounded-[10px] overflow-hidden border-[1px] border-white'>
    <input
      id={name}
      name={name}
      type={type}
      onChange={onChange}
      required={required}
      value={value || ''}
      placeholder={''}
      className='text-white relative font-Sansation text-lg outline-none sm:text-[12px] bg-transparent w-full z-10 mt-[5px] input pb-2 sm:pb-1'
    />
    <label htmlFor={name} className='absolute text-white font-Sansation sm:text-[12px] label text-lg top-3 left-7 sm:left-6 sm:top-[10px]'>{placeholder}</label>
    <div className='bg-white sm:bg-[#44444446] sm:opacity-30 absolute top-0 left-0 opacity-10 w-full h-[60px] rounded-[10px] blur-[4px] sm:blur-[16px]'></div>
  </div>
  )
  return (
    <div className='relative w-full px-8 pt-3 sm:px-6 sm:pt-2 rounded-[10px] overflow-hidden border-[1px] border-white'>
    <input
      id={name}
      name={name}
      type={type}
      onChange={onChange}
      required={required}
      placeholder={''}
      className='text-white relative font-Sansation text-lg outline-none sm:text-[12px] bg-transparent w-full z-10 mt-[5px] input pb-2 sm:pb-1'
    />
    <label htmlFor={name} className='absolute text-white font-Sansation sm:text-[12px] label text-lg top-3 left-7 sm:left-6 sm:top-[10px]'>{placeholder}</label>
    <div className='bg-white sm:bg-[#44444446] sm:opacity-30 absolute top-0 left-0 opacity-10 w-full h-[60px] rounded-[10px] blur-[4px] sm:blur-[16px]'></div>
  </div>
  )
}

export default Input
