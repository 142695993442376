import React, { useEffect, useState } from 'react'
import Eclips from './Eclips'
import { IoSend } from "react-icons/io5";
import ScrollableFeed from 'react-scrollable-feed'
import { useParams, useLocation, Link } from 'react-router-dom';
import { BACKEND_HOST } from '../constants';
import axios from 'axios';
import { toast } from 'react-toastify';
import Button2 from './Button2';
import Button from './Button';
import { CiMobile1 } from 'react-icons/ci';
import { BsNintendoSwitch } from 'react-icons/bs';
import { FaPlaystation, FaXbox } from 'react-icons/fa';
import { DiWindows } from 'react-icons/di';
import { useTranslation } from 'react-i18next';
import CompletePopup from './CompletePopup';
import FeedbackPopup from './FeedbackPopup';
import CustomerDuties from './CustomerDuties';


function Chat() {

  const [order, setOrder] = useState(JSON.parse(localStorage.getItem('order')))
  const [account, setAccount] = useState()
  const [progress, setProgress] = useState()
  const [t] = useTranslation("global")
  const [loading, setLoading] = useState(false)
  const [completePopup, setcompletePopup] = useState(false)
  const [feebackPopup, setFeedbackPopup] = useState(false)
  const [customerDuties, setCutomerDuties] = useState(false)

  const user = JSON.parse(localStorage.getItem('user'))

  const submitAccount = async (e) => {
    e.preventDefault()
    setLoading(true)
    const data = JSON.stringify({
      "order_id": order?.id,
      "name": account?.name,
      "username": account.username,
      "password": account.password,
    })

    console.log(account);

    const response = await fetch(`${BACKEND_HOST}/update_order_credentials`, {
      method: 'POST',
      headers: {
        'Content-Type': 'Application/json'
      },
      body: data,
    })
    const res = await response.json()
    if (res.status == true) {
      toast.success(res.message)
    }
    else {
      toast.error(res.message)
    }
    setLoading(false)
  }

  const fetchProgress = async () => {
    const data = JSON.stringify({
      "order_id": order?.id,
    })

    const response = await fetch(`${BACKEND_HOST}/get_order_progress`, {
      method: 'POST',
      headers: {
        'Content-Type': 'Application/json'
      },
      body: data,
    })
    const res = await response.json()
    if (res.status == true) {
      if (res.data.length > 0)
        setProgress(res.data[0].progress)
    }
    else {
      toast.error(res.message)
    }
  }
  const fetchAccount = async () => {
    const data = JSON.stringify({
      "order_id": order?.id,
    })

    const response = await fetch(`${BACKEND_HOST}/get_order_credentials`, {
      method: 'POST',
      headers: {
        'Content-Type': 'Application/json'
      },
      body: data,
    })
    const res = await response.json()
    if (res.status == true) {
      if (res.data.length > 0)
        setAccount(res.data[0])
    }
    else {
      toast.error(res.message)
    }
  }

  useEffect(() => {

  }, [progress])


  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchAccount();
        await fetchProgress()
      } catch (error) {
        // console.log(error);
      }
    };
    fetchData();
    const intervalId = setInterval(fetchData, 4000);

    return () => clearInterval(intervalId);
  }, [])


  return (
    <>
    <div className='flex flex-col w-[100%] overflow-hidden relative'>
      <div className="relative bg-cover bg-no-repeat bg-left sm:opacity-35 opacity-20 bg-[url('./assets/userDash.jpg');] h-screen w-full -mt-2"></div>

      <div className='pl-60 pr-20 -mt-[570px] z-10 mb-32 sm:px-7 sm:-mt-[550px]'>

        <span className='flex justify-between items-center mb-3'>
          <h1 className='font-Oswald text-[40px] text-white sm:text-2xl'>
            Order <span className='text-primary-100'>#{order?.id}</span>
          </h1>
          <span className='w-[250px] sm:w-[150px]'>
            {order?.booster_id == user?.id && order.status === 'Assigned' && <Button title={t('userDashboard.complete')}  onClick={()=>setcompletePopup(order?.id)}/>}
            {order?.booster_id != user?.id && order.status === 'Completed' && <Button title={t('userDashboard.review')}  onClick={()=>setFeedbackPopup(order?.id)}/>}
          </span>
        </span>
        <p className='text-white font-Sansation text-base sm:text-sm'>
          {t("userDashboard.chat_text")}
        </p>

        <div className='flex flex-col w-[100%] overflow-hidden relative mt-8'>
          <div className=' glass h-[600px] rounded-3xl flex justify-between items-center overflow-hidden sm:flex-col-reverse sm:h-auto'>
            {account && progress && <>
              <div className='w-[70%] flex flex-col gap-0 h-full sm:w-full'>
                <Messages />
              </div>
              <div className='w-[30%] sm:w-full border-l-[1px] border-white/50  bg-white/10 px-4 py-5 flex flex-col gap-2 overflow-hidden  h-[600px] relative overflow-y-scroll'>


                <div className='flex justify-start items-center gap-2 px-1'>
                  <img src={`${BACKEND_HOST.replace('/api', '')}/${order?.service?.service_image}`} className='w-[70px] object-contain h-[70px]' />
                  <div className='flex flex-col'>
                    <h1 className='font-Oswald font-bold text-2xl text-white'>{order?.service?.service_title}</h1>
                    <p className='font-Sansation font-medium text-lg text-white'>{order?.game?.title}</p>
                  </div>
                </div>
                <div className='flex flex-col gap-1'>
                  {order?.rank_type === "Placement" && order?.ranks && <>
                    <div className='flex justify-between items-center px-1'>
                      <p className='font-Sansation text-sm text-white'>Placement</p>
                      <p className='font-Sansation text-sm text-white'>{order.ranks[0]?.rank?.rank_name} {order.ranks[0]?.level?.name}</p>
                    </div>
                  </>}

                  {order?.rank_type === "Conversion" && order?.ranks && <>
                    <div className='flex justify-between items-center px-1'>
                      <p className='font-Sansation text-sm text-white'>Current</p>
                      <p className='font-Sansation text-sm text-white'>{order.ranks[0]?.rank?.rank_name} {order.ranks[0]?.level?.name}</p>
                    </div>
                    <div className='flex justify-between items-center px-1'>
                      <p className='font-Sansation text-sm text-white'>Desired</p>
                      <p className='font-Sansation text-sm text-white'>{order.ranks[1]?.rank?.rank_name} {order.ranks[1]?.level?.name}</p>
                    </div>
                  </>}

                  {order?.number_of_games != 0 &&
                    <div className='flex justify-between items-center px-1'>
                      <p className='font-Sansation text-sm text-white'>Number of Wins</p>
                      <p className='font-Sansation text-sm text-white'>{order?.number_of_games} Wins</p>
                    </div>}
                  {order?.plateform && <div className='flex justify-between items-center px-1'>
                    <p className='font-Sansation text-sm text-white'>Platform</p>
                    <p className='font-Sansation text-sm text-white flex justify-end items-center gap-1'>
                      {order.plateform?.plateform_name.startsWith('PC') && <DiWindows className='text-xl' />}
                      {order.plateform?.plateform_name.startsWith('Play') && <FaPlaystation className='text-xl' />}
                      {order.plateform?.plateform_name.startsWith('Xbox') && <FaXbox className='text-xl' />}
                      {order.plateform?.plateform_name.startsWith('Nintendo') && <BsNintendoSwitch className='text-xl' />}
                      {order.plateform?.plateform_name.startsWith('Mobile') && <CiMobile1 className='text-xl' />}
                      {order.plateform?.plateform_name}
                    </p>
                  </div>}
                  <svg xmlns="http://www.w3.org/2000/svg" width="306" height="2" viewBox="0 0 306 2" fill="none" className='w-full'>
                    <path opacity="0.8" d="M0 1L306 0.999973" stroke="url(#paint0_linear_215_159)" />
                    <defs>
                      <linearGradient id="paint0_linear_215_159" x1="0" y1="1" x2="306" y2="1.00003" gradientUnits="userSpaceOnUse">
                        <stop stop-color="white" stop-opacity="0" />
                        <stop offset="0.49" stop-color="white" />
                        <stop offset="1" stop-color="white" stop-opacity="0" />
                      </linearGradient>
                    </defs>
                  </svg>
                  {order?.customizes?.length > 0 && <>
                    <div className='flex justify-start items-start gap-2 flex-wrap px-1 py-1'>
                      {order?.customizes?.map((c, index) => {
                        return <p key={index} className='glass px-2 py-[2px] rounded-md text-white text-[12px] font-Sansation'>{c.custom_name}</p>
                      })}
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="306" height="2" viewBox="0 0 306 2" fill="none" className='w-full'>
                      <path opacity="0.8" d="M0 1L306 0.999973" stroke="url(#paint0_linear_215_159)" />
                      <defs>
                        <linearGradient id="paint0_linear_215_159" x1="0" y1="1" x2="306" y2="1.00003" gradientUnits="userSpaceOnUse">
                          <stop stop-color="white" stop-opacity="0" />
                          <stop offset="0.49" stop-color="white" />
                          <stop offset="1" stop-color="white" stop-opacity="0" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </>}
                </div>
                <form onSubmit={submitAccount} className='flex flex-col gap-2 bg-primary-200/40 p-4 py-3 mt-1 rounded-lg'>
                  <div className='flex justify-between items-center gap-1'>
                    <p className='text-white text-sm font-Sansation'>Name:</p>
                    <input name='name' defaultValue={account?.name} onChange={(e) => setAccount({ ...account, [e.target.name]: e.target.value })} readOnly={order?.user_id == user?.id ? false : true} id='chatbox' type='text' className='bg-transparent sm:text-sm sm:px-3 px-2 py-1 border-[1px] w-[70%] border-white/55 rounded-lg text-white outline-none text-base font-Sansation' />
                  </div>
                  <div className='flex justify-between items-center gap-1'>
                    <p className='text-white text-sm font-Sansation'>ID:</p>
                    <input name='username' defaultValue={account?.username} onChange={(e) => setAccount({ ...account, [e.target.name]: e.target.value })} readOnly={order?.user_id == user?.id ? false : true} id='chatbox' type='text' className='bg-transparent sm:text-sm sm:px-3 px-2 py-1 border-[1px] w-[70%] border-white/55 rounded-lg text-white outline-none text-base font-Sansation' />
                  </div>
                  <div className='flex justify-between items-center gap-1'>
                    <p className='text-white text-sm font-Sansation'>Password:</p>
                    <input name='password' defaultValue={account?.password} onChange={(e) => setAccount({ ...account, [e.target.name]: e.target.value })} readOnly={order?.user_id == user?.id ? false : true} type='text' className='bg-transparent sm:text-sm sm:px-3 px-2 py-1 border-[1px] w-[70%] border-white/55 rounded-lg text-white outline-none text-base font-Sansation' />
                  </div>
                  {order.user_id === user?.id && <Button2 title={"Update"} disabled={loading} />}
                </form>

                {order?.user_id == user?.id && <div className='flex flex-col gap-0 z-20 relative'>
                  <p className='font-Oswald text-lg text-white'>Progress:</p>
                  <div className='flex justify-between items-center'>
                    <p className='font-Sansation text-sm text-white'>Order Completed</p>
                    <p className='font-Sansation text-base text-white '>{progress}%</p>
                  </div>
                  <div className='flex overflow-hidden w-full h-[6px] glass rounded-full mt-1'>
                    <div className={`bg-primary-100 rounded-full h-full`} style={{ width: `${progress}%` }}></div>
                  </div>
                </div>}

                {order?.user_id != user?.id && <BoosterProgress progress={progress} />}
                <div className='flex justify-between items-center mt-1 z-20 relative'>
                  <div className=' w-[55%] flex flex-col gap-2'>
                    <p className='text-white text-xl font-Oswald'>{t('custduties.head')}</p>
                      <Button2 title={t('custduties.read')} onClick={()=> setCutomerDuties(true)}/>
                  </div>
                  <img src={require('../assets/51HOE6G8nhL 1.png')} className='w-[45%]' />
                </div>
              </div>
            </>}

            {!account && !progress && <div className='w-full h-full flex justify-center items-center bg-white/10' >
              <img src={require('../assets/loading.gif')} alt='wait' className='w-10 h-10' />

            </div>}
          </div>
        </div>
      </div>

      <Eclips />

    </div>
    {completePopup && <CompletePopup setcompletePopup={setcompletePopup} order_id={completePopup}/>}
    {feebackPopup && <FeedbackPopup setFeedbackPopup={setFeedbackPopup} order_id={feebackPopup}/>}
    {customerDuties && <CustomerDuties setCutomerDuties={setCutomerDuties}/>}
    
    </>
  )
}

export default Chat


const Messages = () => {

  const { order_id, reciever_id } = useParams()
  const location = useLocation();
  const profileParam = new URLSearchParams(location.search).get('profile');
  const profile = profileParam ? decodeURIComponent(profileParam) : null;
  const usernameParam = new URLSearchParams(location.search).get('username');
  const username = profileParam ? decodeURIComponent(usernameParam) : null;

  const [text, setText] = useState('')
  const [loading, setLoading] = useState(false)
  const [chats, setChats] = useState(null)
  const user = JSON.parse(localStorage.getItem('user'))

  const fetchChats = async () => {
    let data = JSON.stringify({
      "order_id": order_id
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${BACKEND_HOST}/get_order_chat`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    const response = await axios.request(config)
    if (response.status === 200 && response.data.status == true) {
      setChats(response.data.data)
    }
    else
      toast.error(response.data.message ? response.data.message : response.data.error);
  }

  const sendMessage = async (e) => {
    e.preventDefault()
    if (!text && text?.length === 0)
      return;
    setLoading(true)

    let data = JSON.stringify({
      "sender_id": user.id,
      "receiver_id": reciever_id,
      "message": text,
      "order_id": order_id
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${BACKEND_HOST}/add_order_chat`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    const response = await axios.request(config)
    if (response.status === 200 && response.data.status == true) {
      console.log(response);
      setText('')
      document.getElementById('chatbox').value = ''
    }
    else
      toast.error(response.data.message ? response.data.message : response.data.error);
    setLoading(false)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchChats();
      } catch (error) {
        // console.log(error);
      }
    };
    fetchData();
    const intervalId = setInterval(fetchData, 2000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <div className='h-[70px] bg-primary-300/50 flex justify-start items-center px-4 gap-2'>
        <img src={profile ? `${BACKEND_HOST.replace('/api', '')}/${profile}` : require('../assets/defaultAvatar.jpg')} className='w-11 h-11 rounded-full object-cover' />
        <h2 className='text-lg text-white font-Oswald capitalize'>{username}</h2>
      </div>
      {!chats && <div className='w-full h-full flex justify-center items-center bg-white/10' >
        <img src={require('../assets/loading.gif')} alt='wait' className='w-10 h-10' />
      </div>}

      {chats && <>
        <div className='h-[430px] mt-4 mb-4'>
          <ScrollableFeed>
            <div className='flex flex-col justify-start items-start gap-4 pr-6 pl-4'>
              {chats?.map((c, index) => {
                return <div className={`flex flex-col justify-start gap-1 ${c.sender_id != user?.id ? 'self-start items-start' : 'self-end items-end'}`}>
                  <div key={index} className={`border-[1px] border-white/50  rounded-b-2xl ${c.sender_id != user?.id ? 'rounded-r-2xl' : 'rounded-l-2xl'}  min-w-[10px] max-w-[400px] sm:max-w-[250px] px-3 py-[6px]`}>
                    <p className='text-base font-Sansation text-white sm:text-sm'>{c.message}</p>
                  </div>
                  <p className='text-white/70 text-[10px]'>{c.created_at.substr(0, 16).replace('T', '')}</p>
                </div>
              })}
            </div>
          </ScrollableFeed>
        </div>
        <form onSubmit={sendMessage} className='flex justify-between items-stretch px-4 gap-2 sm:mb-4 sm:gap-1'>
          <input id='chatbox' onChange={(e) => setText(e.target.value)} type='text' className='bg-transparent sm:text-sm sm:px-3 px-5 py-2 border-[1px] w-full border-white/55 rounded-lg text-white outline-none text-base font-Sansation' placeholder={"Type"} />
          <button disabled={loading} className='bg-primary-100/70 disabled:opacity-50 disabled:cursor-wait px-10 py-2 flex justify-center items-center cursor-pointer rounded-md sm:px-4'>
            <IoSend className='text-white text-[26px] sm:text-lg' />
          </button>
        </form>
      </>}
    </>
  )
}



const BoosterProgress = ({ progress }) => {
  const [state, setState] = useState(progress)

  const order = JSON.parse(localStorage.getItem('order'))

  const onChange = (e) => {
    setState(e.target.value)
  }

  const updateProgress = async (e) => {
    e.preventDefault()
    const data = JSON.stringify({
      "order_id": order?.id,
      "progress": state
    })

    const response = await fetch(`${BACKEND_HOST}/update_order_progress`, {
      method: 'POST',
      headers: {
        'Content-Type': 'Application/json'
      },
      body: data
    })
    const res = await response.json()
    if (response.status === 200 && res.status == true) {
      toast.success(res.message)
    }
    else {
      toast.error(res.message)
    }
  }

  return (
    <div className='flex flex-col gap-0 z-10 relative'>
      <p className='font-Oswald text-lg text-white'>Progress:</p>
      <div className='flex justify-between items-center'>
        <p className='font-Sansation text-sm text-white'>Order Completed</p>
        <p className='font-Sansation text-base text-white '>{state}%</p>

      </div>
      <input onBlur={updateProgress} type="range" class="w-full mt-1 bg-transparent cursor-pointer appearance-none disabled:opacity-50 disabled:pointer-events-none focus:outline-none
                                            [&::-webkit-slider-thumb]:w-2.5
                                            [&::-webkit-slider-thumb]:h-2.5
                                            [&::-webkit-slider-thumb]:-mt-0.5
                                            [&::-webkit-slider-thumb]:appearance-none
                                            [&::-webkit-slider-thumb]:bg-white
                                            [&::-webkit-slider-thumb]:shadow-[0_0_0_4px_rgba(37,99,235,1)]
                                            [&::-webkit-slider-thumb]:rounded-full
                                            [&::-webkit-slider-thumb]:transition-all
                                            [&::-webkit-slider-thumb]:duration-150
                                            [&::-webkit-slider-thumb]:ease-in-out
                                            [&::-webkit-slider-thumb]:dark:bg-slate-700

                                            [&::-moz-range-thumb]:w-2.5
                                            [&::-moz-range-thumb]:h-2.5
                                            [&::-moz-range-thumb]:appearance-none
                                            [&::-moz-range-thumb]:bg-white
                                            [&::-moz-range-thumb]:border-4
                                            [&::-moz-range-thumb]:bg-[#0091F7]
                                            [&::-moz-range-thumb]:rounded-full
                                            [&::-moz-range-thumb]:transition-all
                                            [&::-moz-range-thumb]:duration-150
                                            [&::-moz-range-thumb]:ease-in-out

                                            [&::-webkit-slider-runnable-track]:w-full
                                            [&::-webkit-slider-runnable-track]:h-1
                                            [&::-webkit-slider-runnable-track]:bg-gray-100
                                            [&::-webkit-slider-runnable-track]:rounded-full
                                            [&::-webkit-slider-runnable-track]:dark:bg-[#0091F7]

                                            [&::-moz-range-track]:w-full
                                            [&::-moz-range-track]:h-2
                                            [&::-moz-range-track]:bg-[#0091F7]
                                            [&::-moz-range-track]:rounded-full"

        id="steps-range-slider-usage" defaultValue={state} min={0} max={100} step="1" onChange={onChange} />
    </div>
  )
}