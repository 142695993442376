import React from 'react'
import { useTranslation } from 'react-i18next'

function CustomerDuties({ setCutomerDuties }) {
    const [t] = useTranslation("global")
  return (
    <div className='fixed top-0 left-0 z-50 w-full h-screen flex justify-center items-center'>
    <div onClick={() => setCutomerDuties(false)} className='fixed top-0 left-0 w-full h-screen bg-primary-300/70'></div>
        <div className='bg-primary-300 rounded-2xl w-[60%] sm:w-[90%] shadow-2xl z-50 px-6 py-5 sm:px-3 sm:py-3 flex flex-col gap-3'>
        <h1 className='font-Oswald text-2xl text-white'>{t('custduties.head')}</h1>
        <ol className='flex flex-col gap-4 sm:gap-0'>
            <li className='text-sm font-Sansation text-white sm:text-[9px]'>{t("custduties.line_1")}</li>
            <li className='text-sm font-Sansation text-white sm:text-[9px]'>{t("custduties.line_2")}</li>
            <li className='text-sm font-Sansation text-white sm:text-[9px]'>{t("custduties.line_3")}</li>
            <li className='text-sm font-Sansation text-white sm:text-[9px]'>{t("custduties.line_4")}</li>
            <li className='text-sm font-Sansation text-white sm:text-[9px]'>{t("custduties.line_5")}</li>
        </ol>
        </div>
    </div>
  )
}

export default CustomerDuties
