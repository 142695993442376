import i18next from 'i18next'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdOutlineKeyboardDoubleArrowDown } from 'react-icons/md'
import { Link } from 'react-router-dom'
import eng from '../assets/4.png'
import arb from '../assets/saud.png'

function LoginHeader() {
    const [t, i18n] = useTranslation("global")
    const [langDrapDown, setLangDropDown] = useState(false)
    const [selectedLang, setSelectedLang] = useState({
      img: eng,
      name: 'Eng'
  })


  const changeLang = (name) => {
      if (name == 'en') {
          setSelectedLang({
              img: eng,
              name: 'Eng'
          })
      }
      if (name == 'arb') {
          setSelectedLang({
              img: arb,
              name: 'Arab'
          })
      }
      i18next.changeLanguage(name, (err, t) => {
          if (err) return console.log('Something went wrong loading the language', err);
          });
  }

  return (
    <div className='absolute left-[88px] top-[49px] flex justify-start items-center gap-[50px] sm:left-7 sm:top-5 sm:gap-7'>
      <img onClick={()=>window.location.pathname = '/'} src={require('../assets/logo.png')} alt='logo' className='w-[100px] sm:w-[61.75px] drop-shadow-[4.744px_3.795px_13.282px_#123698] cursor-pointer'/>
      <div className={`flex flex-col lang overflow-hidden`} onMouseEnter={() => setLangDropDown(true)} onMouseLeave={() => setLangDropDown(false)}>
                    <div className='rounded-lg border-[1px] border-white px-4 py-1 text-white font-Sansation bg-[#ffffff2b] text-lg flex justify-center items-center cursor-pointer'>
                        <img src={selectedLang.img} alt='' className='h-7 w-7 rounded-full mr-2' />
                        <p className='mr-5'>{selectedLang.name}</p>
                        <MdOutlineKeyboardDoubleArrowDown className='text-lg text-white' />
                    </div>
                    {langDrapDown && <div className='absolute top-12'>
                        <div onClick={() => changeLang('en')} className='mt-6 rounded-t-lg border-[1px] border-white px-4 py-1 bg-[#ffffff25] text-white font-Sansation text-lg flex justify-center items-center cursor-pointer'>
                            <img src={require('../assets/4.png')} alt='' className='h-7 w-7 rounded-full mr-2' />
                            <p className='mr-2'>English</p>
                        </div>
                        <div onClick={() => changeLang('arb')} className='rounded-b-lg border-[1px] border-t-0 bg-[#ffffff25] border-white px-4 py-1 text-white font-Sansation text-lg flex justify-center items-center cursor-pointer'>
                            <img src={require('../assets/saud.png')} alt='' className='h-7 w-7 rounded-full mr-2 bg-white' />
                            <p className='mr-4'>Arabic</p>
                        </div>

                    </div>}

      </div>
    </div>
  )
}

export default LoginHeader
