import React, { useEffect, useState } from 'react'
import Input2 from './Input2'
import Button2 from './Button2'
import { useParams } from 'react-router-dom'
import { BACKEND_HOST } from '../constants'
import axios from 'axios'
import { toast } from 'react-toastify'
import { IoIosCloseCircleOutline } from "react-icons/io";

function Customizations() {
    const [customizations, setCustomizations] = useState(null)
    const [loading, setLoading] = useState(false)
    const [custom, setCustom] = useState({})
    const [customistationToBeadit, setCustomistationToBeadit] = useState(null)

    const { service_id } = useParams()

    const removeFromEdit = () => {
        document.getElementById('custom_name').defaultValue = ''
        document.getElementById('description').defaultValue = ''
        document.getElementById('percentage').defaultValue = ''
        setCustom(null); 
        setCustomistationToBeadit(null)
    }

    const deleteCustom = async (id) => {
        const confirm = window.confirm('Are you sure deleting this?')
        if (!confirm)
            return;

        setLoading(true)
        try {
            const response = await fetch(`${BACKEND_HOST}/admin_delete_service_customizes`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'Application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({ service_customize_id: id })
            })

            const res = await response.json()
            if (response.ok && res.status) {
                setCustomizations(null)
                fetchCustoms()
                toast.success(res.error ? res.error : res.message)
            }
            else
                toast.error(res.error ? res.error : res.message)
        } catch (error) {
            console.log(error);
        }
        setLoading(false)
    }

    const onChange = (e) => {
        setCustom({...custom, [e.target.name]: e.target.value})
    }
    
    const addNewCustom = async () => {
      
        setLoading(true)
        try {
            const data = JSON.stringify({
                custom_name: custom.custom_name,
                percentage: custom.percentage,
                description: custom?.description,
                service_id: service_id,
            })

            console.log(data);

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${BACKEND_HOST}/admin_add_service_customizes`,
                headers: {
                    'Content-Type': 'Application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                data: data
            };

            const response = await axios.request(config);
            if (response.status === 200 && response.data.status == true) {
                setCustomizations(null)
                setCustom(null)
                setCustomistationToBeadit(null)
                document.getElementById('custom_name').value = ''
                document.getElementById('percentage').value = ''
                document.getElementById('description').value = ''
                fetchCustoms()
                toast.success(response.data.error ? response.data.error : response.data.message)
            } else {
                toast.error(response.data.error ? response.data.error : response.data.message);
            }
        } catch (error) {
            
        }
        setLoading(false)
    }

    const editCustom = async () => {
      
        setLoading(true)
        try {
            const data = JSON.stringify({
                custom_name: custom.custom_name,
                percentage: custom.percentage,
                description: custom.description,
                service_id: service_id,
                service_customize_id: custom.id
            })

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${BACKEND_HOST}/admin_update_service_customizes`,
                headers: {
                    'Content-Type': 'Application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                data: data
            };

            const response = await axios.request(config);
            if (response.status === 200 && response.data.status == true) {
                setCustomizations(null)
                fetchCustoms()
                setCustom(null)
                setCustomistationToBeadit(null)
                toast.success(response.data.error ? response.data.error : response.data.message)
                document.getElementById('custom_name').value = ''
                document.getElementById('percentage').value = ''
                document.getElementById('description').value = ''
            } else {
                toast.error(response.data.error ? response.data.error : response.data.message);
            }
        } catch (error) {
            
        }
        setLoading(false)
    }

    const onSubmit = async(e) => {
        e.preventDefault()
        if(custom?.id)
            editCustom()
        else
            addNewCustom()
    }

    const fetchCustoms = async () => {
        try {
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${BACKEND_HOST}/admin_get_service_customizes`,
                headers: {
                    'Content-Type': 'Application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                data: JSON.stringify({service_id: service_id})
            };

            const response = await axios.request(config);
            if (response.status === 200 && response.data.status) {
                setCustomizations(response.data.data);
            }
            else
                toast.error(response.data.error ? response.data.error : response.data.message)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(()=>{
        fetchCustoms()
    }, [])
    useEffect(()=>{
        if(custom?.id){
            document.getElementById('custom_name').defaultValue = custom.custom_name
            document.getElementById('description').defaultValue = custom.description
            document.getElementById('percentage').defaultValue = custom.percentage
        }
    }, [custom])


    return (
        <div className='flex justify-between items-start gap-6 sm:flex-col-reverse sm:gap-2'>
            <div className='w-[72%] sm:w-full flex flex-col gap-3'>
                <div className="overflow-x-auto mt-2 h-[300px] overflow-y-auto">
                    {!customizations && <img src={require('../assets/loading.gif')} alt='loader' className='w-7 h-7' />}
                    {customizations?.length === 0 && <p className='text-white text-sm sm:text-[12px] font-Sansation'>No data found.</p>}
                    {customizations?.length > 0 && <table className="min-w-full sm:min-w-[700px]">
                        <thead>
                            <tr>
                                <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-1 sm:py-1 text-grey">#</th>
                                <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey">Title</th>
                                <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey">Percentage</th>
                                <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey">Description</th>
                                <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {customizations?.map((b, index) => {
                                return <tr className="bg-primary-300 border-b-[4px] border-primary-100/10" key={index}>
                                    <td className="py-2 px-4 text-left font-Sansation text-white text-sm border-b-white sm:px-1 sm:py-1 sm:text-[10px] sm:leading-[20px] align-top">{index + 1}</td>
                                    <td className="py-2 px-4 text-left font-Sansation text-white text-sm border-b-white sm:px-1 sm:py-1 sm:text-[10px] sm:leading-[20px] min-w-[100px] align-top">{b.custom_name}</td>
                                    <td className="py-2 px-4 text-left font-Sansation text-white text-sm border-b-white sm:px-1 sm:py-1 sm:text-[10px] sm:leading-[20px] align-top">{b.percentage}%</td>
                                    <td className="py-2 px-4 text-left font-Sansation text-white text-sm border-b-white sm:px-1 sm:py-1 sm:text-[10px] sm:leading-[20px] min-w-[100px] align-top">{b.description}</td>
                                    <td className="py-2 px-4 text-left font-Sansation text-white text-sm sm:px-2 sm:py-1 sm:text-[10px] sm:leading-[20px] align-top">
                                        <span className='flex justify-start items-start gap-3'>
                                            <button disabled={loading} onClick={() => setCustom(b)} className="text-primary-100">Edit</button>
                                            <button disabled={loading} onClick={() => deleteCustom(b.id)} className="text-red">Delete</button>
                                        </span>
                                    </td>
                                </tr>
                            })}

                        </tbody>
                    </table>}
                </div>
            </div>
            <div className='w-[28%] sm:w-full flex flex-col gap-3 bg-primary-300 px-4 pt-3 pb-5 rounded-2xl mt-4'>
                <h1 className="text-white font-Sansation text-base sm:text-sm">{custom?.id? <span className='flex gap-3 justify-start items-center'>Edit <IoIosCloseCircleOutline onClick={removeFromEdit} className='text-lg cursor-pointer'/> </span> :'Add New'}</h1>
                <form className='flex flex-col gap-4 sm:gap-2' onSubmit={onSubmit}>
                    <Input2 placeholder={"Item title"} type={"text"} name={"custom_name"} value={custom?.custom_name} required={true} onChange={onChange} />
                    <Input2 placeholder={"Description"} type={"text"} name={"description"} value={custom?.description} required={false} onChange={onChange} />
                    <Input2 placeholder={"Percentage"} type={"number"} name={"percentage"} value={custom?.percentage} required={true} onChange={onChange} />
                    <Button2 title={"Submit"} disabled={loading} />
                </form>
            </div>
        </div>
    )
}

export default Customizations
