import React, { useContext, useEffect, useState } from 'react'
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { AdminContext } from '../state/AdminProvider';
import { BACKEND_HOST } from '../constants';
import { toast } from 'react-toastify';
import axios from 'axios';
import Input2 from './Input2';
import Button2 from './Button2';

function BoosterPopup() {
  const { boosterPopup, setBoosterPopup, setRefresh } = useContext(AdminContext)
  const [platforms, setPlatforms] = useState(null)
  const [booster, setBooster] = useState({assignedgames: [], gamePlatforms: []})
  const [games, setGames] = useState(null)
  const [loading, setLoading] = useState(null)
  const [refresh, setRefreshh] = useState(false)
  const [loaded, setLoaded] = useState(false)



  const insertGames = (_id) => {
    const isAlreadyThere = booster.assignedgames.some((b) => b == _id)
    if (!isAlreadyThere) {
      booster.assignedgames.push(_id)
    }
    else {
      booster.assignedgames = booster.assignedgames.filter(b => b != _id)
    }
    setRefreshh(r => !r)
  }

  const insertPlatform = (_id) => {
    const isAlreadyThere = booster.gamePlatforms.some((b) => b == _id)
    if (!isAlreadyThere) {
      booster.gamePlatforms.push(_id)
    }
    else {
      booster.gamePlatforms = booster.gamePlatforms.filter(b => b != _id)
    }

    setRefreshh(r => !r)
  }

  const fetchPlatforms = async () => {
    try {

      const response = await axios.get(`${BACKEND_HOST}/admin_get_plateforms`, {
        headers: {
          'Content-Type': 'Application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })

      if (response.status === 200 && response.data.status) {
        setPlatforms(response.data.data);
      }
      else
        toast.error(response.data.error ? response.data.error : response.data.message)
    } catch (error) {
      console.log(error);
    }
  }

  const onchange = (e) => {
    setBooster({...booster, [e.target.name]: e.target.value})
  }

  const fetchGames = async () => {
    try {
      const response = await axios.get(`${BACKEND_HOST}/admin_get_games`, {
        headers: {
          'Content-Type': 'Application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })

      if (response.status === 200 && response.data.status) {
        setGames(response.data.data);
      }
      else
        toast.error(response.data.error ? response.data.error : response.data.message)
    } catch (error) {
      console.log(error);
    }
  }

  const onSubmit = async() => {
    setLoading(true)
    let data = JSON.stringify({
      "user_id": boosterPopup.id,
      "order_limit": booster.order_limit,
      "order_percent": booster.order_percent,
      "game_ids":booster.assignedgames,
      "plateform_ids": booster.gamePlatforms
    });
    

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${BACKEND_HOST}/convert_user_to_booster`,
      headers: { 
        'Content-Type': 'application/json', 
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      data : data
    };

    const response = await axios.request(config)
    if(response.data.status == true && response.status === 200){
      toast.success(response.data.message)
      setBoosterPopup(null)
      setRefresh(s => !s)
    }
    else{
      toast.error(response.data.error? response.data.error : response.data.message)
    }
    setLoading(false)
  }

  const fetchBooster = async(e) => {
    try {
      const response = await fetch(`${BACKEND_HOST}/get_booster_data`, {
        method: 'Post',
        headers: {
          'Content-Type': 'Application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({booster_id: boosterPopup?.id})
      })

      const res = await response.json()
      if (response.status === 200 && res?.status) {
            for (let i = 0; i < res?.games?.length; i++) {
              booster.assignedgames.push(res?.games[i]?.games.id)
            }
            for (let i = 0; i < res?.plateform?.length; i++) {
              booster.gamePlatforms.push(res?.plateform[i].id)
            }
            booster.order_percent = res.booster[0]?.payment_percentage
            booster.order_limit = res.booster[0]?.order_limit
            setRefreshh(state => !state)
            setLoaded(state => !state)
      }
      else
        toast.error(response.data.error ? response.data.error : response.data.message)
    } catch (error) {
      console.log(error);
    }
  }

  const convertIntoCustomer = async(id) => {
    const confirm = window.confirm('Are you sure assining him as a customer?', id)
    if(!confirm)
        return;
   
    setLoading(true)
    try {
        const response = await fetch(`${BACKEND_HOST}/convert_booster_to_user`, {
            method: 'POST',
            headers: {
                'Content-Type': 'Application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({user_id: boosterPopup.id})
        })

        const res = await response.json()
        if(response.ok && res.status){
          setBoosterPopup(false)
            toast.success(res.error? res.error : res.message)
            setRefresh(s => !s)
        }
        else
            toast.error(res.error? res.error : res.message)
    } catch (error) {
        console.log(error);
    }
    setLoading(false)
  }


  useEffect(() => {
    console.log(booster, "booster");
  }, [refresh])
  
  useEffect(() => {
    fetchBooster()
    fetchPlatforms()
    fetchGames()
  }, [])
  return (
    <div className={`fixed top-0 right-0 sm:w-full w-[80%] h-screen bg-[rgba(0,0,0,0.72)] z-50 flex justify-center items-center popupopens`}>
      <div className={`admin rounded-xl px-7 sm:px-4 sm:w-[80%] sm:h-auto py-5 w-[55%] h-[440px] relative flex gap-2 flex-col transition-all ease-in duration-100`}>
        <IoIosCloseCircleOutline onClick={() => { setBoosterPopup(null) }} className='bg-red/50 text-white p-[2px] rounded-full text-[30px] absolute -top-2 right-2 cursor-pointer' />

        {loaded?
          <div className='w-full sm:w-full flex flex-col gap-3'>
          <div className='flex justify-start items-center gap-1'>
          <img src={boosterPopup.profile_image? `${BACKEND_HOST.replace('/api', '')}/${boosterPopup?.profile_image}` : require('../assets/defaultAvatar.jpg')} alt="avatar" className="w-10 h-10 rounded-full border-[1px] border-white/50 sm:w-8 sm:h-8"/>
            <h1 className="text-white font-Oswald text-2xl sm:text-lg capitalize">{boosterPopup?.username}</h1>
          </div>
          <form onSubmit={(e) =>e.preventDefault()} className='flex flex-col justify-between gap-4'>

          <div className='px-2 py-2 pb-3 glass rounded-xl flex flex-col gap-1'>
              <p className='text-lg text-white font-Oswald'>Platforms</p>
              <div className='flex flex-wrap mt-1 gap-x-4 gap-y-2'>
                {platforms?.map((p, index) => {
                  return <span className='flex justify-start items-center gap-1'>
                    <input type='checkbox' checked={booster?.gamePlatforms?.some(p_id => p_id == p.id)} onClick={() => insertPlatform(p.id)} />
                    <label className='text-sm text-white font-Sansation'>{p.plateform_name}</label>
                  </span>
                })}
              </div>
          </div>
          
          <div className='px-2 py-2 pb-3 glass rounded-xl flex flex-col gap-1'>
              <p className='text-lg text-white font-Oswald'>Games</p>
              <div className='flex flex-wrap mt-1 gap-x-4 gap-y-2'>
                {games?.map((p, index) => {
                  if(index < 9)
                  return <span className='flex justify-start items-center gap-1'>
                    <input type='checkbox' checked={booster?.assignedgames?.some(g_id => g_id == p.id)} onClick={() => insertGames(p.id)} />
                    <label className='text-sm text-white font-Sansation'>{p.title}</label>
                  </span>
                })}
              </div>
          </div>
          <div className='flex gap-3 '>
            <Input2 placeholder={"Order Percent"} type={"number"} name={"order_percent"} value={booster?.order_percent} required={true} onChange={onchange} />
            <Input2 placeholder={"Order Claim Limit"} type={"number"} name={"order_limit"} value={booster?.order_limit} required={true} onChange={onchange} />
          </div>
          
          </form>
          <div className='flex justify-between w-full gap-2'>
          <button onClick={convertIntoCustomer} disabled={loading} className='bg-red/50  hover:bg-red/75 disabled:cursor-wait disabled:bg-red/40 transition-all ease-in duration-100 rounded-md text-sm font-Sansation px-5 sm:px-3 sm:py-1 sm:text-[12px] py-2 text-white'>Customer</button>
            <Button2 title={"Booster"} disabled={loading} onClick={onSubmit}/>
          </div>
        </div>
        :
        <div className='flex justify-center items-center w-full h-full'>
          <img src={require('../assets/loading.gif')} alt='loading' className='w-8 sm:w-6'/>
        </div>}
      </div>

    </div>
  )
}

export default BoosterPopup
