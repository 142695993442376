import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { BACKEND_HOST } from '../constants';
import axios from 'axios';
import Card from '../components/Card';
import { PiPackageFill } from "react-icons/pi";

function Dashboard() {

    const [cardStats, setCardStats] = useState({ total: 0, completed: 0, pending: 0, inprogres: 0 })

    const fetchStats = async () => {
        try {
            const response = await axios.get(`${BACKEND_HOST}/admin_dashboard_statistics`, {
                headers: {
                    'Content-Type': 'Application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })

            if (response.status === 200 && response.data.status) {
                console.log(response.data);
                setCardStats({
                    total: response.data.total_order,
                    completed: response.data.completed_order,
                    pending: response.data.pending_order,
                    inprogres: response.data.ongoing_order
                })
            }
            else
                toast.error(response.data.error ? response.data.error : response.data.message)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchStats()
    }, [])
    return (
        <div className='flex flex-col gap-4'>
            <div className='grid grid-cols-4 sm:grid-cols-2 gap-4 sm:gap-2'>
                <div className='bg-primary-300/70 px-7 py-7 rounded-xl flex flex-col shadow-2xl gap-3 sm:p-4'>
                    <div className='flex justify-start items-center gap-2'>
                        <PiPackageFill className='text-white text-[35px] sm:text-3xl' />
                        <h1 className='text-white font-Oswald text-2xl sm:text-lg'>Total Orders</h1>
                    </div>
                    <p className='text-white font-Oswald text-right text-[32px] leading-[32px] sm:text-xl'>{cardStats.total.toLocaleString()}</p>
                </div>
                <div className='bg-primary-300/70 px-7 py-7 rounded-xl flex flex-col shadow-2xl gap-3 sm:p-4'>
                    <div className='flex justify-start items-center gap-2'>
                        <PiPackageFill className='text-[#8CF33A] text-[35px]  sm:text-3xl' />
                        <h1 className='text-[#8CF33A] font-Oswald text-2xl sm:text-lg'>Completed Orders</h1>
                    </div>
                    <p className='text-white font-Oswald text-right text-[32px] leading-[32px] sm:text-xl'>{cardStats.completed.toLocaleString()}</p>
                </div>
                <div className='bg-primary-300/70 px-7 py-7 rounded-xl flex flex-col shadow-2xl gap-3 sm:p-4'>
                    <div className='flex justify-start items-center gap-2'>
                        <PiPackageFill className='text-primary-100 text-[35px]  sm:text-3xl' />
                        <h1 className='text-primary-100 font-Oswald text-2xl sm:text-lg'>In-Progress</h1>
                    </div>
                    <p className='text-white font-Oswald text-right text-[32px] leading-[32px] sm:text-xl'>{cardStats.inprogres.toLocaleString()}</p>
                </div>
                <div className='bg-primary-300/70 px-7 py-7 rounded-xl flex flex-col shadow-2xl gap-3 sm:p-4'>
                    <div className='flex justify-start items-center gap-2'>
                        <PiPackageFill className='text-[#FC6D6D] text-[35px]  sm:text-3xl' />
                        <h1 className='text-[#FC6D6D] font-Oswald text-2xl sm:text-lg'>Pending Orders</h1>
                    </div>
                    <p className='text-white font-Oswald text-right text-[32px] leading-[32px] sm:text-xl'>{cardStats.pending.toLocaleString()}</p>
                </div>
            </div>

        </div>
    )
}

export default Dashboard
