import React, { useContext, useEffect } from 'react'
import AdminSidebar from '../components/AdminSidebar'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import ReviewsList from '../pages/ReviewsList'
import AdminNavbar from '../components/AdminNavbar'
import BlogList from '../pages/BlogList'
import AddBlog from '../components/AddBlog'
import PromoCode from '../pages/PromoCode'
import Users from '../pages/Users'
import Games from '../components/Games'
import GameMangement from '../pages/GameMangement'
import EditGame from '../pages/EditGame'
import AddService from '../components/AddService'
import FAQs from '../pages/FAQs'
import { AdminContext } from '../state/AdminProvider'
import EditDropdownPopup from '../components/EditDropdownPopup'
import AddRankPopup from '../components/AddRankPopup'
import BoosterPopup from '../components/BoosterPopup'
import AdminOrders from '../pages/AdminOrders'
import OrderDetail from '../components/OrderDetail'
import Dashboard from '../pages/Dashboard'
import LevelPopup from '../components/LevelPopup'
import OrderRequst from '../pages/OrderRequst'
import CustomOrder from '../components/CustomOrder'

function Admin() {

  const { dropdownPopup, rankPopup, boosterPopup, levelPopup, customOrderPopup } = useContext(AdminContext)

  return (
    <div className='flex justify-start items-start'>
      <div className='w-[20%] bg-primary-300 h-screen sm:hidden'>
        <AdminSidebar />
      </div>
      <div className='w-[80%] relative overflow-hidden sm:w-full'>
        <div className='h-screen admin px-10 py-4 sm:w-full sm:px-4 sm:pr-2 flex flex-col gap-5 overflow-scroll '>
          <AdminNavbar />
          <Routes>
            <Route index element={<Navigate to='dashboard' />} />

            <Route path='dashboard' element={<Dashboard />} />
            <Route path='reviews' element={<ReviewsList />} />

            <Route path='blogs' element={<Outlet />}>
              <Route index element={<BlogList />} />
              <Route path='add' element={<AddBlog />} />
              <Route path='edit/:id' element={<AddBlog />} />
            </Route>

            <Route path="discount-codes" element={<PromoCode />} />
            <Route path="users" element={<Users />} />
            <Route path="faqs" element={<FAQs />} />

            <Route path="games-management" element={<Outlet />}>
              <Route index element={<GameMangement />} />
              <Route path='edit/:id' element={<Outlet />}>
                <Route index element={<EditGame />} />
                <Route path='add-service' element={<AddService />} />
                <Route path='edit-service/:service_id' element={<AddService />} />
              </Route>
            </Route>

            <Route path='orders' element={<Outlet />}>
              <Route index element={<AdminOrders />} />
              <Route path='details/:id' element={<OrderDetail />} />
              <Route path='order-request' element={<OrderRequst />} />
            </Route>

          </Routes>

        </div>

        {dropdownPopup && <EditDropdownPopup />}
        {rankPopup && <AddRankPopup />}
        {boosterPopup && <BoosterPopup />}
        {levelPopup && <LevelPopup/>}
        {customOrderPopup && <CustomOrder/>}
      </div>


    </div>
  )
}

export default Admin
