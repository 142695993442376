import React from 'react'

function Eclips({ top, blur1 }) {
  const height = `${window.innerHeight - 340}px`
  return (
    <>
    <div className={`w-full h-[747px] gradient1 absolute -left-0 z-[1] ${top} eclip`}></div>

      <div className={`w-[624px] h-[624px] bg-primary-100 blur-[137px] opacity-25 rounded-full absolute -left-44 top-[380px] z-[1] sm:w-[300px] sm:h-[300px] sm:opacity-25 sm:-left-44 sm:blur-[50px] sm:top-[600px] ${blur1}`}></div>
      <div className='w-[624px] h-[624px] bg-primary-100 blur-[137px] opacity-25 rounded-full absolute -right-44 top-[1100px] z-[1] sm:w-[300px] sm:h-[300px] sm:opacity-25 sm:-right-44 sm:blur-[50px] sm:top-[1100px]'></div>
      <div className='w-[624px] h-[624px] bg-primary-100 blur-[137px] opacity-25 rounded-full absolute -left-44 top-[1900px] z-0 sm:w-[300px] sm:h-[300px] sm:opacity-25 sm:-left-44 sm:blur-[50px] sm:top-[1600px]'></div>
      <div className='w-[624px] h-[624px] bg-primary-100 blur-[137px] opacity-25 rounded-full absolute -right-44 top-[2700px] z-0 sm:w-[300px] sm:h-[300px] sm:opacity-25 sm:-right-44 sm:blur-[50px] sm:top-[2100px]'></div>
      <div className='w-[624px] h-[624px] bg-primary-100 blur-[137px] opacity-25 rounded-full absolute -left-44 top-[3500px] z-0 sm:w-[300px] sm:h-[300px] sm:opacity-25 sm:-left-44 sm:blur-[50px] sm:top-[2600px]'></div>
      <div className='w-[624px] h-[624px] bg-primary-100 blur-[137px] opacity-25 rounded-full absolute -right-44 top-[4300px] z-0 sm:w-[300px] sm:h-[300px] sm:opacity-25 sm:-right-44 sm:blur-[50px] sm:top-[3100px]'></div>
      <div className='w-[624px] h-[624px] bg-primary-100 blur-[137px] opacity-25 rounded-full absolute -right-44 top-[5100px] z-0 sm:w-[300px] sm:h-[300px] sm:opacity-25 sm:-left-44 sm:blur-[50px] sm:top-[3600px]'></div>
    </>
  )
}

export default Eclips
