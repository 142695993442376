import React from 'react'

function Textarea({ name, type, onChange, required, placeholder, value }) {
  return (
    <div className='relative w-full px-8 pt-3 sm:px-6 sm:pt-2 rounded-[10px] overflow-hidden border-[1px] border-white'>
    <div className='bg-white sm:bg-[#44444446] sm:opacity-30 absolute top-0 left-0 opacity-10 w-full h-[1000px] rounded-[10px] blur-[4px] sm:blur-[16px] '></div>
    <textarea
      id={name}
      name={name}
      type={type}
      onChange={onChange}
      value={value || ''}
      required={required}
      placeholder={''}
      className='text-white relative font-Sansation text-lg sm:text-[12px] outline-none bg-transparent w-full mt-[5px] input pb-2 sm:pt-2 h-40 resize-none z-30 sm:leading-[22px]'
    />
    <label htmlFor={name} className='absolute text-white font-Sansation sm:text-[12px] label text-lg top-3 left-7  sm:left-5 sm:top-[10px]'>{placeholder}</label>
  </div>
  )
}

export default Textarea
