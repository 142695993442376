import React, { useContext, useEffect, useState } from 'react'
import Card from '../components/Card'
import { BACKEND_HOST } from '../constants'
import { toast } from 'react-toastify'
import axios from 'axios'
import { AdminContext } from '../state/AdminProvider'

function OrderRequst() {
    const { setCustomOrderPopup } = useContext(AdminContext)
    const [request, setRequest] = useState(null)
    const fetchrequest = async() => {
        try {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${BACKEND_HOST}/get_custom_orders`,
                headers: { }
              };
              
             const response = await axios.request(config)
            if(response.status === 200 && response.data.status){
                setRequest(response.data.order_requests);
            }
            else
                toast.error(response.data.error? response.data.error : response.data.message)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(()=>{
        fetchrequest()
    }, [])
  return (
    <Card>
       <span className='flex justify-between items-center'>
            <h1 className="text-white font-Oswald text-3xl sm:text-2xl">Order Requests</h1>
        </span>
        <div className="overflow-x-auto">
            {!request && <img src={require('../assets/loading.gif')} alt='loader' className='w-7 h-7 sm:w-5 sm:h-5'/>}
            {request?.length === 0 && <p className='text-white text-sm sm:text-[12px] font-Sansation'>No data found.</p>}
            {request?.length > 0 && <table className="min-w-full sm:min-w-[700px]">
                <thead>
                    <tr>
                        <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-1 sm:py-1 text-grey">#</th>
                        <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey">Game</th>
                        <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey">Service</th>
                        <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey whitespace-nowrap">Platform</th>
                        <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey whitespace-nowrap">Customer</th>
                        <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey whitespace-nowrap">Description</th>
                        <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {request?.map((b, index) => {
                        if(b.role !== 'admin')
                        return <tr className="bg-primary-300 border-b-[4px] border-primary-100/10" key={index}>
                                    <td className="py-2 px-4 text-left font-Sansation text-white text-sm border-b-white sm:px-1 sm:py-1 sm:text-[10px] sm:leading-[20px]">{index+1}</td>
                                    <td className="py-2 px-4 text-left font-Sansation text-white text-sm flex justify-start items-center gap-2  sm:px-2 sm:py-1 sm:text-[10px] sm:leading-[20px]">
                                        {b.game?.title}
                                    </td>
                                    <td className="py-2 px-4 text-left font-Sansation text-white text-sm sm:px-2 sm:py-1 sm:text-[10px] sm:leading-[20px]">{b.service?.service_title}</td>
                                    <td className="py-2 px-4 text-left font-Sansation text-white text-sm sm:px-2 sm:py-1 sm:text-[10px] sm:leading-[20px]">{b.plateform_name}</td>
                                    <td className="py-2 px-4 text-left font-Sansation text-white text-sm sm:px-2 sm:py-1 sm:text-[10px] sm:leading-[20px]">@{b.user?.username}</td>
                                    <td className="py-2 px-4 text-left font-Sansation text-white text-sm sm:px-2 sm:py-1 sm:text-[10px] sm:leading-[20px] min-w-[250px]">{b.description}</td>
                                    <td className="py-0 px-4 text-left font-Sansation text-white text-sm sm:px-2 sm:py-1 sm:text-[10px] sm:leading-[20px]">
                                            <button onClick={()=> setCustomOrderPopup(b)} className="text-primary-100">Create</button>
                                    </td>
                                </tr>
                    })}
                    
                </tbody>
            </table>}
        </div>
    </Card>
  )
}

export default OrderRequst
