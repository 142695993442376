import React, { useEffect, useState } from 'react'
import Eclips from '../components/Eclips'
import { useTranslation } from 'react-i18next'
import Games from '../components/Games'
import Faq from '../components/Faq'
import { BACKEND_HOST } from '../constants'
import { Link } from 'react-router-dom'
import StarRatings from 'react-star-ratings'
import { motion } from 'framer-motion'
import Button from '../components/Button'

function Home() {
    const [t,] = useTranslation("global")

    const [reviews, setReviews] = useState([])
    const [blogs, setBlogs] = useState([])
    const [faq, setFaq] = useState([])

    const getFaqs = async() => {
        try {
            const response = await fetch(`${BACKEND_HOST}/home_faqs`,{
                method:'GET',
                headers:{
                    'Content-Type':'Application/json'
                }
            })
    
            const res = await response.json()
            if(response.ok)
                setFaq(res.data)
            else
                console.log("error fetching faqs: " + res);
                console.log("error fetching faqs: " + response);
        } catch (error) {
            console.log(error);
        }
        
    }

    const getBlogs = async() => {
        try {
            const response = await fetch(`${BACKEND_HOST}/get_blogs`,{
                method:'GET',
                headers:{
                    'Content-Type':'Application/json'
                }
            })
    
            const res = await response.json()
            if(response.ok)
                setBlogs(res.data)
            else
                console.log("error fetching blog: " + res);
                console.log("error fetching blog: " + response);
        } catch (error) {
            console.log(error);
        }
       
    }
    const getReviews = async() => {
        try {
            const response = await fetch(`${BACKEND_HOST}/get_reviews`,{
                method:'GET',
                headers:{
                    'Content-Type':'Application/json'
                }
            })
    
            const res = await response.json()
            if(response.ok)
                setReviews(res.data)
            else
                console.log("error fetching getReviews: " + res);
                console.log("error fetching getReviews: " + response);
        } catch (error) {
            console.log(error);
        }
       
    }

    useEffect(()=>{
        try {
            getFaqs()
            getReviews()
            getBlogs()
            
        } catch (error) {
            
        }
    }, [])

    return (
        <motion.div initial={{width:'0%', opacity: 0}} animate={{width:'100%', opacity:1}} exit={{x:window.innerWidth, transition:{duration:1}}} className='opacity-0 w-[100%] relative overflow-hidden'>
            <div className="relative bg-cover bg-no-repeat bg-center opacity-40 sm:opacity-40 bg-[url('./assets/home.jpeg');] h-[800px] w-full -mt-14"></div>

            <div className='relative flex pl-60 pr-20 justify-start items-center mt-[0] sm:px-7 sm:flex-col sm:mt-36 '>
                <motion.div  transition={{ delay: 0.3, duration: 0.4 }} initial={{opacity:0, }} animate={{opacity:1, marginLeft:0}} className='w-[55%] -mt-[750px] z-10 flex justify-start items-start flex-col sm:w-full '>
                    <h1 className='text-white text-[55px] leading-[65px] font-Oswald font-bold sm:text-[35px] sm:leading-[45px]'>{t('home.heading1')} <span className="text-primary-100">{t('home.heading1Blue')}</span></h1>
                    <p className='text-left text-white font-Sansation text-base py-5 w-[75%] sm:text-[12px] sm:leading-[22px] sm:w-full'> {t("home.text1")}</p>

                </motion.div>
                <img src={require('../assets/home1.png')} className='absolute w-[700px] z-[0] right-[-50px] top-[-670px] sm:top-[-550px] sm:right-0 sm:w-full' />
            </div>

            <div className='flex flex-col justify-center items-center mt-[20px] z-[10] pl-56 pr-20 relative sm:px-7 sm:mt-[-100px] '>
                <h1 className='text-white text-[54px] leading-[64px] font-Oswald font-bold sm:text-[26px] sm:leading-[30px] '>{t('home.heading2')} <span className="text-primary-100">{t('home.heading2Blue')}</span></h1>
                <Games />
            </div>

            <div className='flex justify-between items-center pl-60 pr-20 mt-32 sm:px-7 sm:flex-col sm:gap-5 z-10'>
                <div className='flex justify-start items-center gap-7 sm:gap-4'>
                    <img src={require('../assets/Group 105.png')} alt='' className='h-[162.562px]' />
                    <span>
                        <h1 className='text-white text-[50px] leading-[60px] font-Oswald font-bold sm:text-[26px] sm:leading-[36px]'>{t('home.heading3Slice1')}<br /> <span className="text-primary-100">{t('home.heading3Blue')}</span> {t('home.heading3Slice2')}</h1>
                        <p className='text-left text-white font-Sansation text-sm py-5 w-[98%] sm:text-[12px] sm:leading-[22px] sm:py-2'> {t("home.text2")}</p>
                    </span>
                </div>

                <img src={require('../assets/message.png')} alt='section' className='w-[560.612px]' />
            </div>

            <div className='flex justify-between items-center pl-60 pr-20 gap-7 mt-40 sm:flex-col-reverse sm:px-0 sm:mt-28 z-10'>
                <img src={require('../assets/px.png')} alt='section' className='w-[560.612px] scale-x-[-1]' />
                <div className='sm:px-7'>
                    <h1 className='text-white text-[50px] leading-[60px] font-Oswald font-bold sm:text-[26px] sm:leading-[36px]'>{t('home.heading4Slice1')} <br className='sm:hidden'/>{t('home.heading4Slice2')} <span className="text-primary-100">{t('home.heading4Blue')}</span> </h1>
                    <p className='text-left text-white font-Sansation text-base py-5 w-[95%] sm:text-[12px] sm:leading-[22px]'> {t("home.text3")}</p>
                </div>
            </div>

            {/* reviews */}
            <div className='pl-64 pr-20 mt-40 sm:px-2 sm:mt-20 z-10'>
                <h1 className='text-white text-center -ml-4 sm:ml-0 text-[50px] leading-[60px] font-Oswald font-bold sm:text-[26px] sm:leading-[30px]'>{t('home.heading5')} <span className='text-primary-100'>{t('home.heading5')}</span></h1>
                <div className='slider w-full h-[370px] overflow-y-hidden overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide mt-7 z-10 sm:mt-7'>

                    <div className='relative h-[340px] w-[353px] inline-block mr-5 sm:h-[300px] sm:w-[300px] sm:mx-5'>
                        <div className='flex flex-col p-8 gap-5 sm:gap-6 sm:px-3 sm:py-8'>
                            <img src={require('../assets/avator.png')} alt='avator' className='w-[95px] h-[95px] sm:w-[75px] sm:h-[75px] object-cover rounded-full' />
                            <h3 className='text-xl font-Oswald font-bold text-white whitespace-normal sm:text-base'>Darlene Robertson</h3>
                            <p className='text-base font-Sansation text-white -mt-3 -mb-3 whitespace-normal sm:text-sm sm:mb-[0px]'>Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatu</p>
                            <StarRatings rating={5} starRatedColor="#FFA500" starHoverColor="#FFA500"starEmptyColor="#FFA500" starDimension="25px" numberOfStars={5} />
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="353px" height="340px" viewBox="0 0 383 368" fill="none" className='absolute top-0 left-0 sm:h-[300px] sm:w-[300px]'>
                            <path d="M0.474472 9.48798C0.474472 4.51095 4.50915 0.476254 9.48619 0.476254H191.176H249.276C250.95 0.476254 252.591 0.942724 254.016 1.82335L377.606 78.2496C380.261 79.8918 381.878 82.7918 381.878 85.9142V179.754V330.164C381.878 333.17 380.378 335.979 377.88 337.652L336.73 365.207C335.246 366.2 333.501 366.731 331.716 366.731H191.176H95.5881H9.48603C4.50899 366.731 0.474309 362.696 0.474318 357.719L0.474472 275.404V183.604V9.48798Z" stroke="white" stroke-opacity="0.3" stroke-width="0.948602" />
                        </svg>
                    </div>

                    <div className='relative h-[340px] w-[353px] inline-block mr-5 sm:h-[300px] sm:w-[300px]'>
                        <div className='flex flex-col p-8 gap-5 sm:gap-6 sm:px-3 sm:py-8'>
                            <img src={require('../assets/avator.png')} alt='avator' className='w-[95px] h-[95px] sm:w-[75px] sm:h-[75px] object-cover rounded-full' />
                            <h3 className='text-xl font-Oswald font-bold text-white whitespace-normal sm:text-base'>Darlene Robertson</h3>
                            <p className='text-base font-Sansation text-white -mt-3 -mb-3 whitespace-normal sm:text-sm sm:mb-[0px]'>Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatu</p>
                            <StarRatings rating={5} starRatedColor="#FFA500" starHoverColor="#FFA500"starEmptyColor="#ebd8b5" starDimension="25px" numberOfStars={5} />
                        </div>
                         <svg xmlns="http://www.w3.org/2000/svg" width="353px" height="340px" viewBox="0 0 383 368" fill="none" className='absolute top-0 left-0 sm:h-[300px] sm:w-[300px]'>
                            <path d="M0.474472 9.48798C0.474472 4.51095 4.50915 0.476254 9.48619 0.476254H191.176H249.276C250.95 0.476254 252.591 0.942724 254.016 1.82335L377.606 78.2496C380.261 79.8918 381.878 82.7918 381.878 85.9142V179.754V330.164C381.878 333.17 380.378 335.979 377.88 337.652L336.73 365.207C335.246 366.2 333.501 366.731 331.716 366.731H191.176H95.5881H9.48603C4.50899 366.731 0.474309 362.696 0.474318 357.719L0.474472 275.404V183.604V9.48798Z" stroke="white" stroke-opacity="0.3" stroke-width="0.948602" />
                        </svg>
                    </div>

                    <div className='relative h-[340px] w-[353px] inline-block sm:h-[300px] sm:w-[300px]'>
                        <div className='flex flex-col p-8 gap-5 sm:gap-6 sm:px-3 sm:py-8'>
                            <img src={require('../assets/avator.png')} alt='avator' className='w-[95px] h-[95px] sm:w-[75px] sm:h-[75px] object-cover rounded-full' />
                            <h3 className='text-xl font-Oswald font-bold text-white whitespace-normal sm:text-base'>Darlene Robertson</h3>
                            <p className='text-base font-Sansation text-white -mt-3 -mb-3 whitespace-normal sm:text-sm sm:mb-[0px]'>Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatu</p>
                            <StarRatings rating={5} starRatedColor="#FFA500" starHoverColor="#FFA500"starEmptyColor="#FFA500" starDimension="25px" numberOfStars={5} />
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="353px" height="340px" viewBox="0 0 383 368" fill="none" className='absolute top-0 left-0 sm:h-[300px] sm:w-[300px]'>
                            <path d="M0.474472 9.48798C0.474472 4.51095 4.50915 0.476254 9.48619 0.476254H191.176H249.276C250.95 0.476254 252.591 0.942724 254.016 1.82335L377.606 78.2496C380.261 79.8918 381.878 82.7918 381.878 85.9142V179.754V330.164C381.878 333.17 380.378 335.979 377.88 337.652L336.73 365.207C335.246 366.2 333.501 366.731 331.716 366.731H191.176H95.5881H9.48603C4.50899 366.731 0.474309 362.696 0.474318 357.719L0.474472 275.404V183.604V9.48798Z" stroke="white" stroke-opacity="0.3" stroke-width="0.948602" />
                        </svg>
                    </div>

                </div>
            </div>

            {/* blog */}
            <div className='pl-60 pr-20 mt-32 sm:px-7 sm:mt-8 z-10 flex flex-col justify-center items-center'>
                <h1 className='text-white text-center text-[50px] leading-[60px] font-Oswald font-bold sm:text-[26px] sm:leading-[30px]'>{t('home.blog')} <span className='text-primary-100'>{t('home.blogBlue')}</span></h1>
                <div className='flex justify-center items-stretch gap-5 mt-10 sm:mt-7 sm:flex-col'>
                    {blogs.map((b, index) => {
                        return <Link to={`/blogs/blog-detail/${b.id}`} className='rounded-xl overflow-hidden w-[32%] sm:w-full border-[1px] border-white/55 px-4 py-5 hover:bg-primary-300/50 transition-all ease-in duration-300 hover:scale-[0.98] scale-95'>
                                    <img src={`${BACKEND_HOST.replace('/api', '')}/${b.image}`} alt='' className='w-[100%] h-[200px] object-cover sm:h-[150px] rounded-xl'/>
                                    <h1 className='text-white text-left text-xl font-Oswald font-normal pt-2 capitalize sm:text-sm'>{b.title}</h1>
                                    <p className='text-left text-white font-Sansation text-base pt-1 sm:text-[12px] sm:leading-[22px]'>{b.description?.substr(0, 200)}</p>
                                </Link>
                    })}
                </div>
                <Link to='/blogs' className='w-[200px] mt-10'>
                    <Button title={t('home.viewmore')} />
                </Link>
            </div>


            {/* faq */}
            <div className='pl-60 pr-10 mt-40 pb-[180px] sm:pb-[70px] flex justify-center items-center flex-col gap-10 sm:gap-5 sm:px-7 sm:mt-28 z-10'>
                <h1 className='text-white text-center text-[50px] leading-[60px] font-Oswald font-bold sm:text-[24px] sm:leading-[34px]'>{t('home.faq')} <span className='text-primary-100'>{t('home.faqBlue')}</span></h1>

                <div className='flex flex-col gap-4 w-[70%] sm:gap-3 sm:w-full'>
                    {faq.map((f, index) => {
                        return <Faq ques={f.question} ans={f.answer} index={index}/>
                    } )}
                    
                </div>
            </div>



            <Eclips top={"sm:top-[450px]"}/>
            <img src={require('../assets/gameMask.png')} className='absolute top-[880px] h-[1522.597px] z-[2] right-[138px] sm:h-auto sm:w-full sm:right-0 sm:top-[800px]' />
            <img src={require('../assets/gameMask.png')} className='absolute top-[880px] h-[1522.597px] hidden sm:block z-[2] right-[138px] sm:h-auto sm:w-full sm:right-0 sm:top-[1300px]' />
            <div className='hidden sm:block bg-primary-100 rounded-full absolute z-[1] sm:w-[300px] sm:h-[300px] sm:opacity-50 sm:-right-28 sm:blur-[50px] sm:-top-28'></div>
        </motion.div>
    )
}

export default Home
