import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { MdOutlineKeyboardDoubleArrowDown } from "react-icons/md";
import eng from '../assets/4.png'
import arb from '../assets/saud.png'
import { HiOutlineBars3BottomRight } from "react-icons/hi2";
import { GiCrossMark, GiCrossedAirFlows, GiCrossedSwords } from "react-icons/gi";
import { Context } from '../state/Provider';
import i18next from 'i18next';
import { AiFillHome } from 'react-icons/ai';
import { FaUsers, FaQuestionCircle } from 'react-icons/fa';
import { TbMessage } from "react-icons/tb";
import { HiMiniEnvelope } from "react-icons/hi2";


function HeaderNav() {
    const { userLoggedIn, logOut } = useContext(Context)
    const [langDrapDown, setLangDropDown] = useState(false)
    const [drawer, setDrawer] = useState(false)
    const [selectedLang, setSelectedLang] = useState({
        img: eng,
        name: 'Eng'
    })


    const changeLang = (name) => {
        if (name == 'en') {
            setSelectedLang({
                img: eng,
                name: 'Eng'
            })
        }
        if (name == 'arb') {
            setSelectedLang({
                img: arb,
                name: 'Arab'
            })
        }
        i18next.changeLanguage(name, (err, t) => {
            if (err) return console.log('Something went wrong loading the language', err);
            // You can update your UI or perform any other actions here after switching the language
            });
    }

    return (
        <div className='absolute top-7 right-5 z-[50] sm:w-full sm:left-0 sm:right-0 sm:top-5 sm:fixed'>
            <div className='flex justify-end items-start mr-28 gap-5 sm:hidden'>
                {/* <div className='flex'>
                    <div className='rounded-lg border-[1px] border-white px-4 py-1 text-white bg-[#ffffff2b] font-Sansation text-lg flex justify-center items-center cursor-pointer'>
                        <img src={require('../assets/4.png')} alt='' className='h-7 w-7 rounded-full mr-2' />
                        <p className='mr-5'>League of Legend</p>
                        <MdOutlineKeyboardDoubleArrowDown className='text-lg text-white' />
                    </div>
                </div> */}
                <div className={`flex flex-col lang overflow-hidden`} onMouseEnter={() => setLangDropDown(true)} onMouseLeave={() => setLangDropDown(false)}>
                    <div className='rounded-lg border-[1px] border-white px-4 py-1 text-white font-Sansation bg-[#ffffff2b] text-lg flex justify-center items-center cursor-pointer'>
                        <img src={selectedLang.img} alt='' className='h-7 w-7 rounded-full mr-2' />
                        <p className='mr-5'>{selectedLang.name}</p>
                        <MdOutlineKeyboardDoubleArrowDown className='text-lg text-white' />
                    </div>
                    {langDrapDown && <div className='absolute top-8'>
                        <div onClick={() => changeLang('en')} className='mt-6 rounded-t-lg border-[1px] border-white px-4 py-1 bg-[#ffffff25] text-white font-Sansation text-lg flex justify-center items-center cursor-pointer'>
                            <img src={require('../assets/4.png')} alt='' className='h-7 w-7 rounded-full mr-2' />
                            <p className='mr-2'>English</p>
                        </div>
                        <div onClick={() => changeLang('arb')} className='rounded-b-lg border-[1px] border-t-0 bg-[#ffffff25] border-white px-4 py-1 text-white font-Sansation text-lg flex justify-center items-center cursor-pointer'>
                            <img src={require('../assets/saud.png')} alt='' className='h-7 w-7 rounded-full mr-2 bg-white' />
                            <p className='mr-4'>Arabic</p>
                        </div>

                    </div>}

                </div>
                <a onClick={() => userLoggedIn? logOut(): window.location.pathname = '/login'} className='rounded-lg border-[1px] border-white px-4 py-1 text-white font-Sansation text-lg bg-[#ffffff2b] cursor-pointer'>
                {userLoggedIn? 'Sign Out' : 'Sign in'}
                </a>
            </div>

            <div className='hidden sm:flex justify-between items-start px-5'>
                <Link to='/' className='z-50'>
                    <img src={require('../assets/logo.png')} alt='logo' className='sm:w-[75.75px] drop-shadow-[4.744px_3.795px_13.282px_#123698]' />
                </Link>

                <div className='flex justify-end items-start gap-5'>
                    <div className={`flex flex-col lang overflow-hidden`} onClick={() => setLangDropDown(state => !state)}>
                        <div className='rounded-lg border-[1px] border-white px-4 sm:px-3 py-1 text-white font-Sansation bg-[#ffffff2b] text-lg  sm:text-base flex justify-center items-center cursor-pointer'>
                            <img src={selectedLang.img} alt='' className='h-7 w-7 sm:h-5 sm:w-5 rounded-full mr-2' />
                            <p className='mr-5 '>{selectedLang.name}</p>
                            <MdOutlineKeyboardDoubleArrowDown className='text-lg sm:text-base text-white' />
                        </div>
                        {langDrapDown && <div className='absolute top-8'>
                            <div onClick={() => changeLang('en')} className='mt-6 sm:px-3 rounded-t-lg border-[1px] border-white px-4 py-1 sm:text-base bg-[#ffffff25] text-white font-Sansation text-lg flex justify-center items-center cursor-pointer'>
                                <img src={require('../assets/4.png')} alt='' className='h-7 w-7  sm:h-5 sm:w-5 rounded-full mr-2 ' />
                                <p className='mr-2'>English</p>
                            </div>
                            <div onClick={() => changeLang('arb')} className='rounded-b-lg sm:px-3 border-[1px] border-t-0 bg-[#ffffff25] border-white px-4 py-1 text-white font-Sansation sm:text-base text-lg flex justify-center items-center cursor-pointer'>
                                <img src={require('../assets/saud.png')} alt='' className='h-7 w-7  sm:h-5 sm:w-5 rounded-full mr-2 bg-white' />
                                <p className='mr-4'>Arabic</p>
                            </div>

                        </div>}

                    </div>
                    {!drawer && <HiOutlineBars3BottomRight className='text-white text-[40px] ' onClick={() => setDrawer(true)} />}
                    {drawer && <GiCrossedSwords className='text-white text-[40px] m-0 z-50' onClick={() => setDrawer(false)} />}
                    <div className={`absolute w-full bg-primary-300 right-0 ${!drawer ? '-top-[1000px]' : '-top-5'} h-screen transition-all ease-in duration-300 z-0 flex justify-center items-center`}>
                        <nav className='flex flex-col justify-center items-center gap-5' onClick={() => setDrawer(false)}>
                            <Link to='/' className='font-Sansation text-white flex gap-4 justify-center items-center text-xl sidenav leading-0 transition-all ease-in duration-200'><AiFillHome className='text-[25px]' /> Home</Link>
                            <Link to='/join-us' className='font-Sansation text-white flex gap-4 justify-center items-center text-xl sidenav leading-0  transition-all ease-in duration-200 whitespace-nowrap'><FaUsers className='text-[25px]'/> Work with us</Link>
                            <Link to='/contact' className='font-Sansation text-white flex gap-4 justify-center items-center text-xl sidenav leading-0 transition-all ease-in duration-200'><TbMessage className='text-[25px]' />  Contact Us</Link>
                            {userLoggedIn && <Link to='/dashboard' className='font-Sansation text-white flex gap-4 justify-center items-center text-xl leading-0 transition-all ease-in duration-200'><HiMiniEnvelope className='text-[25px]' />Dashboard</Link>}
                            <Link to='/info' className='font-Sansation text-white flex gap-4 justify-center items-center text-xl sidenav leading-0 transition-all ease-in duration-200'><FaQuestionCircle className='text-[25px]' />Info</Link>
                            <a onClick={()=> userLoggedIn? logOut(): window.location.pathname = '/login'} className='font-Sansation rounded-lg border-[1px] border-white px-4 py-1 text-white flex gap-4 justify-center items-center text-xl sidenav leading-0 transition-all ease-in duration-200'>{userLoggedIn? 'Sign Out' : 'Sign in'}</a>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderNav
