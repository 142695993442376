import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import Eclips from '../components/Eclips'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { BACKEND_HOST } from '../constants'
import { FaCalendar } from 'react-icons/fa'

function BlodDetail() {

    const [t] = useTranslation("global")
    const [blog, setblog] = useState(null)

    const { id } = useParams()

    
    const getblog = async() => {
        try {
            const response = await fetch(`${BACKEND_HOST}/get_blog_details`,{
                method:'POST',
                headers:{
                    'Content-Type':'Application/json'
                },
                body: JSON.stringify({blog_id: id})
            })
    
            const res = await response.json()
            if(response.ok)
                setblog(res.data[0])
            else
                console.log("error fetching blog: " + res);
                console.log("error fetching blog: " + response);
        } catch (error) {
            console.log(error);
        }
       
    }

    useEffect(()=>{
        getblog()
    }, [])
  return (
    <motion.div   initial={{width:'0%', opacity: 0}} animate={{width:'100%', opacity:1}} exit={{x:window.innerWidth, transition:{duration:1}}} className='opacity-0 flex flex-col relative  gap-[80px] overflow-hidden sm:gap-0'>
    <div className="relative bg-cover bg-no-repeat bg-center sm:bg-left sm:opacity-30 sm:w-full sm:h-screen opacity-30 bg-[url('./assets/boostingbg.png');] h-screen w-full -my-6 sm:my-0">    
    </div>
    {!blog && 
        <div className='flex justify-start items-center gap-5 pl-52 pr-20 flex-col sm:px-7 -mt-[600px] sm:-mt-[520px] h-[700px] z-10'>
            <img src={require('../assets/loading.gif')} alt='loader' className='w-16 h-16 z-10 sm:w-7 sm:h-7'/>
            <p className='text-left text-white/100 font-Oswald text-lg sm:text-base'>Please wait!</p>
        </div>   
            }
    {blog &&<div className='pl-52 sm:px-7 -mt-[600px] sm:-mt-[520px] z-10  min-h-screen pr-20' >
        <h1 className='text-white text-[54px] leading-[64px] font-Oswald font-bold sm:text-[26px] sm:leading-[30px] mb-5 sm:mb-3'>{blog.title}</h1>
        {blog?.description.split(/\n/g).map((line, index)=>{
                
                   return <div key={index}>
                            {index === 2 &&  <img key={index} src={`${BACKEND_HOST.replace('/api', '')}/${blog.image}`} alt='' className='w-[100%] object-cover mb-10 rounded-xl sm:mb-4 sm:mt-2'/>}
                             <p className='text-left text-white font-Sansation text-lg py-2 sm:text-[12px] sm:leading-[22px] sm:w-full w-[100%] sm:py-1'>{line}</p>
                         </div>
                })}
       
    </div>}

   {blog && <div className='flex justify-end items-center gap-10 pr-20 mb-52 sm:px-7 sm:mt-5 sm:mb-28'>
            <p className='text-left text-white/75 font-Sansation text-sm sm:text-[10px]'>Posted On: {blog?.created_at?.substr(0, 10)}</p>
    </div>}


    <Eclips />
    </motion.div>
  )
}

export default BlodDetail
