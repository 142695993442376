import React, { useEffect, useState } from 'react'
import Eclips from '../components/Eclips'
import { useTranslation } from 'react-i18next'
import { GiCursedStar } from "react-icons/gi";
import { Link, useParams } from 'react-router-dom';
import Button from '../components/Button';
import { BACKEND_HOST } from '../constants';
import axios from 'axios';
import { toast } from 'react-toastify';
import StarRatings from 'react-star-ratings';
import { FaArrowLeft, FaArrowRight, FaPlaystation, FaXbox } from 'react-icons/fa';
import { DiWindows } from 'react-icons/di';
import { BsNintendoSwitch } from 'react-icons/bs';
import { CiMobile1 } from 'react-icons/ci';
import Faq from '../components/Faq';

function Booster() {
    const [t] = useTranslation("global")

    const { id } = useParams()

    const [booster, setBooster] = useState(null)

    const fetchBooster = async () => {
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${BACKEND_HOST}/get_booster_data`,
            headers: {
                "Content-Type": "Application/json"
            },
            data: JSON.stringify({ booster_id: id })
        };

        const res = await axios.request(config)
        if (res.data.status == true) {
            setBooster(res.data)
        }
        else
            toast.error(res.data?.message)
    }

    const slideLeft = () => {
        var slider = document.getElementById('slider');
        slider.scrollLeft = slider.scrollLeft - 500;
    };

    const slideRight = () => {
        var slider = document.getElementById('slider');
        slider.scrollLeft = slider.scrollLeft + 500;
    };


    useEffect(() => {
        fetchBooster()
    }, [id])
    return (
        <div className='flex flex-col relative gap-[100px] overflow-hidden sm:gap-0 pb-[100px]  max-h-max'>
            {booster && <>
                <div className='game-rank-border overflow-hidden ml-60 mr-20 sm:mx-7 mt-40 z-20 relative'>
                    <div className='relative flex flex-col py-8 px-10 gap-9' style={{ background: "linear-gradient(109deg, #239ED6 3.05%, #0776AE 11.09%, #005394 34.57%, #003360 77.68%, #00417F 126.67%)" }}>

                        <div className='flex justify-start items-center gap-3'>
                            <img src={`${BACKEND_HOST.replace('/api', '')}/${booster.booster[0]?.profile_image}`} onError={e => e.target.src = require('../assets/defaultAvatar.jpg')} className='w-[143px] h-[143px] object-cover rounded-full border-[1px] border-primary-300/55' />
                            <div className='flex flex-col gap-2'>
                                <h1 className='text-white font-Oswald font-bold text-3xl capitalize'>{booster?.booster[0]?.first_name}</h1>
                                <p className='text-white font-Sansation font-normal text-lg lowercase'>@{booster?.booster[0]?.username}</p>
                            </div>
                        </div>

                        <div className='flex flex-col gap-2 z-20 relative'>
                            <h1 className='text-white font-Oswald font-bold text-3xl capitalize'>{t("boosterpage.about")}</h1>
                            <p className='text-white font-Sansation font-normal text-lg lowercase'>{booster?.booster[0]?.about}</p>
                        </div>

                        <div className='flex justify-start items-stretch gap-28'>

                            <div className='flex gap-4 flex-col'>
                                <h1 className='text-white font-Oswald font-bold text-3xl capitalize'>{t("boosterpage.lang")}</h1>
                                <div className='glass p-4 rounded-xl flex flex-col gap-2 w-[200px] pb-6'>
                                    <p className='text-white font-Sansation font-normal text-xl capitalize'>English</p>
                                    <p className='text-white font-Sansation font-normal text-xl capitalize'>Arabic</p>
                                </div>
                            </div>
                            <div className='flex gap-4 flex-col'>
                                <h1 className='text-white font-Oswald font-bold text-3xl capitalize'>{t("boosterpage.Reviews")}</h1>
                                <div className='glass p-4 rounded-xl w-[200px]'>
                                    <div className='flex items-center gap-4'>
                                        <GiCursedStar className='text-[32px] text-white' />
                                        <p className='text-primary-100 font-Sansation font-bold text-[30px] capitalize'>4.5</p>
                                    </div>
                                    <p className='text-white font-Sansation font-normal text-xl capitalize'>{t("boosterpage.all")}</p>
                                </div>
                            </div>

                        </div>

                        <div className='flex justify-between items-end'>
                            <div className='flex gap-2 z-20 relative'>
                                {booster?.plateform?.map((p, index) => {
                                    return <>
                                        {p.plateform_name.startsWith('PC') && <DiWindows className='text-[40px] text-white' />}
                                        {p?.plateform_name.startsWith('Play') && <FaPlaystation className='text-[40px]  text-white' />}
                                        {p?.plateform_name.startsWith('Xbox') && <FaXbox className='text-[34px]  text-white' />}
                                        {p?.plateform_name.startsWith('Nintendo') && <BsNintendoSwitch className='text-[36px] text-white' />}
                                        {p?.plateform_name.startsWith('Mobile') && <CiMobile1 className='text-[40px]  text-white' />}

                                    </>
                                })}
                            </div>
                            <div className='flex gap-2 w-[50%]'>
                                <Link className='flex-1'>
                                    <Button title={t('boosterpage.Buy')} />
                                </Link>
                                <Link className='flex-1 hue-rotate-15'>
                                    <Button title={t('boosterpage.req')} />
                                </Link>
                            </div>
                        </div>

                        <svg xmlns="http://www.w3.org/2000/svg" width="256" height="152" viewBox="0 0 256 152" fill="none" className='absolute right-14 -top-6 z-[0] sm:-top-16 sm:right-52  sm:w-[150px]'>
                            <g opacity="0.5" filter="url(#filter0_f_215_126)">
                                <ellipse cx="128.188" cy="38.3958" rx="80.1875" ry="65.3958" fill="#24B0FF" />
                            </g>
                            <defs>
                                <filter id="filter0_f_215_126" x="0.709908" y="-74.2901" width="254.955" height="225.372" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                    <feGaussianBlur stdDeviation="23.645" result="effect1_foregroundBlur_215_126" />
                                </filter>
                            </defs>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="256" height="152" viewBox="0 0 256 152" fill="none" className='absolute -right-14 rotate-90 bottom-20 z-[0]   sm:w-[150px]'>
                            <g opacity="0.5" filter="url(#filter0_f_215_126)">
                                <ellipse cx="128.188" cy="38.3958" rx="80.1875" ry="65.3958" fill="#24B0FF" />
                            </g>
                            <defs>
                                <filter id="filter0_f_215_126" x="0.709908" y="-74.2901" width="254.955" height="225.372" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                    <feGaussianBlur stdDeviation="23.645" result="effect1_foregroundBlur_215_126" />
                                </filter>
                            </defs>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="256" height="152" viewBox="0 0 256 152" fill="none" className='absolute left-40 rotate-180 -bottom-6 z-[0] sm:w-[150px]'>
                            <g opacity="0.5" filter="url(#filter0_f_215_126)">
                                <ellipse cx="128.188" cy="38.3958" rx="80.1875" ry="65.3958" fill="#24B0FF" />
                            </g>
                            <defs>
                                <filter id="filter0_f_215_126" x="0.709908" y="-74.2901" width="254.955" height="225.372" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                    <feGaussianBlur stdDeviation="23.645" result="effect1_foregroundBlur_215_126" />
                                </filter>
                            </defs>
                        </svg>
                        <div className='bg-primary-100/75 rounded-full h-[200px] w-[300px] absolute top-60 blur-3xl opacity-50 right-44 -z-[0]'></div>
                    </div>
                </div>

                {booster?.games?.length > 0 && 
                <div className='flex justify-between items-start gap-5 ml-60 mr-20 z-20 relative'>
                    <div className='flex flex-col gap-6 w-[70%]'>
                        <h1 className='text-white font-Oswald font-bold text-3xl capitalize'>{t("boosterpage.games")}</h1>
                        <div className='game-rank-border overflow-hidden'>
                            <div className='relative flex flex-col py-8 px-10 gap-9' style={{ background: "linear-gradient(109deg, #239ED6 3.05%, #0776AE 11.09%, #005394 34.57%, #003360 77.68%, #00417F 126.67%)" }}>
                                <div className='flex justify-between items-center'>
                                    <div className='flex flex-col w-[50%]'>
                                        <span className='flex items-center'>
                                            <h1 className='text-white text-3xl font-Oswald'>{booster.games[0]?.games.title}</h1>
                                        </span>
                                        <div className='flex gap-2 justify-start items-center mt-5 z-20 relative'>
                                                <img className='w-[120px]' src={`${BACKEND_HOST.replace('/api', '')}/${booster.games[0]?.games?.services[0]?.rank?.ranks[booster.games[0]?.games?.services[0]?.rank?.ranks?.length-1]?.rank_icon}`}/>
                                                <h1 className='text-white text-2xl font-Oswald'>{booster.games[0]?.games?.services[0]?.rank?.ranks[booster.games[0]?.games?.services[0]?.rank?.ranks?.length-1]?.rank_name}</h1>
                                        </div>
                                    </div>
                                    <div className='w-[2px] h-full bg-white z-20 relative'></div>
                                    <div className='flex flex-col w-[50%]'>
                                        <span className='flex items-center'>
                                            <h1 className='text-white text-2xl font-Oswald'>{booster.games[1]?.games.title}</h1>
                                        </span>
                                        <div className='flex gap-2 justify-start items-center mt-5 z-20 relative'>
                                                <img className='w-[120px]' src={`${BACKEND_HOST.replace('/api', '')}/${booster.games[1]?.games?.services[0]?.rank?.ranks[booster.games[0]?.games?.services[0]?.rank?.ranks?.length-1]?.rank_icon}`}/>
                                                <h1 className='text-white text-2xl font-Oswald'>{booster.games[1]?.games?.services[0]?.rank?.ranks[booster.games[0]?.games?.services[0]?.rank?.ranks?.length-1]?.rank_name}</h1>
                                        </div>
                                    </div>
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="256" height="152" viewBox="0 0 256 152" fill="none" className='absolute right-14 -top-6 z-[0] sm:-top-16 sm:right-52  sm:w-[150px]'>
                                    <g opacity="0.5" filter="url(#filter0_f_215_126)">
                                        <ellipse cx="128.188" cy="38.3958" rx="80.1875" ry="65.3958" fill="#24B0FF" />
                                    </g>
                                    <defs>
                                        <filter id="filter0_f_215_126" x="0.709908" y="-74.2901" width="254.955" height="225.372" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                            <feGaussianBlur stdDeviation="23.645" result="effect1_foregroundBlur_215_126" />
                                        </filter>
                                    </defs>
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" width="256" height="152" viewBox="0 0 256 152" fill="none" className='absolute -right-14 rotate-90 bottom-20 z-[0]   sm:w-[150px]'>
                                    <g opacity="0.5" filter="url(#filter0_f_215_126)">
                                        <ellipse cx="128.188" cy="38.3958" rx="80.1875" ry="65.3958" fill="#24B0FF" />
                                    </g>
                                    <defs>
                                        <filter id="filter0_f_215_126" x="0.709908" y="-74.2901" width="254.955" height="225.372" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                            <feGaussianBlur stdDeviation="23.645" result="effect1_foregroundBlur_215_126" />
                                        </filter>
                                    </defs>
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" width="256" height="152" viewBox="0 0 256 152" fill="none" className='absolute left-40 rotate-180 -bottom-6 z-[0] sm:w-[150px]'>
                                    <g opacity="0.5" filter="url(#filter0_f_215_126)">
                                        <ellipse cx="128.188" cy="38.3958" rx="80.1875" ry="65.3958" fill="#24B0FF" />
                                    </g>
                                    <defs>
                                        <filter id="filter0_f_215_126" x="0.709908" y="-74.2901" width="254.955" height="225.372" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                            <feGaussianBlur stdDeviation="23.645" result="effect1_foregroundBlur_215_126" />
                                        </filter>
                                    </defs>
                                </svg>
                            </div>
                        </div>
                    </div>
                    {JSON.parse(booster?.booster[0]?.achievements || "[]").length > 0 &&
                    <div className='flex flex-col gap-6 w-[30%]'>
                        <h1 className='text-white font-Oswald font-bold text-3xl capitalize'>{t("boosterpage.ache")}</h1>
                        <div className='glass px-7 py-2 rounded-2xl min-h-0 max-h-[300px] overflow-y-scroll'>
                            {JSON.parse(booster?.booster[0]?.achievements || "[]").map((ac, index) => {
                                return <div className='flex flex-col'>
                                    <div className='flex justify-start items-center gap-5 py-3'>
                                        <img src={require('../assets/tro.png')} alt='' className='w-10' />
                                        <h2 className='text-white text-xl font-Oswald leading-4' >{ac.text}</h2>
                                    </div>
                                    {index < JSON.parse(booster?.booster[0]?.achievements || "[]").length - 1 &&
                                        <svg xmlns="http://www.w3.org/2000/svg" width="306" height="2" viewBox="0 0 306 2" fill="none" className='w-full'>
                                            <path opacity="0.8" d="M0 1L306 0.999973" stroke="url(#paint0_linear_215_159)" />
                                            <defs>
                                                <linearGradient id="paint0_linear_215_159" x1="0" y1="1" x2="306" y2="1.00003" gradientUnits="userSpaceOnUse">
                                                    <stop stop-color="white" stop-opacity="0" />
                                                    <stop offset="0.49" stop-color="white" />
                                                    <stop offset="1" stop-color="white" stop-opacity="0" />
                                                </linearGradient>
                                            </defs>
                                        </svg>}
                                </div>
                            })}
                        </div>
                    </div>}
                </div>}

                {booster?.reviews?.length > 0 &&
                <div className='flex flex-col gap-6 ml-60'>
                    <h1 className='text-white font-Oswald font-bold text-3xl capitalize'>{t("boosterpage.testim")}</h1>
                    <div id='slider' className='slider w-full h-full overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide z-10 sm:mt-3'>
                        {booster?.reviews.map((r, index) => {
                            return <div className='rounded-[20px] border-white border-[1px] glass w-[367px] sm:w-[250px] px-7 py-7 inline-block whitespace-normal mr-[19px]'>
                                <div className='flex gap-4 justify-start items-center'>
                                    <img src={`${BACKEND_HOST.replace('/api', '')}/${r.user?.profile_image}`} onError={e => e.target.src = require('../assets/defaultAvatar.jpg')} className='w-[70px] h-[70px] object-cover rounded-full border-[1px] border-primary-300/55' />
                                    <h1 className='text-white font-Oswald font-bold text-2xl lowercase'>@{r.user?.username}</h1>
                                </div>
                                <div className='flex justify-between items-center mt-4'>
                                    <h1 className='text-white font-Sansation text-base'>Speed Rating:</h1>
                                    <StarRatings rating={r.speed_rating} starRatedColor="#FFA500" starHoverColor="#FFA500" starEmptyColor="#ebd8b5" starDimension="22px" numberOfStars={5} />
                                </div>
                                <div className='flex justify-between items-center mt-2'>
                                    <h1 className='text-white font-Sansation text-base'>Communication Rating:</h1>
                                    <StarRatings rating={r.communication_rating} starRatedColor="#FFA500" starHoverColor="#FFA500" starEmptyColor="#ebd8b5" starDimension="22px" numberOfStars={5} />
                                </div>
                                <p className='text-white font-Sansation text-base mt-4'>
                                    {r.description}
                                </p>
                            </div>

                        })}
                    </div>
                    <span className='flex justify-start items-center gap-2 mr-20 z-20 sm:hidden'>
                        <FaArrowLeft className='flex justify-center items-center border-[1px] border-primary-100 p-3 text-white cursor-pointer rounded-full text-[45px] hover:bg-primary-100 transition-all ease-in duration-150' onClick={slideLeft} />
                        <FaArrowRight className='flex justify-center items-center border-[1px] border-primary-100 p-3 bg-primary-100 text-white cursor-pointer rounded-full text-[45px]' onClick={slideRight} />
                    </span>
                </div>}
            </>}



           
            <Eclips />
        </div>
    )
}

export default Booster
