import React, { useEffect, useState } from 'react'
import Card from '../components/Card'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { BACKEND_HOST } from '../constants'
import axios from 'axios'
import Input2 from '../components/Input2'
import AddPromocode from '../components/AddPromocode'
import Button2 from '../components/Button2'

function PromoCode() {
    const [codes, setCodes] = useState(null)
    const [loading, setLoading] = useState(false) 
    const [popup, setPopup] = useState(false) 

    const fetchCodes = async() => {
        try {
            const response = await axios.get(`${BACKEND_HOST}/admin_get_promo_code`, {
                headers: {
                    'Content-Type': 'Application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })
    
            console.log(response);
            if(response.status === 200 && response.data.status){
                console.log(response.data.data);
                setCodes(response.data.data);
            }
            else
                toast.error(response.data.error? response.data.error : response.data.message)
        } catch (error) {
            console.log(error);
        }
    }

    const deleteCode = async(id) => {
        const confirm = window.confirm('Are you sure deleting this?', id)
        if(!confirm)
            return;
       
        setLoading(true)
        try {
            const response = await fetch(`${BACKEND_HOST}/admin_delete_promo_code`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'Application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({promo_code_id: id})
            })
    
            const res = await response.json()
            if(response.ok && res.status){
                setCodes(null)
                fetchCodes()
                toast.success(res.error? res.error : res.message)
            }
            else
                toast.error(res.error? res.error : res.message)
        } catch (error) {
            console.log(error);
        }
        setLoading(false)
    }

    const deactivate = async(id) => {
        const confirm = window.confirm('Are you sure deactivating this?', id)
        if(!confirm)
            return;
       
        setLoading(true)
        try {
            const response = await fetch(`${BACKEND_HOST}/admin_close_promo_code`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'Application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({promo_code_id: id})
            })
    
            const res = await response.json()
            if(response.ok && res.status){
                setCodes(null)
                fetchCodes()
                toast.success(res.error? res.error : res.message)
            }
            else
                toast.error(res.error? res.error : res.message)
        } catch (error) {
            console.log(error);
        }
        setLoading(false)
    }

    const activate = async(id) => {
        const confirm = window.confirm('Are you sure activating this?', id)
        if(!confirm)
            return;
       
        setLoading(true)
        try {
            const response = await fetch(`${BACKEND_HOST}/admin_open_promo_code`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'Application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({promo_code_id: id})
            })
    
            const res = await response.json()
            if(response.ok && res.status){
                setCodes(null)
                fetchCodes()
                toast.success(res.error? res.error : res.message)
            }
            else
                toast.error(res.error? res.error : res.message)
        } catch (error) {
            console.log(error);
        }
        setLoading(false)
    }

    

    useEffect(()=>{
        fetchCodes()
    }, [])
  return (
    <div className='flex justify-between items-start gap-6 sm:flex-col-reverse'>
        <div className='w-[100%] sm:w-full'>
            <Card>
                <span className='flex justify-between items-center'>
                        <h1 className="text-white font-Oswald text-3xl sm:text-2xl">Discount Codes</h1>
                        <Button2 title={"Add New"} onClick={()=> setPopup(true)}/>
                </span>
                <div className="overflow-x-auto">
                    {!codes && <img src={require('../assets/loading.gif')} alt='loader' className='w-7 h-7 sm:h-5 sm:w-5'/>}
                    {codes?.length === 0 && <p className='text-white text-sm sm:text-[12px] font-Sansation'>No data found.</p>}
                    {codes?.length > 0 && <table className="min-w-full sm:min-w-[700px]">
                        <thead>
                            <tr>
                                <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-1 sm:py-1 text-grey">#</th>
                                <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey">Code</th>
                                <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey">Percent</th>
                                <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey whitespace-nowrap">Start Date</th>
                                <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey whitespace-nowrap">End Date</th>
                                <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey">Status</th>
                                <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey whitespace-nowrap">Users</th>
                                <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey whitespace-nowrap">Orders</th>
                                <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey whitespace-nowrap">Total Available</th>
                                <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {codes?.map((b, index) => {
                                return <tr className="bg-primary-300 border-b-[4px] border-primary-100/10" key={index}>
                                            <td className="py-2 px-4 text-left font-Sansation text-white text-sm border-b-white sm:px-1 sm:py-1 sm:text-[10px] sm:leading-[20px]">{index+1}</td>
                                            <td className="py-2 px-4 text-left font-Sansation text-white text-sm border-b-white sm:px-1 sm:py-1 sm:text-[10px] sm:leading-[20px]">{b.promo_code}</td>
                                            <td className="py-2 px-4 text-left font-Sansation text-white text-sm border-b-white sm:px-1 sm:py-1 sm:text-[10px] sm:leading-[20px]">{b.percentage}%</td>
                                            <td className="py-2 px-4 text-left font-Sansation text-white text-sm border-b-white sm:px-1 sm:py-1 sm:text-[10px] sm:leading-[20px]">{b.start_date}</td>
                                            <td className="py-2 px-4 text-left font-Sansation text-white text-sm border-b-white sm:px-1 sm:py-1 sm:text-[10px] sm:leading-[20px]">{b.end_date}</td>
                                            <td className="py-2 px-4 text-left font-Sansation text-white text-sm border-b-white sm:px-1 sm:py-1 sm:text-[10px] sm:leading-[20px]">{b.status == 0 ? 'Active': 'Deactive'}</td>
                                            <td className="py-2 px-4 text-left font-Sansation text-white text-sm border-b-white sm:px-1 sm:py-1 sm:text-[10px] sm:leading-[20px]">{b.applied_to? b.applied_to : 0}</td>
                                            <td className="py-2 px-4 text-left font-Sansation text-white text-sm border-b-white sm:px-1 sm:py-1 sm:text-[10px] sm:leading-[20px]">{b.total_orders? b.total_orders : 0}</td>
                                            <td className="py-2 px-4 text-left font-Sansation text-white text-sm sm:px-2 sm:py-1 sm:text-[10px] sm:leading-[20px]">{b.total_availables? b.total_availables : 0}</td>
                                            <td className="py-0 px-4 text-left font-Sansation text-white text-sm sm:px-2 sm:py-1 sm:text-[10px] sm:leading-[20px]">
                                                <span className='flex justify-end items-start gap-3'>
                                                <button onClick={()=> {b.status === 0 ? deactivate(b.id) : activate(b.id)} } className="text-primary-100">{b.status === 0 ? 'Deactivate': 'Activate'}</button>
                                                <button disabled={loading} onClick={()=>deleteCode(b.id)} className="text-red">Delete</button>
                                                </span>
                                            </td>
                                        </tr>
                            })}
                            
                        </tbody>
                    </table>}
                </div>
            </Card>
        </div>
        <AddPromocode fetchCodes={fetchCodes} setCodes={setCodes} popup={popup} setPopup={setPopup}/>
    </div>
   
  )
}

export default PromoCode
