import React from 'react'
import { useTranslation } from 'react-i18next'

function BoosterRules() {
    const [t] = useTranslation('global')
  return (
    <div className='flex flex-col px-7 py-7  glass rounded-3xl gap-5'>
      <h1 className="text-white font-Oswald text-3xl sm:text-2xl text-left mb-0">{t('userDashboard.booster_rules')}</h1>
      <p className='text-left text-white font-Sansation text-lg py-5 sm:text-[12px] sm:leading-[22px] sm:w-full'>Looking to dominate the competition and rise to the top? Our Rocket League Rank Boost Services will help you achieve your desired rank and become a force to be reckoned with. Don't settle for mediocrity - unleash your true potential and conquer the field of Rocket League!</p>
    </div>
  )
}

export default BoosterRules
