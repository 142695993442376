import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { BACKEND_HOST } from '../constants'
import axios from 'axios'
import { toast } from 'react-toastify'
import { IoIosCloseCircleOutline } from 'react-icons/io'
import { AdminContext } from '../state/AdminProvider'
import Input2 from './Input2'
import Button2 from './Button2'

function LevelPopup({ }) {
    const { levelPopup, setLevelPopup, setRefresh } = useContext(AdminContext)
    const [level, setLevel] = useState(levelPopup?.level)
    const [loading, setLoading] = useState(null)


    const onChange = (e) => {
        setLevel({ ...level, [e.target.name]: e.target.value })
    }

    const addNew = async () => {
        let data = JSON.stringify({
            'rank_id': levelPopup.rank_id,
            "price": level.price,
            "name": level.name,
            "time": level.time
        })

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${BACKEND_HOST}/admin_add_rank_levels`,
            headers: {
                'Content-Type': 'Application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            data: data
        };
        try {
            const response = await axios.request(config)
            if (response.status === 200 && response.data.status == true) {
                toast.success(response.data.message)
                setLevel(response.data.data[0])
            }
            else
                toast.error(response.data.error ? response.data.error : response.data.message)
        } catch (error) {
            console.log(error);
        }
    }

    const update = async () => {
        let data = JSON.stringify({
            "level_id": level.id,
            'rank_id': levelPopup.rank_id,
            "price": level.price,
            "name": level.name,
            "time": level.time
        })

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${BACKEND_HOST}/admin_update_rank_levels`,
            headers: {
                'Content-Type': 'Application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            data: data
        };
        try {
            const response = await axios.request(config)
            if (response.status === 200 && response.data.status == true) {
                toast.success(response.data.message)
            }
            else
                toast.error(response.data.error ? response.data.error : response.data.message)
        } catch (error) {
            console.log(error);
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        if (level?.id)
            await update()
        else
            await addNew()
        setRefresh(state => !state)
        setLoading(false)
    }
    return (
        <div className={`fixed top-0 sm:w-full right-0 w-[80%] h-screen bg-[rgba(0,0,0,0.72)] z-[100] flex justify-center items-center popupopens`}>
            <div className={`admin sm:px-4 sm:w-[90%] rounded-xl px-7 py-5 w-[55%] ${level?.id? 'h-[370px]': 'h-[200px]'} relative flex gap-2 flex-col transition-all ease-in duration-100`}>
                <IoIosCloseCircleOutline onClick={() => setLevelPopup(false)} className='bg-red/50 text-white p-[2px] rounded-full text-[30px] absolute -top-2 right-2 cursor-pointer' />
                <h1 className='text-white font-Oswald text-xl'>{level?.id ? 'Edit Level' : 'Add Level'}</h1>
                <form onSubmit={onSubmit} className='flex flex-col w-full gap-2 mt-2'>
                    <div className='flex gap-2 w-full'>
                        <Input2 type='text' id='lname'  value={level?.name} required placeholder={"Name"} name='name' onChange={onChange} />
                        <Input2 type='number' step="any" id='lprice' value={level?.price} required placeholder={"Price"} name='price' onChange={onChange} />
                        <Input2 type='number' step="any" id='ltime' value={level?.time} required placeholder={"Time (hours)"} name='time' onChange={onChange} />
                    </div>
                    <button disabled={loading} type='submit' className='bg-primary-100/75 text-white text-sm rounded-lg w-[25%] py-2 disabled:opacity-65 disabled:cursor-wait hover:bg-primary-100 transition-all ease-in duration-100'>{level?.id ? 'Update' : 'Add'}</button>
                </form>
                
                {level?.id && <>
                    <h1 className='text-white font-Oswald text-xl mt-10'>Level Points</h1>
                    <LevelPoints level_id={level.id}/>
                </>}

            </div>
        </div>
    )
}

export default LevelPopup


const LevelPoints = ( { level_id } ) => {
    const [points, setPoints] = useState(null)
    const [loading, setLoading] = useState(false)
    const [wait, setWait] = useState(true)

    const onChange = (e) => {
        setPoints({ ...points, [e.target.name]: e.target.value })
    }


    const update = async () => {
        let data = JSON.stringify({
            "point_id":points?.id,
            'level_id': level_id,
            "price": points.price,
            "max_point": points.max_points,
            "min_point": points.min_points,
        })

        console.log('data to be updated', data);
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${BACKEND_HOST}/admin_update_level_points`,
            headers: {
                'Content-Type': 'Application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            data: data
        };
        try {
            const response = await axios.request(config)
            if (response.status === 200 && response.data.status == true) {
                toast.success(response.data.message)
                fetchPoints()
            }
            else
                toast.error(response.data.error ? response.data.error : response.data.message)
        } catch (error) {
            console.log(error);
        }
    }

    const add = async () => {
        let data = JSON.stringify({
            'level_id': level_id,
            "price": points.price,
            "max_point": points.max_points,
            "min_point": points.min_points,
        })

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${BACKEND_HOST}/admin_add_level_points`,
            headers: {
                'Content-Type': 'Application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            data: data
        };
        try {
            const response = await axios.request(config)
            if (response.status === 200 && response.data.status == true) {
                toast.success(response.data.message)
                fetchPoints()
            }
            else
                toast.error(response.data.error ? response.data.error : response.data.message)
        } catch (error) {
            console.log(error);
        }
    }

    const fetchPoints = async () => {
        let data = JSON.stringify({ level_id })
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${BACKEND_HOST}/admin_get_level_points`,
            headers: {
                'Content-Type': 'Application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            data: data
        };
        try {
            const response = await axios.request(config)
            setWait(false)
            setLoading(false)
            if (response.status === 200 && response.data.status == true) {
                if (response.data.data.length > 0) {
                    console.log(response.data.data[0]);
                    setPoints(response.data.data[0])
                }
            }
            else
                toast.error(response.data.error ? response.data.error : response.data.message)
        } catch (error) {
            console.log(error);
        }
    }

    const deletePoint = async () => {
        const confirm = window.confirm('Are you sure deleting rank points?')
        if(!confirm)
            return;
        setLoading(true)
        let data = JSON.stringify({ point_id: points.id })

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${BACKEND_HOST}/admin_delete_level_points`,
            headers: {
                'Content-Type': 'Application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            data: data
        };
        try {
            const response = await axios.request(config)
            setWait(true)
            if (response.status === 200 && response.data.status == true) {
                setPoints(null)
                toast.success(response.data.error ? response.data.error : response.data.message)
                fetchPoints()
            }
            else
                toast.error(response.data.error ? response.data.error : response.data.message)
        } catch (error) {
            console.log(error);
        }
        setLoading(false)
    }

    const submit = async (e) => {
        e.preventDefault()
        setLoading(true)
        if (points?.id)
            await update()
        else
            await add()
    }

    useEffect(() => {
        fetchPoints()
    }, [])

    useEffect(() => {
        if(points?.id){
            document.getElementById('min_points').defaultValue = points.min_point
            document.getElementById('max_points').defaultValue = points.max_point
            document.getElementById('price').defaultValue = points.price
        }
    }, [points])
    return (
        <div className='relative'>
            {wait &&  <img src={require('../assets/loading.gif')} alt='loader' className='w-4 h-4 mt-3'/>}
           {!wait && <>
                <form onSubmit={submit} className='flex flex-col gap-3 '>
                    <div className='flex gap-1'>
                        <Input2 placeholder={"Min Points"} value={points?.min_point} name={'min_points'} required={true} onChange={onChange} type={"number"} />
                        <Input2 placeholder={"Max Points"} value={points?.max_point} name={'max_points'} required={true} onChange={onChange} type={"number"} />
                        <Input2 placeholder={"Point Price"} value={points?.price} name={'price'} required={true} onChange={onChange} type={"number"} />
                    </div>
                    <div className='w-[30%] flex'>
                        <Button2 title={points?.id ? 'Update' : 'Submit'} disabled={loading} />
                    </div>
                </form>
                {points?.id && <button onClick={deletePoint} disabled={loading} className='bg-red/50 absolute bottom-0 right-0 hover:bg-red/100 disabled:cursor-wait disabled:bg-red/50 transition-all ease-in duration-100 rounded-md text-sm font-Sansation px-5 py-2 text-white sm:px-3 sm:py-2 sm:text-[12px] sm:w-[30%]'>Delete Points</button>}
            </>}
        </div>
    )
}