import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { IoImageOutline } from "react-icons/io5";

function ImageUploader({setImg, required, img, id}) {
    const [image, setImage] = useState(img)

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.readAsDataURL(file);
          reader.onload = () => {
            resolve(reader.result)
          }
        })
      }

    const handleImage = async(e) => {
      e.stopPropagation();
        if(e.target.files[0]?.size >= 5000000){
            toast.error('Image size should be less than 5MB')
            return;
        }
        console.log(e.target.files);
        setImg(e.target.files[0])
        const base64 = await convertBase64(e.target.files[0])
        setImage(base64)
    } 

    useEffect(()=>{
      if(img)
        setImage(img)
    }, [])

  return (
    <div className='relative w-full h-full rounded-[10px] overflow-hidden border-[1px] border-white/55 cursor-pointer flex justify-center items-center' onClick={()=>document.getElementById(id)?.click()}>
       <div className='bg-white sm:bg-[#44444446] sm:opacity-30 absolute top-0 left-0 opacity-10 w-full h-[1000px] rounded-[10px] blur-[4px] sm:blur-[16px] '></div>
        <input type='file' accept='image/*' image required={required} className='opacity-0 absolute top-[40%]' id={id} onChange={handleImage} onClick={(e)=>e.stopPropagation()}/>
        {!image && <div >
                <IoImageOutline className='text-white/75 text-[60px] cursor-pointer sm:text-[45px]'/>
            </div>}
        {image && <img src={image} className='w-full h-full object-cover'/>}

    </div>
  )
}

export default ImageUploader
