import React from 'react'

function Textarea2({ name, type, onChange, required, placeholder, value, height }) {
  return (
    <div className='relative w-full px-4 pt-3 sm:px-3 sm:pt-2 rounded-[10px] overflow-hidden border-[1px] border-white/55'>
    <div className='bg-white sm:bg-[#44444446] sm:opacity-30 absolute top-0 left-0 opacity-10 w-full h-[1000px] rounded-[10px] blur-[4px] sm:blur-[16px] '></div>
    <textarea
      id={name}
      name={name}
      type={type}
      onChange={onChange}
      value={value || ''}
      required={required}
      placeholder={''}
      className={`text-white relative font-Sansation text-base sm:text-[12px] outline-none bg-transparent w-full mt-[8px] input pb-1 sm:pt-2 ${height? height : 'h-36'} sm:h-20 resize-none z-30 sm:leading-[22px]`}
    />
    <label htmlFor={name} className='absolute text-white/55 font-Sansation sm:text-[12px] label text-base top-3 left-2  sm:left-3 sm:top-[10px]'>{placeholder}</label>
  </div>
  )
}

export default Textarea2
