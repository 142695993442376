import React, { useEffect, useState } from 'react'
import Input2 from './Input2'
import axios from 'axios'
import { BACKEND_HOST } from '../constants'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'

function AssignBooster({ setBoosterr, fetchBooster }) {
    const [booster, setBooster] = useState(null)
    const [allBooster, setAllBooster] = useState(null)
    const [selectedBooster, setSelectedBooster] = useState(-99)
    const b = null

    const { id } = useParams()
    const searchBooster = (e) => {
        const filtered = allBooster?.filter((u) => (u.email?.toLowerCase().startsWith(e.target.value.toLowerCase()) || u.username?.toLowerCase().startsWith(e.target.value.toLowerCase())))
        setBooster(filtered)
    }

    const fetchUsers = async () => {
        try {
            const response = await axios.get(`${BACKEND_HOST}/admin_get_all_users`, {
                headers: {
                    'Content-Type': 'Application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })

            if (response.status === 200 && response.data.status) {
                const filtered = response.data.data.filter(b => b.role === 'employee')
                setAllBooster(filtered);
                setBooster(filtered);
            }
            else
                toast.error(response.data.error ? response.data.error : response.data.message)
        } catch (error) {
            console.log(error);
        }
    }


    const asignBooster = async (_id) => {
        const confirm = window.confirm('Are you sure assining him to this game?')
        if(!confirm){
            setSelectedBooster(-99)
            return;
        }
        try {
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${BACKEND_HOST}/assign_booster_to_game`,
                headers: {
                    'Content-Type': 'application/json',
                  'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                data: JSON.stringify({booster_id: _id, game_id: id})
              };
    
              const response = await axios.request(config);

            if (response.status === 200 && response.data.status) {
                setSelectedBooster(-99)
                setBoosterr(null)
                fetchBooster()
                toast.success(response.data.error ? response.data.error : response.data.message)
            }
            else
                toast.error(response.data.error ? response.data.error : response.data.message)
        } catch (error) {
            console.log(error);
        }
    }



    useEffect(() => {
        fetchUsers()
    }, [])
    return (
        <div className='flex flex-col gap-3'>
            <Input2 onChange={searchBooster} placeholder={"Search booster"} />
            <div className='flex flex-col gap-3 overflow-x-hidden overflow-y-scroll h-[150px] cart-booster mt-1'>
                {booster?.length === 0 && <p className='text-white text-sm sm:text-[12px] font-Sansation'>No data found.</p>}
                {booster?.map((b, index) => {
                    return <div className='flex justify-between items-center'>
                        <div className='flex justify-start items-center gap-2'>
                            <img src={b.profile_image ? `${BACKEND_HOST.replace('/api', '')}/${b?.profile_image}` : require('../assets/defaultAvatar.jpg')} alt='avatar' className='h-8 w-8 sm:h-5 sm:w-5 rounded-full object-cover border-white/60 border-[1px]' />
                            <p className='text-white font-Sansation text-sm capitalize sm:text-[12px]'>{b.username}</p>
                        </div>
                        <div className='flex justify-end items-center gap-2'>
                            <span className='flex justify-center items-center p-1 border-primary-100 border-[1px] rounded-full cursor-pointer' onClick={() => {setSelectedBooster(index); asignBooster(b.id)}}>
                                <span className={`w-3 h-3 sm:h-2 sm:w-2 ${selectedBooster === index && 'bg-primary-100'} rounded-full`}></span>
                            </span>
                        </div>
                    </div>
                })}
            </div>
        </div>
    )
}

export default AssignBooster
