import React, { useEffect, useState } from 'react'
import Card from '../components/Card'
import { useParams } from 'react-router-dom'
import GameForm from '../components/GameForm'
import GameServices from '../components/GameServices'
import GameFAQ from '../components/GameFAQ'
import GameBooster from '../components/GameBooster'
import Accordian from '../components/Accordian'
import { BACKEND_HOST } from '../constants'
import axios from 'axios'
import { toast } from 'react-toastify'

function EditGame() {
  const { id } = useParams()

  const [game, setGame] = useState(null)
  const [platforms, setPlatforms] = useState(null)

  const fetchGame = async () => {
    try {
      const response = await axios.get(`${BACKEND_HOST}/admin_get_games`, {
        headers: {
          'Content-Type': 'Application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })

      if (response.status === 200 && response.data.status) {
        const g = response.data.data.filter((gam) => gam.id == id)[0]
        setGame(g);
      }
      else
        toast.error(response.data.error ? response.data.error : response.data.message)
    } catch (error) {
      console.log(error);
    }
  }

  const fetchPlatforms = async () => {
    try {

      const response = await axios.get(`${BACKEND_HOST}/admin_get_plateforms`, {
        headers: {
          'Content-Type': 'Application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })

      if (response.status === 200 && response.data.status) {
        setPlatforms(response.data.data);
      }
      else
        toast.error(response.data.error ? response.data.error : response.data.message)
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchGame()
    fetchPlatforms()
  }, [])

  return (
    <Card>
      <div className='flex flex-col gap-3'>
        <h1 className="text-white font-Oswald text-3xl sm:text-2xl">Edit Game</h1>

        {!game && <img src={require('../assets/loading.gif')} alt='loader' className='w-7 h-7 sm:h-5 sm:w-5' />}
        {game &&
          <>
            <GameForm id={id} gamee={game} platforms={platforms}/>

            <Accordian title={"Services"} hight={'h-[300px]'}>
              <GameServices id={id} />
            </Accordian>

            <Accordian title="Boosters" hight={'h-[300px] sm:h-[550px]'}>
              <GameBooster />
            </Accordian>

            <Accordian title="Faqs" hight={'h-[360px] sm:h-[550px]'}>
              <GameFAQ />
            </Accordian>

          </>}





      </div>

    </Card>
  )
}

export default EditGame
