import React, { useEffect, useState } from 'react'
import Card from '../components/Card'
import AddFaq from '../components/AddFaq'
import { useParams } from 'react-router-dom'
import { BACKEND_HOST } from '../constants'
import axios from 'axios'
import { toast } from 'react-toastify'
import Input2 from './Input2'
import Textarea2 from './Textarea2'
import Button2 from './Button2'

function JoinusFAQS() {
    const [joinFaqs, setJoinFaqs] = useState (null)
    const [loading, setLoading] = useState(false) 

    const [faq, setFaq] = useState(null)

    const onChange = (e)  => {
        setFaq({ ...faq, [e.target.name]: e.target.value })
    }

    const addNewFaq = async(e) => {
        e.preventDefault()
        setLoading(true)
        try {
            let data = JSON.stringify({
                "question": faq.question,
                "answer": faq.answer
              });
    
          let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${BACKEND_HOST}/admin_add_work_faqs`,
            headers: {
                'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            data: data
          };

          const response = await axios.request(config);
          if (response.status === 200 && response.data.status) {
               toast.success(response.data.error ? response.data.error : response.data.message)
                  setFaq(null)
                  document.getElementById('question').value = ''
                  document.getElementById('answer').value = ''
                  setJoinFaqs(null)
                  fetchjoinFaqs()
          } else {
            toast.error(response.data.error ? response.data.error : response.data.message);
          }
        } catch (error) {
          console.log(error);
        }
        setLoading(true)
    }

    const fetchjoinFaqs = async() => {
        try {
            const response = await axios.get(`${BACKEND_HOST}/admin_get_work_faqs`, {
                headers: {
                    'Content-Type': 'Application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })
            if(response.status === 200 && response.data.status){
                setJoinFaqs(response.data.data);
            }
            else
                toast.error(response.data.error? response.data.error : response.data.message)
        } catch (error) {
            console.log(error);
        }
    }

    const deleteFaq = async(id) => {
        const confirm = window.confirm('Are you sure deleting this?', id)
        if(!confirm)
            return;
       
        setLoading(true)
        try {
            const response = await fetch(`${BACKEND_HOST}/admin_delete_work_faqs`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'Application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({faq_id: id})
            })
    
            const res = await response.json()
            if(response.ok && res.status){
                setJoinFaqs(null)
                fetchjoinFaqs()
                toast.success(res.error? res.error : res.message)
            }
            else
                toast.error(res.error? res.error : res.message)
        } catch (error) {
            console.log(error);
        }
        setLoading(false)
    }


    useEffect(()=>{
        fetchjoinFaqs()
    }, [])
  return (
          <div className='flex justify-between items-start gap-6 sm:flex-col-reverse sm:gap-0'>
        <div className='w-[72%] sm:w-full flex flex-col gap-3 mt-3'>
                <div className="overflow-x-auto  overflow-y-auto h-[330px]">
                    {!joinFaqs && <img src={require('../assets/loading.gif')} alt='loader' className='w-7 h-7 sm:h-5 sm:w-5'/>}
                    {joinFaqs?.length === 0 && <p className='text-white text-sm sm:text-[12px] font-Sansation'>No data found.</p>}
                    {joinFaqs?.length > 0 && <table className="min-w-full sm:min-w-[700px]">
                        <thead>
                            <tr>
                                <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-1 sm:py-1 text-grey">#</th>
                                <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey">Question</th>
                                <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey">Answer</th>
                                <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {joinFaqs?.map((b, index) => {
                                return <tr className="bg-primary-300 border-b-[4px] border-primary-100/10" key={index}>
                                            <td className="py-2 px-4 text-left font-Sansation text-white text-sm border-b-white sm:px-1 sm:py-1 sm:text-[10px] sm:leading-[20px] align-top">{index+1}</td>
                                            <td className="py-2 px-4 text-left font-Sansation text-white text-sm border-b-white sm:px-1 sm:py-1 sm:text-[10px] sm:leading-[20px] min-w-[150px] align-top">{b.question}</td>
                                            <td className="py-2 px-4 text-left font-Sansation text-white text-sm border-b-white sm:px-1 sm:py-1 sm:text-[10px] sm:leading-[20px] min-w-[220px] align-top">{b.answer}</td>
                                            <td className="py-2 px-4 text-left font-Sansation text-white text-sm sm:px-2 sm:py-1 sm:text-[10px] sm:leading-[20px] align-top">
                                                <span className='flex justify-start items-start gap-3'>
                                                <button disabled={loading} onClick={()=>deleteFaq(b.id)} className="text-red">Delete</button>
                                                </span>
                                            </td>
                                        </tr>
                            })}
                            
                        </tbody>
                    </table>}
                </div>
        </div>
        <div className='w-[28%] sm:w-full flex flex-col gap-2 bg-primary-300 px-4 pt-3 pb-5 rounded-2xl mt-4'>
                <h1 className="text-white font-Sansation text-base sm:text-sm">Add New</h1>
                <form className='flex flex-col gap-2' onSubmit={addNewFaq}>
                    <Input2 placeholder={"Question"} type={"text"} name={"question"} value={faq?.question} required={true} onChange={onChange} />
                    <Textarea2 placeholder={"Answer"} type={"text"} name={"answer"} value={faq?.answer} required={true} onChange={onChange} />
                    <Button2 title={"Submit"} disabled={loading} />
                </form>
        </div>
    </div>
  )
}

export default JoinusFAQS

