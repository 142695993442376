import React, { useContext, useEffect, useState } from 'react'
import { AdminContext } from '../state/AdminProvider'
import { AiOutlinePlus } from "react-icons/ai";
import Select from 'react-select'
import { selectStylesAdmin } from '../utils/selectStyles'
import Button2 from './Button2'
import Input2 from './Input2'
import { BACKEND_HOST } from '../constants'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'

function AddRanks() {
  const { setRankPopup, setRankMetadata, refresh, rankPopup } = useContext(AdminContext)

  const [loading, setLoading] = useState(false)
  const [rankType, setRankType] = useState(null)
  const [ranks, setRanks] = useState(null)
  const [isRankTypeAdded, setIsRankTypeAdded] = useState(false)
  const { service_id } = useParams()


  const onChange = (e) => {
    setRankType({ ...rankType, [e.target.name]: e.target.value })
  }

  const addRankType = async () => {
    let data = JSON.stringify({
      "service_id": service_id,
      "title": rankType.title ? rankType.title : rankType.rank_type,
      "rank_type": rankType.rank_type
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${BACKEND_HOST}/admin_add_rank_types`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      data: data
    };

    try {
      const response = await axios.request(config)
      if (response.status === 200 && response.data.status == true) {
        fetchRankType()
        toast.success(response.data.message)
      }
      else
        toast.error(response.data.error ? response.data.error : response.data.message)
    } catch (error) {
      console.log(error);
    }
  }

  const updateType = async () => {
    let data = JSON.stringify({
      "rank_type_id": rankType.id,
      "service_id": service_id,
      "title": rankType.title ? rankType.title : rankType.rank_type,
      "rank_type": rankType.rank_type
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${BACKEND_HOST}/admin_update_rank_types`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      data: data
    };

    try {
      const response = await axios.request(config)
      if (response.status === 200 && response.data.status == true) {
        fetchRankType()
        toast.success(response.data.message)
      }
      else
        toast.error(response.data.error ? response.data.error : response.data.message)
    } catch (error) {
      console.log(error);
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    if (isRankTypeAdded)
      await updateType()
    else
      await addRankType()
    setLoading(false)
  }

  const deleteRankType = async () => {
    const confirm = window.confirm('Are you sure deleting the ranks?')
    if(!confirm)
      return;
   
    setLoading(true)

    let data = JSON.stringify({"rank_type_id":rankType.id})
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${BACKEND_HOST}/admin_delete_rank_types`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      data: data
    };

    try {
      const response = await axios.request(config)
      if (response.status === 200 && response.data.status == true) {
        fetchRankType()
        setRanks(null)
        setIsRankTypeAdded(false)
        setRankType(null)
        toast.success(response.data.message)
      }
      else
        toast.error(response.data.error ? response.data.error : response.data.message)
    } catch (error) {
      console.log(error);
    }
    setLoading(false)
  }

  const fetchRankType = async () => {
    let data = JSON.stringify({
      "service_id": service_id,
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${BACKEND_HOST}/admin_get_rank_types`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      data: data
    };
    try {
      const response = await axios.request(config)
      if (response.status === 200 && response.data.status == true) {
        if (response.data.data.length > 0) {
          setRankType(response.data.data[0])
          fecthRanks()
          setIsRankTypeAdded(true)
        }
      }
      else
        toast.error(response.data.error ? response.data.error : response.data.message)
    } catch (error) {
      console.log(error);
    }
  }


  const fecthRanks = async () => {
    let data = JSON.stringify({
      service_id: service_id
    })
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${BACKEND_HOST}/admin_get_ranks`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      data: data
    };
    try {
      const response = await axios.request(config)
      if (response.status === 200 && response.data.status == true) {
          setRanks(response.data.data)
          if(rankPopup === true){
            console.log();
            setRankPopup(response.data.data[response.data.data.length - 1])
          }
        }
      else
        toast.error(response.data.error ? response.data.error : response.data.message)
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    setRanks(null)
    fetchRankType()
  }, [refresh])

  useEffect(() => {
  }, [rankType])

  return (
    <div className='flex justify-between items-start gap-6 sm:flex-col sm:gap-2'>
      <div className='w-[70%] sm:w-full flex gap-1 flex-wrap mt-4'>
        {rankType?.id && <>
          {!ranks && <img src={require('../assets/loading.gif')} alt='loader' className='w-7 h-7 sm:w-5 sm:h-5'/> }
          {ranks?.map((r, index) => {
            return <div className={`relative flex justify-center items-center rounded-lg sm:rounded-md p-1 w-[80px] h-[68px] sm:w-[40px] sm:h-[36px] cursor-pointer hover:border-white/50 border-[1px] border-transparent opacity-100 transition-all ease-in duration-150`} onClick={() => {setRankMetadata({service_id, rank_type_id: rankType.id}); setRankPopup(r)}}>
              <img src={`${BACKEND_HOST.replace('/api', '')}/${r.rank_icon}`} alt='rank' className='w-[52px] z-20 sm:w-[25px]' />
              <svg xmlns="http://www.w3.org/2000/svg" width="72" height="45" viewBox="0 0 79 52" fill="none" className='absolute bottom-1 left-[3px] sm:w-[38px] sm:h-[22px] sm:left-0'>
                <path d="M0.147461 29.1798C0.147461 25.3521 2.61645 21.9614 6.2593 20.7863L66.7353 1.2779C72.4302 -0.559141 78.2623 3.68763 78.2623 9.67142V42.9165C78.2623 47.7874 74.3138 51.736 69.4429 51.736H8.96687C4.09604 51.736 0.147461 47.7874 0.147461 42.9165V29.1798Z" fill="#0091F7" />
              </svg>
            </div>
          })}
         {ranks && <div className={`relative flex justify-center items-center rounded-lg sm:rounded-md p-1 w-[80px] h-[68px] sm:w-[40px] sm:h-[36px] cursor-pointer hover:border-white/50 border-[1px] border-transparent opacity-100 transition-all ease-in duration-150`} onClick={() => {setRankMetadata({service_id, rank_type_id: rankType.id}); setRankPopup(true)}}>
            <AiOutlinePlus className='text-white text-[40px] z-10' />
            <svg xmlns="http://www.w3.org/2000/svg" width="72" height="45" viewBox="0 0 79 52" fill="none" className='absolute bottom-1 left-[3px] sm:w-[38px] sm:h-[22px] sm:left-0'>
              <path d="M0.147461 29.1798C0.147461 25.3521 2.61645 21.9614 6.2593 20.7863L66.7353 1.2779C72.4302 -0.559141 78.2623 3.68763 78.2623 9.67142V42.9165C78.2623 47.7874 74.3138 51.736 69.4429 51.736H8.96687C4.09604 51.736 0.147461 47.7874 0.147461 42.9165V29.1798Z" fill="#0091F7" />
            </svg>
          </div>}
        </>}
      </div>


      <div className='flex flex-col gap-3 w-[30%] bg-primary-300 px-4 pt-3 pb-5 rounded-2xl mt-2 sm:w-full'>
        <form className='w-full flex flex-col gap-2' onSubmit={onSubmit}>
          <div className='flex flex-col gap-1 z-20'>
            <label className='text-white font-Sansation text-sm sm:text-sm'>Rank Type</label>
            <div className='flex justify-between items-center'>
              <span className='flex gap-2 justify-start items-center text-sm text-white font-Sansation'>
                <input type='radio' name='rank_type' checked={rankType?.rank_type === 'Conversion'} value={"Conversion"} onChange={onChange} />
                Conversion
              </span>
              <span className='flex gap-2 justify-start items-center text-sm text-white font-Sansation'>
                <input type='radio' name='rank_type' checked={rankType?.rank_type === 'Placement'} value={"Placement"} onChange={onChange} />
                Placement
              </span>
            </div>
          </div>
          {rankType?.rank_type === 'Placement' && <Input2 placeholder={"Title"} type={"text"} name={"title"} value={rankType?.title} required={true} onChange={onChange} />}
          <Button2 title={isRankTypeAdded ? 'Update' : "Submit"} disabled={loading} />
        </form>
        {rankType?.id && <button disabled={loading} onClick={deleteRankType} className='bg-red/75 hover:bg-red/100 disabled:cursor-wait disabled:bg-red/50 transition-all ease-in duration-100 rounded-md text-sm font-Sansation px-5 py-2 sm:py-1 sm:text-[12px] text-white'>Delete</button>}
      </div>

    </div>
  )
}

export default AddRanks
