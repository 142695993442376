import React from "react";
import Card from "../components/Card";
import StarRatings from "react-star-ratings";

function ReviewsList() {
  return (
<Card>
  <h1 className="text-white font-Oswald text-3xl sm:text-2xl">Reviews</h1>
  <div className="overflow-x-auto">
    <table className="min-w-full sm:min-w-[700px]">
      <thead>
        <tr>
          <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-1 sm:py-1 text-grey">#</th>
          <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey">Name</th>
          <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey">Rating</th>
          <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey">Description</th>
          <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey whitespace-nowrap">Added On</th>
          <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr className="bg-primary-300 border-b-[4px] border-primary-100/10">
          <td className="py-2 px-4 text-left font-Sansation text-white text-sm border-b-white align-top sm:px-1 sm:py-1 sm:text-[10px] sm:leading-[20px]">1</td>
          <td className="py-2 px-4 text-left font-Sansation text-white text-sm flex justify-start items-center gap-1 align-top  sm:px-2 sm:py-1 sm:text-[10px] sm:leading-[20px]">
              <img src={require('../assets/avator.png')} alt="avatar" className="w-7 h-7 rounded-full sm:w-5 sm:h-5"/>
              John
          </td>
          <td className="py-2 px-4 text-left font-Sansation text-white text-sm align-top sm:px-2 sm:py-1 sm:text-[10px] sm:leading-[20px]">
            <StarRatings rating={3} starRatedColor="#FFA500" starHoverColor="#FFA500" starEmptyColor="#ebd8b5" starDimension="16px" numberOfStars={5} />
          </td>
          <td className="py-2 px-4 text-left font-Sansation text-white text-sm align-top max-w-[370px] sm:px-2 sm:py-1 sm:text-[10px] sm:leading-[20px] sm:max-w-[400px]">hrenj jnrqejn rjngjnj hrenj jnrqejn rjngjnj hrenj jnrqejn rjngjnj  hrenj jnrqejn </td>
          <td className="py-2 px-4 text-left font-Sansation text-white text-sm align-top sm:px-2 sm:py-1 sm:text-[10px] sm:leading-[20px]">20-10-2023</td>
          <td className="py-2 px-4 text-left font-Sansation text-white text-sm align-top sm:px-2 sm:py-1 sm:text-[10px] sm:leading-[20px]">
            <button className="text-red">Delete</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</Card>

  );
}

export default ReviewsList;
