import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Button from '../components/Button'
import OurBossterRequirements from '../components/OurBossterRequirements'
import WhyBoostings from '../components/WhyBoostings'
import Eclips from '../components/Eclips'
import Faq from '../components/Faq'
import { BACKEND_HOST } from '../constants'
import { motion } from 'framer-motion'

function JoinUs() {
  const [t,] = useTranslation("global")
  const [faq, setFaq] = useState([])

  const getFaqs = async () => {
    try {
      const response = await fetch(`${BACKEND_HOST}/work_faqs`, {
        method: 'GET',
        headers: {
          'Content-Type': 'Application/json'
        }
      })
  
      const res = await response.json()
      if (response.ok)
        setFaq(res.data)
      else
        console.log("error fetching faqs: " + res);
      console.log("error fetching faqs: " + response);
    } catch (error) {
        console.log(error);
    }
  }

  useEffect(() => {
    getFaqs()
  }, [])

  return (
    <motion.div initial={{width:'0%', opacity: 0}} animate={{width:'100%', opacity:1}} exit={{x:window.innerWidth, transition:{duration:1}}} className='opacity-0 flex flex-col w-[100%] gap-[150px] overflow-hidden relative'>
      <div className="relative bg-cover bg-no-repeat bg-top sm:bg-center opacity-30 sm:h-[1000px] sm:opacity-30 bg-[url('./assets/joinus.png');] h-[800px] w-full -mt-14">
      </div>
      <div className='relative flex pl-60 pr-20 justify-start items-center -mt-[0] sm:px-7'>
        <motion.div transition={{ delay: 0.3, duration: 0.4 }} initial={{opacity:0, }} animate={{opacity:1, marginLeft:0}} className='opacity-0 w-[57%] -mt-[950px] sm:-mt-[1550px] z-10 flex justify-start items-start flex-col sm:w-full'>
          <h1 className='text-white text-[60px] leading-[70px] font-Oswald font-bold  sm:text-[33px] sm:leading-[43px]'>{t('joinPage.headingSlice1')} <cpan className="text-primary-100">{t('joinPage.headingSlice2')}</cpan> {t('joinPage.headingSlice3')}</h1>
          <p className='text-left text-white font-Sansation text-base py-5 sm:text-[12px] sm:leading-[22px]'> {t("joinPage.text1")}</p>
          <Link to='/contact' className='w-52 sm:w-40 z-20'>
            <Button title={t("joinPage.work_with_us")} />
          </Link>
        </motion.div>
        <img src={require('../assets/joinus1.png')} className='absolute h-[1164px] z-[0] right-20 top-[-920px] sm:right-20 sm:top-[-640px]  sm:h-[752px]' />
      </div>

      <div className='flex gap-7 w-[40%] ml-60 z-[5] -mt-20 sm:ml-0 sm:px-7 sm:w-full sm:-mt-[200px] sm:gap-4 sm:justify-center sm:items-center z-10'>
        <img src={require('../assets/Group 105.png')} alt='bar' className='h-[162px] sm:h-[110px]' />
        <span>
          <h1 className='text-white text-[50px] leading-[60px] font-Oswald font-bold sm:text-[26px] sm:leading-[30px]'>{t('joinPage.why')} <cpan className="text-primary-100">{t('joinPage.join')}</cpan> {t('joinPage.us')}</h1>
          <p className='text-left text-white font-Sansation text-base py-5 sm:text-[12px] sm:leading-[22px] sm:py-2'> {t("joinPage.text2")}</p>
        </span>
      </div>
      <img src={require('../assets/joinus2.png')} className='absolute w-[603px] sm:w-[330px] right-28 top-[780px] z-[2] sm:top-[1220px] sm:right-7 z-10' />

      <WhyBoostings />

      <OurBossterRequirements />

      {/* faq */}
      <div className='pl-60 pr-10 pb-[180px] sm:pb-[70px] flex justify-center items-center flex-col gap-14 sm:gap-5 sm:px-7 sm:-mt-14 z-10'>
        <h1 className='text-white text-center text-[50px] leading-[60px] font-Oswald font-bold sm:text-[24px] sm:leading-[34px]'>{t('home.faq')} <span className='text-primary-100'>{t('home.faqBlue')}</span></h1>

        <div className='flex flex-col gap-4 w-[70%] sm:gap-3 sm:w-full'>
          {faq.map((f, index) => {
            return <Faq ques={f.question} ans={f.answer} index={index} />
          })}
        </div>
      </div>

      <Eclips top='sm:top-[680px]' blur1="sm:hidden"/>
      <div className='hidden sm:block bg-primary-100 rounded-full absolute z-[1] sm:w-[300px] sm:h-[300px] sm:opacity-50 sm:-right-28 sm:blur-[50px] sm:-top-28'></div>
    </motion.div>
  )
}

export default JoinUs
