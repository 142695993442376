import React, { useEffect, useState } from 'react'
import Button2 from './Button2'
import Input2 from './Input2'
import Textarea2 from './Textarea2'
import { BACKEND_HOST } from '../constants'
import axios from 'axios'
import { toast } from 'react-toastify'

function GameForm({ id, gamee, platforms }) {

  const [loading, setLoading] = useState(false)
  const [img, setImg] = useState()
  const [game, setGame] = useState(gamee)
  
  let gamePlatforms = []
 

  const onChange = (e) => {
    setGame({ ...game, [e.target.name]: e.target.value })
  }

  const insertPlatform = (_id) => {
    const isAlreadyThere = gamePlatforms.some((b) => b == _id)
    if(!isAlreadyThere){
      gamePlatforms.push(_id)
    }
    else{
      gamePlatforms = gamePlatforms.filter(b => b != _id)
    }
  }


  const updateGame = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      let data = new FormData();
      data.append('title', game.title);
      data.append('game_id', game.id);
      data.append('description', game.description);
      data.append('plateform_ids', JSON.stringify(gamePlatforms));
      if(img)
      data.append('image', img);

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${BACKEND_HOST}/admin_update_games`,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        data: data
      };

      const response = await axios.request(config);

      if (response.status === 200 && response.data.status) {
        toast.success(response.data.error ? response.data.error : response.data.message);
      } else {
        toast.error(response.data.error ? response.data.error : response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false)
  }

 useEffect(()=>{
  for (let i = 0; i < gamee?.game_plateforms?.length; i++) {
    gamePlatforms.push(gamee.game_plateforms[i].plateform_id)
  }
 }, [])


  return (
    <>
      <form onSubmit={updateGame} className='flex flex-col justify-start items-start gap-2 w-full mb-8'>
        <div className='flex justify-between items-start gap-4 w-full mb-1 sm:flex-col'>
          <div className='w-[75%] flex flex-col gap-3 sm:w-full'>
            <Input2 placeholder={"Title"} type={"text"} name={"title"} value={game?.title} required={true} onChange={onChange} disabled={true} />
            <Textarea2 placeholder={"Description"} name={"description"} value={game?.description} required={true} onChange={onChange} />
          </div>
          <div className='w-[25%] sm:w-full'>
            <p className='text-lg text-white font-Sansation'>Platforms</p>
            <div className='flex flex-col flex-wrap mt-1 gap-1 sm:flex-row sm:gap-3'>
              {platforms?.map((p, index) => {
                return <span className='flex justify-start items-center gap-2 sm:gap-1'>
                        <input type='checkbox' defaultChecked={game.game_plateforms.some(platform => platform.plateform_id === p.id)}  onClick={()=>insertPlatform(p.id)} />
                        <label className='text-sm text-white font-Sansation'>{p.plateform_name}</label>
                      </span>
              })}
            </div>
          </div>
        </div>
        <Button2 title={"Update"} disabled={loading} />
      </form>
    </>
  )
}

export default GameForm
