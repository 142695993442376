import React, { useContext, useEffect, useState } from 'react'
import { IoIosCloseCircleOutline } from 'react-icons/io'
import { AdminContext } from '../state/AdminProvider'
import Input2 from './Input2'
import Textarea2 from './Textarea2'
import { WithContext as ReactTags } from 'react-tag-input';
import Button2 from './Button2'
import axios from 'axios'
import { toast } from 'react-toastify'
import { BACKEND_HOST } from '../constants'

function CustomOrder() {
    const { setCustomOrderPopup, customOrderPopup } = useContext(AdminContext)

    const [rank_type, setRank_type] = useState("Conversion")

    const [dropdownsItems, setDropdownItems] = useState([]);
    const [customization, setCustomization] = useState([]);
    const [loading, setLoading] = useState(false);
    const [order, setOrder] = useState()

    const handleDeleteItem = (i) => {
        const newTags = [...dropdownsItems];
        newTags.splice(i, 1);
        setDropdownItems(newTags);
    };

    const handleAdditionItem = (dropdownsItem) => {
        setDropdownItems([...dropdownsItems, dropdownsItem]);
    };
    const handleDeleteCust = (i) => {
        const newTags = [...customization];
        newTags.splice(i, 1);
        setCustomization(newTags);
    };

    const handleAdditionCust = (dropdownsItem) => {
        setCustomization([...customization, dropdownsItem]);
    };

    const onChange = (e) => {
        setOrder({ ...order, [e.target.name]: e.target.value })
    }

    const onSubmit = async (e) => {
        e.preventDefault()

        setLoading(true)
        order.rank_type = rank_type
        order.ranks = rank_type === 'Conversion' ? JSON.stringify({
            current: order.current_rank_and_level,
            desired: order.desired_rank_and_level
        }) : order.placement_rank_and_level

        order.items = JSON.stringify(dropdownsItems)
        order.customizes = JSON.stringify(customization)
        order.service_id = customOrderPopup.service_id
        order.game_id = customOrderPopup.game_id
        order.plateform_id = customOrderPopup.plateform_id
        order.user_id = customOrderPopup.user_id


        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${BACKEND_HOST}/update_custom_order`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(order)
        };

        const res = await axios.request(config)
        if (res.data.status == true) {
            setCustomOrderPopup(false)
            toast.success(res.data.message)
        }
        else
            toast.error(res.data.message)

        setLoading(false)
    }

    useEffect(() => {

    }, [rank_type])
    return (
        <div className='fixed sm:w-full top-0 right-0 w-[80%] h-screen bg-[rgba(0,0,0,0.72)] popupopens z-50 flex justify-center items-center'>
            <form onSubmit={onSubmit} className='admin sm:px-4 sm:w-[90%] rounded-xl px-7 py-5 w-[60%]  relative flex justify-start items-start gap-4 flex-col border-[1px] border-white/40'>
                <IoIosCloseCircleOutline onClick={() => setCustomOrderPopup(false)} className='bg-red/50 text-white p-[2px] rounded-full text-[30px] absolute -top-2 right-2 cursor-pointer' />
                <div className='flex gap-2 w-full'>
                    <span className='w-[50%]'>
                        <Input2 type='text' id='lname' value={customOrderPopup?.game?.title} required placeholder={"Name"} name='name' readOnly={true} />
                    </span>
                    <span className='w-[50%]'>
                        <Input2 type='text' id='lname' value={customOrderPopup?.plateform_name} required placeholder={"Name"} name='name' readOnly={true} />
                    </span>
                </div>
                <Textarea2 height={'h-20'} placeholder={"Description"} value={customOrderPopup?.description} readOnly={true} />
                {/* <div className='flex justify-end items-center gap-3 w-full'>
                    <span className='flex gap-2 justify-center items-center'>
                        <input type='radio' defaultChecked={true} name='rank_type' onChange={() => setRank_type('Conversion')} />
                        <p className='text-white text-sm font-Sansation'>Conversion</p>
                    </span>
                    <span className='flex gap-2 justify-center items-center'>
                        <input type='radio' name='rank_type' onChange={() => setRank_type('Placement')} />
                        <p className='text-white text-sm font-Sansation'>Placement</p>
                    </span>
                </div>
                <div className='flex justify-between items-center gap-2 w-full'>
                    {rank_type === 'Conversion' ?
                        <>
                            <Input2 type='text' id='current_rank_and_level' value={''} required placeholder={"Current Rank & Level"} name='current_rank_and_level' onChange={onChange} />
                            <Input2 type='text' id='desired_rank_and_level' value={''} required placeholder={"Desired Rank & Level"} name='desired_rank_and_level' onChange={onChange} />
                        </>
                        :
                        <Input2 type='text' id='placement_rank_and_level' value={''} required placeholder={"Placement Rank & Level"} name='placement_rank_and_level' onChange={onChange} />
                    }
                    <Input2 type='number' id='points' value={''} required placeholder={"Points"} name='points' onChange={onChange} />
                </div>
                <div className='flex flex-col gap-1 w-full admin-x rounded-xl glass border-[1px] border-white/75'>
                    <ReactTags
                        tags={dropdownsItems}
                        handleDelete={handleDeleteItem}
                        handleAddition={handleAdditionItem}
                        handleDrag={() => { }}
                        placeholder="Dropdown Items"
                    />
                </div>
                <div className='flex flex-col gap-1 w-full admin-x rounded-xl glass border-[1px] border-white/75'>
                    <ReactTags
                        tags={customization}
                        handleDelete={handleDeleteCust}
                        handleAddition={handleAdditionCust}
                        handleDrag={() => { }}
                        placeholder="Customization Items"
                    />
                </div> */}

                <div className='flex justify-between gap-2 w-full'>
                    <span className='w-[50%]'>
                        <Input2 type='number' id='price' value={''} required placeholder={"Price"} name='total_amount' onChange={onChange} />
                    </span>
                    <Button2 title={"Create"} disabled={loading} />
                </div>

            </form>
        </div>
    )
}

export default CustomOrder
