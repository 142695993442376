import React, { useContext, useEffect, useRef, useState } from 'react'
import { BACKEND_HOST } from '../constants'
import { Context } from '../state/Provider'
import { useParams } from 'react-router-dom'

function GameRanks({ id, title, ranks, rank_type }) {
    const {
        selectedPlacementRank, setSelectedPlacementRank,
        selectedCurrentRank, setSelectedCurrentRank,
        selectedDesiredank, setSelectedDesiredRank,
        selectedCurrentRankLevel, setSelectedCurrentRankLevel,
        selectedPlacementRankLevel, setSelectedPlacementRankLevel,
        selectedDesiredankLevel, setSelectedDesiredRankLevel,
        disabledDesiredRankIndex,
        disabledDesiredRankLevelIndex,
        selectedService
    } = useContext(Context)

    const { b_id } = useParams()

    const [localRank, setLocalRank] = useState()
    const [localLevel, setLocalLevel] = useState()
    const [localRankPoints, setLocalRankPoints] = useState()
    const [localLevelPoints, setLocalLevelPoints] = useState()



    const select = (r) => {
        if (id === 0)
            setSelectedCurrentRank(r)
        else
            setSelectedDesiredRank(r)
    }

    const handleRankSelection = (r) => {
        if (rank_type === 'Conversion')
            select(r)
        else
            setSelectedPlacementRank(r)
    }

    useEffect(() => {
        if (rank_type === 'Conversion') {
            setSelectedCurrentRank(ranks[0])
            setSelectedCurrentRankLevel(ranks[0]?.levels[0])
            setSelectedDesiredRank(ranks[0])
            setSelectedDesiredRankLevel(ranks[0]?.levels[1])
        }
        else
            setSelectedPlacementRank(ranks[0])
    }, [selectedService])

    useEffect(() => {
        if (rank_type === 'Conversion') {
            if (id === 0) {
                setLocalLevel(selectedCurrentRankLevel)
                if (selectedCurrentRankLevel?.levelpoints?.length > 0)
                    setLocalLevelPoints(selectedCurrentRankLevel?.levelpoints[0])
                else
                    setLocalLevelPoints(null)
            }
            else {
                setLocalLevel(selectedDesiredankLevel)
                if (selectedDesiredankLevel?.levelpoints?.length > 0)
                    setLocalLevelPoints(selectedDesiredankLevel?.levelpoints[0])
                else
                    setLocalLevelPoints(null)
            }
        }
        else {
            setLocalLevel(selectedPlacementRankLevel)
            if (selectedPlacementRankLevel?.levelpoints?.length > 0)
                setLocalLevelPoints(selectedPlacementRankLevel?.levelpoints[0])
            else
                setLocalLevelPoints(null)
        }


    }, [selectedCurrentRankLevel, selectedPlacementRankLevel, selectedDesiredankLevel])

    useEffect(() => {
        try {
            if (rank_type === 'Conversion') {
                if (id === 0) {
                    setLocalRank(selectedCurrentRank)
                    setLocalRankPoints(selectedCurrentRank?.points[0])
                    // setSelectedCurrentRankLevel(selectedCurrentRank?.levels[0])
                }
                else {
                    setLocalRank(selectedDesiredank)
                    setLocalRankPoints(selectedDesiredank?.points[0])
                    setSelectedDesiredRankLevel(selectedDesiredank?.levels[1])
                }
            }
            else {
                setLocalRank(selectedPlacementRank)
                setLocalRankPoints(selectedPlacementRank?.points[0])
            }
        } catch (error) {
            console.log(error);
        }

    }, [selectedCurrentRank, selectedDesiredank, selectedPlacementRank])

    useEffect(()=>{}, [localLevel, localRank])

    return (
        <div className='game-rank-border overflow-hidden'>
            <div className='relative overflow-hidden px-5 py-4 rounded-[18px] sm:rounded-sm sm:px-3 sm:py-2 transition-all ease-in duration-150' style={{ background: "linear-gradient(109deg, #239ED6 3.05%, #0776AE 11.09%, #005394 34.57%, #003360 77.68%, #00417F 126.67%)" }}>
                <h1 className='text-white font-Oswald text-3xl font-bold z-20 relative  sm:text-[20px]'>{title}</h1>
                <span className='flex justify-start items-center gap-2 mt-3 z-10 relative sm:mt-0 sm:gap-1'>
                    <img src={`${BACKEND_HOST.replace('/api', '')}/${localRank?.rank_icon}`} alt='rank' className='w-[41px] sm:w-[25px]' />
                    <p className='capitalize text-white font-Sansation text-lg sm:text-[12px] capitalize'>{localRank?.rank_name} {localLevel?.name}</p>
                </span>
                <div className='flex flex-wrap gap-0 justify-start items-start mt-2 z-10 relative sm:mt-1'>
                    {ranks.map((r, index) => {
                        return <button key={index} disabled={id !== 0 && disabledDesiredRankIndex?.includes(index)} className={` disabled:opacity-40 relative flex justify-center items-center rounded-lg sm:rounded-md p-1 w-[80px] h-[68px] sm:w-[40px] sm:h-[36px] cursor-pointer ${localRank?.id === r.id && 'border-white/75 border-[1px]'} `} onClick={() => handleRankSelection(r)}>
                            <img src={`${BACKEND_HOST.replace('/api', '')}/${r.rank_icon}`} alt='rank' className='w-[52px] z-20 sm:w-[25px]' />
                            <svg xmlns="http://www.w3.org/2000/svg" width="72" height="45" viewBox="0 0 79 52" fill="none" className='absolute bottom-1 left-[3px] sm:w-[38px] sm:h-[22px] sm:left-0'>
                                <path d="M0.147461 29.1798C0.147461 25.3521 2.61645 21.9614 6.2593 20.7863L66.7353 1.2779C72.4302 -0.559141 78.2623 3.68763 78.2623 9.67142V42.9165C78.2623 47.7874 74.3138 51.736 69.4429 51.736H8.96687C4.09604 51.736 0.147461 47.7874 0.147461 42.9165V29.1798Z" fill="#0091F7" />
                            </svg>
                        </button>

                    })}
                </div>

                <div className='flex flex-wrap gap-1 justify-start items-start z-10 relative mt-3 sm:mt-2'>
                    {localRank?.levels?.length > 1 && localRank?.levels?.map((l, index) => {
                        return <button disabled={id !== 0 && disabledDesiredRankLevelIndex?.some(x => x.rank_id == selectedDesiredank?.id && x.index === index)} onClick={() => {
                            if (rank_type === 'Conversion') {
                                if (id === 0)
                                    setSelectedCurrentRankLevel(l)
                                else
                                    setSelectedDesiredRankLevel(l)
                            }
                            else
                                setSelectedPlacementRankLevel(l)
                        }} className={`disabled:opacity-40 flex  flex-1 justify-center min-w-[100px] sm:min-w-[20px] items-center px-5 sm:px-2 sm:py-[1px] py-1 bg-[#0091F7]/80  ${localLevel?.id == l.id ? 'border-[1px] border-white/95' : 'border-[1px] border-[#0091F7]'} rounded-md cursor-pointer transition-all ease-in duration-150`}>
                            <p className='text-white font-O text-base uppercase font-bold  sm:text-[12px] sm:leading-0 sm:font-medium'>{l.name}</p>
                        </button>
                    })}
                </div>

                {localLevelPoints && <Points localRank={localRank} localLevel={localLevel} points={localLevelPoints} rank_type={rank_type} id={id} points_type={'level'} />}
                {localRankPoints && <Points localRank={localRank} localLevel={localLevel} points={localRankPoints} rank_type={rank_type} id={id} points_type={'rank'} />}


                <svg xmlns="http://www.w3.org/2000/svg" width="256" height="152" viewBox="0 0 256 152" fill="none" className='absolute right-36 -top-6 z-[0] sm:-top-16 sm:right-52  sm:w-[150px]'>
                    <g opacity="0.5" filter="url(#filter0_f_215_126)">
                        <ellipse cx="128.188" cy="38.3958" rx="80.1875" ry="65.3958" fill="#24B0FF" />
                    </g>
                    <defs>
                        <filter id="filter0_f_215_126" x="0.709908" y="-74.2901" width="254.955" height="225.372" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                            <feGaussianBlur stdDeviation="23.645" result="effect1_foregroundBlur_215_126" />
                        </filter>
                    </defs>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="256" height="152" viewBox="0 0 256 152" fill="none" className='absolute left-10 -top-4 z-[0] sm:-top-14 sm:left-32 sm:w-[150px]'>
                    <g opacity="0.5" filter="url(#filter0_f_215_126)">
                        <ellipse cx="128.188" cy="38.3958" rx="80.1875" ry="65.3958" fill="#24B0FF" />
                    </g>
                    <defs>
                        <filter id="filter0_f_215_126" x="0.709908" y="-74.2901" width="254.955" height="225.372" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                            <feGaussianBlur stdDeviation="23.645" result="effect1_foregroundBlur_215_126" />
                        </filter>
                    </defs>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="256" height="152" viewBox="0 0 256 152" fill="none" className='absolute right-0 -bottom-16 rotate-180 z-[0] sm:-bottom-16 sm:-right-2 sm:w-[150px]'>
                    <g opacity="0.5" filter="url(#filter0_f_215_126)">
                        <ellipse cx="128.188" cy="38.3958" rx="80.1875" ry="65.3958" fill="#24B0FF" />
                    </g>
                    <defs>
                        <filter id="filter0_f_215_126" x="0.709908" y="-74.2901" width="254.955" height="225.372" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                            <feGaussianBlur stdDeviation="23.645" result="effect1_foregroundBlur_215_126" />
                        </filter>
                    </defs>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="256" height="152" viewBox="0 0 256 152" fill="none" className='absolute left-20 -bottom-16 rotate-180 z-[0] sm:-bottom-16 sm:w-[150px] sm:left-5'>
                    <g opacity="0.5" filter="url(#filter0_f_215_126)">
                        <ellipse cx="128.188" cy="38.3958" rx="80.1875" ry="65.3958" fill="#24B0FF" />
                    </g>
                    <defs>
                        <filter id="filter0_f_215_126" x="0.709908" y="-74.2901" width="254.955" height="225.372" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                            <feGaussianBlur stdDeviation="23.645" result="effect1_foregroundBlur_215_126" />
                        </filter>
                    </defs>
                </svg>
            </div>
        </div>
    )
}

export default GameRanks


const Points = ({ points, rank_type, id, points_type, localRank, localLevel }) => { 
    const [currentValue, setCurrentValue] = useState(points.min_point);
    const inputRef = useRef(null);
    const { setPlacementRankPoints, setDesiredRankPoints, setCurrentRankPoints, setCurrentLevelPoints, setDesiredLevelPoints, setPlacementLevelPoints } = useContext(Context)

    const handleDecrease = () => {
        if (currentValue > parseFloat(points.min_point)) {
            setCurrentValue(currentValue - 1);
        }
    };

    const handleIncrease = () => {
        if (currentValue < parseFloat(points.max_point)) {
            setCurrentValue(currentValue + 1);
        }
    };

    const handleInputChange = (e) => {
        let newValue = parseInt(e.target.value);

        if (isNaN(newValue) || newValue < parseFloat(points.min_point)) {
            setCurrentValue(points.min_point);
        } else if (newValue <= parseFloat(points.max_point)) {
            setCurrentValue(newValue);
        } else {
            setCurrentValue(parseFloat(points.max_point));
        }
    };

    useEffect(() => {
        if (rank_type === 'Conversion') {
            if (id == 0) {
                if (points_type === 'rank')
                    setCurrentRankPoints(currentValue);
                else
                    setCurrentLevelPoints(currentValue)
            }
            else {
                if (points_type === 'rank')
                    setDesiredRankPoints(currentValue);
                else
                    setDesiredLevelPoints(currentValue)
            }
        }
        else {
            if (points_type === 'rank')
                setPlacementRankPoints(currentValue);
            else
                setPlacementLevelPoints(currentValue)
        }

    }, [currentValue, localRank, localLevel])

    useEffect(()=>{
        setCurrentValue(points.min_point)
    }, [localLevel, localRank])

    return (
        <div className='flex justify-between items-stretch gap-2 mt-3 sm:mt-2 z-10 relative sm:gap-1 sm:items-end'>
            <button onClick={handleDecrease} className='sm:py-[0px] flex justify-center items-center text-white bg-[#0091F7] py-1 px-10 text-lg leading-0 hover:opacity-80 transition-all ease-in duration-150 cursor-pointer rounded-md'>−</button>
            <input ref={inputRef} onChange={handleInputChange} min={points.min_point} max={points.max_point} value={currentValue} type="number" className='sm:text-sm sm:py-[4px] w-full outline-none text-center bg-[#0A1F42] rounded-md text-white font-Sansation text-base' />
            <button onClick={handleIncrease} className='sm:py-[0px] flex justify-center items-center text-white bg-[#0091F7] py-1 px-10 text-lg leading-0 hover:opacity-80 transition-all ease-in duration-150 cursor-pointer rounded-md'>+</button>
        </div>
    )
}