import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import Eclips from '../components/Eclips'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { BACKEND_HOST } from '../constants'

function Blogs() {
    const [t] = useTranslation("global")
    const [blogs, setBlogs] = useState(null)

    
    const getBlogs = async() => {
        try {
            const response = await fetch(`${BACKEND_HOST}/get_blogs`,{
                method:'GET',
                headers:{
                    'Content-Type':'Application/json'
                }
            })
    
            const res = await response.json()
            if(response.ok)
                setBlogs(res.data)
            else
                console.log("error fetching blog: " + res);
                console.log("error fetching blog: " + response);
        } catch (error) {
            console.log(error);
        }
       
    }

    useEffect(()=>{
        getBlogs()
    }, [])
  return (
    <motion.div  initial={{width:'0%', opacity: 0}} animate={{width:'100%', opacity:1}} exit={{x:window.innerWidth, transition:{duration:1}}} className='opacity-0 flex flex-col relative  gap-[150px] overflow-hidden sm:gap-0'>
    <div className="relative bg-cover bg-no-repeat bg-center sm:bg-left sm:opacity-30 sm:w-full sm:h-screen opacity-30 bg-[url('./assets/loginBg.jpg');] h-screen w-full -my-6 sm:my-0">    
    </div>

    <div className='pl-52 sm:px-7 -mt-[650px]  z-10 sm:-mt-[500px]' >
        <h1 className='text-white text-[50px] leading-[60px] font-Oswald font-bold sm:text-[26px] sm:leading-[30px]'>{t('home.blog')} <span className='text-primary-100'>{t('home.blogBlue')}</span></h1>
        <p className='text-left text-white font-Sansation text-base py-5 sm:text-[12px] sm:leading-[22px] sm:w-full w-[90%]'>Looking to dominate the competition and rise to the top? Our Rocket League Rank Boost Services will help you achieve your desired rank and become a force to be reckoned with. Don't settle for mediocrity - unleash your true potential and conquer the field of Rocket League!</p>
    </div>

    <div className='flex justify-starts items-stretch sm:items-start gap-10 flex-wrap min-h-[400px] pb-[100px] pl-52 sm:px-7 z-10 -mt-28 sm:mt-4'>
        {!blogs && <img src={require('../assets/loading.gif')} alt='loader' className='w-7 h-7'/>}
        {blogs?.length === 0 && <p className='text-white text-sm sm:text-[12px] font-Sansation'>No data found.</p>}
            
        {blogs?.map((b, index) => {
                        return <Link to={`/blogs/blog-detail/${b.id}`} className='rounded-xl overflow-hidden w-[29%] sm:w-full border-[1px] border-white/55 px-4 py-5 hover:bg-primary-300/50 transition-all ease-in duration-300 hover:scale-[0.98]'>
                                    <img src={`${BACKEND_HOST.replace('/api', '')}/${b.image}`} alt='' className='w-[100%] h-[200px] object-cover sm:h-[150px] rounded-xl'/>
                                    <h1 className='text-white text-left text-xl font-Oswald font-normal pt-2 capitalize sm:text-sm'>{b.title}</h1>
                                    <p className='text-left text-white font-Sansation text-sm pt-1 sm:text-[12px] sm:leading-[22px]'>{b.description?.substr(0, 200)}...</p>
                                </Link>
                    })}

    </div>

    <Eclips />
    </motion.div>
  )
}

export default Blogs
