import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DiWindows } from "react-icons/di";
import { FaPlaystation, FaXbox } from "react-icons/fa";
import { BsNintendoSwitch } from "react-icons/bs";
import { CiMobile1 } from "react-icons/ci";
import { Context } from '../state/Provider';

function SelectselectedPlaform({ plat_forms }) {
    const [t] = useTranslation('global')
    const {selectedPlaform, setSelectedPlaform} = useContext(Context)

    const PC = plat_forms.filter((p) => p.plateform_name.startsWith('PC')).length > 0 ? plat_forms.filter((p) => p.plateform_name.startsWith('PC'))[0] : null
    const Play = plat_forms.filter((p) => p.plateform_name.startsWith('Play')).length > 0 ? plat_forms.filter((p) => p.plateform_name.startsWith('Play'))[0] : null
    const Xbox = plat_forms.filter((p) => p.plateform_name.startsWith('Xbox')).length > 0 ? plat_forms.filter((p) => p.plateform_name.startsWith('Xbox'))[0] : null
    const Nintendo = plat_forms.filter((p) => p.plateform_name.startsWith('Nintendo')).length > 0 ? plat_forms.filter((p) => p.plateform_name.startsWith('Nintendo'))[0] : null
    const Mobile = plat_forms.filter((p) => p.plateform_name.startsWith('Mobile')).length > 0 ? plat_forms.filter((p) => p.plateform_name.startsWith('Mobile'))[0] : null


    useEffect(() => {
        const selectedPlaforms = [PC, Play, Xbox, Nintendo, Mobile];
        const validselectedPlaform = selectedPlaforms.find(selectedPlaform => selectedPlaform !== null);
        setSelectedPlaform(validselectedPlaform); 
    }, [])

    return (
        <div className='flex flex-col gap-3'>
            <h1 className='text-white font-Oswald text-[38px] font-bold sm:text-[26px]'>{t('orderPage.selectselectedPlaform')}</h1>
            <div className='flex justify-start items-center gap-3 flex-wrap'>
                {PC && <div className={`rounded-xl px-5 cursor-pointer py-4 flex justify-center items-center gap-2 sm:gap-1 sm:px-3 sm:py-2 sm:rounded-md ${selectedPlaform?.id == PC?.id ? 'bg-gradient-to-r from-[#0091F7] to-[#0674C1] border-[1px] border-primary-100/75' : 'bg-[#ffffff1e] border-[1px] border-white'} transition-all ease-in duration-200`} onClick={() =>
                    setSelectedPlaform(PC)
                }>
                    <DiWindows className='text-[35px] text-white sm:text-2xl' />
                    <p className='text-white font-Oswald text-[22px] text-bold sm:text-lg'>PC</p>
                </div>}
                {Play && <div className={`rounded-xl px-5 cursor-pointer py-4 flex justify-center items-center gap-2 sm:gap-1 sm:px-3 sm:py-2 sm:rounded-md ${selectedPlaform?.id == Play?.id ? 'bg-gradient-to-r from-[#0091F7] to-[#0674C1] border-[1px] border-primary-100/75' : 'bg-[#ffffff1e] border-[1px] border-white'} transition-all ease-in duration-200`} onClick={() =>
                    setSelectedPlaform(Play)}>
                    <FaPlaystation className='text-[35px] text-white sm:text-2xl' />
                    <p className='text-white font-Oswald text-[22px] text-bold sm:text-lg'>Play Station</p>
                </div>}
                {Xbox && <div className={`rounded-xl px-5 cursor-pointer py-4 flex justify-center items-center gap-2 sm:gap-1 sm:px-3 sm:py-2 sm:rounded-md ${selectedPlaform?.id == Xbox?.id ? 'bg-gradient-to-r from-[#0091F7] to-[#0674C1] border-[1px] border-primary-100/75' : 'bg-[#ffffff1e] border-[1px] border-white'} transition-all ease-in duration-200`} onClick={() =>
                    setSelectedPlaform(Xbox)}>
                    <FaXbox className='text-[30px] text-white sm:text-xl' />
                    <p className='text-white font-Oswald text-[22px] text-bold sm:text-lg'>Xbox</p>
                </div>}
                {Nintendo && <div className={`rounded-xl px-5 cursor-pointer py-4 flex justify-center items-center gap-2 sm:gap-1 sm:px-3 sm:py-2 sm:rounded-md ${selectedPlaform?.id == Nintendo?.id ? 'bg-gradient-to-r from-[#0091F7] to-[#0674C1] border-[1px] border-primary-100/75' : 'bg-[#ffffff1e] border-[1px] border-white'} transition-all ease-in duration-200`} onClick={() =>
                    setSelectedPlaform(Nintendo)}>
                    <BsNintendoSwitch className='text-[30px] text-white sm:text-xl' />
                    <p className='text-white font-Oswald text-[22px] text-bold sm:text-lg'>Nintendo</p>
                </div>}
                {Mobile && <div className={`rounded-xl px-5 cursor-pointer py-4 flex justify-center items-center gap-2 sm:gap-1 sm:px-3 sm:py-2 sm:rounded-md ${selectedPlaform?.id == Mobile?.id ? 'bg-gradient-to-r from-[#0091F7] to-[#0674C1] border-[1px] border-primary-100/75' : 'bg-[#ffffff1e] border-[1px] border-white'} transition-all ease-in duration-200`} onClick={() =>
                    setSelectedPlaform(Mobile)}>
                    <CiMobile1 className='text-[35px] text-white sm:text-xl' />
                    <p className='text-white font-Oswald text-[22px] text-bold sm:text-lg'>Mobile</p>
                </div>}
            </div>
        </div>
    )
}

export default SelectselectedPlaform
