import React, { useEffect, useState } from 'react'
import AssignBooster from './AssignBooster'
import { BACKEND_HOST } from '../constants'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'

function GameBooster() {
    const [booster, setBooster] = useState(null)
    const [loading, setLoading] = useState(null)
    const { id } = useParams()

    const remove = async(_id) => {
        const confirm = window.confirm('Are you sure unassigning him from this game?')
        if(!confirm)
            return
        setLoading(true)
        try {
            const response = await fetch(`${BACKEND_HOST}/unassign_booster_to_game`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'Application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({ game_id: id, booster_id: _id })
            })

            const res = await response.json()
            if (response.status === 200 && res.status) {
                setBooster(null)
                fetchBooster()
                toast.success(res.error ? res.error : res.message)
            }
            else
                toast.error(res.error ? res.error : res.message)
        } catch (error) {
            console.log(error);
        }
        setLoading(false)
    }

    const fetchBooster = async() => {
        try {
            const response = await fetch(`${BACKEND_HOST}/get_assigned_boosters`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'Application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({ game_id: id })
            })

            const res = await response.json()
            if (response.status === 200 && res.status) {
            
                setBooster(res.data);
            }
            else
                toast.error(res.error ? res.error : res.message)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(()=>{
        fetchBooster()
    }, [])

  return (
    <div className='flex justify-between items-start gap-6 sm:flex-col-reverse sm:gap-1'>
     <div className='w-[72%] sm:w-full flex flex-col gap-3 mt-3'>
            <div className="overflow-x-auto overflow-y-auto h-[285px]">
                {!booster && <img src={require('../assets/loading.gif')} alt='loader' className='w-7 h-7 sm:h-5 sm:w-5'/>}
                {booster?.length === 0 && <p className='text-white text-sm sm:text-[12px] font-Sansation'>No data found.</p>}
                {booster?.length > 0 && <table className="min-w-full sm:min-w-[700px]">
                    <thead>
                        <tr>
                            <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-1 sm:py-1 text-grey">#</th>
                            <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey">Username</th>
                            <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey">Platforms</th>
                            <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey">Rating</th>
                            <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {booster?.map((b, index) => {
                            return <tr className="bg-primary-300 border-b-[4px] border-primary-100/10" key={index}>
                                        <td className="py-2 px-4 text-left font-Sansation text-white text-sm border-b-white sm:px-1 sm:py-1 sm:text-[10px] sm:leading-[20px] align-top">{index+1}</td>
                                        <td className="py-2 px-4 text-left font-Sansation text-white text-sm flex justify-start items-center gap-2  sm:px-2 sm:py-1 sm:text-[10px] sm:leading-[20px] min-w-[150px]">
                                            <img src={b.profile_image? `${BACKEND_HOST.replace('/api', '')}/${b?.profile_image}` : require('../assets/defaultAvatar.jpg')} alt="avatar" className="w-7 h-7 rounded-full border-[1px] border-white/50 sm:w-5 sm:h-5"/>
                                            {b.username}
                                        </td>
                                        <td className="py-2 px-4 text-left font-Sansation text-white text-sm border-b-white sm:px-1 sm:py-1 sm:text-[10px] sm:leading-[20px] align-top min-w-[150px]">{b.answer}</td>
                                        <td className="py-2 px-4 text-left font-Sansation text-white text-sm border-b-white sm:px-1 sm:py-1 sm:text-[10px] sm:leading-[20px] align-top min-w-[150px]">{b.answer}</td>
                                        <td className="py-2 px-4 text-left font-Sansation text-white text-sm sm:px-2 sm:py-1 sm:text-[10px] sm:leading-[20px] align-top">
                                            <span className='flex justify-start items-start gap-3'>
                                                <button disabled={loading} onClick={()=>remove(b.id)} className="text-red">Remove</button>
                                            </span>
                                        </td>
                                    </tr>
                        })}
                    </tbody>
                </table>}
            </div>
    </div>
    <div className='w-[28%] sm:w-full flex flex-col gap-3  bg-primary-300 px-4 pt-3 pb-5 rounded-2xl mt-4'>
            <h1 className="text-white font-Sansation text-base sm:text-sm">Assign New</h1>
            <AssignBooster setBoosterr={setBooster} fetchBooster={fetchBooster}/>
    </div>
</div>
  )
}

export default GameBooster
