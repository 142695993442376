import React, { useEffect, useState } from 'react'
import Card from '../components/Card'
import { BACKEND_HOST } from '../constants'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import axios from 'axios'

function BlogList() {
    const [blogs, setBlogs] = useState(null)
    const [loading, setLoading] = useState(false) 

    const fetchBlogs = async() => {
        try {
            const response = await axios.get(`${BACKEND_HOST}/admin_get_blogs`, {
                headers: {
                    'Content-Type': 'Application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })
    
            if(response.status === 200 && response.data.status){
                setBlogs(response.data.data);
            }
            else
                toast.error(response.data.error? response.data.error : response.data.message)
        } catch (error) {
            console.log(error);
        }
    }

    const deleteBlog = async(id) => {
        const confirm = window.confirm('Are you sure deleting this?', id)
        if(!confirm)
            return;
       
        setLoading(true)
        try {
            const response = await fetch(`${BACKEND_HOST}/admin_delete_blogs`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'Application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({blog_id: id})
            })
    
            const res = await response.json()
            if(response.ok && res.status){
                setBlogs(null)
                fetchBlogs()
                toast.success(res.error? res.error : res.message)
            }
            else
                toast.error(res.error? res.error : res.message)
        } catch (error) {
            console.log(error);
        }
        setLoading(false)
    }

    

    useEffect(()=>{
        fetchBlogs()
    }, [])
  return (
    <Card>
        <span className='flex justify-between items-center'>
            <h1 className="text-white font-Oswald text-3xl sm:text-2xl">Blogs</h1>
            <Link to='add' className='bg-primary-100/75 hover:bg-primary-100/100 transition-all ease-in duration-100 rounded-md text-sm font-Sansation px-5 py-2 text-white sm:px-3 sm:py-1 sm:text-[12px]'>Add Blog</Link>
        </span>
        <div className="overflow-x-auto">
            {!blogs && <img src={require('../assets/loading.gif')} alt='loader' className='w-7 h-7 sm:h-5 sm:w-5'/>}
            {blogs?.length === 0 && <p className='text-white text-sm sm:text-[12px] font-Sansation'>No data found.</p>}
            {blogs?.length > 0 && <table className="min-w-full sm:min-w-[700px]">
                <thead>
                    <tr>
                        <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-1 sm:py-1 text-grey">#</th>
                        <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey">Title</th>
                        <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey whitespace-nowrap">Added On</th>
                        <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey whitespace-nowrap">Updated On</th>
                        <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {blogs?.map((b, index) => {
                        return <tr className="bg-primary-300 border-b-[4px] border-primary-100/10" key={index}>
                                    <td className="py-2 px-4 text-left font-Sansation text-white text-sm border-b-white sm:px-1 sm:py-1 sm:text-[10px] sm:leading-[20px]">{index+1}</td>
                                    <td className="py-2 px-4 text-left font-Sansation text-white text-sm flex justify-start items-center gap-2  sm:px-2 sm:py-1 sm:text-[10px] sm:leading-[20px] min-w-[400px] sm:min-w-[200px]">
                                        <img src={`${BACKEND_HOST.replace('/api', '')}/${b?.image}`} alt="avatar" className="w-7 h-7 rounded-md border-[1px] border-white/50 sm:w-5 sm:h-5"/>
                                        {b.title?.length > 100? `${b.title.substr(0, 100)}...` : b.title}
                                    </td>
                                    <td className="py-2 px-4 text-left font-Sansation text-white text-sm sm:px-2 sm:py-1 sm:text-[10px] sm:leading-[20px]">{b.created_at.substr(0,10)}</td>
                                    <td className="py-2 px-4 text-left font-Sansation text-white text-sm sm:px-2 sm:py-1 sm:text-[10px] sm:leading-[20px]">{b.updated_at.substr(0,10)}</td>
                                    <td className="py-0 px-4 text-left font-Sansation text-white text-sm sm:px-2 sm:py-1 sm:text-[10px] sm:leading-[20px]">
                                        <span className='flex justify-start items-start gap-3'>
                                        <Link to={`edit/${b.id}`} className="text-primary-100">Edit</Link>
                                        <button disabled={loading} onClick={()=>deleteBlog(b.id)} className="text-red">Delete</button>
                                        </span>
                                    </td>
                                </tr>
                    })}
                    
                </tbody>
            </table>}
        </div>
    </Card>
  )
}

export default BlogList
