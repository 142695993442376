import './App.css';
import Login from './pages/Login';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import SignUp from './pages/SignUp';
import Footer from './components/Footer';
import Contact from './pages/Contact';
import SideBar from './components/SideBar';
import JoinUs from './pages/JoinUs';
import { ToastContainer, Zoom, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Home from './pages/Home';
import GameDetail from './pages/GameDetail';
import HeaderNav from './components/HeaderNav';
import UserDashboard from './pages/UserDashboard';
import { AnimatePresence } from 'framer-motion'
import { useEffect } from 'react';
import Boosting from './pages/Boosting';
import Provider from './state/Provider';
import Admin from './layout/Admin';
import Blogs from './pages/Blogs';
import BlodDetail from './pages/BlodDetail';
import AdminProvider from './state/AdminProvider';
import Chat from './components/Chat';
import Booster from './pages/Booster';
import CustomeOrderRequest from './pages/CustomeOrderRequest';


function App() {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)

    if (window.location.pathname.startsWith('/admin') && localStorage.getItem('role') != 'admin')
      window.location.pathname = '/'
  }, [location])


  if (window.location.pathname === '/login' || window.location.pathname === '/signup')
    return (
      <>
        <div className='bg-primary-200 w-full overflow-hidden'>
          <Routes>
            <Route path='/login' element={<Login />} />
            <Route path='/signup' element={<SignUp />} />
          </Routes>
        </div>
        <Footer />
        <ToastContainer transition={Zoom} position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />

      </>
    );

  else if (window.location.pathname.startsWith('/admin') && localStorage.getItem('role') === 'admin')
    return (
      <AdminProvider>
        <div className='bg-primary-200 w-full overflow-hidden'>
          <Routes>
            <Route path='/admin/*' element={<Outlet />}>
              <Route path='*' element={<Admin />} />
            </Route>
          </Routes>
        </div>
        <ToastContainer transition={Zoom} position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
      </AdminProvider>
    );

  else
    return (
      <>

        <Provider>
          <SideBar />
          <HeaderNav />
          <div className='bg-primary-200 w-full overflow-hidden'>
            <AnimatePresence>
              <Routes>
                <Route path='/' element={<Home />} />
                {/* <Route path='/game-detail/:id/:name' element={<GameDetail />} /> */}

                <Route path='/game-detail/:id/:name' element={<Outlet />}>
                  <Route index element={<GameDetail />} />
                  <Route path='boosting/:b_id/:b_name' element={<Boosting />} />
                </Route>

                <Route path='/join-us' element={<JoinUs />} />
                <Route path='/booster/:id' element={<Booster />} />
                <Route path='/contact' element={<Contact />} />
                <Route path='/dashboard/*' element={<Outlet />}>
                  <Route path='*' element={<UserDashboard />} />
                </Route>
                
                <Route path='/orders/chat/:order_id/:reciever_id' element={<Chat />} />
 
                {/* <Route path='/dashboard' element={<UserDashboard />} /> */}
                <Route path='/custom-order' element={<CustomeOrderRequest />} />
                <Route path='/blogs' element={<Outlet />}>
                  <Route index element={<Blogs />} />
                  <Route path='blog-detail/:id' element={<BlodDetail />} />
                </Route>
                <Route path='*' element={<Navigate to='/' />} />
              </Routes>
            </AnimatePresence>
          </div>
          <Footer />
        </Provider>

        <ToastContainer transition={Zoom} position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
      </>
    );
}

export default App;
