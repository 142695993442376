import React, { useEffect, useState } from 'react'
import Input2 from './Input2'
import { toast } from 'react-toastify'
import axios from 'axios'
import { BACKEND_HOST } from '../constants'
import Button2 from './Button2'
import { IoIosCloseCircleOutline } from 'react-icons/io'

function AddPromocode({ fetchCodes, setCodes, popup, setPopup }) {
  const [code, setCode] = useState(null)
  const [loading, setLoading] = useState(false)

  const onChange = (e) => {
    setCode({ ...code, [e.target.name]: e.target.value })
    console.log(code);
  }

  const addNewCode = async () => {
    console.log(code);
    try {
      let data = JSON.stringify({
        "promo_code": code.promo_code,
        "percentage": code.percentage,
        "start_date": code.start_date,
        "end_date": code.end_date,
        "total_availables": code.total_availables,
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${BACKEND_HOST}/admin_add_promo_code`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        data: data
      };

      const response = await axios.request(config);
      console.log(response);
      if (response.status === 200 && response.data.status == true) {
        toast.success(response.data.error ? response.data.error : response.data.message)
        setCode(null)
        document.getElementById('promo_code').value = ''
        document.getElementById('percentage').value = ''
        setCodes(null)
        setPopup(false)
        fetchCodes()
      } else {
        toast.error(response.data.error ? response.data.error : response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    addNewCode()
    await new Promise((r) => setTimeout(() => r(), 1500));
    setLoading(false)
  }

  useEffect(() => {

  }, [code, popup])

  return (
    <div className={`fixed sm:w-full  right-0 w-[80%] h-screen bg-[rgba(0,0,0,0.72)] z-50 flex justify-center items-center ${popup ? 'z-50 top-0 popupopens' : '-z-50 top-[-2000px]'}`}>
      <div className={`admin rounded-xl sm:px-4 sm:w-[80%] px-7 py-5 w-[55%] relative flex gap-2 flex-col transition-all ease-in duration-100`}>
        <IoIosCloseCircleOutline onClick={() => { setPopup(false) }} className='bg-red/50 text-white p-[2px] rounded-full text-[30px] absolute -top-2 right-2 cursor-pointer' />
        <h1 className="text-white font-Oswald text-3xl sm:text-2xl">Add New Discount Codes</h1>
        <form className='flex flex-col gap-4' onSubmit={onSubmit}>
          <div className='flex gap-3 sm:flex-col sm:gap-2'>
            <Input2 placeholder={"Code"} type={"text"} name={"promo_code"} value={code?.promo_code} required={true} onChange={onChange} />
            <Input2 placeholder={"Percentage"} type={"number"} name={"percentage"} value={code?.percentage} required={true} onChange={onChange} />
          </div>
          <div className='flex gap-3 sm:flex-col sm:gap-2'>
            <Input2 placeholder={"Start Date"} type={"date"} name={"start_date"} value={code?.start_date} required={true} onChange={onChange} />
            <Input2 placeholder={"End Date"} type={"date"} name={"end_date"} value={code?.end_date} required={true} onChange={onChange} />
            <Input2 placeholder={"Total Available"} type={"number"} name={"total_availables"} value={code?.total_availables} required={true} onChange={onChange} />
          </div>
          <button disabled={loading} className='bg-primary-100 text-base py-2 text-white rounded-xl disabled:opacity-50 disabled:cursor-wait opacity-90'>Submit</button>
        </form>
      </div>
    </div>
  )
}

export default AddPromocode
