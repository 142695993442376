import React from 'react'
import { useTranslation } from 'react-i18next';
import { BiSolidDollarCircle } from "react-icons/bi";
import { FaArrowLeft, FaArrowRight, FaComments, FaSackDollar } from "react-icons/fa6";

function WhyBoostings() {
  const [t,] = useTranslation("global")
  const slideLeft = () => {
    var slider = document.getElementById('slider');
    slider.scrollLeft = slider.scrollLeft - 500;
  };

  const slideRight = () => {
    var slider = document.getElementById('slider');
    slider.scrollLeft = slider.scrollLeft + 500;
  };
  return (
    <div className='relative flex items-start ml-60 mt-20 gap-4 flex-col sm:ml-0 sm:pl-7 sm:mt-60  z-10'>
      <div className='flex justify-between items-center w-full'>
        <h1 className='text-white text-[50px] leading-[60px] font-Oswald font-bold sm:text-[26px] sm:leading-[36px]'>{t('joinPage.Why_Boostinger')} <span className="text-primary-100">{t('joinPage.Why_BoostingerBlue')}</span></h1>
        <span className='flex justify-end items-center gap-2 mr-20 z-20 sm:hidden'>
          <FaArrowLeft className='flex justify-center items-center border-[1px] border-primary-100 p-3 text-white cursor-pointer rounded-full text-[45px] hover:bg-primary-100 transition-all ease-in duration-150' onClick={slideLeft}/>
          <FaArrowRight className='flex justify-center items-center border-[1px] border-primary-100 p-3 bg-primary-100 text-white cursor-pointer rounded-full text-[45px]' onClick={slideRight}/>
        </span>
      </div>
      <div id='slider' className='slider w-full h-full overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide mt-7 z-10 sm:mt-3'>
        <div className='rounded-[20px] border-white border-[1px] glass w-[367px] sm:w-[250px] px-[26px] py-[45px] inline-block whitespace-normal mr-[19px]'>
          <img src={require('../assets/6769630-removebg-preview.png')} alt='click 1' className='w-[84px] z-10 mx-auto mb-3 sm:mb-4 sm:w-[50px]' />
          <h1 className='text-white text-[28px] leading-[38px] font-Oswald font-bold text-center sm:text-[16px] sm:leading-[26px]'>{t('joinPage.whyboostiengerCard1Head')}</h1>
          <p className='text-white text-base font-Sansation text-center mt-3 sm:text-[12px] sm:leading-[22px]'>{t('joinPage.whyboostiengerCard1Text')}</p>
        </div>
        <div className='rounded-[20px] border-primary-100 border-[1px] bg-primary-100 w-[367px] sm:w-[250px] px-[40px] py-[45px] sm:pb-[43px] inline-block whitespace-normal mr-[19px]'>
          <BiSolidDollarCircle className='text-[84px] text-white mx-auto mb-5 sm:text-[50px] sm:mb-1' />
          <h1 className='text-white text-[28px] leading-[38px] font-Oswald font-bold text-center sm:text-[16px] sm:leading-[26px]'>{t('joinPage.whyboostiengerCard2Head')}</h1>
          <p className='text-white text-base font-Sansation text-center mt-5 sm:mt-2 sm:text-[12px] sm:leading-[22px]'>{t('joinPage.whyboostiengerCard2Text')}</p>
        </div>
        <div className='rounded-[20px] border-white border-[1px] glass w-[367px]  sm:w-[250px] px-[26px] py-[45px] inline-block whitespace-normal mr-[19px]'>
          <FaSackDollar className='text-[64px] text-white mx-auto mb-3 sm:text-[50px] sm:mb-1' />
          <h1 className='text-white text-[28px] leading-[38px] font-Oswald font-bold text-center sm:text-[16px] sm:leading-[26px]'>{t('joinPage.whyboostiengerCard3Head')}</h1>
          <p className='text-white text-base font-Sansation text-center mt-3 sm:mt-1 sm:text-[12px] sm:leading-[22px]'>{t('joinPage.whyboostiengerCard3Text')}</p>
        </div>
        <div className='rounded-[20px] border-white border-[1px] glass w-[367px]  sm:w-[250px] px-[26px] py-[45px] inline-block whitespace-normal mr-[19px]'>
          <FaComments className='text-[84px] text-white mx-auto mb-3 sm:text-[50px] sm:mb-1' />
          <h1 className='text-white text-[28px] leading-[38px] font-Oswald font-bold text-center sm:text-[16px] sm:leading-[26px]'>{t('joinPage.whyboostiengerCard4Head')}</h1>
          <p className='text-white text-base font-Sansation text-center mt-3 sm:mt-1 mb-2 sm:mb-6 sm:text-[12px] sm:leading-[22px]'>{t('joinPage.whyboostiengerCard4Text')}</p>
          <p className='text-white text-base font-Sansation text-center mt-3 mb-2 sm:mb-0'>{}</p><br/><br/><br className='sm:hidden'/>
        </div>

      </div>
    </div>

  )
}

export default WhyBoostings
