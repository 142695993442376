import React, { useEffect, useState } from 'react'
import Card from '../components/Card'
import { BACKEND_HOST } from '../constants'
import axios from 'axios'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import Platforms from '../components/Platforms'

function GameMangement() {
  const [games, setGames] = useState(null)


  const fetchGames = async() => {
    try {
        const response = await axios.get(`${BACKEND_HOST}/admin_get_games`, {
            headers: {
                'Content-Type': 'Application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })

        if(response.status === 200 && response.data.status){
            console.log(response.data);
            setGames(response.data.data);
        }
        else
            toast.error(response.data.error? response.data.error : response.data.message)
    } catch (error) {
        console.log(error);
    }
  }
 

  useEffect(()=>{
    fetchGames()
  }, [])

  return (
    <div className='flex gap-5 sm:flex-col sm:gap-2'>
        <div className='w-[50%] sm:w-full'>
            <Card>
                <h1 className="text-white font-Oswald text-3xl sm:text-2xl">Games</h1>
                <div className="overflow-x-auto">
                        {!games && <img src={require('../assets/loading.gif')} alt='loader' className='w-7 h-7 sm:h-5 sm:w-5'/>}
                        {games?.length === 0 && <p className='text-white text-sm sm:text-[12px] font-Sansation'>No data found.</p>}
                        {games?.length > 0 && <table className="min-w-full sm:min-w-[700px]">
                            <thead>
                                <tr>
                                    <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-1 sm:py-1 text-grey">#</th>
                                    <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey">Name</th>
                                    <th className="py-2 px-4 text-right font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {games?.map((b, index) => {
                                    if(index > 8)
                                        return;
                                    return <tr className="bg-primary-300 border-b-[4px] border-primary-100/10" key={index}>
                                                <td className="py-2 px-4 text-left font-Sansation text-white text-sm border-b-white sm:px-1 sm:py-1 sm:text-[10px] sm:leading-[20px] align-top">{index+1}</td>
                                                <td className="py-2 px-4 text-left font-Sansation text-white text-sm flex justify-start items-center gap-2  sm:px-2 sm:py-1 sm:text-[10px] sm:leading-[20px]  align-top min-w-[350px]">{b.title}</td>
                                                <td className="py-2 px-4 text-left font-Sansation text-white text-sm sm:px-2 sm:py-1 sm:text-[10px] sm:leading-[20px]  align-top">
                                                    <span className='flex justify-end items-start gap-3  align-top'>
                                                    <Link to={`edit/${b.id}`} className="bg-primary-100/75 py-1 px-3 rounded-md text-white">Edit</Link>
                                                    </span>
                                                </td>
                                            </tr>
                                })}
                                
                            </tbody>
                        </table>}
                </div>
            </Card>
        </div>

        <div className='w-[50%] sm:w-full'>
            <Platforms />
        </div>

    </div>
  )
}

export default GameMangement
