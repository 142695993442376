import React, { useEffect, useState } from 'react'
import Textarea2 from './Textarea2'
import axios from 'axios'
import { BACKEND_HOST } from '../constants'
import Input2 from './Input2'
import Button2 from './Button2'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'

function AddFaq( { fetchfaqs, setFaqs } ) {
    const [faq, setFaq] = useState(null)
    const [loading, setLoading] = useState(false)

    const { id } = useParams()

    const onChange = (e)  => {
        setFaq({ ...faq, [e.target.name]: e.target.value })
        console.log(faq);
    }

    const addNewFaq = async() => {
        try {
            let data = JSON.stringify({
                "game_id": id,
                "question": faq.question,
                "answer": faq.answer
              });
    
          let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${BACKEND_HOST}/admin_add_faqs`,
            headers: {
                'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            data: data
          };

          const response = await axios.request(config);
          if (response.status === 200 && response.data.status) {
               toast.success(response.data.error ? response.data.error : response.data.message)
                  setFaq(null)
                  document.getElementById('question').value = ''
                  document.getElementById('answer').value = ''
                  setFaqs(null)
                  fetchfaqs()
          } else {
            toast.error(response.data.error ? response.data.error : response.data.message);
          }
        } catch (error) {
          console.log(error);
        }
    }

    const onSubmit = async(e) => {
        e.preventDefault()
        setLoading(true)
        addNewFaq()
        await new Promise((r) => setTimeout(() => r(), 1500));
        setLoading(false)
    }
    
  useEffect(()=>{

  }, [faq])

  return (
    <form className='flex flex-col gap-2' onSubmit={onSubmit}>
      <Input2 placeholder={"Question"} type={"text"} name={"question"} value={faq?.question} required={true} onChange={onChange} />
      <Textarea2 placeholder={"Answer"} type={"text"} name={"answer"} value={faq?.answer} required={true} onChange={onChange} />
      <Button2 title={"Submit"} disabled={loading} />
    </form>
  )
}

export default AddFaq
