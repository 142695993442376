import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { BACKEND_HOST } from '../constants'
import { Link } from 'react-router-dom'

function GameServices({ id }) {
    const [gameServices, setGameServices] = useState(null)
    const [loading, setLoading] = useState(false)

    const fetchGameServices = async () => {
        try {
            const response = await axios.get(`${BACKEND_HOST}/admin_get_game_services`, {
                headers: {
                    'Content-Type': 'Application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })

            if (response.status === 200 && response.data.status) {
                const filter = response.data.data.filter((s) => s.game_id == id)
                setGameServices(filter);
            }
            else
                toast.error(response.data.error ? response.data.error : response.data.message)
        } catch (error) {
            console.log(error);
        }
    }

    const deleteService = async (id) => {
        const confirm = window.confirm('Are you sure deleting this?', id)
        if (!confirm)
            return;

        setLoading(true)
        try {
            const response = await fetch(`${BACKEND_HOST}/admin_delete_game_services`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'Application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({ game_service_id: id })
            })

            const res = await response.json()
            if (response.ok && res.status) {
                setGameServices(null)
                fetchGameServices()
                toast.success(res.error ? res.error : res.message)
            }
            else
                toast.error(res.error ? res.error : res.message)
        } catch (error) {
            console.log(error);
        }
        setLoading(false)
    }

    useEffect(() => {
        fetchGameServices()
    }, [])

    return (
        <>
            <span className='flex justify-end items-center mt-4'>
                <Link to='add-service' className='bg-primary-100/75 hover:bg-primary-100/100 transition-all ease-in duration-100 rounded-md text-sm font-Sansation px-5 py-2 text-white'>Add Service</Link>
            </span>
            <div className="overflow-x-auto overflow-y-auto h-[250px] mt-2">
                {!gameServices && <img src={require('../assets/loading.gif')} alt='loader' className='w-7 h-7' />}
                {gameServices?.length === 0 && <p className='text-white text-sm sm:text-[12px] font-Sansation'>No data found.</p>}
                {gameServices?.length > 0 && <table className="min-w-full sm:min-w-[700px]">
                    <thead>
                        <tr>
                            <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-1 sm:py-1 text-grey">#</th>
                            <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey">Title</th>
                            <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey whitespace-nowrap">Added On</th>
                            <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey whitespace-nowrap">Updated On</th>
                            <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {gameServices?.map((b, index) => {
                            return <tr className="bg-primary-300 border-b-[4px] border-primary-100/10" key={index}>
                                <td className="py-2 px-4 text-left font-Sansation text-white text-sm border-b-white sm:px-1 sm:py-1 sm:text-[10px] sm:leading-[20px]">{index + 1}</td>
                                <td className="py-2 px-4 text-left font-Sansation text-white text-sm flex justify-start items-center gap-2  sm:px-2 sm:py-1 sm:text-[10px] sm:leading-[20px] min-w-[400px]">
                                    {b.service_title}
                                </td>
                                <td className="py-2 px-4 text-left font-Sansation text-white text-sm sm:px-2 sm:py-1 sm:text-[10px] sm:leading-[20px]">{b.created_at.substr(0, 10)}</td>
                                <td className="py-2 px-4 text-left font-Sansation text-white text-sm sm:px-2 sm:py-1 sm:text-[10px] sm:leading-[20px]">{b.updated_at.substr(0, 10)}</td>
                                <td className="py-0 px-4 text-left font-Sansation text-white text-sm sm:px-2 sm:py-1 sm:text-[10px] sm:leading-[20px]">
                                    <span className='flex justify-start items-start gap-3'>
                                        <Link to={`edit-service/${b.id}`} className="text-primary-100">Edit</Link>
                                        <button disabled={loading} onClick={() => deleteService(b.id)} className="text-red">Delete</button>
                                    </span>
                                </td>
                            </tr>
                        })}
                      

                    </tbody>
                </table>}
            </div>

        </>
    )
}

export default GameServices
