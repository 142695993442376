import React, { useState } from 'react'
import { MdOutlineKeyboardArrowDown } from 'react-icons/md'

function Accordian(props) {

    const [accordian, setAccordian] = useState(false)

  return (
        <div className='relative overflow-hidden border-[1px] border-white/40 px-5 py-4 sm:px-4 sm:py-3 rounded-xl bg-primary-200 bg-primary-100/5'>
                <div className='flex justify-between items-center cursor-pointer' onClick={() => setAccordian(state => !state)}>
                    <span className='flex justify-start items-center gap-3'>
                        <h2 className='text-white font-Sansation text-lg capitalize sm:text-sm'>{props.title}</h2>
                    </span>
                    <MdOutlineKeyboardArrowDown className={`text-white text-3xl sm:text-2xl ${accordian && 'rotate-180'} transition-all ease-in duration-200`} />
                </div>
                <div className={`${accordian ? props.hight : 'h-0 overflow-hidden'} transition-all ease-in duration-200`}>
                    {props.children}
                </div>
            </div>
  )
}

export default Accordian
