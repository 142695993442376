import React, { createContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BACKEND_HOST } from "../constants";
import axios from "axios";
import { toast } from "react-toastify";

export const Context = createContext();

function Provider(props) {
  const [userLoggedIn, setUserLoggedIn] = useState(false)
  const [game, setGame] = useState(null)
  const [gameBooster, setGameBooster] = useState(null)
  const [gameServices, setGameServices] = useState(null)
  const [gameFaqs, setGameFaqs] = useState(null)



  const [selectedService, setSelectedService] = useState()

  const [selectedPlacementRank, setSelectedPlacementRank] = useState()
  const [selectedCurrentRank, setSelectedCurrentRank] = useState()
  const [selectedDesiredank, setSelectedDesiredRank] = useState()

  const [selectedPlaform, setSelectedPlaform] = useState()

  const [selectedPlacementRankLevel, setSelectedPlacementRankLevel] = useState()
  const [selectedCurrentRankLevel, setSelectedCurrentRankLevel] = useState()
  const [selectedDesiredankLevel, setSelectedDesiredRankLevel] = useState()

  const [disabledDesiredRankIndex, setDisabledDesiredRankIndex] = useState([])
  const [disabledDesiredRankLevelIndex, setDisabledDesiredRankLevelIndex] = useState([])

  const [selectedNGames, setSelectedNGames] = useState(1)
  const [selectedNGamesPrice, setSelectedNGamesPrice] = useState(0)
  const [selectedCutomization, setSelectedCustomization] = useState([])
  const [selectedDropdownItems, setSelectedDropdownItems] = useState([])

  const [currentRankPoints, setCurrentRankPoints] = useState(0)
  const [desiredRankPoints, setDesiredRankPoints] = useState(0)
  const [placementRankPoints, setPlacementRankPoints] = useState(0)
  
  const [currentLevelPoints, setCurrentLevelPoints] = useState(0)
  const [desiredLevelPoints, setDesiredLevelPoints] = useState(0)
  const [placementLevelPoints, setPlacementLevelPoints] = useState(0)

  const [price, setPrice] = useState(0)
  const [timeSpan, setTimeSpan] = useState(0)

  const logOut = () => {
    localStorage.clear()
    setUserLoggedIn(false)
    window.location.pathname = '/'
  }


  const fetchGame = async (id) => {
    let data = JSON.stringify({
      "game_id": id
    })
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${BACKEND_HOST}/get_single_game_details`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      data: data
    };
    try {
      const response = await axios.request(config)
      if (response.status === 200 && response.data.status == true) {
        let game = response.data.data[0]
        setGame(game)
        setGameBooster(game.boosters)
        setGameServices(game.services)
        setGameFaqs(game.faqs)
      }
      else
        toast.error(response.data.error ? response.data.error : response.data.message)
    } catch (error) {
      console.log(error);
    }
  }

  const handleSelectedService = (s_id) => {
    const service = gameServices?.filter((s) => s.id == s_id)[0]
    setSelectedCustomization([])
    setSelectedDropdownItems([])
    setSelectedCurrentRank(null)
    setSelectedDesiredRank(null)
    setSelectedPlacementRank(null)
    setSelectedCurrentRankLevel(null)
    setSelectedDesiredRank(null)
    setSelectedPlacementRankLevel(null)
    setCurrentRankPoints(null)
    setDesiredRankPoints(null)
    setPlacementRankPoints(null)
    setCurrentLevelPoints(null)
    setDesiredLevelPoints(null)
    setPlacementLevelPoints(null)
    setSelectedNGames(1)
    // finally update the service
    setSelectedService(service)
  }


  const calculateTime = (x) => {

    let days = 0
    for (let i = 24; i <= x; i + 24) {
      days++;
      x -= 24
    }

    setTimeSpan({
      days: days,
      hours: x,
    })
  }



  const calculatePriceForConversion = async(current_r_index, desired_r_index, current_l_index, desired_l_index) => {
    const ranks = selectedService?.rank?.ranks;

    let _price = 0
    let hours = 0

    for (let i = current_r_index; i <= desired_r_index; i++) {
      const startLevel = (i === current_r_index) ? current_l_index + 1 : 0;
      const endLevel = (i === desired_r_index) ? desired_l_index : ranks[i].levels.length - 1;

      // level price being calcualeted
      for (let j = startLevel; j <= endLevel; j++) {
        if (ranks[i].levels[j]) {
          let level_price = parseFloat(ranks[i].levels[j]?.price)

          if (selectedNGames)
            level_price *= selectedNGames

          _price += level_price

          hours += parseFloat(ranks[i].levels[j]?.time)
        }
      }

      // level points price being calculated
      for (let j = startLevel-1; j <= endLevel; j++) {
        if(j < 0)
          continue;

        if(j === current_l_index && i === current_r_index){
          let points_range = parseInt(ranks[i]?.levels[j].levelpoints[0]?.max_point) - currentLevelPoints
          if(points_range >= 1)
            _price += parseFloat(points_range * parseFloat(ranks[i]?.levels[j].levelpoints[0]?.price))
        }
        
        else if(j === desired_l_index){
          let points_range = desiredLevelPoints - parseInt(ranks[i]?.levels[j].levelpoints[0]?.min_point) 
          if(points_range >= 1)
            _price += parseFloat(points_range * parseFloat(ranks[i]?.levels[j].levelpoints[0]?.price))
        }
        else{
          let points_range = parseInt(ranks[i]?.levels[j].levelpoints[0]?.max_point)  - parseInt(ranks[i]?.levels[j].levelpoints[0]?.min_point) 
          if(points_range >= 1)
            _price += parseFloat(points_range * parseFloat(ranks[i]?.levels[j].levelpoints[0]?.price))
        }
       
        
      }


      // rank point price being calculated
      if(current_r_index === desired_r_index){
        let points_range = desiredRankPoints - currentRankPoints
        if(points_range >= 1)
          _price += parseFloat(points_range * parseFloat(ranks[i]?.points[0]?.price))
      }
      else if(i === current_r_index){
        let points_range = parseInt(ranks[i]?.points[0]?.max_point) - currentRankPoints
        if(points_range >= 1)
          _price += parseFloat(points_range * parseFloat(ranks[i]?.points[0]?.price))
      }
      else if(i === desired_r_index){
        let points_range = desiredRankPoints - parseInt(ranks[i]?.points[0]?.min_point)
        if(points_range > 0 )
          _price += parseFloat(points_range * parseFloat(ranks[i]?.points[0]?.price))
      }
      else{
        let points_range = parseInt(ranks[i]?.points[0]?.max_point) - parseInt(ranks[i]?.points[0]?.min_point)
        if(points_range >= 1)
          _price += parseFloat(points_range * parseFloat(ranks[i]?.points[0]?.price))
      }
    }

    let dropdownPrices = 0
    let customPrices = 0

    for (let i = 0; i < selectedDropdownItems.length; i++) {
      dropdownPrices += parseFloat(_price * parseFloat(selectedDropdownItems[i].price)) / 100
    }
    for (let i = 0; i < selectedCutomization?.length; i++) {
      customPrices += parseFloat(_price * parseFloat(selectedCutomization[i].percentage)) / 100
    }

    _price += dropdownPrices
    _price += customPrices

    calculateTime(hours)
    setPrice(_price)
  }

  const calaulatePriceForNotRanks = () => {
    let _price = selectedNGames * selectedNGamesPrice

    let dropdownPrices = 0
    let customPrices = 0

    for (let i = 0; i < selectedDropdownItems?.length; i++) {
      dropdownPrices += parseFloat(_price * parseFloat(selectedDropdownItems[i].price)) / 100
    }
    for (let i = 0; i < selectedCutomization?.length; i++) {
      customPrices += parseFloat(_price * parseFloat(selectedCutomization[i].percentage)) / 100
    }

    _price += dropdownPrices
    _price += customPrices

    setPrice(_price)
  }

  const calculatePriceForPlacement = () => {
    let _price = parseFloat(selectedPlacementRankLevel?.price)

    let rank_points_range =  parseInt(placementRankPoints) - parseInt(selectedPlacementRank?.points[0]?.min_point) 
    if(rank_points_range >= 1){
      _price += parseFloat(rank_points_range * parseFloat(selectedPlacementRank?.points[0]?.price))
    }

    let level_points_range =  parseInt(placementLevelPoints) - parseInt(selectedPlacementRankLevel?.levelpoints[0]?.min_point) 
    if(level_points_range >= 1){
      _price += parseFloat(level_points_range * parseFloat(selectedPlacementRankLevel?.levelpoints[0]?.price))
    }

    _price *= selectedNGames

    let dropdownPrices = 0
    let customPrices = 0

    for (let i = 0; i < selectedDropdownItems.length; i++) {
      dropdownPrices += parseFloat(_price * parseFloat(selectedDropdownItems[i].price)) / 100
    }
    for (let i = 0; i < selectedCutomization?.length; i++) {
      customPrices += parseFloat(_price * parseFloat(selectedCutomization[i].percentage)) / 100
    }

    _price += dropdownPrices
    _price += customPrices

    let hours = parseFloat(selectedPlacementRankLevel?.time)
    hours *= selectedNGames

    calculateTime(hours)

    setPrice(isNaN(_price) ? 0 : _price)

  }

  useEffect(() => {
    setSelectedPlacementRankLevel(selectedPlacementRank?.levels[0])
  }, [selectedPlacementRank])

  useEffect(() => {
    const current_rank_index = selectedService?.rank?.ranks?.findIndex(r => r.id === selectedCurrentRank?.id)
    let disabled = []
    for (let i = current_rank_index - 1; i >= 0; i--) {
      disabled.push(i)
    }
    setDisabledDesiredRankIndex(disabled)
    setSelectedDesiredRank(selectedService?.rank?.ranks[current_rank_index])
  }, [selectedCurrentRank])

  useEffect(() => {
    const current_rank_index = selectedService?.rank?.ranks?.findIndex(r => r.id === selectedCurrentRank?.id)
    const current_level_index = selectedService?.rank?.ranks[current_rank_index]?.levels?.findIndex(r => r.id === selectedCurrentRankLevel?.id)
    let disabled = []
    for (let i = current_level_index; i >= 0; i--) {
      disabled.push({
        rank_id: selectedCurrentRank?.id,
        index: i
      })
    }
    setDisabledDesiredRankLevelIndex(disabled)
    if (current_level_index == selectedService?.rank?.ranks[current_rank_index]?.levels.length - 1) {
      if (current_rank_index + 1 <= selectedService?.rank?.ranks.length - 1) {
        setSelectedDesiredRank(selectedService?.rank?.ranks[current_rank_index + 1])
        setSelectedDesiredRankLevel(selectedService?.rank?.ranks[current_rank_index + 1]?.levels[0])
      }
    }
    // else
      // setSelectedDesiredRankLevel(selectedService?.rank?.ranks[current_rank_index]?.levels[current_level_index + 1])
  }, [selectedCurrentRankLevel, selectedCurrentRank])


  useEffect(() => {
    if (selectedService?.rank?.rank_type === 'Conversion') {
      const current_rank_index = selectedService?.rank?.ranks?.findIndex(r => r.id === selectedCurrentRank?.id)
      const disered_rank_index = selectedService?.rank?.ranks?.findIndex(r => r.id === selectedDesiredank?.id)

      const current_level_index = selectedService?.rank?.ranks[current_rank_index]?.levels?.findIndex(r => r.id === selectedCurrentRankLevel?.id)
      const desired_level_index = selectedService?.rank?.ranks[disered_rank_index]?.levels?.findIndex(r => r.id === selectedDesiredankLevel?.id)
      calculatePriceForConversion(current_rank_index, disered_rank_index, current_level_index, desired_level_index)
    }
    else if (selectedService?.rank?.rank_type === 'Placement') {
      calculatePriceForPlacement()
    }
    else if (!selectedService?.rank) {
      calaulatePriceForNotRanks()
    }
    else { }
  })

  useEffect(() => {
    if (localStorage.getItem('token'))
      setUserLoggedIn(true)
  }, [])

  const contextValues = {
    logOut,
    userLoggedIn,
    fetchGame,
    game,
    gameBooster,
    gameFaqs,
    gameServices,
    selectedService,
    setSelectedService,
    handleSelectedService,
    selectedPlacementRank, setSelectedPlacementRank,
    selectedCurrentRank, setSelectedCurrentRank,
    selectedDesiredank, setSelectedDesiredRank,
    selectedCutomization, setSelectedCustomization,
    selectedNGames, setSelectedNGames,
    selectedPlacementRankLevel, setSelectedPlacementRankLevel,
    selectedCurrentRankLevel, setSelectedCurrentRankLevel,
    selectedDesiredankLevel, setSelectedDesiredRankLevel,
    disabledDesiredRankIndex,
    disabledDesiredRankLevelIndex,
    selectedDropdownItems, setSelectedDropdownItems,
    setGame, setGameBooster, setGameFaqs, setGameServices,
    price,
    timeSpan,
    setSelectedNGamesPrice,
    selectedPlaform, setSelectedPlaform,
    placementRankPoints, setPlacementRankPoints,
    desiredRankPoints, setDesiredRankPoints,
    currentRankPoints, setCurrentRankPoints,
    setCurrentLevelPoints, setDesiredLevelPoints, setPlacementLevelPoints
  };

  return (
    <Context.Provider value={contextValues}>
      {props.children}
    </Context.Provider>
  );
}

export default Provider;
