import React, { createContext, useEffect, useState } from "react";

export const AdminContext = createContext();

function AdminProvider(props) {
  const [dropdownPopup, setDropDownPopup] = useState(false)
  const [rankPopup, setRankPopup] = useState(false)
  const [rankMetadata, setRankMetadata] = useState(null)
  const [refresh, setRefresh] = useState(false)
  const [boosterPopup, setBoosterPopup] = useState(false)
  const [customOrderPopup, setCustomOrderPopup] = useState(false)
  
  const [levelPopup, setLevelPopup] = useState(false)

  const contextValues = {
    setDropDownPopup, dropdownPopup,
    rankPopup, setRankPopup,
    rankMetadata, setRankMetadata,
    refresh, setRefresh,
    boosterPopup, setBoosterPopup,
    levelPopup, setLevelPopup,
    customOrderPopup, setCustomOrderPopup
  };
  return (
    <AdminContext.Provider value={contextValues}>
      {props.children}
    </AdminContext.Provider>
  );
}

export default AdminProvider;
