import React, { useContext, useEffect } from 'react'
import { Context } from '../state/Provider';
import { useParams } from 'react-router-dom';
import apex_tr from '../assets/apex_tr.png'
import cs_tr from '../assets/cs_tr.png'
import ll_tr from '../assets/ll_tr.png'
import over_tr from '../assets/over_tr.png'
import rain_tr from '../assets/rain_tr.png'
import rock_tr from '../assets/rock_tr.png'
import warzone_tr from '../assets/warzone_tr.png'
import defaultTR from '../assets/trophy (2).png'

const trophies = [
    ll_tr,
    '',
    rain_tr,
    rock_tr,
    over_tr,
    cs_tr,
    warzone_tr,
    apex_tr,
    warzone_tr,
]

function NumberOfGameAddon({ range }) {
    const { id } = useParams()
    const { selectedNGames, setSelectedNGames, setSelectedNGamesPrice } = useContext(Context)

    const handleOnchange = (e) => {
        let newValue = parseInt(e.target.value, 10);

        if (isNaN(newValue) || newValue < Number(range.min)) {
            setSelectedNGames(range.min);
        } else if (newValue <= Number(range.max)) {
            setSelectedNGames(newValue);
        } else {
            setSelectedNGames(Number(range.max));
        }
    }

    useEffect(()=>{
        setSelectedNGamesPrice(range.price)
        setSelectedNGames(range.min)
    }, [])



    useEffect(()=>{
        document.getElementById('numgmaes').value = selectedNGames
    }, [selectedNGames])
    return (
        <div className='game-rank-border overflow-hidden'>
            <div className='relative overflow-hidden px-5 py-4 rounded-[18px] sm:px-3 sm:py-2 sm:rounded-sm transition-all ease-in duration-150' style={{ background: "linear-gradient(189deg, #239ED6 3.05%, #0776AE 11.09%, #005394 34.57%, #003360 77.68%, #00417F 126.67%)" }}>
                <div className='flex gap-4 justify-start items-center sm:gap-2'>
                    <img src={trophies[id-1]} onError={(event) => event.target.src = defaultTR} alt='trophy' className='w-14 drop-shadow-[4.744px_3.795px_13.282px_#123698] sm:w-8' />
                    <span className='flex flex-col'>
                        <h1 className='text-3xl text-white font-Oswald  sm:text-[20px] sm:leading-[30px]'>Number of Games</h1>
                        <p className='text-white font-Sansation text-sm sm:text-[10px]'>Select the Number of Games</p>
                    </span>
                </div>
                <div className='flex justify-between items-center mt-5'>
                    <div className='w-[80%] flex justify-between items-center gap-3 sm:gap-2'>
                        <p className='text-white font-Oswald text-lg sm:text-sm'>{range.min}</p>
                        <input type="range" class="w-full bg-transparent cursor-pointer appearance-none disabled:opacity-50 disabled:pointer-events-none focus:outline-none
                                                    [&::-webkit-slider-thumb]:w-2.5
                                                    [&::-webkit-slider-thumb]:h-2.5
                                                    [&::-webkit-slider-thumb]:-mt-0.5
                                                    [&::-webkit-slider-thumb]:appearance-none
                                                    [&::-webkit-slider-thumb]:bg-white
                                                    [&::-webkit-slider-thumb]:shadow-[0_0_0_4px_rgba(37,99,235,1)]
                                                    [&::-webkit-slider-thumb]:rounded-full
                                                    [&::-webkit-slider-thumb]:transition-all
                                                    [&::-webkit-slider-thumb]:duration-150
                                                    [&::-webkit-slider-thumb]:ease-in-out
                                                    [&::-webkit-slider-thumb]:dark:bg-slate-700

                                                    [&::-moz-range-thumb]:w-2.5
                                                    [&::-moz-range-thumb]:h-2.5
                                                    [&::-moz-range-thumb]:appearance-none
                                                    [&::-moz-range-thumb]:bg-white
                                                    [&::-moz-range-thumb]:border-4
                                                    [&::-moz-range-thumb]:bg-[#0091F7]
                                                    [&::-moz-range-thumb]:rounded-full
                                                    [&::-moz-range-thumb]:transition-all
                                                    [&::-moz-range-thumb]:duration-150
                                                    [&::-moz-range-thumb]:ease-in-out

                                                    [&::-webkit-slider-runnable-track]:w-full
                                                    [&::-webkit-slider-runnable-track]:h-1
                                                    [&::-webkit-slider-runnable-track]:bg-gray-100
                                                    [&::-webkit-slider-runnable-track]:rounded-full
                                                    [&::-webkit-slider-runnable-track]:dark:bg-[#0091F7]

                                                    [&::-moz-range-track]:w-full
                                                    [&::-moz-range-track]:h-2
                                                    [&::-moz-range-track]:bg-[#0091F7]
                                                    [&::-moz-range-track]:rounded-full" 

                                                    id="steps-range-slider-usage" value={selectedNGames} min={range.min} max={range.max} step="1" onChange={handleOnchange} />
                        <p className='text-white font-Oswald text-lg sm:text-sm'>{range.max}</p>
                    </div>
                    <input type='number' id='numgmaes' onChange={handleOnchange} value={selectedNGames} className='bg-[#0A1F42] px-10 py-1 rounded-md sm:px-5 text-lg outline-none text-center text-white font-Oswald sm:text-base w-[15%]'/>
                </div>
            </div>
        </div>
    )
}

export default NumberOfGameAddon
