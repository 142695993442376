import React from 'react'

function Input2({ name, type, onChange, required, placeholder, value, disabled, readOnly = false }) {
  if(value)
  return (
    <div className='relative w-full px-4 pt-2 sm:px-3 sm:pt-2 rounded-[10px] overflow-hidden border-[1px] border-white/55'>
    <input
      disabled={disabled}
      id={name}
      name={name}
      type={type}
      onChange={onChange}
      required={required}
      readOnly={readOnly}
      step="any"
      defaultValue={value || ''}
      placeholder={''}
      className='text-white relative font-Sansation text-base outline-none sm:text-[12px] bg-transparent w-full z-10 mt-[8px] input pb-1 sm:pb-1 disabled:cursor-not-allowed'
    />
    <label htmlFor={name} className='absolute text-white/55 font-Sansation sm:text-[12px] label text-base top-3 left-4 sm:left-3 sm:top-[10px]'>{placeholder}</label>
    <div className='bg-white sm:bg-[#44444446] sm:opacity-30 absolute top-0 left-0 opacity-10 w-full h-[60px] rounded-[10px] blur-[4px] sm:blur-[16px]'></div>
  </div>
  )

  return (
    <div className='relative w-full px-4 pt-2 sm:px-3 sm:pt-2 rounded-[10px] overflow-hidden border-[1px] border-white/55'>
    <input
      disabled={disabled}
      id={name}
      name={name}
      type={type}
      readOnly={readOnly}
      step="any"
      onChange={onChange}
      required={required}
      placeholder={''}
      className='text-white relative font-Sansation text-base outline-none sm:text-[12px] bg-transparent w-full z-10 mt-[8px] input pb-1 sm:pb-1 disabled:cursor-not-allowed'
    />
    <label htmlFor={name} className='absolute text-white/55 font-Sansation sm:text-[12px] label text-base top-3 left-4  sm:left-3 sm:top-[10px]'>{placeholder}</label>
    <div className='bg-white sm:bg-[#44444446] sm:opacity-30 absolute top-0 left-0 opacity-10 w-full h-[60px] rounded-[10px] blur-[4px] sm:blur-[16px]'></div>
  </div>
  )
}

export default Input2
