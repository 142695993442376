import React, { useContext, useEffect } from 'react'
import Select from 'react-select'
import { selectMobStyles, selectStyles } from '../utils/selectStyles'
import { Context } from '../state/Provider'

function DropdownAddon({ dropdowns }) {
    return (
        <div className='game-rank-border relative'>
            <div className='flex absolute w-full gap-1 px-5 py-4 sm:px-3 sm:py-2 sm:rounded-3xl rounded-[18px]'>
                {dropdowns?.map((d, index) => {
                    return <div className={`w-[${d.width}] flex flex-col gap-1 z-10 relative`}>
                        <p className='text-white font-Oswald text-lg sm:text-sm'>{d.title}</p>
                        <SelectDropdown items={d.items} />
                    </div>
                })}
            </div>
            <div className='relative overflow-hidden h-[100px] sm:h-[90px] flex gap-2 px-5 py-4 rounded-[18px] sm:px-3 sm:py-2 sm:rounded-[10px]' style={{ background: "linear-gradient(59deg, #239ED6 3.05%, #0776AE 11.09%, #005394 34.57%, #003360 77.68%, #00417F 126.67%)" }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="256" height="152" viewBox="0 0 256 152" fill="none" className='absolute right-16 -top-16 z-[0] sm:-top-16 sm:right-52  sm:w-[150px]'>
                    <g opacity="0.5" filter="url(#filter0_f_215_126)">
                        <ellipse cx="128.188" cy="38.3958" rx="80.1875" ry="65.3958" fill="#24B0FF" />
                    </g>
                    <defs>
                        <filter id="filter0_f_215_126" x="0.709908" y="-74.2901" width="254.955" height="225.372" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                            <feGaussianBlur stdDeviation="23.645" result="effect1_foregroundBlur_215_126" />
                        </filter>
                    </defs>
                </svg>
            </div>
        </div>
    )
}

export default DropdownAddon


const SelectDropdown = ({ items }) => {
    const { selectedDropdownItems, setSelectedDropdownItems } = useContext(Context)
    let options = []
    for (let i = 0; i < items.length; i++) {
        let option = {
            value: items[i].id,
            label: items[i].name,
            price: items[i].price,
        }
        options.push(option)
    }

    const handleOnChange = (opt) => {
        const filteredItems = selectedDropdownItems.filter(item =>
            !options.find(option => option.value === item.value)
        )
        setSelectedDropdownItems([...filteredItems, opt]);
    }


    return (
        <Select onChange={(opt) => handleOnChange(opt)} components={{ IndicatorSeparator: () => null }} options={options} styles={window.innerWidth >= 768 ? selectStyles : selectMobStyles} />
    )
}