import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Textarea from './Textarea'
import Button from './Button'
import StarRatings from 'react-star-ratings'
import { BACKEND_HOST } from '../constants'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

function FeedbackPopup({ setFeedbackPopup, order_id }) {
    const [t] = useTranslation('global')
    const [review, setReview] = useState({ speed_rating: 0, communication_rating: 0, description: '' })
    const [refresh, setRefresh] = useState(false)
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()

    const onSubmit = async(e) => {
        e.preventDefault();
        if (review.speed_rating == 0 || review.communication_rating == 0)
            return;

        setLoading(true)
        const user = JSON.parse(localStorage.getItem('user'))
        const order = JSON.parse(localStorage.getItem('order'))

        let data = JSON.stringify({
            "user_id": user?.id,
            "booster_id": order?.booster_id,
            "order_id": order?.id,
            "speed_rating": review.speed_rating,
            "communication_rating": review.communication_rating,
            "description": review.description
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${BACKEND_HOST}/add_order_feedback`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        let response = await axios.request(config)
        if(response.data?.status == true){
            toast.success(response.data.message)
            setFeedbackPopup(false)
            navigate(-1)
        }else
            toast.error(response.data.message)
        setLoading(false)
    }
    return (
        <div className='fixed top-0 left-0 z-50 w-full h-screen flex justify-center items-center'>
            <div onClick={() => setFeedbackPopup(false)} className='fixed top-0 left-0 w-full h-screen bg-primary-300/70'></div>
            <form onSubmit={onSubmit} className='bg-primary-300 rounded-2xl w-[40%] sm:w-[80%] shadow-2xl z-50 px-6 py-5 flex flex-col gap-2'>
                <h1 className='font-Oswald text-2xl text-white'>{t('userDashboard.leavefeedbak')}</h1>
                <div className='flex justify-between items-center mt-4'>
                    <p className='font-Sansation text-lg text-white'>{t('userDashboard.speed')}</p>
                    <StarRatings rating={review.speed_rating} starRatedColor="#FFA500" starHoverColor="#FFA500" starEmptyColor="#ebd8b5" starDimension="25px" numberOfStars={5} changeRating={(x) => { review.speed_rating = x; setRefresh(state => !state) }} />
                </div>
                <div className='flex justify-between items-center mb-3'>
                    <p className='font-Sansation text-lg text-white'>{t('userDashboard.comm')}</p>
                    <StarRatings rating={review.communication_rating} starRatedColor="#FFA500" starHoverColor="#FFA500" starEmptyColor="#ebd8b5" starDimension="25px" numberOfStars={5} changeRating={(x) => { review.communication_rating = x; setRefresh(state => !state) }} />
                </div>
                <Textarea required={true} placeholder={t("userDashboard.review")} name={"description"} value={review.description} onChange={(e) => { review.description = e.target.value; setRefresh(state => !state) }} />
                <Button title={t('userDashboard.publish')} disabled={loading}/>
            </form>
        </div>
    )
}

export default FeedbackPopup
