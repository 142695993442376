import React, { useContext, useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import Eclips from '../components/Eclips'
import { Link, useParams } from 'react-router-dom'
import { RiSecurePaymentFill } from "react-icons/ri";
import { useTranslation } from 'react-i18next';
import { MdOutlineKeyboardDoubleArrowDown, MdVpnLock } from 'react-icons/md';
import { BiSolidSave, BiSupport } from "react-icons/bi";
import { FaHandHoldingUsd } from "react-icons/fa";
import { AiFillDollarCircle } from "react-icons/ai";
import GameRanks from '../components/GameRanks';
import Cart from '../components/Cart';
import SelectPlatform from '../components/SelectPlatform';
import SelectQueue from '../components/SelectQueue';
import CustomizeBoosting from '../components/CustomizeBoosting';
import { Context } from '../state/Provider';
import DropdownAddon from '../components/DropdownAddon';
import NumberOfGameAddon from '../components/NumberOfGameAddon';
import { BACKEND_HOST } from '../constants';
import Faq from '../components/Faq';
import axios from 'axios';

const bgimg = [ 
    "bg-[url('./assets/LeaqueLengBG.jpeg')]", 
    "bg-[url('./assets/peakpx (1).jpg')]", 
    "bg-[url('./assets/rainbowBG.jpeg')]" ,
    "bg-[url('./assets/rocketleagueBG.jpeg')]" ,
    "bg-[url('./assets/overwatchhbg.jpeg')]" ,
    "bg-[url('./assets/IMG_1380.jpeg')]" ,
    "bg-[url('./assets/CODMWBG.png')]" ,
    "bg-[url('./assets/APEXLEGENDSBG.jpeg')]" ,
    "bg-[url('./assets/cod_warzone.jpg')]" ,
]

function Boosting() {
    const { fetchGame, gameServices, game, selectedService, handleSelectedService, setSelectedService } = useContext(Context)
    const { id, name, b_id, b_name } = useParams()
    const [t] = useTranslation("global")

    
    const [faq, setFaq] = useState([])

    const getFaqs = async() => {
        let data = JSON.stringify({
            "service_id": b_id
            });
            
            let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${BACKEND_HOST}/service_faqs`,
            headers: { 
                'Content-Type': 'application/json'
            },
            data : data
            };
            

        const res = await axios.request(config)
        if(res.data.status == true)
            setFaq(res.data.data)
        else
            console.log("error fetching faqs: " + res);
   
    }

    useEffect(()=>{
        setSelectedService(game?.services?.filter(s => s.id == b_id)[0])
    }, [game])

    useEffect(()=>{
        handleSelectedService(b_id)
        getFaqs()
    }, [b_id])

    useEffect(()=>{
        fetchGame(id)
    }, [id])

    return (
        <motion.div initial={{ width: '0%', opacity: 0 }} animate={{ width: '100%', opacity: 1 }} exit={{ x: window.innerWidth, transition: { duration: 1 } }} className='opacity-0 flex flex-col w-[100%] gap-[150px] sm:gap-[80px] overflow-hidden relative '>
            <div className={`relative bg-cover bg-no-repeat bg-center sm:bg-center opacity-30 sm:opacity-30 sm:h-[900px] ${bgimg[id-1]} h-screen w-full -mt-0`}>
            </div>
            <div className='flex flex-col justify-start items-start z-10 pl-60 pr-20 -mt-[660px] sm:px-7 sm:-mt-[800px]'>
                <h1 className='text-white text-[60px] leading-[70px] font-Oswald font-bold sm:text-[35px] sm:leading-[45px] capitalize'>{name} <span className='text-primary-100'>{b_name}</span></h1>
                <p className='text-left text-white font-Sansation text-lg py-5 sm:text-[12px] sm:leading-[22px] sm:w-full w-[90%]'>{game?.description}</p>
                <div className='flex gap-3 justify-start items-center z-10 my-5 sm:flex-wrap sm:gap-2'>
                        <div className='relative'>
                            <div className='flex feature cursor-pointer z-10 justify-start items-center px-4 py-2 bg-[#0090f755] rounded-[50px] gap-2  sm:gap-1 sm:px-3 sm:py-1 z-20'>
                                <RiSecurePaymentFill className='text-primary-100/95 text-[22px] sm:text-base'/>
                                <p className='text-white text-sm font-Sansation sm:text-[10px]'>{t('orderPage.ssl')}</p>
                            </div>
                            <div className='absolute opacity-0 left-2 z-20 transition-all ease-in saturate-100 top-12 sm:top-8 shadow-sm bg-white/95 px-3 py-1 win-w-[0px] rounded-r-xl rounded-b-xl border-[1px] border-white/60 max-w-[150px]'>
                                <p className='font-semibold text-sm leading-4 font-Sansation sm:text-[10px]'>{t('orderPage.ssl_info')} </p>
                            </div>
                        </div>
                        <div className='relative'>
                        <div className='flex feature cursor-pointer z-10 justify-start items-center px-4 py-2 bg-[#0090f755] rounded-[50px] gap-2 sm:gap-1 sm:px-3 sm:py-1'>
                            <MdVpnLock  className='text-primary-100/95 text-[22px] sm:text-base'/>
                            <p className='text-white text-sm font-Sansation sm:text-[10px]'>{t('orderPage.vpn')}</p>
                        </div>
                        <div className='absolute opacity-0 left-2 z-20 transition-all ease-in saturate-100 top-12 sm:top-8 shadow-sm bg-white/95 px-3 py-1 win-w-[0px] rounded-r-xl rounded-b-xl border-[1px] border-white/60 max-w-[150px]'>
                                <p className='font-semibold text-sm leading-4 font-Sansation sm:text-[10px]'>{t('orderPage.vpn_info')} </p>
                            </div>
                        </div>
                        <div className='relative'>
                        <div className='flex feature cursor-pointer z-10 justify-start items-center px-4 py-2 bg-[#0090f755] rounded-[50px] gap-2  sm:gap-1 sm:px-3 sm:py-1'>
                            <BiSolidSave  className='text-primary-100/95 text-[22px] sm:text-base'/>
                            <p className='text-white text-sm font-Sansation sm:text-[10px]'>{t('orderPage.save_serv')}</p>
                        </div>
                        <div className='absolute opacity-0 left-2 z-20 transition-all ease-in saturate-100 top-12 sm:top-8 shadow-sm bg-white/95 px-3 py-1 win-w-[0px] rounded-r-xl rounded-b-xl border-[1px] border-white/60 max-w-[150px]'>
                                <p className='font-semibold text-sm leading-4 font-Sansation sm:text-[10px]'>{t('orderPage.safe_info')} </p>
                            </div>
                        </div>
                        <div className='relative'>
                        <div className='flex feature cursor-pointer z-10 justify-start items-center px-4 py-2 bg-[#0090f755] rounded-[50px] gap-2  sm:gap-1 sm:px-3 sm:py-1'>
                            <BiSupport className='text-primary-100/95 text-[22px] sm:text-base'/>
                            <p className='text-white text-sm font-Sansation sm:text-[10px]'>{t('orderPage.24_7')}</p>
                        </div>
                        <div className='absolute opacity-0 left-2 z-20 transition-all ease-in saturate-100 top-12 sm:top-8 shadow-sm bg-white/95 px-3 py-1 win-w-[0px] rounded-r-xl rounded-b-xl border-[1px] border-white/60 max-w-[150px]'>
                                <p className='font-semibold text-sm leading-4 font-Sansation sm:text-[10px]'>{t('orderPage.24_info')} </p>
                            </div>
                        </div>
                           <div className='relative'>
                        <div className='flex feature cursor-pointer z-10 justify-start items-center px-4 py-2 bg-[#0090f755] rounded-[50px] gap-2  sm:gap-1 sm:px-3 sm:py-1'>
                            <FaHandHoldingUsd className='text-primary-100/95 text-[22px] sm:text-base'/>
                            <p className='text-white text-sm font-Sansation sm:text-[10px]'>{t('orderPage.money_refund')}</p>
                        </div>
                        <div className='absolute opacity-0 left-2 z-20 transition-all ease-in saturate-100 top-12 sm:top-8 shadow-sm bg-white/95 px-3 py-1 win-w-[0px] rounded-r-xl rounded-b-xl border-[1px] border-white/60 max-w-[150px]'>
                        <p className='font-semibold text-sm leading-4 font-Sansation sm:text-[10px]'>{t('orderPage.money_info')} </p>
                    </div>
                        </div>
                        <div className='relative'>
                        <div className='flex feature cursor-pointer z-10 justify-start items-center px-4 py-2 bg-[#0090f755] rounded-[50px] gap-2  sm:gap-1 sm:px-3 sm:py-1'>
                            <AiFillDollarCircle className='text-primary-100/95 text-[22px] sm:text-base'/>
                            <p className='text-white text-sm font-Sansation sm:text-[10px]'>{t('orderPage.cash')}</p>
                        </div>
                        <div className='absolute opacity-0 left-2 z-20 transition-all ease-in saturate-100 top-12 sm:top-8 shadow-sm bg-white/95 px-3 py-1 win-w-[0px] rounded-r-xl rounded-b-xl border-[1px] border-white/60 max-w-[150px]'>
                        <p className='font-semibold text-sm leading-4 font-Sansation sm:text-[10px]'>{t('orderPage.cash_info')} </p>
                    </div>
                </div>
                </div>

                <div className=' mt-10 sm:mt-5 rounded-[60px] min-w-0 max-w-full border-[1px] border-white overflow-hidden sm:border-none sm:rounded-none sm:flex-wrap sm:bg-transparent flex flex-row justify-start items-center sm:justify-start sm:items-start' >
                   {gameServices?.map((s, index) =>{
                    return <Link to={`/game-detail/${id}/${name}/boosting/${s.id}/${s.service_title}`} className={`cursor-pointer ${b_id == s.id && 'bg-gradient-to-r from-[#0091F7] to-[#0674C1] border-[1px] border-white'}  py-[10px] px-7 sm:px-3 sm:py-1 rounded-[60px] `}>
                                <p className='text-white font-Sansation text-base sm:text-[10px] tracking-wider'>{s.service_title}</p>
                            </Link>
                   })}
                </div>
            </div>

            <div className='flex justify-between items-start gap-10 pl-60 -mt-[90px]  z-10 pr-20 sm:px-7 sm:flex-col sm:gap-14 sm:-mt-[50px]'>
                <div className='w-[65%] flex flex-col gap-9 sm:w-full sm:gap-5'>
                   
                   {selectedService?.rank?.rank_type === 'Placement' && <GameRanks id={0} rank_type={selectedService?.rank?.rank_type} ranks={selectedService?.rank?.ranks} title={selectedService?.rank?.title}/>}
                   {selectedService?.rank?.rank_type === 'Conversion' && 
                    <div className='relative flex flex-col gap-5 sm:gap-3'>
                            <GameRanks id={0} rank_type={selectedService?.rank?.rank_type} ranks={selectedService?.rank?.ranks} title={t('orderPage.current_rank')}/>
                            <span className='flex justify-center items-center bg-[#0091F7] rounded-full py-2 px-2 absolute top-[46%] left-[45%] z-30 sm:p-1'>
                                <MdOutlineKeyboardDoubleArrowDown className='text-white text-[25px] sm:text-lg'/>
                            </span>
                            <GameRanks id={1} rank_type={selectedService?.rank?.rank_type} ranks={selectedService?.rank?.ranks} title={t('orderPage.desired_rank')}/>
                        </div>
                    }
                    {selectedService?.dropdowns?.length > 0 && <DropdownAddon dropdowns={selectedService?.dropdowns}/>}
                    {selectedService?.numberofgames?.length > 0 && <NumberOfGameAddon range={selectedService?.numberofgames[0]}/>}
                    {game?.game_plateforms?.length>0 && <SelectPlatform plat_forms={game?.game_plateforms}/>}
                    {game?.queue && <SelectQueue />}
                    {selectedService?.customizes?.length > 0 && <CustomizeBoosting  customizes={selectedService?.customizes}/>}
                </div>
                <div className='w-[35%] sm:w-full'>
                    <Cart />
                </div>
            </div>

              {/* faq */}
              <div className='pl-60 pr-10 mt-10 pb-[180px] sm:pb-[70px] flex justify-center items-center flex-col gap-10 sm:gap-5 sm:px-7 sm:mt-28 z-10'>
                <h1 className='text-white text-center text-[50px] leading-[60px] font-Oswald font-bold sm:text-[24px] sm:leading-[34px]'>{t('home.faq')} <span className='text-primary-100'>{t('home.faqBlue')}</span></h1>

                <div className='flex flex-col gap-4 w-[70%] sm:gap-3 sm:w-full'>
                    {faq.map((f, index) => {
                        return <Faq ques={f.question} ans={f.answer} index={index}/>
                    } )}
                    
                </div>
            </div>

            <Eclips top={'sm:top-[300px]'}/>
            <div className='hidden sm:block bg-primary-100 rounded-full absolute z-[1] sm:w-[300px] sm:h-[300px] sm:opacity-50 sm:-right-28 sm:blur-[50px] sm:-top-28'></div>
        </motion.div>
    )
}

export default Boosting
