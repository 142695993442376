import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Eclips from '../components/Eclips'
import { GiLotusFlower } from "react-icons/gi";
import { Link, useParams } from 'react-router-dom';
import Button from '../components/Button';
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import { BACKEND_HOST } from '../constants';
import Faq from '../components/Faq';
import { motion } from 'framer-motion'

import codmv3 from '../assets/codmw3.png'
import apexChar from '../assets/apex top.png'
import apexCharBottom from '../assets/apex bottom.png'
import codwarzone2 from '../assets/codwarzone2.png'
import rocketleagueChar from '../assets/rocketleagueChar.png'
import leagueoflegendschar from '../assets/leagueoflegendschar.png'
import valorant from '../assets/joinus1.png'
import rainbowchar from '../assets/rainbowchar.png'
import overwatchChar from '../assets/overwatchChar.png'
import counterStrikeChar from '../assets/counterStrikeChar.png'
import codBottom from '../assets/COD bottom.png'
import overwatchB from '../assets/overwatchB.png'
import csBot from '../assets/counter strike bottom.png'
import raniB from '../assets/rainbow six siege bottom.png'
import rockBot from '../assets/rocket league bottom.png'
import volBot from '../assets/Valorant bottom.png'
import warBot from '../assets/warzone 2 bottom.png'
import leagueB from '../assets/px.png'
import { Context } from '../state/Provider';

const chars = [
    {
        img: leagueoflegendschar,
        classes: 'w-[80%] -mt-10 sm:mt-0'
    },
    {
        img: valorant,
        classes: 'mt-32 sm:mt-[300px] sm:-ml-14'
    },
    {
        img: rainbowchar,
        classes: 'mt-[400px] h-[1000px] sm:h-auto sm:mt-[450px]'
    },
    {
        img: rocketleagueChar,
        classes: 'h-auto sm:-mt-20'
    },
    {
        img: overwatchChar,
        classes: ''
    },
    {
        img: counterStrikeChar,
        classes: 'mt-[400px] ml-[50px] h-[1000px] sm:h-auto sm:mt-[470px]'
    },
    {
        img: codmv3,
        classes: 'mt-20 sm:mt-24'
    },
    {
        img: apexChar,
        classes: '-mt-16 ml-10 sm:ml-20 h-[700px] sm:h-auto sm:mt-20 sm:h-[450px]'
    },
    {
        img: codwarzone2,
        classes: '-mt-20 h-[500px] object-cover sm:-mt-10 sm:h-[350px]'
    },
]

const bottomChars = [
    {
        img: leagueB,
        classes: 'h-[500px] sm:h-[450px] scale-x-[-1]'
    },
    {
        img: volBot,
        classes: 'h-[500px] sm:h-[400px]'
    },
    {
        img: raniB,
        classes: ' h-[550px] sm:h-[450px]'
    },
    {
        img: rockBot,
        classes: 'w-[500px] sm:w-[370px]'
    },
    {
        img: overwatchB,
        classes: 'h-[500px] sm:h-[450px]'
    },
    {
        img: csBot,
        classes: 'sm:h-[450px] h-[550px] scale-x-[-1]'
    },
    {
        img: codBottom,
        classes: 'h-[650px] sm:h-[450px]'
    },
    {
        img: apexCharBottom,
        classes: 'h-[550px] sm:h-[450px]'
    },
    {
        img: warBot,
        classes: 'h-[500px] scale-x-[-1] h-auto'
    },
]



const bgimg = [ 
    "bg-[url('./assets/IMG_1387.jpeg')]", 
    "bg-[url('./assets/1075657.jpg')]", 
    "bg-[url('./assets/IMG_1377.jpeg')]" ,
    "bg-[url('./assets/rock.png')]" ,
    "bg-[url('./assets/IMG_1371.jpeg')]" ,
    "bg-[url('./assets/IMG_1382.jpeg')]" ,
    "bg-[url('./assets/codmw3(2).jpeg')]" ,
    "bg-[url('./assets/IMG_5387.JPG')]" ,
    "bg-[url('./assets/codwarzone(2).jpg')]" ,
]


const cardGradient = [
    'linear-gradient(270deg, #0AC8B9 5%, #089BBF 100%)',
    'linear-gradient(70deg, #FD4556 20%, #bd0013 100%)',
    'linear-gradient(70deg, #7C7C7C 1%, #BABABA 100%)',
    'linear-gradient(270deg, #009AED 1%, #00358D 100%)',
    'linear-gradient(270deg, #ad4200 1%, #EF6209 99%)',
    'linear-gradient(270deg, #F2A121 1%, #cc7e00 99%)',
    'linear-gradient(270deg, #D80000 1%, #9C0000 99%)',
    'linear-gradient(70deg, #F70000 1%, #6B0000 99%)',
    'linear-gradient(270deg, #7EC92E 30%, #336b05 99%)',
]

function GameDetail() {
    const { fetchGame, gameBooster, gameFaqs, gameServices, setGame, setGameBooster, setGameFaqs, setGameServices } = useContext(Context)
    const [t] = useTranslation("global")

    const { id, name } = useParams()

    const slideLeft = () => {
        var slider = document.getElementById('slider');
        slider.scrollLeft = slider.scrollLeft - 500;
    };

    const slideRight = () => {
        var slider = document.getElementById('slider');
        slider.scrollLeft = slider.scrollLeft + 500;
    };

    useEffect(() => {
        setGame(null); setGameBooster(null); setGameFaqs(null); setGameServices(null)
        fetchGame(id)
    }, [id])

    return (
        <motion.div initial={{width:'0%', opacity: 0}} animate={{width:'100%', opacity:1}} exit={{x:window.innerWidth, transition:{duration:1}}} className='opacity-0 flex flex-col w-[100%] gap-[150px] sm:gap-[80px] overflow-hidden relative '>
            <div className={`relative bg-cover bg-no-repeat bg-center sm:bg-center opacity-30 sm:opacity-30 sm:h-[900px]  ${bgimg[id - 1]} h-screen w-full -mt-14`}>
            </div>

            <div className='flex justify-between items-center pl-60 -mt-[600px] sm:px-0 sm:flex-col sm:-mt-[800px] pr-10 sec '>
                <motion.div transition={{ delay: 0.3, duration: 0.4 }} initial={{opacity:0, }} animate={{opacity:1, marginLeft:0}} className='flex flex-col gap-10 justify-start items-start z-10 sm:gap-4 sm:px-7 w-[45%] -mt-32 sm:w-full sm:mt-0'>
                    <h1 className='text-white text-[68px] leading-[78px] font-Oswald font-bold sm:text-[38px] sm:leading-[48px] capitalize'>{name} <br /><span className="text-primary-100">{t('gameDetail.boost')}</span> </h1>
                    <p className='text-left text-white font-Sansation text-lg py-0 sm:text-[12px] sm:leading-[20px] sm:py-3'> {t("gameDetail.text1")}</p>
                </motion.div>

                <div className='w-[50%] overflow-visible flex justify-start items-center h-[400px] sm:w-full'>
                    <img src={chars[id - 1].img} alt={name} className={`drop-shadow-2xl ${chars[id - 1].classes}`} />

                </div>

            </div>


            {gameServices?.length > 0 && <motion.div transition={{ delay: 0.5, duration: 0.4 }} initial={{opacity:0, }} animate={{opacity:1, marginLeft:0}}  className='pl-60 pr-20 z-10 flex flex-col justify-start items-start gap-10 -mt-16 sm:-mt-16 sm:px-7 sm:gap-5'>
                <h1 className='text-white text-center text-[54px] leading-[64px] font-Oswald font-bold w-full sm:text-[28px]'>{t("gameDetail.boosting")} </h1>
                <div className='flex justify-center items-stretch gap-4 sm:flex-col flex-wrap sm:w-full sm:gap-6 w-full'>

                    {gameServices?.map((s, index) =>{
                         return <div className='game-rank-border rounded-[23.445px] overflow-hidden w-[32%] sm:w-full'>
                            <Link key={index} to={`/game-detail/${id}/${name}/boosting/${s.id}/${s.service_title}`} className={` px-5 py-5 h-full flex justify-between items-center relative overflow-hidden sm:w-[100%] sm:h-auto  bg-cover bg-no-repeat`} style={{background: cardGradient[id - 1] }}>
                                    <span className='z-10 w-[50%]'>
                                        <h3 className='font-Oswald text-white text-[22px] leading-[32px] sm:text-[16px] sm:leading-[26px] capitalize'>{s.service_title}</h3>
                                        <p className='text-white font-Sansation text-[12px] mt-2 flex justify-start items-start sm:text-[9px] sm:mt-1 sm:leading-[19px]'> <GiLotusFlower className="text-[white] mr-1" /> {s.feature1}</p>
                                        <p className='text-white font-Sansation text-[12px] mt-2 flex justify-start items-start sm:text-[9px] sm:mt-1 sm:leading-[19px]'> <GiLotusFlower className="text-white mr-1" /> {s.feature2}</p>
                                        <p className='text-white font-Sansation text-[12px] mt-2 flex justify-start items-start sm:text-[9px] sm:mt-1 sm:leading-[19px]'> <GiLotusFlower className="text-white mr-1" /> {s.feature3}</p>
                                    </span>
                                    <img src={`${BACKEND_HOST.replace('/api', '')}/${s.service_image}`} className=' top-[-2px] right-0 w-[50%] sm:h-[135px] object-contain' />
                                </Link>
                         </div>
                    })}
                </div>

                <Link to={`/game-detail/${id}/${name}/boosting/${gameServices[0].id}/${gameServices[0].service_title}`} className='w-40 mx-auto sm:hidden'>
                    <Button title="View More" />
                </Link>
            </motion.div>}


            <div className='flex justify-between items-center pl-60 pr-20 gap-7 mt-0 sm:flex-col-reverse sm:px-0 z-10'>
                <img src={bottomChars[id - 1].img} alt='section' className={`drop-shadow-2xl mx-auto ${bottomChars[id - 1].classes}`} />
                <div className='sm:px-7 w-[60%] sm:w-full'>
                    <h1 className='text-white text-[50px] leading-[60px] font-Oswald font-bold sm:text-[28px] sm:leading-[38px]'>{t('home.heading4Slice1')} <br className='sm:hidden'/>{t('home.heading4Slice2')} <span className="text-primary-100">{t('home.heading4Blue')}</span> </h1>
                    <p className='text-left text-white font-Sansation text-base py-5 w-[95%] sm:text-[12px] sm:leading-[22px]'> {t("home.text3")}</p>
                </div>
            </div>


            {gameBooster?.length > 0 && 
            <div className='ml-60 relative sm:ml-0 sm:pl-7 z-10'>
                <h1 className='text-white text-center text-[50px] leading-[60px] font-Oswald font-bold sm:text-[28px] sm:leading-[38px] sm:text-left'>{t("gameDetail.boosters")} </h1>
                <div id='slider' className='slider w-full h-full overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide mt-16 z-10 sm:mt-7'>

                    {gameBooster?.map((b, index) => {
                        let active = index % 2 === 0 ? true : false
                        return <Link key={index} to={`/booster/${b.id}`} className={`rounded-[28.39px] ${active ? 'bg-[#0091F7]' : 'glass border-white'} py-7 px-10 sm:px-5 sm:py-4 border-[1px]  w-[334.055px] h-[315.055px] sm:w-[250px] sm:h-[218px] inline-block mr-5 sm:mr-3 relative overflow-hidden`}>
                            <img src={b.profile_image? `${BACKEND_HOST.replace('/api', '')}/${b.profile_image}` : require('../assets/defaultAvatar.jpg')} alt='' className='rounded-full sm:h-[65.141px] sm:w-[65.141px] bg-white h-[87.063px] w-[87.063px] object-cover mx-auto z-10 relative' />
                            <h1 className='text-white text-center text-[28px] leading-[38px] font-Oswald mt-5 mb-4 sm:text-lg sm:my-3'>{b.username}</h1>
                            <span className='flex justify-center items-center gap-2'>
                                <img src={require('../assets/rank1.png')} alt='rank' className='w-[35px] h-[35px] object-cover sm:w-[25px] sm:h-[25px]' />
                                <img src={require('../assets/rank2.png')} alt='rank' className='w-[35px] h-[35px] object-cover sm:w-[25px] sm:h-[25px]' />
                            </span>
                            <span className='flex justify-center items-center gap-5 mt-5 sm:mt-2 z-10 relative'>
                                <div className='bg-white flex justify-center items-center w-[50%] py-2 rounded-3xl gap-2 sm:py-1'>
                                    <p className='font-Sansation text-[#121212] sm:text-[12px]'>{b.rating? b.rating : 0}</p>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none" className='sm:h-[15px] sm:w-[16px]'>
                                        <path d="M4.60034 19.3787L6.23067 12.379L0.809814 7.67302L7.95041 7.05382L10.747 0.452026L13.5435 7.0525L20.6828 7.67171L15.2632 12.3777L16.8936 19.3773L10.747 15.6621L4.60034 19.3787Z" fill="#0091F7" />
                                    </svg>
                                </div>
                                <div className='bg-transparent border-[1px] border-white flex justify-center items-center w-[50%] py-2 rounded-3xl gap-2 sm:py-1'>
                                    <p className='font-Sansation text-white text-sm sm:text-[12px]'>4.9</p>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 21" fill="none" className='sm:h-[15px] sm:w-[16px]'>
                                        <path d="M2.60546 0.452026C1.50715 0.452026 0.618164 1.34024 0.618164 2.44057V14.3639C0.618164 15.4629 1.50715 16.3511 2.60546 16.3511H7.55118L10.0035 20.0299C10.064 20.1207 10.146 20.1951 10.2421 20.2466C10.3382 20.2981 10.4456 20.325 10.5546 20.325C10.6637 20.325 10.771 20.2981 10.8672 20.2466C10.9633 20.1951 11.0453 20.1207 11.1058 20.0299L13.5581 16.3511H18.5038C19.6021 16.3511 20.4911 15.4629 20.4911 14.3625V2.44057C20.4911 1.34024 19.6021 0.452026 18.5038 0.452026H2.60546Z" fill="white" />
                                    </svg>
                                </div>
                            </span>

                            {active && <>
                                <svg xmlns="http://www.w3.org/2000/svg" width="319" height="158" viewBox="0 0 319 158" fill="none" className='absolute -top-6 z-0'>
                                    <g opacity="0.5" filter="url(#filter0_f_75_930)">
                                        <ellipse cx="170.263" cy="-30.0784" rx="127.282" ry="94.16" transform="rotate(-61.282 170.263 -30.0784)" fill="white" />
                                    </g>
                                    <defs>
                                        <filter id="filter0_f_75_930" x="0.326698" y="-217.727" width="339.873" height="375.297" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                            <feGaussianBlur stdDeviation="33.5853" result="effect1_foregroundBlur_75_930" />
                                        </filter>
                                    </defs>
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" width="169" height="118" viewBox="0 0 169 118" fill="none" className='absolute -bottom-0 right-0 z-0'>
                                    <g opacity="0.7" filter="url(#filter0_f_75_927)">
                                        <ellipse cx="143.924" cy="139.793" rx="127.282" ry="94.16" transform="rotate(-40.8213 143.924 139.793)" fill="#FF0000" />
                                    </g>
                                    <defs>
                                        <filter id="filter0_f_75_927" x="0.28595" y="0.929382" width="287.276" height="277.726" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                            <feGaussianBlur stdDeviation="14.6576" result="effect1_foregroundBlur_75_927" />
                                        </filter>
                                    </defs>
                                </svg>
                            </>}
                        </Link>
                    })}

                </div>
                <span className='flex justify-start items-center gap-2 mt-6 z-20 relative sm:hidden'>
                    <FaArrowLeft className='flex justify-center items-center border-[1px] border-primary-100 p-3 text-white cursor-pointer rounded-full text-[45px] hover:bg-primary-100 transition-all ease-in duration-150' onClick={slideLeft} />
                    <FaArrowRight className='flex justify-center items-center border-[1px] border-primary-100 p-3 bg-primary-100 text-white cursor-pointer rounded-full text-[45px]' onClick={slideRight} />
                </span>
            </div>
            }

            {gameFaqs?.length > 0 &&
             <div className='pl-60 pr-10 pb-[180px] sm:pb-[70px] flex justify-center items-center flex-col gap-14 sm:gap-5 sm:px-7 sm:-mt-5 z-10'>
                <h1 className='text-white text-center text-[50px] leading-[60px] font-Oswald font-bold sm:text-[24px] sm:leading-[34px]'>{t('home.faq')} <span className='text-primary-100'>{t('home.faqBlue')}</span></h1>

                <div className='flex flex-col gap-4 w-[70%] sm:gap-3 sm:w-full'>
                    {gameFaqs?.map((f, index) => {
                        return <Faq key={index} ques={f.question} ans={f.answer} index={index}/>
                    } )}
                    
                </div>
            </div>
            }




            <Eclips top={'sm:top-[490px]'} blur1={"sm:hidden"}/>
            <div className='hidden sm:block bg-primary-100 rounded-full absolute z-[1] sm:w-[300px] sm:h-[300px] sm:opacity-50 sm:-right-28 sm:blur-[50px] sm:-top-28'></div>
        </motion.div>
    )
}

export default GameDetail
