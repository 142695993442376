import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { BsCartCheckFill } from "react-icons/bs";
import { BACKEND_HOST } from '../constants'
import Button from '../components/Button'
import { CiCircleInfo } from "react-icons/ci";
import { toast } from 'react-toastify'
import { DiWindows } from 'react-icons/di'
import { FaPlaystation, FaXbox } from 'react-icons/fa'
import { BsNintendoSwitch } from 'react-icons/bs'
import { CiMobile1 } from 'react-icons/ci'
import { Link } from 'react-router-dom';

function UserOrders() {
    const [t,] = useTranslation("global")
    const [state, setState] = useState(0)

    const [Orders, setOrder] = useState(null)
    const [loading, setLoading] = useState(false)

    const claimOrder = async(id) => {
        setLoading(true)
        const user = JSON.parse(localStorage.getItem('user'))
        const response = await fetch(`${BACKEND_HOST}/assign_order_to_booster`, {
            method: 'POST',
            headers: {
                'Content-Type': 'Application/json',
            },
            body: JSON.stringify({
                order_id: id,
                booster_id: user?.id 
            })
        })
        const res = await response.json()
        if (response.status === 200 && res.status == true) {
            console.log(res);
            setState(1)
        }
        else
            toast.error(res.error ? res.error : res.message) 
        setLoading(false)
    }

    const getClaimedOrder = async(id) => {
        setLoading(true)
        const user = JSON.parse(localStorage.getItem('user'))
        const response = await fetch(`${BACKEND_HOST}/get_booster_claimed_orders`, {
            method: 'POST',
            headers: {
                'Content-Type': 'Application/json',
            },
            body: JSON.stringify({
                booster_id: user.id 
            })
        })
        const res = await response.json()
        if (response.status === 200 && res.status == true) {
            console.log(res);
            if(state === 1)
                setOrder(res.claimed_orders)
            if(state === 2)
                setOrder(res.completed_unpaid_orders)
            if(state === 3)
                setOrder(res.completed_paid_orders)
        }
        else
            toast.error(res.error ? res.error : res.message) 
        setLoading(false)
    }

    const fetchAvailableorders = async () => {
        const response = await fetch(`${BACKEND_HOST}/get_unassigned_orders`, {
            method: 'GET',
            headers: {
                'Content-Type': 'Application/json',
            },
        })
        const res = await response.json()
        if (response.status === 200 && res.status == true) {
            setOrder(res.data)
        }
        else
            toast.error(res.error ? res.error : res.message)

    }

    useEffect(() => {
        setOrder(null)
        if(state === 0)
            fetchAvailableorders()
        else
            getClaimedOrder()
    }, [state])
    return (
        < >
            <h1 className='font-Oswald text-[58px] font-bold text-white sm:text-[28px] sm:leading-[38px]'>{t("userDashboard.orders")}</h1>
            <p className='text-white font-base font-Sansation mb-4 sm:text-[12px] sm:leading-[22px] sm:mb-1'>{t('userDashboard.track')}</p>

            <div className='bg-[#ffffff1e] rounded-[60px] border-[1px] border-white overflow-hidden sm:border-none sm:rounded-none sm:flex-wrap sm:bg-transparent flex flex-row justify-between items-center sm:justify-start sm:items-start w-[95%]' >
                <div className={`cursor-pointer ${state === 0 && 'bg-gradient-to-r from-[#0091F7] to-[#0674C1] border-[1px] border-white'}  py-3 px-16 sm:px-3 sm:py-2 rounded-[60px] `} onClick={() => setState(0)}>
                    <p className='text-white font-lg font-Sansation sm:text-[10px]'>{t('userDashboard.Available')}</p>
                </div>
                <div className={`cursor-pointer ${state === 1 && 'bg-gradient-to-r from-[#0091F7] to-[#0674C1] border-[1px] border-white'} py-3 px-16 sm:px-3 sm:py-2 rounded-[60px] `} onClick={() => setState(1)}>
                    <p className='text-white font-lg font-Sansation sm:text-[10px] '>{t('userDashboard.Claimed')}</p>
                </div>
                <div className={`cursor-pointer ${state === 2 && 'bg-gradient-to-r from-[#0091F7] to-[#0674C1] border-[1px] border-white'} py-3 px-16 sm:px-3 sm:py-2 rounded-[60px] `} onClick={() => setState(2)}>
                    <p className='text-white font-lg font-Sansation sm:text-[10px] '>{t('userDashboard.Completed_Unpaid')}</p>
                </div>
                <div className={`cursor-pointer ${state === 3 && 'bg-gradient-to-r from-[#0091F7] to-[#0674C1] border-[1px] border-white'} py-3 px-16 sm:px-3 sm:py-2 rounded-[60px] `} onClick={() => setState(3)}>
                    <p className='text-white font-lg font-Sansation sm:text-[10px] '>{t('userDashboard.Completed_Paid')}</p>
                </div>
            </div>


            <div className='flex justify-start items-stretch gap-5 w-[95%] mt-8 flex-wrap sm:w-full'>
                {!Orders && <img src={require('../assets/loading.gif')} alt='loader' className='w-7 h-7' />}
                {Orders?.length === 0 && <p className='text-sm text-white font-Sansation'>No activity found.</p>}
                {Orders?.map((o, index) => {
                    return <div key={index} className='flex flex-col justify-between gap-3 rounded-2xl glass px-2 py-3 w-[31%] overflow-hidden sm:w-full'>
                       <div className='flex flex-col gap-2'>
                        <p className='font-Sansation text-base capitalize mt-1 text-white'>ORDER # {o.id}</p>
                        <div className='flex justify-start items-center gap-2 px-1'>
                            <img src={`${BACKEND_HOST.replace('/api', '')}/${o.service?.service_image}`} className='w-[70px] object-contain h-[70px]' />
                            <div className='flex flex-col'>
                                <h1 className='font-Oswald font-bold text-2xl text-white'>{o?.service?.service_title}</h1>
                                <p className='font-Sansation font-medium text-lg text-white'>{o?.game?.title}</p>
                            </div>
                        </div>

                        {o?.rank_type === "Placement" && o?.ranks && <>
                            <div className='flex justify-between items-center px-1'>
                                <p className='font-Sansation text-sm text-white'>Placement</p>
                                <p className='font-Sansation text-sm text-white'>{o.ranks[0]?.rank?.rank_name} {o.ranks[0]?.level?.name} {o?.ranks[0]?.points != null && (`- ${o?.ranks[0]?.points} Points`)}</p>
                            </div>
                        </>}

                        {o?.rank_type === "Conversion" && o?.ranks && <>
                            <div className='flex justify-between items-center px-1 '>
                                <p className='font-Sansation text-sm text-white'>Current</p>
                                <p className='font-Sansation text-sm text-white'>{o.ranks[0]?.rank?.rank_name} {o.ranks[0]?.level?.name} {o?.ranks[0]?.points != null && (`- ${o?.ranks[0]?.points} Points`)}</p>
                            </div>
                            <div className='flex justify-between items-center px-1 -mt-1'>
                                <p className='font-Sansation text-sm text-white'>Desired</p>
                                <p className='font-Sansation text-sm text-white'>{o.ranks[1]?.rank?.rank_name} {o.ranks[1]?.level?.name} {o?.ranks[1]?.points != null && (`- ${o?.ranks[1]?.points} Points`)}</p>
                            </div>
                        </>}

                        {o.number_of_games != 0 &&
                            <div className='flex justify-between items-center px-1 -mt-1'>
                                <p className='font-Sansation text-sm text-white'>Number of Wins</p>
                                <p className='font-Sansation text-sm text-white'>{o.number_of_games} Wins</p>
                            </div>}
                        {o.plateform && <div className='flex justify-between items-center px-1 -mt-1'>
                            <p className='font-Sansation text-sm text-white'>Platform</p>
                            <p className='font-Sansation text-sm text-white flex justify-end items-center gap-1'>
                                {o.plateform?.plateform_name.startsWith('PC') && <DiWindows className='text-xl' />}
                                {o.plateform?.plateform_name.startsWith('Play') && <FaPlaystation className='text-xl' />}
                                {o.plateform?.plateform_name.startsWith('Xbox') && <FaXbox className='text-xl' />}
                                {o.plateform?.plateform_name.startsWith('Nintendo') && <BsNintendoSwitch className='text-xl' />}
                                {o.plateform?.plateform_name.startsWith('Mobile') && <CiMobile1 className='text-xl' />}
                                {o.plateform?.plateform_name}
                            </p>
                        </div>}
                        <svg xmlns="http://www.w3.org/2000/svg" width="306" height="2" viewBox="0 0 306 2" fill="none" className='w-full'>
                            <path opacity="0.8" d="M0 1L306 0.999973" stroke="url(#paint0_linear_215_159)" />
                            <defs>
                                <linearGradient id="paint0_linear_215_159" x1="0" y1="1" x2="306" y2="1.00003" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="white" stop-opacity="0" />
                                    <stop offset="0.49" stop-color="white" />
                                    <stop offset="1" stop-color="white" stop-opacity="0" />
                                </linearGradient>
                            </defs>
                        </svg>

                        {o.items?.length > 0 && <>
                            <div className='flex justify-start items-start gap-2 flex-wrap px-1 py-1'>
                                {o?.items?.map((i, index) => {
                                    return <p key={index} className='bg-primary-100/50 px-2 py-[2px] rounded-md text-white text-[12px] font-Sansation'>{i.item?.name}</p>
                                })}
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="306" height="2" viewBox="0 0 306 2" fill="none" className='w-full'>
                                <path opacity="0.8" d="M0 1L306 0.999973" stroke="url(#paint0_linear_215_159)" />
                                <defs>
                                    <linearGradient id="paint0_linear_215_159" x1="0" y1="1" x2="306" y2="1.00003" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="white" stop-opacity="0" />
                                        <stop offset="0.49" stop-color="white" />
                                        <stop offset="1" stop-color="white" stop-opacity="0" />
                                    </linearGradient>
                                </defs>
                            </svg>
                        </>}

                        {o.customizes?.length > 0 && <>
                            <div className='flex justify-start items-start gap-2 flex-wrap px-1 py-1'>
                                {o?.customizes?.map((c, index) => {
                                    return <p key={index} className='glass px-2 py-[2px] rounded-md text-white text-[12px] font-Sansation'>{c.custom_name}</p>
                                })}
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="306" height="2" viewBox="0 0 306 2" fill="none" className='w-full'>
                                <path opacity="0.8" d="M0 1L306 0.999973" stroke="url(#paint0_linear_215_159)" />
                                <defs>
                                    <linearGradient id="paint0_linear_215_159" x1="0" y1="1" x2="306" y2="1.00003" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="white" stop-opacity="0" />
                                        <stop offset="0.49" stop-color="white" />
                                        <stop offset="1" stop-color="white" stop-opacity="0" />
                                    </linearGradient>
                                </defs>
                            </svg>
                        </>}

                        <div className='flex justify-between items-end px-1'>
                            <p className='font-Sansation font-medium text-[12px] text-white'>{o?.created_at.substr(0, 16).replace('T', ' ')}</p>
                            <p className='font-Sansation font-bold text-lg text-primary-100 '>${o?.total_amount}</p>
                        </div>

                        <p className='absolute top-3 -right-4 px-4 pr-8 py-1 text-[14px] text-white bg-primary-100/50 rounded-3xl'>{o.status}</p>
                        </div>
                       
                        {state === 0 && <Button title={t("userDashboard.claim")} onClick={() => claimOrder(o.id)} disabled={loading}/>}
                        {state !== 0 && 
                        <Link to={`/orders/chat/${o.id}/${o.user?.id}?username=${o.user?.username}&profile=${o.user?.profile_image}`} onClick={()=> localStorage.setItem('order', JSON.stringify(o))}> 
                            <Button title={t("userDashboard.chatwith")} disabled={loading}/>
                        </Link>
                        }
                    </div>
                })}
            </div>


            <div className='flex justify-start items-start gap-6 mt-20 w-[90%] sm:gap-2 sm:w-full'>
                <div className='border-[1px] border-white bg-[#ffffff1e] rounded-[20px] flex flex-col gap-5 justify-center items-center px-5 py-5 sm:px-2 sm:py-3 pt-7 w-[33%] sm:gap-2'>
                    <BsCartCheckFill className='text-white text-[70px] sm:text-[30px]' />
                    <p className='text-white text-[28px] leading-[30px] text-center font-Sansation sm:text-[12px] sm:leading-[22px]'>{t("userDashboard.totalComplted")}</p>
                    <p className='text-[#0091F7] text-[40px] font-Sansation font-bold sm:text-[12px] sm:leading-[22px]'>$189.54</p>
                </div>
                <div className='border-[1px] border-white bg-[#ffffff1e] rounded-[20px] flex flex-col gap-5 justify-center items-center px-5 py-5 pt-7 sm:px-2 sm:py-3 w-[33%] sm:gap-2'>
                    <BsCartCheckFill className='text-white text-[70px] sm:text-[30px]' />
                    <p className='text-white text-[28px] leading-[30px] text-center font-Sansation sm:text-[12px] sm:leading-[22px] sm:mt-1'>{t("userDashboard.totalEarned")}</p>
                    <p className='text-[#0091F7] text-[40px] font-Sansation font-bold mt-7 sm:mt-4 sm:text-[12px] sm:leading-[22px]'>$240.15</p>
                </div>
                <div className='border-[1px] border-white bg-[#ffffff1e] rounded-[20px] flex flex-col gap-5 justify-center items-center px-5 py-5 pt-7 sm:px-2 sm:py-3  w-[33%] sm:gap-2'>
                    <BsCartCheckFill className='text-white text-[70px] sm:text-[30px]' />
                    <p className='text-white text-[28px] leading-[30px] text-center font-Sansation sm:text-[12px] sm:leading-[22px] sm:mt-1'>{t("userDashboard.totalUnpaid")}</p>
                    <p className='text-[#0091F7] text-[40px] font-Sansation font-bold mt-7 sm:mt-4 sm:text-[12px] sm:leading-[22px]'>$25.10</p>
                </div>
            </div>

            <div className='flex justify-center items-center gap-12 mt-20 w-[90%] sm:flex-col sm:w-full sm:gap-3'>
                <div className='flex justify-center items-center gap-3'>
                    <span className='w-[220px] sm:w-[150px]'>
                        <Button title={t('userDashboard.Request_Payment')} />
                    </span>
                    <CiCircleInfo className='text-white text-[30px] cursor-pointer sm:text-2xl' />
                </div>
                <div className='flex justify-center items-center gap-3'>
                    <span className='w-[300px] sm:w-[200px]'>
                        <Button title={t('userDashboard.Request_Priority_Payment')} />
                    </span>
                    <CiCircleInfo className='text-white text-[30px] cursor-pointer sm:text-2xl' />
                </div>
            </div>
        </>
    )
}

export default UserOrders
