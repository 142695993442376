import React, { useEffect, useState } from 'react'
import Card from './Card'
import Input2 from './Input2'
import Textarea2 from './Textarea2'
import ImageUploader from './ImageUploader'
import Button2 from './Button2'
import { toast } from 'react-toastify'
import { BACKEND_HOST } from '../constants'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'

function AddBlog() {
  const [blog, setBlog] = useState(null)
  const [img, setImg] = useState()
  const [edit, setEdit] = useState(null)
  const [loading, setLoading] = useState(false)
  const [editPage, setEditPage] = useState(true)

  const navigate = useNavigate()

  const { id } = useParams()

  const OnChange = (e) => {
    setBlog({ ...blog, [e.target.name]: e.target.value })
  }

  const addNewBlog = async() => {
    try {
      let data = new FormData();
      data.append('title', blog.title);
      data.append('description', blog.description);
      data.append('file', img);

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${BACKEND_HOST}/admin_add_blogs`,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        data: data
      };

      const response = await axios.request(config);
      if (response.status === 200 && response.data.status) {
        toast.success(response.data.error ? response.data.error : response.data.message)
      } else {
        toast.error(response.data.error ? response.data.error : response.data.message);
      }

    } catch (error) {
      console.log(error);
    }
  }

  const updateBlog = async() => {
    try {
      let data = new FormData();
      data.append('title', blog.title);
      data.append('blog_id', id);
      data.append('description', blog.description);
      data.append('file', img);

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${BACKEND_HOST}/admin_update_blogs`,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        data: data
      };


      const response = await axios.request(config);
      if (response.status === 200 && response.data.status) {
        toast.success(response.data.error ? response.data.error : response.data.message)
      } else {
        toast.error(response.data.error ? response.data.error : response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }



  const onSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    
    if(window.location.pathname.startsWith('/admin/blogs/edit'))
      await updateBlog()
    else
      await addNewBlog()

    navigate('/admin/blogs')
    
    setLoading(false)
  }

  const fetchBlog = async () => {
    try {
      const response = await fetch(`${BACKEND_HOST}/admin_get_blogs`, {
        method: 'GET',
        headers: {
          'Content-Type': 'Application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        timeout: 10000,
      })

      const res = await response.json()
      if (response.ok && res.status) {
        const b = res.data.filter((b) => b.id == id)[0]
        setBlog(b)
        setEditPage(false)
      }
      else
        toast.error(res.error ? res.error : res.message)
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (window.location.pathname.startsWith('/admin/blogs/edit')){
      fetchBlog()
    }
    else
    setEditPage(false)
  }, [id])

  return (
    <Card>
      <h1 className="text-white font-Oswald text-3xl sm:text-2xl">{window.location.pathname.startsWith('/admin/blogs/edit')? 'Edit Blog' : 'Add New Blog'}</h1>
      {editPage && !blog && <img src={require('../assets/loading.gif')} alt='loader' className='w-7 h-7 sm:h-5 sm:w-5'/>}
      {!editPage && <form onSubmit={onSubmit} className='flex flex-col gap-5 justify-start items-start'>
        <div className='flex justify-between items-start gap-5 w-full sm:flex-col-reverse'>
          <div className='w-[65%] flex flex-col gap-5 sm:w-full'>
            <Input2 placeholder={"Title"} name={"title"} value={blog?.title} required={true} onChange={OnChange} />
            <Textarea2 placeholder={"Description"} name={"description"} value={blog?.description} required={true} onChange={OnChange} />
          </div>
          <div className='w-[35%] h-[255px] sm:w-full sm:h-[150px]'>
            <ImageUploader id={'blog_img'} setImg={setImg} img={blog?.image? `${BACKEND_HOST.replace('/api', '')}/${blog?.image}` : null} required={blog?.image? false : true}/>
          </div>
        </div>
        <Button2 title={"Submit"} disabled={loading} />
      </form>}
    </Card>
  )
}

export default AddBlog
