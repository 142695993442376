import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoLogoWhatsapp } from "react-icons/io";
import { IoMail } from "react-icons/io5";
import Input from '../components/Input';
import Textarea from '../components/Textarea';
import Button from '../components/Button';
import { Link } from 'react-router-dom';
import { BACKEND_HOST } from '../constants';
import { toast } from 'react-toastify';
import Eclips from '../components/Eclips';
import { motion } from 'framer-motion'

function Contact() {

  const [t, i18n] = useTranslation("global")

  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)

  const onChange = (e) => {
    setData({...data, [e.target.name]:e.target.value})
  }

  const onSubmit = async(e) => {
    e.preventDefault()
    setLoading(true)
    try {
      const response = await fetch(`${BACKEND_HOST}/submit_contact_us`,{
        method:'POST',
        headers:{
          'Content-Type':'Application/json',
        },
        bosy: JSON.stringify(data)
      })
  
      const res = await response.json()
      if(response.ok){
        setData({})
        toast.success(res.message)
      }
      else{
        toast.error(res.error?res.error : res.message)
      }
      
    } catch (error) {
      console.log(error);
    }

    setLoading(false)
    
  }


  useEffect(()=>{

  }, [data])

  return (
    <motion.div  initial={{width:'0%', opacity: 0}} animate={{width:'100%', opacity:1}} exit={{x:window.innerWidth, transition:{duration:1}}} className='opacity-0 flex flex-col relative  gap-[150px] overflow-hidden sm:gap-0'>
      <div className="relative bg-cover bg-no-repeat bg-top sm:bg-left sm:bg-[url('./assets/contactBgMobile.jpg')] sm:opacity-30 sm:w-full sm:h-screen opacity-30 bg-[url('./assets/contactBg.jpg');] h-screen w-full -my-6 sm:my-0">
        {/* <div className=' w-full h-[547px] gradient1 absolute -left-0 -bottom-60'></div>
        <div className='w-[624px] h-[624px] bg-primary-100 blur-[137px] opacity-50 rounded-full absolute -left-18 -bottom-48 z-0'></div> */}
      </div>
      <div className='flex pl-60 pr-20 justify-between items-center -mt-[700px] z-[5]  sm:px-7 sm:flex-col sm:gap-16 sm:-mt-[600px]'>
        <motion.div  transition={{ delay: 0.3, duration: 0.4 }} initial={{opacity:0, }} animate={{opacity:1, marginLeft:0}} className='w-[52%] sm:w-full'>
          <h1 className='text-white text-[60px] font-Oswald font-bold sm:text-[35px] sm:leading-[45px]'>{t('contactPage.headingSlice1')} <cpan className="text-primary-100">{t('contactPage.headingSlice2')}</cpan> {t('contactPage.headingSlice3')}</h1>
          <p className='text-left text-white font-Sansation text-base py-5 sm:text-[12px] sm:leading-[22px]'> {t("contactPage.text1")} <br /> {t("contactPage.text2")} </p>
          <a className='flex justify-start items-start gap-3 mt-7 sm:mt-3'>
            <IoLogoWhatsapp className='text-3xl text-primary-100 sm:text-[20px]' />
            <p className=' text-white font-Sansation text-lg sm:text-[12px] sm:leading-[22px]'>1234-567-899</p>
          </a>
          <a className='flex justify-start items-start gap-3 mt-4 sm:mt-2'>
            <IoMail className='text-3xl text-primary-100  sm:text-[20px]' />
            <p className=' text-white font-Sansation text-lg  sm:text-[12px] sm:leading-[22px]'>www.boostingerTeam@gmail.com</p>
          </a>
        </motion.div>
        <div className='bg-[#01396A] w-[43%] relative rounded-[30px] overflow-hidden gradient-border-contact sm:w-full sm:rounded-[21.776px]'>


          <div className='px-7 py-10 sm:px-5 sm:py-5'>
            <div className='w-[380px] h-[380px] bg-[#24B0FF] blur-[87px] opacity-80 rounded-full absolute -right-28 -top-28'></div>
            <div className='w-[198px] h-[198px] bg-[#24B0FF] blur-[87px] opacity-100 rounded-full absolute -left-5 top-28'></div>
            <div className='w-[198px] h-[198px] bg-[#24B0FF] blur-[87px] opacity-80 rounded-full absolute left-40 -bottom-20'></div>

            <form className='flex flex-col gap-5 sm:gap-3' onSubmit={onSubmit}>
              <Input name='name' value={data?.name} type="text" placeholder={t("name")} onChange={onChange} required={true} />
              <Input name='email' value={data?.email} type="email" placeholder={t("email")} onChange={onChange} required={true} />
              <Textarea name='message' value={data?.message} placeholder={t("message")} onChange={onChange} required={true} />
              <Button title="Submit" disabled={loading} />
            </form>

          </div>
        </div>
      </div>

      <div className='flex pl-60 pr-20 pb-[150px] justify-between items-center z-[5] overflow-hidden relative sm:flex-col sm:px-7 sm:gap-20 sm:py-[80px]'>
          <div className='flex justify-start items-start flex-col w-[40%] gap-11 sm:w-full sm:gap-7'>
              <h1 className='text-white text-[54px] leading-[68px] font-Oswald font-bold sm:text-[28px] sm:leading-[38px]'>{t('contactPage.joinText')}</h1>
              <Link to='/join-us' className='w-32'>
                <Button title={t('contactPage.joinBtn')} />
              </Link>
          </div>
          <img src={require('../assets/join.png')} alt='join' className='h-[390px] z-[5] sm:w-full sm:h-auto'/>
      <div className='w-[624px] h-[624px] bg-primary-100 blur-[137px] opacity-30 rounded-full absolute -right-80 -bottom-80 z-0'></div>
      </div>
      
      <Eclips />
      <div className='hidden sm:block bg-primary-100 rounded-full absolute z-[1] sm:w-[300px] sm:h-[300px] sm:opacity-50 sm:-right-28 sm:blur-[50px] sm:-top-28'></div>
    </motion.div>
  )
}

export default Contact
