import React, { useEffect, useState } from 'react'
import { BACKEND_HOST } from '../constants'
import axios from 'axios'
import { toast } from 'react-toastify'
import Input2 from './Input2'
import Button2 from './Button2'
import Card from './Card'

function Platforms() {  
    const [platform, setPlatform] = useState(null)
    const [loading, setLoading] = useState(false)
    const [platforms, setPlatforms] = useState(null)
  
    const onChange = (e) => {
      setPlatform({...platform, [e.target.name]: e.target.value})
    } 
    const fetchPlatforms = async () => {
        try {

            const response = await axios.get(`${BACKEND_HOST}/admin_get_plateforms`, {
                headers: {
                    'Content-Type': 'Application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })

            if (response.status === 200 && response.data.status) {
                setPlatforms(response.data.data);
            }
            else
                toast.error(response.data.error ? response.data.error : response.data.message)
        } catch (error) {
            console.log(error);
        }
    }

    const updatePlatform = async () => {
        let data = JSON.stringify({
            plateform_name: platform.plateform_name,
            plateform_id: platform.id
        })

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${BACKEND_HOST}/admin_update_plateforms`,
            headers: {
                'Content-Type': 'Application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            data: data
        };
        try {
            const response = await axios.request(config)
            if (response.status === 200 && response.data.status == true) {
                setPlatforms(null)
                setPlatform(null)
                document.getElementById('plateform_name').value = ''
                document.getElementById('plateform_name').defaultValue = ''
                toast.success(response.data.message)
                fetchPlatforms()
            }
            else
                toast.error(response.data.error ? response.data.error : response.data.message)
        } catch (error) {
            console.log(error);
        }
    }

    const addNewPlatform = async () => {
        let data = JSON.stringify({
            'plateform_name': platform.plateform_name,
        })

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${BACKEND_HOST}/admin_add_plateforms`,
            headers: {
                'Content-Type': 'Application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            data: data
        };
        try {
            const response = await axios.request(config)
            if (response.status === 200 && response.data.status == true) {
                setPlatforms(null)
                setPlatform(null)
                document.getElementById('plateform_name').value = ''
                document.getElementById('plateform_name').defaultValue = ''
                toast.success(response.data.message)
                fetchPlatforms()
            }
            else
                toast.error(response.data.error ? response.data.error : response.data.message)
        } catch (error) {
            console.log(error);
        }
    }

    const deletePlat = async(_id) => {
        const confirm = window.confirm('Are you sure deleting platform?')
        if(!confirm){
            return
        }
        setLoading(true)
        let data = JSON.stringify({
            "plateform_id": _id,
        })

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${BACKEND_HOST}/admin_delete_plateforms`,
            headers: {
                'Content-Type': 'Application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            data: data
        };
        try {
            const response = await axios.request(config)
            if (response.status === 200 && response.data.status == true) {
              toast.success(response.data.message)
              setPlatforms(null)
              fetchPlatforms()
            }
            else
              toast.error(response.data.error ? response.data.error : response.data.message)
          } catch (error) {
            console.log(error);
          }
          
        setLoading(false)
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        if (platform?.id)
            await updatePlatform()
        else
            await addNewPlatform()
        setLoading(false)
    }

    useEffect(() => {
        fetchPlatforms()
    }, [])

    useEffect(()=>{
        if(platform?.id){
        document.getElementById('plateform_name').value = platform.plateform_name
        document.getElementById('plateform_name').defaultValue = platform.plateform_name
            console.log(platform);
    }
    }, [platform])
    return (
        <Card>
            <h1 className="text-white font-Oswald text-3xl sm:text-2xl">Platforms</h1>
            <form onSubmit={onSubmit} className='flex gap-3'>
                <span className='w-[70%] sm:w-[62%]'>
                    <Input2 type={'text'} name={'plateform_name'} value={platform?.plateform_name} required={true} placeholder={'Platform Name'} onChange={onChange} />
                </span>
                <Button2 title={platform?.id ? "Update":'Add Platform'} disabled={loading} />
            </form>
            <div className="overflow-x-auto">
                {!platforms && <img src={require('../assets/loading.gif')} alt='loader' className='w-7 h-7 sm:h-5 sm:w-5' />}
                {platforms?.length === 0 && <p className='text-white text-sm sm:text-[12px] font-Sansation'>No data found.</p>}
                {platforms?.length > 0 && <table className="min-w-full sm:min-w-[700px]">
                    <thead>
                        <tr>
                            <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-1 sm:py-1 text-grey">#</th>
                            <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey">Name</th>
                            <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {platforms?.map((b, index) => {
                            return <tr className="bg-primary-300 border-b-[4px] border-primary-100/10" key={index}>
                                <td className="py-2 px-4 text-left font-Sansation text-white text-sm border-b-white sm:px-1 sm:py-1 sm:text-[10px] sm:leading-[20px] align-top">{index + 1}</td>
                                <td className="py-2 px-4 text-left font-Sansation text-white text-sm flex justify-start items-center gap-2  sm:px-2 sm:py-1 sm:text-[10px] sm:leading-[20px]  align-top min-w-[320px]">{b.plateform_name}</td>
                                <td className="py-2 px-4 text-left font-Sansation text-white text-sm sm:px-2 sm:py-1 sm:text-[10px] sm:leading-[20px]  align-top">
                                    <span className='flex justify-end items-start gap-3  align-top'>
                                        <button onClick={()=>setPlatform(b)} className="text-primary-100">Edit</button>
                                        <button onClick={()=>deletePlat(b.id)} className="text-red">Delete</button>
                                    </span>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>}
            </div>
        </Card>
    )
}

export default Platforms
