import React, { useEffect, useState } from 'react'
import Card from './Card'
import { useTranslation } from 'react-i18next'
import Input from './Input'
import Button from './Button'
import { BACKEND_HOST } from '../constants'
import { IoImageOutline } from 'react-icons/io5'
import { toast } from 'react-toastify'
import axios from 'axios'
import Textarea from './Textarea'
import { WithContext as ReactTags } from 'react-tag-input';

function EditProfile({ setRefresh }) {
  const [t] = useTranslation("global")
  const [data, setData] = useState(JSON.parse(localStorage.getItem('user')))
  const [img, setImg] = useState(null)
  const [image, setImage] = useState(localStorage.getItem('profile_image') != 'null' ? `${BACKEND_HOST.replace('/api', '')}/${localStorage.getItem('profile_image')}` : require('../assets/defaultAvatar.jpg'))
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState(null)

  const [tags, setTags] = useState([]);

  const handleDelete = (i) => {
    const newTags = [...tags];
    newTags.splice(i, 1);
    setTags(newTags);
  };

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
  };

  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }


  const onChangePass = (e) => {
    setPassword({ ...password, [e.target.name]: e.target.value })
  }

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result)
      }
    })
  }

  const handleImage = async (e) => {
    if (e.target?.files[0]?.size >= 5000000) {
      toast.error('Image size should be less than 5MB')
      return;
    }
    setImg(e?.target?.files[0])
    const base64 = await convertBase64(e.target?.files[0])
    setImage(base64)
  }


  const updateProfile = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      let fd = new FormData();
      fd.append('first_name', data.first_name);
      fd.append('last_name', data.last_name);
      fd.append('user_id', localStorage.getItem('user_id'));
      fd.append('email', data.email);
      fd.append('discord', data.discord);
      fd.append('username', data.username);
      fd.append('about', data.about);
      fd.append('profile_image', img);
      fd.append('achievements', JSON.stringify(tags));

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${BACKEND_HOST}/update_user_profile`,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        data: fd
      };


      const response = await axios.request(config);
      console.log(response);
      if (response.status === 200 && response.data.status) {
        localStorage.setItem('user', JSON.stringify(response.data.data))
        localStorage.setItem('profile_image', response.data.data?.profile_image)
        localStorage.setItem('username', response.data.data?.username)
        toast.success(response.data.error ? response.data.error : response.data.message)
        setData(response.data.data)
        setRefresh(state => !state)
      } else {
        toast.error(response.data.error ? response.data.error : response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false)
  }

  const updatePass = async (e) => {
    e.preventDefault()
    setLoading(true)

    try {
      const json = JSON.stringify({
        "old_password": password.old_password,
        "new_password": password.new_password,
        "confirm_password": password.confirm_password,
        "user_id": localStorage.getItem('user_id')
      })


      console.log(json);
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${BACKEND_HOST}/update_user_password`,
        headers: {
          'Content-Type': 'Application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        data: json
      };


      const response = await axios.request(config);
      if (response.status === 200 && response.data.status == 200) {
        toast.success(response.data.error ? response.data.error : response.data.message)
      } else {
        toast.error(response.data.error ? response.data.error : response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false)
  }

  useEffect(()=>{
    if(data){
      const tags = JSON.parse(data?.achievements)
      setTags(tags || [])
    }
  }, [data])

  return (


    <div className='flex flex-col px-7 py-7  glass rounded-3xl gap-5'>
      <h1 className="text-white font-Oswald text-3xl sm:text-2xl text-center mb-0">{t('userDashboard.Edit_Profile')}</h1>
      <form onSubmit={updateProfile} className='flex justify-between items-center gap-5 flex-col-reverse'>
        <div className='w-[50%] flex justify-start items-center gap-3 flex-col sm:w-full'>
          <div className='flex justify-between items-center gap-2 w-full'>
            <span className='w-[50%]'>
              <Input name='username' value={data?.username} type="text" placeholder={t("Username")} onChange={onChange} required={false} />
            </span>
            <span className='w-[50%]'>
              <Input name='first_name' value={data?.first_name} type="text" placeholder={t("Name")} onChange={onChange} required={false} />
            </span>
          </div>
          <Input name='email' value={data?.email} type="email" placeholder={t("email")} onChange={onChange} required={true} />
          <Textarea placeholder={"About"} name={"about"} value={data?.about} required={false} onChange={onChange} />
          <Input name='discord' value={data?.discord} type="text" placeholder={t("Discord Name")} onChange={onChange} required={true} />
          <div className='flex flex-col gap-1 w-full admin-x rounded-xl glass border-[1px] border-white/75'>
                    <ReactTags
                        tags={tags}
                        handleDelete={handleDelete}
                        handleAddition={handleAddition}
                        handleDrag={() => { }}
                        placeholder="Add Achievments"
                    />
                </div>
          <span className='w-full mt-2'>
            <Button title={t('update')} disabled={loading} />
          </span>
        </div>
        <div className='w-[30%] -mt-0  flex justify-center items-center sm:w-[70%]'>
          <div className='w-[200px] h-[200px] rounded-full bg-primary-200 relative overflow-hidden sm:w-[100px] sm:h-[100px]'>
            <img src={image} className='rounded-full w-full h-full object-cover' />
            <div className='absolute w-full h-full bg-[#00000051] top-0 flex justify-center items-center cursor-pointer opacity-0 transition-all ease-in duration-150 hover:opacity-100' onClick={() => document.getElementById('img').click()}>
              <IoImageOutline className='text-white/75 text-[60px] sm:text-[25px]' />
            </div>
            <input type='file' id='img' accept='image/*' className='absolute top-20 left-5 opacity-0' onChange={handleImage} />
          </div>
        </div>
      </form>

      <h1 className="text-white font-Oswald text-2xl sm:text-xl mt-10 text-center">{t('userDashboard.updatePass')}</h1>
      <form onSubmit={updatePass} className='flex justify-center items-center gap-3 flex-col w-[100%] '>
        <div className='w-[50%] flex justify-center items-center flex-col gap-3 sm:w-full'>
          <Input name='old_password' value={password?.old_password} type="password" placeholder={t("userDashboard.old_pass")} onChange={onChangePass} required={true} />
          <Input name='new_password' value={password?.new_password} type="password" placeholder={t("userDashboard.new_pass")} onChange={onChangePass} required={true} />
          <Input name='confirm_password' value={password?.confirm_password} type="password" placeholder={t("userDashboard.conf_pass")} onChange={onChangePass} required={true} />
          <span className='w-full mt-2'>
            <Button title={t('update')} disabled={loading} />
          </span>

        </div>
      </form>
    </div>

  )
}

export default EditProfile
