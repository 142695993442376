import React, { useState } from 'react'
import { RiArrowDownSLine } from "react-icons/ri";

function Faq({ index, ques, ans }) {
  const [opened, setOpened] = useState(false)
  return (
    <>

      <div className={`relative overflow-hidden border-[1px] border-white rounded-3xl px-6 pt-3 pb-3`}>
        <span className='flex justify-between items-center cursor-pointer z-10 relative' onClick={() => setOpened(state => !state)}>
          <h3 className='text-white text-center text-lg font-Sansation font-normal capitalize sm:text-base'>{index + 1}. {ques}</h3>
          <RiArrowDownSLine className={`text-white text-3xl sm:text-xl ${!opened ? 'rotate-0' : 'rotate-180'} transition-all ease-in duration-100`} />
        </span>
        <div className={`${opened ? 'h-[100px] sm:h-[50px]' : 'h-0'} z-10 transition-all ease-in duration-200 overflow-hidden flex justify-start items-center flex-col gap-4`}>
          <svg xmlns="http://www.w3.org/2000/svg" width="00" height="2" viewBox="0 0 1249 2" fill="none" className='mt-5 w-full sm:-mt-3 sm:w-[300px]'>
            <path opacity="0.5" d="M0.249023 0.885254H1248.84" stroke="url(#paint0_linear_207_656)" stroke-width="0.990553" />
            <defs>
              <linearGradient id="paint0_linear_207_656" x1="1302.33" y1="1.37562" x2="46.3097" y2="1.36234" gradientUnits="userSpaceOnUse">
                <stop stop-color="white" stop-opacity="0" />
                <stop offset="0.51" stop-color="white" />
                <stop offset="1" stop-color="white" stop-opacity="0" />
              </linearGradient>
            </defs>
          </svg>
          <p className='text-left text-white font-Sansation text-base sm:text-[12px] sm:leading-[22px] w-full'>{ans}</p>
        </div>


        <div className='bg-white absolute top-0 left-0 opacity-10 w-full h-[1000px] rounded-[10px] blur-[4px] z-0'></div>
      </div>
    </>
  )
}

export default Faq


