export const selectStyles = {
    control: (provided, state) => ({
      ...provided,
      // border: state.isFocused ? '0px solid rgba(255, 255, 255, 0.55)' : '0px solid rgba(255, 255, 255, 0.55)',
      borderRadius: '10px',
      border: '0px',
      boxShadow: 'none',
      padding: '4px 0px 4px 10px',
      fontFamily: 'Sansation',
      color:'#fff',
      backgroundColor:'#0A1F42',
      cursor: 'pointer',
   
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#0674C1' : '#0A1F42',
      color: 'white', // Set the color of the option text to white
      padding: '8px 29px 8px 19px',
      fontSize: '14px',
      cursor: 'pointer',
      fontFamily: 'Sansation',
      borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
      borderTop: 'none',
      marginBottom: '0',
      marginTop: '0',
      transition: '0.2s ease-in',
      '&:hover': {
        backgroundColor: '#0674C1',
        color: 'white'
      }
    }),
    menu: (provided, state) => ({
      ...provided,
      background: '#0A1F42',
      boxShadow: 'none',
    }),
    menuList: (provided, state) => ({
      ...provided,
      padding: '0',
      marginTop: '-7px',
      marginBottom: '0',
      borderRadius: '5px',
      backgroundColor: '#0A1F42',
      border: '1px solid rgba(255, 255, 255, 0.2)', // Add borderTop to the menuList
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      transition: 'transform 0.3s', // Add transition for smooth rotation effect
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null, // Rotate the arrow when menu is open
      color: 'white'
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'white' // Set the color of the selected text to white
    }),
    placeholder: (provided) => ({
      ...provided,
      color: 'white' // Set the color of the placeholder text to white
    })
};




export const selectMobStyles = {
  control: (provided, state) => ({
    ...provided,
    // border: state.isFocused ? '0px solid rgba(255, 255, 255, 0.55)' : '0px solid rgba(255, 255, 255, 0.55)',
    borderRadius: '8px',
    border: '0px',
    boxShadow: 'none',
    padding: '3px 0px 3px 6px',
    fontFamily: 'Sansation',
    fontSize: '10px',
    color:'#fff',
    backgroundColor:'#0A1F42',
    cursor: 'pointer',
 
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#0674C1' : '#0A1F42',
    color: 'white', // Set the color of the option text to white
    padding: '8px 29px 8px 10px',
    fontSize: '10px',
    cursor: 'pointer',
    fontFamily: 'Sansation',
    borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
    borderTop: 'none',
    marginBottom: '0',
    marginTop: '0',
    transition: '0.2s ease-in',
    '&:hover': {
      backgroundColor: '#0674C1',
      color: 'white'
    }
  }),
  menu: (provided, state) => ({
    ...provided,
    background: '#0A1F42',
    boxShadow: 'none',
  }),
  menuList: (provided, state) => ({
    ...provided,
    padding: '0',
    marginTop: '-7px',
    marginBottom: '0',
    borderRadius: '5px',
    backgroundColor: '#0A1F42',
    border: '1px solid rgba(255, 255, 255, 0.2)', // Add borderTop to the menuList
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    fontSize: '10px',
    width: '30px',
    transition: 'transform 0.3s', // Add transition for smooth rotation effect
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null, // Rotate the arrow when menu is open
    color: 'white'
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'white' // Set the color of the selected text to white
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'white' // Set the color of the placeholder text to white
  })
};
