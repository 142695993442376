import React from 'react'
import HomeFaqs from '../components/HomeFaqs'
import JoinusFAQS from '../components/JoinusFAQS'
import Card from '../components/Card'
import Accordian from '../components/Accordian'

function FAQs() {
  return (
    <Card>
      
      <div className='flex flex-col gap-3'>
        <Accordian title='Home Faqs' hight={"h-[350px] sm:h-[600px]"}>
            <HomeFaqs />
        </Accordian>
        <Accordian title='Join-Us Faqs' hight={"h-[350px] sm:h-[600px]"}>
            <JoinusFAQS />
        </Accordian>
      </div>

    </Card>
  )
}

export default FAQs
