import React, { useEffect, useState } from 'react'
import Card from '../components/Card'
import Button2 from '../components/Button2'
import { Link } from 'react-router-dom'
import { BACKEND_HOST } from '../constants'
import { toast } from 'react-toastify'
import axios from 'axios'

function AdminOrders() {
    const [orders, setOrders] = useState(null)
    const fetchorders = async() => {
        try {
            const response = await axios.get(`${BACKEND_HOST}/admin_get_orders`, {
                headers: {
                    'Content-Type': 'Application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })
    
            if(response.status === 200 && response.data.status){
                console.log(response.data.data);
                setOrders(response.data.data);
            }
            else
                toast.error(response.data.error? response.data.error : response.data.message)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(()=>{
        fetchorders()
    }, [])
  return (
    <Card>
        <span className='flex justify-between items-center'>
            <h1 className="text-white font-Oswald text-3xl sm:text-2xl">Orders</h1>
            <Link to={'/admin/orders/order-request'}>
                <Button2 title={"Order Request"} />
            </Link>
        </span>
        <div className="overflow-x-auto">
            {!orders && <img src={require('../assets/loading.gif')} alt='loader' className='w-7 h-7 sm:w-5 sm:h-5'/>}
            {orders?.length === 0 && <p className='text-white text-sm sm:text-[12px] font-Sansation'>No data found.</p>}
            {orders?.length > 0 && <table className="min-w-full sm:min-w-[700px]">
                <thead>
                    <tr>
                        <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-1 sm:py-1 text-grey">#</th>
                        <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey">Game</th>
                        <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey">Service</th>
                        <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey whitespace-nowrap">Status</th>
                        <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey whitespace-nowrap">Price</th>
                        <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey whitespace-nowrap">Customer</th>
                        <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey whitespace-nowrap">Booster</th>
                        <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {orders?.map((b, index) => {
                        if(b.role !== 'admin')
                        return <tr className="bg-primary-300 border-b-[4px] border-primary-100/10" key={index}>
                                    <td className="py-2 px-4 text-left font-Sansation text-white text-sm border-b-white sm:px-1 sm:py-1 sm:text-[10px] sm:leading-[20px]">{index+1}</td>
                                    <td className="py-2 px-4 text-left font-Sansation text-white text-sm flex justify-start items-center gap-2  sm:px-2 sm:py-1 sm:text-[10px] sm:leading-[20px]">
                                        {b.game?.title}
                                    </td>
                                    <td className="py-2 px-4 text-left font-Sansation text-white text-sm sm:px-2 sm:py-1 sm:text-[10px] sm:leading-[20px]">{b.service?.service_title}</td>
                                    <td className="py-2 px-4 text-left font-Sansation text-white text-sm sm:px-2 sm:py-1 sm:text-[10px] sm:leading-[20px]">{b.status === 'Assigned'? 'In-Progress' : b.status}</td>
                                    <td className="py-2 px-4 text-left font-Sansation text-white text-sm sm:px-2 sm:py-1 sm:text-[10px] sm:leading-[20px]">${b.total_amount}</td>
                                    <td className="py-2 px-4 text-left font-Sansation text-white text-sm sm:px-2 sm:py-1 sm:text-[10px] sm:leading-[20px]">{b.user?.username}</td>
                                    <td className="py-2 px-4 text-left font-Sansation text-white text-sm sm:px-2 sm:py-1 sm:text-[10px] sm:leading-[20px]">{b.booster?.username}</td>
                                    <td className="py-0 px-4 text-left font-Sansation text-white text-sm sm:px-2 sm:py-1 sm:text-[10px] sm:leading-[20px]">
                                        <Link to={`/admin/orders/details/${b.id}`} className='flex justify-start items-start gap-3'>
                                            <button className="text-primary-100">Details</button>
                                        </Link>
                                    </td>
                                </tr>
                    })}
                    
                </tbody>
            </table>}
        </div>
    </Card>
  )
}

export default AdminOrders
