import React, { useContext, useEffect, useState } from 'react'
import { BACKEND_HOST } from '../constants'
import axios from 'axios'
import { toast } from 'react-toastify'
import { AdminContext } from '../state/AdminProvider'

function RankLevels({ rank_id }) {
    const { refresh, setRefesh, setLevelPopup } = useContext(AdminContext)
    const [levels, setLevels] = useState(null)
    const [loading, setLoading] = useState(null)


    const fetchLevels = async () => {
        let data = JSON.stringify({
            "rank_id": rank_id
        })
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${BACKEND_HOST}/admin_get_rank_levels`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            data: data
        };
        try {
            const response = await axios.request(config)
            if (response.status === 200 && response.data.status == true) {
                setLevels(response.data.data)
            }
            else
                toast.error(response.data.error ? response.data.error : response.data.message)
        } catch (error) {
            console.log(error);
        }
    }

    const deleteLevel = async (_id) => {
        const confirm = window.confirm('Are you sure deleting level?')
        if (!confirm) {
            return
        }

        setLoading(true)
        let data = JSON.stringify({
            "level_id": _id,
        })

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${BACKEND_HOST}/admin_delete_rank_levels`,
            headers: {
                'Content-Type': 'Application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            data: data
        };
        try {
            const response = await axios.request(config)
            if (response.status === 200 && response.data.status == true) {
                toast.success(response.data.message)
                setLevels(null)
                fetchLevels()
                document.getElementById('lname').value = ''
                document.getElementById('lprice').value = ''
                document.getElementById('ltime').value = ''
            }
            else
                toast.error(response.data.error ? response.data.error : response.data.message)
        } catch (error) {
            console.log(error);
        }
        setLoading(false)
    }


    useEffect(() => {
        fetchLevels()
    }, [refresh])
    return (
        <div className='flex flex-col justify-start items-end gap-2'>
            <button disabled={loading} onClick={() => setLevelPopup({ rank_id })} className="bg-primary-100/75 rounded-md text-white text-sm px-2 py-1 w-[15%]">Add</button>
            <div className="overflow-x-auto h-[160px] overflow-y-auto w-full">
                {!levels && <img src={require('../assets/loading.gif')} alt='loader' className='w-5 h-5' />}
                {levels?.length === 0 && <p className='text-white text-[12px] sm:text-[12px] font-Sansation'>No data found.</p>}
                {levels?.length > 0 && <table className="min-w-full sm:min-w-[700px]">
                    <thead>
                        <tr>
                            <th className="py-1 px-2 text-left font-Sansation text-[12px] sm:text-sm sm:px-1 sm:py-1 text-grey">#</th>
                            <th className="py-1 px-2 text-left font-Sansation text-[12px] sm:text-sm sm:px-2 sm:py-1 text-grey">Name</th>
                            <th className="py-1 px-2 text-left font-Sansation text-[12px] sm:text-sm sm:px-2 sm:py-1 text-grey whitespace-nowrap">Price</th>
                            <th className="py-1 px-2 text-left font-Sansation text-[12px] sm:text-sm sm:px-2 sm:py-1 text-grey whitespace-nowrap">Time</th>
                            <th className="py-1 px-2 text-center font-Sansation text-[12px] sm:text-sm sm:px-2 sm:py-1 text-grey">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {levels?.map((b, index) => {
                            return <tr className="bg-primary-200/70 border-b-[1px] border-primary-100/10" key={index}>
                                <td className="py-1 px-2 text-left font-Sansation text-white text-[12px] border-b-white sm:px-1 sm:py-1 sm:text-[10px] sm:leading-[20px]">{index + 1}</td>
                                <td className="py-1 px-2 text-left font-Sansation text-white text-[12px] sm:px-2 sm:py-1 sm:text-[10px] sm:leading-[20px] min-w-[100px]">{b.name}</td>
                                <td className="py-1 px-2 text-left font-Sansation text-white text-[12px] sm:px-2 sm:py-1 sm:text-[10px] sm:leading-[20px] min-w-[100px]">${b.price}</td>
                                <td className="py-1 px-2 text-left font-Sansation text-white text-[12px] sm:px-2 sm:py-1 sm:text-[10px] sm:leading-[20px] min-w-[100px]">{b.time} hours </td>
                                <td className="py-1 px-4 text-left font-Sansation text-white text-[14px] sm:px-2 sm:py-1 sm:text-[10px] sm:leading-[20px]">
                                    <span className='flex justify-end items-start gap-2'>

                                        <button disabled={loading} onClick={() => setLevelPopup({ rank_id, level: b })} className="text-primary-100">Edit</button>
                                        <button disabled={loading} onClick={() => deleteLevel(b.id)} className="text-red">Delete</button>
                                    </span>
                                </td>
                            </tr>
                        })}

                    </tbody>
                </table>}
            </div>
        </div>
    )
}

export default RankLevels
