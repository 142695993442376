import React, { useContext, useEffect, useState } from 'react'
import { IoIosCloseCircleOutline } from 'react-icons/io'
import { AdminContext } from '../state/AdminProvider'
import { toast } from 'react-toastify'
import axios from 'axios'
import { BACKEND_HOST } from '../constants'

function EditDropdownPopup() {
    const { dropdownPopup, setDropDownPopup } = useContext(AdminContext)
    const [items, setItems] = useState(null)
    const [item, setItem] = useState(null)
    const [loading, setLoading] = useState(null)
    const [edit, setEdit] = useState()

    const removeFromEdit = () => {
        setEdit(false)
        document.getElementById('name').value = ''
        document.getElementById('price').value = ''
        setItem(null)

    }

    const onChange = (e) => {
        setItem({ ...item, [e.target.name]: e.target.value })
    }

    const deleteItem = async (_id) => {
        const confirm = window.confirm('Are you sure deleting this?')
        if (!confirm)
            return;
        setLoading(true)

        let data = JSON.stringify({
            "item_id": _id,
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${BACKEND_HOST}/admin_delete_items`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            data: data
        };

        const response = await axios.request(config)
        if (response.status === 200 && response.data.status == true) {
            setItems(null)
            fetchItems()
            toast.success(response.data.message)
        }
        else
            toast.error(response.data.error ? response.data.error : response.data.message)
        setLoading(false)
    }

    const editItem = async () => {
        let data = JSON.stringify({
            "item_id":item.id,
            "dropdown_id": dropdownPopup.id,
            "name": item.name,
            "price":item.price
        });

        console.log(data);
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${BACKEND_HOST}/admin_update_items`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            data: data
        };

        const response = await axios.request(config)
        if (response.status === 200 && response.data.status == true) {
            setEdit(false)
            document.getElementById('name').value = ''
            document.getElementById('price').value = ''
            setItem(null)
            setItems(null)
            fetchItems()
            toast.success(response.data.message)
        }
        else
            toast.error(response.data.error ? response.data.error : response.data.message)
    }

    const addItem = async () => {
        let data = JSON.stringify({
            "dropdown_id": dropdownPopup.id,
            "name": item.name,
            "price": item.price
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${BACKEND_HOST}/admin_add_items`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            data: data
        };

        const response = await axios.request(config)
        if (response.status === 200 && response.data.status == true) {
            setItem(null)
            setItems(null)
            fetchItems()
            document.getElementById('name').value = ''
            document.getElementById('price').value = ''
            toast.success(response.data.message)
        }
        else
            toast.error(response.data.error ? response.data.error : response.data.message)
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)

        if (edit)
            await editItem()
        else
            await addItem()

        setLoading(false)
    }

    const fetchItems = async () => {
        let data = JSON.stringify({
            "dropdown_id": dropdownPopup.id,
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${BACKEND_HOST}/admin_get_items`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            data: data
        };

        const response = await axios.request(config)
        if (response.status === 200 && response.data.status == true) {
            setItems(response.data.data)
        }
        else
            toast.error(response.data.error ? response.data.error : response.data.message)
    }


    useEffect(() => {

    }, [item])
    useEffect(() => {
        fetchItems()
    }, [])

    return (
        <div className='fixed sm:w-full top-0 right-0 w-[80%] h-screen bg-[rgba(0,0,0,0.72)] popupopens z-50 flex justify-center items-center'>
            <div className='admin sm:px-4 sm:w-[90%] rounded-xl px-7 py-5 w-[50%] h-[410px] relative flex gap-4 flex-col border-[1px] border-white/40'>
                <IoIosCloseCircleOutline onClick={() => setDropDownPopup(false)} className='bg-red/50 text-white p-[2px] rounded-full text-[30px] absolute -top-2 right-2 cursor-pointer' />

                <h2 className='text-white font-Oswald text-2xl flex gap-3'>Lp Gain <span className='text-primary-100'>30%</span></h2>

                <div className="overflow-x-auto overflow-y-scroll h-[260px] sm:h-auto">
                    {!items && <img src={require('../assets/loading.gif')} alt='loader' className='w-5 h-5' />}
                    {items?.length === 0 && <p className='text-white text-sm sm:text-[12px] font-Sansation'>No data found.</p>}
                    {items?.length > 0 && <table className="min-w-full">
                        <thead>
                            <tr>
                                <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-1 sm:py-1 text-grey">#</th>
                                <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey">Item</th>
                                <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey whitespace-nowrap">Percentage</th>
                                <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items?.map((b, index) => {
                                return <tr className="bg-primary-300 border-b-[4px] border-primary-100/10" key={index}>
                                    <td className="py-2 px-4 text-left font-Sansation text-white text-sm border-b-white sm:px-1 sm:py-1 sm:text-[10px] sm:leading-[20px]">{index + 1}</td>
                                    <td className="py-2 px-4 text-left font-Sansation text-white text-sm flex justify-start items-center gap-2  sm:px-2 sm:py-1 sm:text-[10px] sm:leading-[20px] min-w-[150px]">
                                        {b.name}
                                    </td>
                                    <td className="py-2 px-4 text-left font-Sansation text-white text-sm sm:px-2 sm:py-1 sm:text-[10px] sm:leading-[20px] min-w-[150px]">{b.price}%</td>
                                    <td className="py-0 px-4 text-left font-Sansation text-white text-sm sm:px-2 sm:py-1 sm:text-[10px] sm:leading-[20px]">
                                        <span className='flex justify-start items-start gap-3'>
                                            <button onClick={() => {setEdit(true); setItem(b)}} className="text-primary-100">Edit</button>
                                            <button disabled={loading} onClick={() => deleteItem(b.id)} className="text-red  disabled:cursor-wait">Delete</button>
                                        </span>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>}
                </div>

                <form onSubmit={onSubmit} className='flex flex-col gap-2 justify-between items-start bg-primary-200/75 border-[1px] border-white/20 px-3 py-4 rounded-xl'>
                    <p className='text-white text-base sm:text-sm font-Sansation'>{edit?<span className='flex gap-2 items-center'>Edit Item  <IoIosCloseCircleOutline onClick={removeFromEdit} className='text-lg cursor-pointer' /></span> : 'Add Item'}</p>
                    <div className='flex gap-1 w-full'>
                        <input type='text' className='bg-transparent  sm:text-sm sm:px-3 px-5 py-2 border-[1px] border-white/55 rounded-lg text-white outline-none text-sm font-Sansation w-[50%]' id='name' value={item?.name} name='name' required placeholder={"Item Name"} onChange={onChange} />
                        <input type='number' className='bg-transparent  sm:text-sm sm:px-3 px-5 py-2 border-[1px] border-white/55 rounded-lg text-white outline-none text-sm font-Sansation w-[50%]' id='price' value={item?.price} name='price' required placeholder={"Item Percentage"} onChange={onChange} />
                    </div>
                    <button disabled={loading} className='bg-[#026CB7] text-white text-sm rounded-lg py-2 disabled:cursor-wait disabled:opacity-75 w-full'>{edit? 'Update':'Add'}</button>
                </form>

            </div>
        </div>
    )
}

export default EditDropdownPopup
