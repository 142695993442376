import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { PiGameControllerFill } from "react-icons/pi";
import {Context} from '../state/Provider';
import Button from './Button';
import { BACKEND_HOST } from '../constants';
import { toast } from 'react-toastify';

function Cart() {
    const [t] = useTranslation("global")
    const [boosterAccord, setBossterAccord] = useState(false)
    const [promoAccord, setPromoAccord] = useState(false)
    const [promoApplied, setPromoApplied] = useState(false)
    const [selectedBooster, setSelectedBooster] = useState(null)
    const [loading, setLoading] = useState(false)
    const [totalPrice, setTotalPrice] = useState(0)
    const [checkPrice, setCheckPrice] = useState(false)

    const {
        selectedService,

        selectedCurrentRank,
        selectedDesiredank,
        selectedPlacementRank,

        selectedCurrentRankLevel,
        selectedDesiredankLevel,
        selectedPlacementRankLevel,

        selectedPlaform,

        selectedCutomization,
        selectedDropdownItems,

        selectedNGames,

        currentRankPoints,
        desiredRankPoints,
        placementRankPoints,

        game,

        gameBooster,
        price,
        timeSpan
    } = useContext(Context)


    const selectBooster = (b) => {
        if(selectedBooster?.id === b.id)
            setSelectedBooster(null)
        else
            setSelectedBooster(b)
    }

    const appLyPromodecode = async() => {
        const promo_code = document.getElementById('promo_code').value
        const response = await fetch(`${BACKEND_HOST}/apply_promo`,{
            method: 'POST',
            headers: {
                'Content-Type': 'Application/json',
            },
            body: JSON.stringify({promo_code})
        })

        const res = await response.json()
        if(response.status == 200 && res?.status == true){
            toast.success(res.message)
            setPromoApplied(res.data)
            setPromoAccord(false)
        }
        else
            toast.error(res.message)
        console.log(res);

    }

    const placeOrder = async() => {

        if(!localStorage.getItem('user')){
            window.location.pathname = '/login'
            return;
        }

        setLoading(true)

        let selectedCustom = []
        for (let i = 0; i < selectedCutomization.length; i++) {
            selectedCustom.push(selectedCutomization[i].id)
        }
        let selectedDropItems = []
        for (let i = 0; i < selectedDropdownItems.length; i++) {
            selectedDropItems.push(selectedDropdownItems[i].value)
        }

        let conversionRank = {
            current: {
                rank_id: selectedCurrentRank?.id,
                level_id: selectedCurrentRankLevel?.id, 
                points: currentRankPoints,
            },
            desired: {
                rank_id: selectedDesiredank?.id,
                level_id: selectedDesiredankLevel?.id,
                points: desiredRankPoints,
            }
        }

        let placementRank = {
            placement: {
                rank_id: selectedPlacementRank?.id,
                level_id: selectedPlacementRankLevel?.id,
                points: placementRankPoints,
            }
        }

        const user = JSON.parse(localStorage.getItem('user'))
        const data = {
            service_id: selectedService?.id,
            game_id: game.id,
            user_id: user.id,
            promo_code_applied: promoApplied? true : false,
            total_amount: totalPrice,
            rank_type: selectedService?.rank.rank_type,
            order_ranks: selectedService?.rank?.ranks && (selectedService?.rank.rank_type === 'Conversion' ? conversionRank : placementRank),
            order_customizes: selectedCustom,
            plateform_id: selectedPlaform.id,
            number_of_games: selectedNGames,
            booster_id : selectedBooster?.id,
            order_items: selectedDropItems,
        }

        const response = await fetch(`${BACKEND_HOST}/add_order`,{
            method: 'POST',
            headers: {
                'Content-Type': 'Application/json',
            },
            body: JSON.stringify(data)
        })

        const res = await response.json()
        if(response.status === 200){
            window.location.pathname = '/dashboard/my-orders'
        }
        else
            toast.error(res.message? res.message : res.error)

        setLoading(false)
    }

    useEffect(()=>{
        if(promoApplied){
            let discounted_price = price * (1 - parseFloat(promoApplied.percentage) / 100);
            setTotalPrice(discounted_price)
        }
        else
            setTotalPrice(price)
    }, [checkPrice, promoApplied])

    useEffect(()=> {
        if(price !== checkPrice){
            setCheckPrice(state => !state)
        }
    }, [price])
    
  return (
    <div className='border-[1px] border-white rounded-3xl sm:rounded-xl sm:px-5 sm:py-6 bg-[#ffffff1e] px-6 py-7 flex flex-col gap-5 sm:gap-3'>
    <h1 className='text-white font-Oswald text-3xl font-bold sm:text-[27px]'>{t('orderPage.summary')}</h1>
   {selectedService?.rank &&  <>
   
    <p className='text-white font-Sansation text-base flex gap-2 items-center -mt-1 sm:text-sm'>
         <svg xmlns="http://www.w3.org/2000/svg" width="15" height="18" viewBox="0 0 15 18" fill="none">
             <path d="M7.5 6.14286V10.1429H10.2083M5.875 1H9.125M7.5 3.28571C5.77609 3.28571 4.12279 4.00816 2.90381 5.29413C1.68482 6.58009 1 8.32423 1 10.1429C1 11.9615 1.68482 13.7056 2.90381 14.9916C4.12279 16.2776 5.77609 17 7.5 17C9.22391 17 10.8772 16.2776 12.0962 14.9916C13.3152 13.7056 14 11.9615 14 10.1429C14 8.32423 13.3152 6.58009 12.0962 5.29413C10.8772 4.00816 9.22391 3.28571 7.5 3.28571Z" stroke="white"/>
         </svg>
         {/* {selectedService?.rank?.rank_type == "Conversion" && 
         <> */}
         {t('orderPage.expected_time')} {timeSpan?.days > 0 && `${timeSpan.days} day`} {timeSpan?.hours > 0 && `${timeSpan.hours} hours`} {timeSpan?.min > 0 && `${timeSpan.min} minutes`}
         {/* </>} */}
         {/* {selectedService?.rank?.rank_type == "Placement" && 
         <>
         {t('orderPage.expected_time')} {selectedPlacementRankLevel?.time ? selectedPlacementRankLevel?.time : 0} hours
         </>} */}
     </p>
     <svg xmlns="http://www.w3.org/2000/svg" width="306" height="2" viewBox="0 0 306 2" fill="none" className='w-full'>
         <path opacity="0.8" d="M0 1L306 0.999973" stroke="url(#paint0_linear_215_159)"/>
         <defs>
             <linearGradient id="paint0_linear_215_159" x1="0" y1="1" x2="306" y2="1.00003" gradientUnits="userSpaceOnUse">
             <stop stop-color="white" stop-opacity="0"/>
             <stop offset="0.49" stop-color="white"/>
             <stop offset="1" stop-color="white" stop-opacity="0"/>
             </linearGradient>
         </defs>
     </svg>
     </>}

     <div className='flex flex-col gap-0'>
        <div>
           {selectedService?.rank?.rank_type == "Conversion" ?
            <span className='flex justify-start items-center gap-1'>
                <p className='text-white font-Sansation text-base capitalize sm:text-sm'>{selectedCurrentRank?.rank_name} {selectedCurrentRankLevel?.name}</p>
                <MdOutlineKeyboardDoubleArrowRight className='text-lg text-white'/>
                <p className='text-white font-Sansation text-base capitalize  sm:text-sm'>{selectedDesiredank?.rank_name} {selectedDesiredankLevel?.name}</p>
            </span>
            :
            <p className='text-white font-Sansation text-base capitalize sm:text-sm'>{selectedPlacementRank?.rank_name} {selectedPlacementRankLevel?.name}</p>
            }
            {!selectedService?.rank && 
                <p className='text-white font-Sansation text-base capitalize  sm:text-sm'>{selectedNGames} Wins</p>
            }
        </div>
        
            {Array.isArray(selectedCutomization) && 
            selectedCutomization?.map((c, index)=>{
                return  <span key={index} className='flex justify-between items-center'>
                            <p className='text-white font-Sansation text-base capitalize mt-1 sm:text-sm'>{c.custom_name}</p>
                            <p className='text-white font-Sansation text-base capitalize sm:text-sm'>{c.percentage == 0 ? 'Free' : `+${c.percentage}%`}</p>
                        </span>
            })}
            {Array.isArray(selectedDropdownItems) && 
            selectedDropdownItems?.map((d, index)=>{
                return  <span key={index} className='flex justify-between items-center'>
                            <p className='text-white font-Sansation text-base capitalize mt-1 sm:text-sm'>{d.label}</p>
                            <p className='text-white font-Sansation text-base capitalize sm:text-sm'>{d.price == 0 ? 'Free' :  d.price > 0? `+${d.price}%` : `${d.price}%`}</p>
                        </span>
            })}
       
     </div>

     <svg xmlns="http://www.w3.org/2000/svg" width="306" height="2" viewBox="0 0 306 2" fill="none" className='w-full'>
         <path opacity="0.8" d="M0 1L306 0.999973" stroke="url(#paint0_linear_215_159)"/>
         <defs>
             <linearGradient id="paint0_linear_215_159" x1="0" y1="1" x2="306" y2="1.00003" gradientUnits="userSpaceOnUse">
             <stop stop-color="white" stop-opacity="0"/>
             <stop offset="0.49" stop-color="white"/>
             <stop offset="1" stop-color="white" stop-opacity="0"/>
             </linearGradient>
         </defs>
     </svg>

    <div className='relative -mb-2'>
        <div className='flex justify-between items-center cursor-pointer' onClick={()=>setBossterAccord(state => !state)}>
            <span className='flex justify-start items-center gap-3 pb-2'>
                <PiGameControllerFill className="text-white text-3xl"/>
                <p className='text-white font-Sansation text-base capitalize sm:text-sm'>{t('orderPage.specific_booster')}</p>
            </span>
            <MdOutlineKeyboardArrowDown className={`text-white text-3xl sm:text-2xl ${boosterAccord && 'rotate-180'} transition-all ease-in duration-200`}/>
        </div>
        <div className={`${boosterAccord? 'h-[150px]' : 'h-0'} transition-all ease-in duration-200 overflow-hidden`}>
            <div className='flex flex-col gap-3 overflow-x-hidden overflow-y-scroll h-[150px] cart-booster'>
                {gameBooster?.map((b, index) => {
                    return <div className='flex justify-between items-center'>
                                <div className='flex justify-start items-center gap-2'>
                                    <img src={b.profile_image? `${BACKEND_HOST.replace('/api', '')}/${b.profile_image}` : require('../assets/defaultAvatar.jpg')} alt='avatar' className='h-8 w-8 rounded-full object-cover border-white/60 border-[1px]'/>
                                    <p className='text-white font-Sansation text-sm capitalize sm:text-[12px]'>{b.username}</p>
                                </div>
                                <div className='flex justify-end items-center gap-2'>
                                    <span className='flex justify-center items-center p-1 border-primary-100 border-[1px] rounded-full cursor-pointer' onClick={()=>selectBooster(b)}>
                                        <span className={`w-3 h-3 ${selectedBooster?.id === b.id &&  'bg-primary-100'} rounded-full`}></span>
                                    </span>
                                </div>
                            </div>
                })}
            </div>
        </div>
    </div>

    <svg xmlns="http://www.w3.org/2000/svg" width="306" height="2" viewBox="0 0 306 2" fill="none" className='w-full'>
         <path opacity="0.8" d="M0 1L306 0.999973" stroke="url(#paint0_linear_215_159)"/>
         <defs>
             <linearGradient id="paint0_linear_215_159" x1="0" y1="1" x2="306" y2="1.00003" gradientUnits="userSpaceOnUse">
             <stop stop-color="white" stop-opacity="0"/>
             <stop offset="0.49" stop-color="white"/>
             <stop offset="1" stop-color="white" stop-opacity="0"/>
             </linearGradient>
         </defs>
     </svg>
    
    <div className='relative overflow-hidden'>
        <div className='flex justify-between items-center cursor-pointer' onClick={()=>setPromoAccord(state => !state)}>
            <span className='flex justify-start items-center gap-3'>
                <PiGameControllerFill className="text-white text-3xl"/>
                <p className='text-white font-Sansation text-base capitalize sm:text-sm'>{t('orderPage.promo')}</p>
            </span>
            <MdOutlineKeyboardArrowDown className={`text-white text-3xl sm:text-2xl ${promoAccord && 'rotate-180'} transition-all ease-in duration-200`}/>
        </div>
        <div className={`${promoAccord? 'h-[60px]' : 'h-0'} transition-all ease-in duration-200`}>
            <form className='flex gap-2  pt-4' onSubmit={(e)=>{e.preventDefault(); appLyPromodecode()}}>
                <input id='promo_code' type='text' className='bg-transparent  sm:text-sm sm:px-3 px-5 py-2 border-[1px] w-[70%] border-white/55 rounded-lg text-white outline-none text-sm font-Sansation' placeholder={t('orderPage.Promo_Code')}/>
                <button className='bg-[#026CB7] text-white text-sm rounded-lg w-[30%] py-2'>{t('orderPage.apply')}</button>
            </form>
        </div>
    </div>

    <svg xmlns="http://www.w3.org/2000/svg" width="306" height="2" viewBox="0 0 306 2" fill="none" className='w-full'>
         <path opacity="0.8" d="M0 1L306 0.999973" stroke="url(#paint0_linear_215_159)"/>
         <defs>
             <linearGradient id="paint0_linear_215_159" x1="0" y1="1" x2="306" y2="1.00003" gradientUnits="userSpaceOnUse">
             <stop stop-color="white" stop-opacity="0"/>
             <stop offset="0.49" stop-color="white"/>
             <stop offset="1" stop-color="white" stop-opacity="0"/>
             </linearGradient>
         </defs>
     </svg>

   {promoApplied && <div className='flex justify-start items-center'>
        <p className='text-white font-Sansation text-base font-extrabold w-[50%] sm:text-sm'>{t('orderPage.Promo_Code')}</p>
        <p className='text-white font-Sansation text-base w-[50%] sm:text-sm'>{promoApplied.percentage}%</p>
     </div>}
     <div className='flex justify-start items-center'>
        <p className='text-white font-Sansation text-base font-extrabold w-[50%] sm:text-sm'>{t('orderPage.tammount')}</p>
        <p className='text-white font-Sansation text-base w-[50%] sm:text-sm'>{promoApplied && <span className='line-through'>${price.toFixed(2)}</span>} ${totalPrice?.toFixed(2)}</p>
     </div>
     <div className='flex justify-start items-center'>
        <p className='text-white font-Sansation text-base font-extrabold w-[50%] sm:text-sm'>{t('orderPage.Cashback')}</p>
        <p className='text-white font-Sansation text-base w-[50%] sm:text-sm'>$0.00</p>
     </div>
     <Button title={t("orderPage.Checkout")} onClick={placeOrder} disabled={loading}/>
 </div>
  )
}

export default Cart
