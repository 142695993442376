import React, { useEffect, useState } from 'react'
import { Link, Navigate, Outlet, Route, Routes, useNavigate } from 'react-router-dom'
import { BiSolidEditAlt } from "react-icons/bi";
import Eclips from '../components/Eclips';
import { useTranslation } from 'react-i18next';
import UserOrders from '../components/UserOrders';
import { motion } from 'framer-motion'
import { IoStatsChartSharp } from "react-icons/io5";
import { CgNotes } from "react-icons/cg";
import { IoWallet } from "react-icons/io5";
import EditProfile from '../components/EditProfile';
import { BACKEND_HOST } from '../constants';
import BoosterRules from '../components/BoosterRules';
import MyOrder from '../components/MyOrder';
import Chat from '../components/Chat';
import CustomeOrderRequest from './CustomeOrderRequest';

function UserDashboard() {
  const [t,] = useTranslation("global")
  const navigate = useNavigate()

  const [resfres, setRefresh] = useState(false)

  useEffect(()=>{
    if(!localStorage.getItem('token'))
      navigate('/')
  },[resfres])
  return (
    <motion.div initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0,transition:{duration:1}}} className='flex flex-col w-[100%] overflow-hidden relative'>
    <div className="relative bg-cover bg-no-repeat bg-left sm:opacity-35 opacity-20 bg-[url('./assets/userDash.jpg');] h-screen w-full -mt-2"></div>
      
      <div className='pl-60 pr-40 flex justify-center items-start gap-14 -mt-[600px] z-10 sm:px-7 sm:flex-col sm:justify-center sm:gap-7 sm:items-center sm:-mt-[550px]'>
        <img src={localStorage.getItem('profile_image') != 'null'? `${BACKEND_HOST.replace('/api', '')}/${localStorage.getItem('profile_image')}` : require('../assets/defaultAvatar.jpg')} className='border-[1px] border-white rounded-full object-cover h-[185.116px] w-[185.116px] sm:h-[100px] sm:w-[100px]' alt='profile' style={{filter:'drop-shadow(0px 0px 10px #0091F7)'}}/>
          <div className='flex flex-col gap-5 w-[55%] sm:w-full'>
            <h1 className='font-Oswald text-[35px] font-bold text-white sm:text-[26px] sm:leading-[36px] sm:text-center'>{t("userDashboard.hi_again")} <span className='capitalize'>{localStorage.getItem('username')}</span></h1>
          
            {localStorage.getItem('role') == 'user'? <>
              <Link to='/dashboard/my-orders' className='flex relative justify-start items-center sm:px-4 sm:py-2 gap gap-5 px-7 py-3 border-white border-[1px] hover:bg-[#0091F7]  glass rounded-[60px] w-full transition-all ease-in duration-150 hover:border-[#0091F7]'>
                  <IoStatsChartSharp className='text-white text-[30px] sm:text-[25px]'/>
                  <p className='font-Oswald text-[20px] text-white sm:text-lg'>{t("userDashboard.myorders")}</p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="18" viewBox="0 0 16 23" fill="none" className='absolute right-5 top-[22px] sm:w-[10px] sm:top-3'>
                    <path d="M0.429973 1.9392C0.292625 1.82947 0.185251 1.70039 0.113979 1.55934C0.0427084 1.41828 0.00893497 1.26801 0.0145893 1.1171C0.0202436 0.966195 0.0652151 0.817605 0.146935 0.679817C0.228656 0.542029 0.345524 0.417742 0.490869 0.314052C0.636212 0.210362 0.807187 0.1293 0.994028 0.0754937C1.18087 0.0216874 1.37992 -0.00380926 1.57981 0.000459732C1.77971 0.00472872 1.97653 0.0386796 2.15904 0.100374C2.34155 0.162068 2.50618 0.250298 2.64353 0.360025L15.5839 10.704C15.8511 10.9173 16 11.1999 16 11.4935C16 11.7872 15.8511 12.0697 15.5839 12.2831L2.64353 22.6282C2.50709 22.7403 2.3425 22.8309 2.15931 22.8946C1.97613 22.9583 1.778 22.9939 1.57645 22.9993C1.37489 23.0047 1.17392 22.9798 0.985209 22.9261C0.796499 22.8723 0.623809 22.7909 0.477171 22.6863C0.330534 22.5818 0.212871 22.4563 0.131018 22.3171C0.0491638 22.178 0.00475216 22.0279 0.000361443 21.8757C-0.00403023 21.7235 0.0316858 21.5721 0.105437 21.4304C0.179188 21.2887 0.289502 21.1594 0.429973 21.0502L12.3838 11.4935L0.429973 1.9392Z" fill="white"/>
                  </svg>
              </Link>
              </>
            :
            <>
            <Link to='/dashboard/orders' className='flex relative justify-start items-center sm:px-4 sm:py-2 gap gap-5 px-7 py-3 border-white border-[1px] hover:bg-[#0091F7]  glass rounded-[60px] w-full transition-all ease-in duration-150 hover:border-[#0091F7]'>
                <IoStatsChartSharp className='text-white text-[30px] sm:text-[25px]'/>
                <p className='font-Oswald text-[20px] text-white sm:text-lg'>{t("userDashboard.orders")}</p>
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="18" viewBox="0 0 16 23" fill="none" className='absolute right-5 top-[22px] sm:w-[10px] sm:top-3'>
                  <path d="M0.429973 1.9392C0.292625 1.82947 0.185251 1.70039 0.113979 1.55934C0.0427084 1.41828 0.00893497 1.26801 0.0145893 1.1171C0.0202436 0.966195 0.0652151 0.817605 0.146935 0.679817C0.228656 0.542029 0.345524 0.417742 0.490869 0.314052C0.636212 0.210362 0.807187 0.1293 0.994028 0.0754937C1.18087 0.0216874 1.37992 -0.00380926 1.57981 0.000459732C1.77971 0.00472872 1.97653 0.0386796 2.15904 0.100374C2.34155 0.162068 2.50618 0.250298 2.64353 0.360025L15.5839 10.704C15.8511 10.9173 16 11.1999 16 11.4935C16 11.7872 15.8511 12.0697 15.5839 12.2831L2.64353 22.6282C2.50709 22.7403 2.3425 22.8309 2.15931 22.8946C1.97613 22.9583 1.778 22.9939 1.57645 22.9993C1.37489 23.0047 1.17392 22.9798 0.985209 22.9261C0.796499 22.8723 0.623809 22.7909 0.477171 22.6863C0.330534 22.5818 0.212871 22.4563 0.131018 22.3171C0.0491638 22.178 0.00475216 22.0279 0.000361443 21.8757C-0.00403023 21.7235 0.0316858 21.5721 0.105437 21.4304C0.179188 21.2887 0.289502 21.1594 0.429973 21.0502L12.3838 11.4935L0.429973 1.9392Z" fill="white"/>
                </svg>
            </Link>
             <Link to='/dashboard/my-orders' className='flex relative justify-start items-center sm:px-4 sm:py-2 gap gap-5 px-7 py-3 border-white border-[1px] hover:bg-[#0091F7]  glass rounded-[60px] w-full transition-all ease-in duration-150 hover:border-[#0091F7]'>
                  <IoStatsChartSharp className='text-white text-[30px] sm:text-[25px]'/>
                  <p className='font-Oswald text-[20px] text-white sm:text-lg'>{t("userDashboard.myorders")}</p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="18" viewBox="0 0 16 23" fill="none" className='absolute right-5 top-[22px] sm:w-[10px] sm:top-3'>
                    <path d="M0.429973 1.9392C0.292625 1.82947 0.185251 1.70039 0.113979 1.55934C0.0427084 1.41828 0.00893497 1.26801 0.0145893 1.1171C0.0202436 0.966195 0.0652151 0.817605 0.146935 0.679817C0.228656 0.542029 0.345524 0.417742 0.490869 0.314052C0.636212 0.210362 0.807187 0.1293 0.994028 0.0754937C1.18087 0.0216874 1.37992 -0.00380926 1.57981 0.000459732C1.77971 0.00472872 1.97653 0.0386796 2.15904 0.100374C2.34155 0.162068 2.50618 0.250298 2.64353 0.360025L15.5839 10.704C15.8511 10.9173 16 11.1999 16 11.4935C16 11.7872 15.8511 12.0697 15.5839 12.2831L2.64353 22.6282C2.50709 22.7403 2.3425 22.8309 2.15931 22.8946C1.97613 22.9583 1.778 22.9939 1.57645 22.9993C1.37489 23.0047 1.17392 22.9798 0.985209 22.9261C0.796499 22.8723 0.623809 22.7909 0.477171 22.6863C0.330534 22.5818 0.212871 22.4563 0.131018 22.3171C0.0491638 22.178 0.00475216 22.0279 0.000361443 21.8757C-0.00403023 21.7235 0.0316858 21.5721 0.105437 21.4304C0.179188 21.2887 0.289502 21.1594 0.429973 21.0502L12.3838 11.4935L0.429973 1.9392Z" fill="white"/>
                  </svg>
              </Link>
            <Link to='/dashboard/payment-logs' className='flex relative justify-start items-center sm:px-4 sm:py-2 gap gap-5 px-7 py-3 border-white border-[1px] hover:bg-[#0091F7] glass rounded-[60px] w-full transition-all ease-in duration-150 hover:border-[#0091F7]'>
                <IoWallet  className='text-white text-[30px] sm:text-[25px]'/>
                <p className='font-Oswald text-[20px] text-white sm:text-lg'>{t("userDashboard.log")}</p>
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="18" viewBox="0 0 16 23" fill="none" className='absolute right-5 top-[22px] sm:w-[10px] sm:top-3'>
                  <path d="M0.429973 1.9392C0.292625 1.82947 0.185251 1.70039 0.113979 1.55934C0.0427084 1.41828 0.00893497 1.26801 0.0145893 1.1171C0.0202436 0.966195 0.0652151 0.817605 0.146935 0.679817C0.228656 0.542029 0.345524 0.417742 0.490869 0.314052C0.636212 0.210362 0.807187 0.1293 0.994028 0.0754937C1.18087 0.0216874 1.37992 -0.00380926 1.57981 0.000459732C1.77971 0.00472872 1.97653 0.0386796 2.15904 0.100374C2.34155 0.162068 2.50618 0.250298 2.64353 0.360025L15.5839 10.704C15.8511 10.9173 16 11.1999 16 11.4935C16 11.7872 15.8511 12.0697 15.5839 12.2831L2.64353 22.6282C2.50709 22.7403 2.3425 22.8309 2.15931 22.8946C1.97613 22.9583 1.778 22.9939 1.57645 22.9993C1.37489 23.0047 1.17392 22.9798 0.985209 22.9261C0.796499 22.8723 0.623809 22.7909 0.477171 22.6863C0.330534 22.5818 0.212871 22.4563 0.131018 22.3171C0.0491638 22.178 0.00475216 22.0279 0.000361443 21.8757C-0.00403023 21.7235 0.0316858 21.5721 0.105437 21.4304C0.179188 21.2887 0.289502 21.1594 0.429973 21.0502L12.3838 11.4935L0.429973 1.9392Z" fill="white"/>
                </svg>
            </Link>
            <Link to='/dashboard/booster-rules' className='flex relative justify-start items-center sm:px-4 sm:py-2 gap gap-5 px-7 py-3 border-white border-[1px] hover:bg-[#0091F7] glass rounded-[60px] w-full transition-all ease-in duration-150 hover:border-[#0091F7]'>
                <CgNotes className='text-white text-[30px] sm:text-[25px]'/>
                <p className='font-Oswald text-[20px] text-white sm:text-lg'>{t("userDashboard.rules")}</p>
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="18" viewBox="0 0 16 23" fill="none" className='absolute right-5 top-[22px] sm:w-[10px] sm:top-3'>
                  <path d="M0.429973 1.9392C0.292625 1.82947 0.185251 1.70039 0.113979 1.55934C0.0427084 1.41828 0.00893497 1.26801 0.0145893 1.1171C0.0202436 0.966195 0.0652151 0.817605 0.146935 0.679817C0.228656 0.542029 0.345524 0.417742 0.490869 0.314052C0.636212 0.210362 0.807187 0.1293 0.994028 0.0754937C1.18087 0.0216874 1.37992 -0.00380926 1.57981 0.000459732C1.77971 0.00472872 1.97653 0.0386796 2.15904 0.100374C2.34155 0.162068 2.50618 0.250298 2.64353 0.360025L15.5839 10.704C15.8511 10.9173 16 11.1999 16 11.4935C16 11.7872 15.8511 12.0697 15.5839 12.2831L2.64353 22.6282C2.50709 22.7403 2.3425 22.8309 2.15931 22.8946C1.97613 22.9583 1.778 22.9939 1.57645 22.9993C1.37489 23.0047 1.17392 22.9798 0.985209 22.9261C0.796499 22.8723 0.623809 22.7909 0.477171 22.6863C0.330534 22.5818 0.212871 22.4563 0.131018 22.3171C0.0491638 22.178 0.00475216 22.0279 0.000361443 21.8757C-0.00403023 21.7235 0.0316858 21.5721 0.105437 21.4304C0.179188 21.2887 0.289502 21.1594 0.429973 21.0502L12.3838 11.4935L0.429973 1.9392Z" fill="white"/>
                </svg>
            </Link>
            </>
            }
           
            <Link to='/dashboard/edit-profile' className='flex relative justify-start items-centers sm:px-4 sm:py-2 gap gap-5 px-7 py-3 border-white border-[1px] hover:bg-[#0091F7] glass rounded-[60px] w-full transition-all ease-in duration-150 hover:border-[#0091F7]'>
                <BiSolidEditAlt className='text-white text-[30px] sm:text-[25px]'/>
                <p className='font-Oswald text-[20px] text-white sm:text-lg'>{t("userDashboard.edit")}</p>
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="18" viewBox="0 0 16 23" fill="none" className='absolute right-5 top-[22px] sm:w-[10px] sm:top-3'>
                  <path d="M0.429973 1.9392C0.292625 1.82947 0.185251 1.70039 0.113979 1.55934C0.0427084 1.41828 0.00893497 1.26801 0.0145893 1.1171C0.0202436 0.966195 0.0652151 0.817605 0.146935 0.679817C0.228656 0.542029 0.345524 0.417742 0.490869 0.314052C0.636212 0.210362 0.807187 0.1293 0.994028 0.0754937C1.18087 0.0216874 1.37992 -0.00380926 1.57981 0.000459732C1.77971 0.00472872 1.97653 0.0386796 2.15904 0.100374C2.34155 0.162068 2.50618 0.250298 2.64353 0.360025L15.5839 10.704C15.8511 10.9173 16 11.1999 16 11.4935C16 11.7872 15.8511 12.0697 15.5839 12.2831L2.64353 22.6282C2.50709 22.7403 2.3425 22.8309 2.15931 22.8946C1.97613 22.9583 1.778 22.9939 1.57645 22.9993C1.37489 23.0047 1.17392 22.9798 0.985209 22.9261C0.796499 22.8723 0.623809 22.7909 0.477171 22.6863C0.330534 22.5818 0.212871 22.4563 0.131018 22.3171C0.0491638 22.178 0.00475216 22.0279 0.000361443 21.8757C-0.00403023 21.7235 0.0316858 21.5721 0.105437 21.4304C0.179188 21.2887 0.289502 21.1594 0.429973 21.0502L12.3838 11.4935L0.429973 1.9392Z" fill="white"/>
                </svg>
            </Link>


          </div>
      </div>



      <div className='pl-60 pr-20 mt-24 z-10 flex flex-col gap-2 sm:px-7 sm:gap-1 mb-[100px] sm:10'>
        <Routes>
          <Route index element={localStorage.getItem('role') == 'user' ? <Navigate to='my-orders' /> : <Navigate to='orders' />} />
          <Route path='orders' element={<UserOrders />}/>
          <Route path='my-orders' element={<MyOrder />}/>
          <Route path='edit-profile' element={<EditProfile setRefresh={setRefresh}/>}/>
          <Route path='booster-rules' element={<BoosterRules />}/>
          <Route path='chat/:order_id/:reciever_id/' element={<Chat />} />
        </Routes>
      </div>
      

      <Eclips />
    </motion.div>
  )
}

export default UserDashboard
