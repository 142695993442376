import React, { useState } from 'react'
import LoginHeader from '../components/LoginHeader'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Input from '../components/Input'
import Button from '../components/Button'
import { motion } from 'framer-motion'
import { BACKEND_HOST } from '../constants'
import { toast } from 'react-toastify'

function Login() {
    const [t, i18n] = useTranslation("global")
    const [login, setLogin] = useState(null)
    const [loading, setLoading] = useState(false)

    const previousURL = document.referrer;

    const onChange = (e) => {
      setLogin({...login, [e.target.name]: e.target.value})
    }

    const onSubmit = async(e) => {
      e.preventDefault()
      setLoading(true)
      try {
        const response = await fetch(`${BACKEND_HOST}/login_user`, {
          method: 'POST',
          headers: {
            'Content-Type': 'Application/json'
          },
          body: JSON.stringify(login)
        })
  
        const res = await response.json()
        if (response.ok && res.status) {
            localStorage.setItem('token', res.token)
            localStorage.setItem('profile_image', res.user.profile_image)
            localStorage.setItem('username', res.user.username)
            localStorage.setItem('role', res.user.role)
            localStorage.setItem('user_id', res.user.id)
            localStorage.setItem('user', JSON.stringify(res.user))
            if(res.user.role === 'admin')
              window.location.pathname = '/admin'
            else{
              if(previousURL.includes('boosting')){
                window.location = previousURL
                return;
              }
              window.location.pathname = '/dashboard'
            }
        }
        else{
          toast.error(res.error?res.error : res.message)
        }
  
      } catch (error) {
        console.log(error);
      }
      setLoading(false)
    }

  return (
    <motion.div initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0,transition:{duration:1}}} className='relative overflow-hidden h-[870px] sm:h-screen w-full sm:bg-[url("./assets/loginBg.jpg")] sm:bg-cover sm:bg-center sm:-mt-[100px]'>
      <img src={require('../assets/loginBg.jpg')} alt='bg' className='absolute left-[400px] sm:hidden -top-20 h-[950px] w-[2084px] sm:left-0 sm:top-0 sm:h-screen'/>
      <div className='gradient w-full h-[870px]  sm:h-screen absolute top-0 left-0 flex sm:mt-[100px]'>
        <div className='w-[950px] h-[950px] bg-primary-200 blur-[70px] rounded-full absolute -left-64 -top-44 sm:hidden'></div>
        <div className='w-[624px] h-[624px] bg-primary-100 blur-[137px] opacity-30 rounded-full absolute -right-44 sm:-top-20 sm:opacity-50 sm:-right-20 -top-48 sm:h-[300px]  sm:w-[300px]'></div>
        <div className='w-[624px] h-[624px] bg-primary-100 blur-[137px] opacity-20 rounded-full absolute -left-52 -bottom-64 sm:h-[200px] sm:opacity-80 sm:w-[200px] '></div>
        <LoginHeader/>

        <div className='z-10 mx-[88px] mt-[250px] sm:mt-[200px] w-[40%] sm:mx-7 sm:w-full'>
            <h3 className='font-Sansation text-lg text-white sm:text-[12px]'>{t('loginPage.subheading')}</h3>
            <h1 className='font-Oswald font-bold text-[68px] leading-[78px] mt-[50px] sm:text-[38px] sm:mt-0 sm:leading-[48px] text-white'>{t('loginPage.heading')}</h1>
            <h3 className='font-Sansation text-lg text-white mt-2 sm:text-[12px] sm:mt-0'>{t('loginPage.text')} <Link to='/signup' className='text-[#50C0F5] underline'>{t('loginPage.signup')}</Link></h3>

            <form className='flex flex-col gap-5 w-full mt-8' onSubmit={onSubmit}> 
               <Input name='email' type='text'  value={login?.email} onChange={onChange} required={true} placeholder={t("email")} />
               <Input name='password' type='password' value={login?.password} onChange={onChange} required={true} placeholder={t("password")} />
               <Button title={t('loginPage.loginbutton')} disabled={loading}/>
            </form>
        
        </div>

      </div>
      <div className='hidden sm:block bg-primary-100 rounded-full absolute z-[1] sm:w-[300px] sm:h-[300px] sm:opacity-50 sm:-right-32 sm:blur-[50px] sm:-top-20'></div>
    </motion.div>
  )
}

export default Login
