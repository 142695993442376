import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

function SelectQueue() {
    const [t] = useTranslation('global')
    const [queue, setQueue] = useState(0)
    return (
        <div className='flex flex-col gap-3'>
            <h1 className='text-white font-Oswald text-[38px] font-bold sm:text-[26px]'>{t('orderPage.selectQueue')}</h1>
            <div className='flex justify-start items-center gap-3 flex-wrap sm:gap-x-3 sm:gap-y-3'>
                <div className={`rounded-xl sm:rounded-md px-5 cursor-pointer py-4 flex justify-start items-start gap-3 w-[200px] sm:gap-2 sm:px-3 sm:py-2 sm:w-[140px] ${queue === 0 ? 'bg-gradient-to-r from-[#0091F7] to-[#0674C1] border-[1px] border-primary-100/75' : 'bg-[#ffffff1e] border-[1px] border-white'} transition-all ease-in duration-200`} onClick={() => setQueue(0)}>
                    {queue === 0 && <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none" className='mt-2'>
                        <path d="M14.581 8C14.581 12.2261 11.4954 15.5769 7.77718 15.5769C4.05897 15.5769 0.97337 12.2261 0.97337 8C0.97337 3.7739 4.05897 0.423077 7.77718 0.423077C11.4954 0.423077 14.581 3.7739 14.581 8Z" stroke="white" stroke-width="0.846154" />
                        <ellipse cx="7.77716" cy="8.00001" rx="5.00323" ry="5.53846" fill="white" />
                    </svg>}
                    <span className='flex flex-col gap-1 sm:gap-0'>
                        <p className='text-white font-Oswald text-[22px] font-bold sm:text-base'>1v1</p>
                        <p className='text-white font-Sansation text-base font-medium sm:text-[12px]'>Solo Duels</p>
                    </span>
                </div>
                <div className={`rounded-xl sm:rounded-md px-5 cursor-pointer py-4 flex justify-start items-start gap-3 w-[200px] sm:gap-2 sm:px-3 sm:py-2 sm:w-[140px] ${queue === 1 ? 'bg-gradient-to-r from-[#0091F7] to-[#0674C1] border-[1px] border-primary-100/75' : 'bg-[#ffffff1e] border-[1px] border-white'} transition-all ease-in duration-200`} onClick={() => setQueue(1)}>
                    {queue === 1 && <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none" className='mt-2'>
                        <path d="M14.581 8C14.581 12.2261 11.4954 15.5769 7.77718 15.5769C4.05897 15.5769 0.97337 12.2261 0.97337 8C0.97337 3.7739 4.05897 0.423077 7.77718 0.423077C11.4954 0.423077 14.581 3.7739 14.581 8Z" stroke="white" stroke-width="0.846154" />
                        <ellipse cx="7.77716" cy="8.00001" rx="5.00323" ry="5.53846" fill="white" />
                    </svg>}
                    <span className='flex flex-col gap-1 sm:gap-0'>
                        <p className='text-white font-Oswald text-[22px] font-bold sm:text-base'>2v2</p>
                        <p className='text-white font-Sansation text-base font-medium sm:text-[12px]'>Doubles</p>
                    </span>
                </div>
                <div className={`rounded-xl sm:rounded-md px-5 cursor-pointer py-4 flex justify-start items-start gap-3 w-[200px] sm:gap-2 sm:px-3 sm:py-2 sm:w-[140px] ${queue === 2 ? 'bg-gradient-to-r from-[#0091F7] to-[#0674C1] border-[1px] border-primary-100/75' : 'bg-[#ffffff1e] border-[1px] border-white'} transition-all ease-in duration-200`} onClick={() => setQueue(2)}>
                    {queue === 2 && <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none" className='mt-2'>
                        <path d="M14.581 8C14.581 12.2261 11.4954 15.5769 7.77718 15.5769C4.05897 15.5769 0.97337 12.2261 0.97337 8C0.97337 3.7739 4.05897 0.423077 7.77718 0.423077C11.4954 0.423077 14.581 3.7739 14.581 8Z" stroke="white" stroke-width="0.846154" />
                        <ellipse cx="7.77716" cy="8.00001" rx="5.00323" ry="5.53846" fill="white" />
                    </svg>}
                    <span className='flex flex-col gap-1 sm:gap-0'>
                        <p className='text-white font-Oswald text-[22px] font-bold sm:text-base'>3v3</p>
                        <p className='text-white font-Sansation text-base font-medium sm:text-[12px]'>Standard <span className='text-primary-100 font-bold'>+20%</span></p>
                    </span>
                </div>
                <div className={`rounded-xl sm:rounded-md px-5 cursor-pointer py-4 flex justify-start items-start gap-3 w-[200px] sm:gap-2 sm:px-3 sm:py-2 sm:w-[140px] ${queue === 3 ? 'bg-gradient-to-r from-[#0091F7] to-[#0674C1] border-[1px] border-primary-100/75' : 'bg-[#ffffff1e] border-[1px] border-white'} transition-all ease-in duration-200`} onClick={() => setQueue(3)}>
                    {queue === 3 && <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none" className='mt-2'>
                        <path d="M14.581 8C14.581 12.2261 11.4954 15.5769 7.77718 15.5769C4.05897 15.5769 0.97337 12.2261 0.97337 8C0.97337 3.7739 4.05897 0.423077 7.77718 0.423077C11.4954 0.423077 14.581 3.7739 14.581 8Z" stroke="white" stroke-width="0.846154" />
                        <ellipse cx="7.77716" cy="8.00001" rx="5.00323" ry="5.53846" fill="white" />
                    </svg>}
                    <span className='flex flex-col gap-1 sm:gap-0'>
                        <p className='text-white font-Oswald text-[22px] font-bold sm:text-base'>Dropshop</p>
                        <p className='text-white font-Sansation text-base font-medium sm:text-[12px]'><span className='text-primary-100 font-bold'>+30%</span></p>
                    </span>
                </div>
                <div className={`rounded-xl sm:rounded-md px-5 cursor-pointer py-4 flex justify-start items-start gap-3 w-[200px] sm:gap-2 sm:px-3 sm:py-2 sm:w-[140px] ${queue === 4 ? 'bg-gradient-to-r from-[#0091F7] to-[#0674C1] border-[1px] border-primary-100/75' : 'bg-[#ffffff1e] border-[1px] border-white'} transition-all ease-in duration-200`} onClick={() => setQueue(4)}>
                    {queue === 4 && <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none" className='mt-2'>
                        <path d="M14.581 8C14.581 12.2261 11.4954 15.5769 7.77718 15.5769C4.05897 15.5769 0.97337 12.2261 0.97337 8C0.97337 3.7739 4.05897 0.423077 7.77718 0.423077C11.4954 0.423077 14.581 3.7739 14.581 8Z" stroke="white" stroke-width="0.846154" />
                        <ellipse cx="7.77716" cy="8.00001" rx="5.00323" ry="5.53846" fill="white" />
                    </svg>}
                    <span className='flex flex-col gap-1 sm:gap-0'>
                        <p className='text-white font-Oswald text-[22px] font-bold sm:text-base'>Hoops</p>
                        <p className='text-white font-Sansation text-base font-medium sm:text-[12px]'><span className='text-primary-100 font-bold'>+20%</span></p>
                    </span>
                </div>
                <div className={`rounded-xl sm:rounded-md px-5 cursor-pointer py-4 flex justify-start items-start gap-3 w-[200px] sm:gap-2 sm:px-3 sm:py-2 sm:w-[140px] ${queue === 5 ? 'bg-gradient-to-r from-[#0091F7] to-[#0674C1] border-[1px] border-primary-100/75' : 'bg-[#ffffff1e] border-[1px] border-white'} transition-all ease-in duration-200`} onClick={() => setQueue(5)}>
                    {queue === 5 && <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none" className='mt-2'>
                        <path d="M14.581 8C14.581 12.2261 11.4954 15.5769 7.77718 15.5769C4.05897 15.5769 0.97337 12.2261 0.97337 8C0.97337 3.7739 4.05897 0.423077 7.77718 0.423077C11.4954 0.423077 14.581 3.7739 14.581 8Z" stroke="white" stroke-width="0.846154" />
                        <ellipse cx="7.77716" cy="8.00001" rx="5.00323" ry="5.53846" fill="white" />
                    </svg>}
                    <span className='flex flex-col gap-1 sm:gap-0'>
                        <p className='text-white font-Oswald text-[22px] font-bold sm:text-base'>Snowday</p>
                        <p className='text-white font-Sansation text-base font-medium sm:text-[12px]'><span className='text-primary-100 font-bold'>+30%</span></p>
                    </span>
                </div>

            </div>
        </div>
    )
}

export default SelectQueue
