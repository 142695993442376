import React, { useEffect, useState } from 'react'
import Card from './Card'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { BACKEND_HOST } from '../constants'
import { toast } from 'react-toastify'
import Select from 'react-select'
import { selectStyles } from '../utils/selectStyles'
import Button2 from './Button2'
import ScrollableFeed from 'react-scrollable-feed'

function OrderDetail() {
    const { id } = useParams()
    const [order, setOrder] = useState(null)
    const [accordian, setaccordian] = useState(null)
    const [boosters, setBooster] = useState(null)
    const [selectedBooster, setSelectedBooster] = useState(null)
    const [loading, setLoading] = useState(false)

    const fethBooster = async () => {
        try {
            const response = await axios.get(`${BACKEND_HOST}/admin_get_all_users`, {
                headers: {
                    'Content-Type': 'Application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })

            if (response.status === 200 && response.data.status) {
                const x = response.data.data.filter(p => p.role === "employee")
                let options = []
                for (let i = 0; i < x.length; i++) {
                    options.push({
                        value: x[i].id,
                        label: x[i].username
                    })

                }
                setBooster(options);
            }
            else
                toast.error(response.data.error ? response.data.error : response.data.message)
        } catch (error) {
            console.log(error);
        }
    }

    const fetchOrderDetail = async () => {
        let data = JSON.stringify({
            "order_id": id
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${BACKEND_HOST}/admin_get_order_details`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            data: data
        };

        const response = await axios.request(config)
        if (response.status === 200 && response.data.status == true) {
            setOrder(response.data.data[0])
        }
        else
            toast.error(response.data.message)

    }

    const Assignbooster = async (e) => {
        e.preventDefault()
        if (!selectedBooster)
            return;
        setLoading(true)
        let data = JSON.stringify({
            "order_id": id,
            "booster_id": selectedBooster
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${BACKEND_HOST}/admin_assign_order`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            data: data
        };

        const response = await axios.request(config)
        if (response.status === 200 && response.data.status == true) {
            toast.success(response.data.message)
            setOrder(null)
            fetchOrderDetail()
            setaccordian(false)
        }
        else
            toast.error(response.data.message)
        setLoading(false)

    }

    useEffect(() => {
        fetchOrderDetail()
        fethBooster()
    }, [id])
    return (
        <div className='flex flex-col gap-10 sm:gap-7'>
            <Card>
                {!order && <img src={require('../assets/loading.gif')} alt='wait' className='w-8 h-8 sm:w-5 sm:h-5' />}
                {order && <>
                    <h1 className="text-white font-Oswald text-3xl sm:text-2xl">Order# {order?.id} <span className='text-primary-100'>{order?.status === 'Assigned' ? 'In-Progress' : order?.status}</span></h1>
                    <div className='flex justify-between items-stretch gap-12 sm:flex-col sm:gap-4'>
                        <div className='w-[50%] flex flex-col sm:w-full'>
                            <div className='flex justify-between items-center border-b-[1px] border-white/30 py-2'>
                                <p className='font-Oswald text-lg sm:text-base text-white'>Game:</p>
                                <p className='font-Sansation text-base sm:text-sm text-white'>{order?.game?.title}</p>
                            </div>
                            <div className='flex justify-between items-center border-b-[1px] border-white/30 py-2'>
                                <p className='font-Oswald text-lg sm:text-base text-white'>Service:</p>
                                <p className='font-Sansation text-base sm:text-sm text-white'>{order?.service?.service_title}</p>
                            </div>
                            {order?.rank_type === 'Conversion' &&
                                <>
                                    <div className='flex justify-between items-center border-b-[1px] border-white/30 py-2'>
                                        <p className='font-Oswald text-lg sm:text-base text-white'>Current:</p>
                                        <p className='font-Sansation text-base sm:text-sm text-white flex justify-end items-center'>
                                            <img src={`${BACKEND_HOST.replace('/api', '')}/${order?.ranks[0]?.rank?.rank_icon}`} alt='rank_icon' className='w-7 h-7 object-contain' />
                                            {order?.ranks[0]?.rank?.rank_name} {order?.ranks[0]?.level?.name} {order?.ranks[0]?.points != null && (`- ${order?.ranks[0]?.points} Points`)}
                                        </p>
                                    </div>
                                    <div className='flex justify-between items-center border-b-[1px] border-white/30 py-2'>
                                        <p className='font-Oswald text-lg sm:text-base text-white'>Desired:</p>
                                        <p className='font-Sansation text-base sm:text-sm text-white flex justify-end items-center'>
                                            <img src={`${BACKEND_HOST.replace('/api', '')}/${order?.ranks[1]?.rank?.rank_icon}`} alt='rank_icon' className='w-7 h-7 object-contain' />
                                            {order?.ranks[1]?.rank?.rank_name} {order?.ranks[1]?.level?.name} {order?.ranks[1]?.points != null && (`- ${order?.ranks[1]?.points} Points`)}
                                        </p>
                                    </div>
                                </>
                            }
                            {order?.rank_type === 'Placement' &&
                                <div className='flex justify-between items-center border-b-[1px] border-white/30 py-2'>
                                    <p className='font-Oswald text-lg sm:text-base text-white'>Placement:</p>
                                    <p className='font-Sansation text-base sm:text-sm text-white flex justify-end items-center'>
                                        <img src={`${BACKEND_HOST.replace('/api', '')}/${order?.ranks[0]?.rank?.rank_icon}`} alt='rank_icon' className='w-7 h-7' />
                                        {order?.ranks[0]?.rank?.rank_name} {order?.ranks[0]?.level?.name}
                                    </p>
                                </div>
                            }
                            {order?.number_of_games > 0 &&
                                <div className='flex justify-between items-center border-b-[1px] border-white/30 py-2'>
                                    <p className='font-Oswald text-lg sm:text-base text-white'>Number of Wins:</p>
                                    <p className='font-Sansation text-base sm:text-sm text-white'>{order?.number_of_games} Wins</p>
                                </div>
                            }
                            {order?.items && order?.items.length != 0 &&
                                <div className='flex justify-between items-start border-b-[1px] border-white/30 py-2'>
                                    <p className='font-Oswald text-lg sm:text-base text-white'>Dropdown items:</p>
                                    <p className='font-Sansation text-base sm:text-sm text-white text-right w-[50%]'>
                                        {order.items.map((i, index) => {
                                            return <span key={index}>{index !== 0 && ', '} {i.item?.name}</span>
                                        })}
                                    </p>
                                </div>
                            }
                            {order?.customizes && order?.customizes.length != 0 &&
                                <div className='flex justify-between items-start border-b-[1px] border-white/30 py-2'>
                                    <p className='font-Oswald text-lg sm:text-base text-white'>Customizes:</p>
                                    <p className='font-Sansation text-base sm:text-sm text-white text-right w-[50%]'>
                                        {order.customizes.map((i, index) => {
                                            return <span key={index}>{index !== 0 && ', '} {i.custom_name}</span>
                                        })}
                                    </p>
                                </div>
                            }
                            <div className='flex justify-between items-center border-b-[1px] border-white/30 py-2'>
                                <p className='font-Oswald text-lg sm:text-base text-white'>Order Date:</p>
                                <p className='font-Sansation text-base sm:text-sm text-white'>{order?.created_at?.substr(0, 16).replace('T', ' ')}</p>
                            </div>
                            <div className='flex justify-between items-center py-2'>
                                <p className='font-Oswald text-lg sm:text-base text-white'>Order Cost:</p>
                                <p className='font-Sansation text-lg text-primary-100 sm:text-base'>${order?.total_amount}</p>
                            </div>
                        </div>
                        <hr className='h-full w-[1px] bg-white/50 sm:w-full sm:h-[1px] sm:bg-white' />
                        <div className='w-[50%] flex flex-col justify-between sm:w-full'>
                            <div>
                                <div className='flex justify-between items-center border-b-[1px] border-white/30 py-2'>
                                    <p className='font-Oswald text-lg sm:text-base text-white'>Customer:</p>
                                    <p className='font-Sansation text-base sm:text-sm text-white capitalize'>{order?.user?.username}</p>
                                </div>
                                <div className='flex justify-between items-center border-b-[1px] border-white/30 py-2'>
                                    <p className='font-Oswald text-lg sm:text-base text-white'>Payment Method:</p>
                                    <p className='font-Sansation text-base sm:text-sm text-white'>{order?.payment_method}</p>
                                </div>
                                <div className='flex justify-between items-center py-2'>
                                    <p className='font-Oswald text-lg sm:text-base text-white'>Payment ID:</p>
                                    <p className='font-Sansation text-base sm:text-sm text-white'>{order?.payment_id}</p>
                                </div>
                            </div>
                            <div>

                                <div className={`relative ${!accordian && 'overflow-hidden'} `}>
                                    <h2 className='text-primary-100 text-sm font-Sansation text-right cursor-pointer' onClick={() => setaccordian(state => !state)}>Assign Booster</h2>
                                    <div className={` ${accordian ? 'h-[100px] glass' : 'h-0 overflow-hidden'} rounded-lg mt-2 transition-all ease-in duration-200 `}>
                                        <form onSubmit={Assignbooster} className='p-3 flex justify-between items-start gap-2'>
                                            <span className='w-full'>
                                                <Select styles={selectStyles} options={boosters} placeholder={'Search booster'} onChange={(opt) => setSelectedBooster(opt.value)} />
                                            </span>

                                            <Button2 title='Assign' disabled={loading} />
                                        </form>
                                    </div>
                                </div>

                                {order?.status != 'Pending' && <>
                                    <div className='flex justify-between items-center border-b-[1px] border-white/30 py-2'>
                                        <p className='font-Oswald text-lg sm:text-base text-white'>Booster:</p>
                                        <p className='font-Sansation text-base sm:text-sm text-white capitalize'>{order?.booster?.username}</p>
                                    </div>
                                    <div className='flex justify-between items-center border-b-[1px] border-white/30 py-2'>
                                        <p className='font-Oswald text-lg sm:text-base text-white'>Booster Percent:</p>
                                        <p className='font-Sansation text-base sm:text-sm text-white capitalize'>{order?.booster?.payment_percentage}%</p>
                                    </div>
                                    <div className='flex justify-between items-center py-2'>
                                        <p className='font-Oswald text-lg sm:text-base text-white'>Completion Progress:</p>
                                        <p className='font-Sansation text-base sm:text-sm text-white capitalize'>{order?.progress}%</p>
                                    </div>
                                </>}
                            </div>


                        </div>

                    </div>
                </>}
            </Card>

            {order && order?.status !== 'Pending' && <Chat order_id={order?.id} customer={order?.user} booster={order?.booster} />}
        </div>
    )
}

export default OrderDetail


const Chat = ({ order_id, customer, booster }) => {
    const [chats, setChats] = useState(null)

    const fetchChats = async () => {
        let data = JSON.stringify({
            "order_id": order_id
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${BACKEND_HOST}/admin_get_order_chat`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`

            },
            data: data
        };

        const response = await axios.request(config)
        if (response.status === 200 && response.data.status == true) {
            setChats(response.data.data)
        }
        else
            toast.error(response.data.message ? response.data.message : response.data.error);
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                await fetchChats();
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
        const intervalId = setInterval(fetchData, 2000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <Card>
            <h1 className="text-white font-Oswald text-3xl sm:text-2xl">Chat</h1>
            <div className='flex flex-col rounded-xl overflow-hidden h-[480px]'>
                <div className='flex justify-between items-center h-[70px] bg-white/40 sm:h-[50px]'>
                    <div className='flex justify-start items-center px-4 gap-2'>
                        <img src={customer?.profile_image ? `${BACKEND_HOST.replace('/api', '')}/${customer?.profile_image}` : require('../assets/defaultAvatar.jpg')} className='w-11 h-11 rounded-full object-cover sm:h-8 sm:w-8' />
                        <h2 className='text-lg text-white font-Oswald capitalize'>{customer?.username}</h2>
                    </div>
                    <div className='flex justify-start items-center px-4 gap-2'>
                        <img src={booster?.profile_image ? `${BACKEND_HOST.replace('/api', '')}/${booster?.profile_image}` : require('../assets/defaultAvatar.jpg')} className='w-11 h-11 rounded-full object-cover  sm:h-8 sm:w-8' />
                        <h2 className='text-lg text-white font-Oswald capitalize'>{booster?.username}</h2>
                    </div>
                </div>
                {!chats && <div className='w-full h-full flex justify-center items-center bg-white/10' >
                    <img src={require('../assets/loading.gif')} alt='wait' className='w-10 h-10' />
                </div>}
                {chats && 
                 <div className='h-[430px] bg-white/15'>
                 <ScrollableFeed>
                   <div className='flex flex-col justify-start items-start gap-4 pr-6 pl-4 py-5'>
                     {chats?.map((c, index) => {
                       return <div className={`flex flex-col justify-start gap-1 ${c.sender_id == customer?.id ? 'self-start items-start' : 'self-end items-end'}`}>
                         <div key={index} className={`border-[1px] border-white/50  rounded-b-2xl ${c.sender_id == customer?.id ? 'rounded-r-2xl' : 'rounded-l-2xl'}  min-w-[10px] max-w-[400px] px-3 py-[6px]`}>
                           <p className='text-base font-Sansation text-white'>{c.message}</p>
                         </div>
                         <p className='text-white/70 text-[10px]'>{c.created_at.substr(0, 16).replace('T', '')}</p>
                       </div>
                     })}
                   </div>
                 </ScrollableFeed>
               </div>}
            </div>
        </Card>
    )
}
