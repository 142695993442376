import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Accordian2 from '../components/Accordian2'
import Select from 'react-select'
import { selectStyles } from '../utils/selectStyles'
import { BACKEND_HOST } from '../constants'
import axios from 'axios'
import { toast } from 'react-toastify'
import Textarea from '../components/Textarea'
import Button from '../components/Button'
import Button2 from '../components/Button2'

function CustomeOrderRequest() {
    const [t] = useTranslation("global")
    return (
        <div className='pl-60 pr-20 py-36 h-screen sm:px-7'>
            <div className='flex flex-col px-7 py-7 sm:px-4  glass rounded-3xl gap-5 sm:gap-2'>
                <h1 className="text-white font-Oswald text-3xl sm:text-2xl text-left mb-0">{t('userDashboard.custome')}</h1>
                    <Request />
            </div>
        </div>
    )
}

export default CustomeOrderRequest


const Request = () => {
    const [request, setRequest] = useState({})
    const games = [
        {
            value: '1',
            label: 'League of Legend'
        },
        {
            value: '2',
            label: 'Valorant'
        },
        {
            value: '3',
            label: 'Rainbow Six'
        },
        {
            value: '4',
            label: 'Rocket League'
        },
        {
            value: '5',
            label: 'Overwatch 2'
        },
        {
            value: '6',
            label: 'Counter Strike 2'
        },
        {
            value: '7',
            label: 'COD Modern Warfare |||'
        },
        {
            value: '8',
            label: 'Apex Legends'
        },
        {
            value: '8',
            label: 'COD Warzone 2'
        },
    ]

    const [platform, setPlatform] = useState(null)
    const [services, setServices] = useState(null)
    const [loading, setLoading] = useState(null)
    const [refresh, setRefresh] = useState(false)

    const fetchPlatforms = async () => {
        try {

            const response = await axios.get(`${BACKEND_HOST}/admin_get_plateforms`, {
                headers: {
                    'Content-Type': 'Application/json',
                }
            })

            if (response.status === 200 && response.data.status) {
                let options = []
                for (let i = 0; i < response.data.data.length; i++) {
                    options.push({
                        value: response.data.data[i].id,
                        label: response.data.data[i].plateform_name,
                    })
                }
                setPlatform(options)
            }
            else
                toast.error(response.data.error ? response.data.error : response.data.message)
        } catch (error) {
            console.log(error);
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        if (!request.service_id || !request.game_id || !request.plateform_id)
            return toast.error("Please select all fields.")

        setLoading(true)

        const user = JSON.parse(localStorage.getItem("user"))

        let data = JSON.stringify({
            "game_id": request.game_id,
            "plateform_id": request.plateform_id,
            "user_id": user.id,
            "description": request.description
        });
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${BACKEND_HOST}/add_custom_order`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: data
        }

        const res = await axios.request(config)
        if (res.data.status == true) {
            toast.success(res.data.message)
        }
        else {
            toast.error(res.message)
        }

        setLoading(false)
    }



    useEffect(() => {
    }, [refresh])

    useEffect(() => {
        fetchPlatforms()
    }, [])
    return (
        
            <form onSubmit={onSubmit} className='flex flex-col justify-start items-start gap-2 mt-4 '>
                <div className='flex justify-between items-center gap-2 w-full'>
                    <div className='flex flex-col gap-1 w-[50%]'>
                        <h1 className='text-base text-white font-Sansation'>Game</h1>
                        <span className='w-full'>
                            <Select styles={selectStyles} options={games} placeholder={'Select'} isSearchable={false} onChange={(opt) => { request.game_id = opt.value;  request.service_id = null; setRefresh(state => !state); }} />
                        </span>
                    </div>
                    <div className='flex flex-col gap-1 w-[50%]'>
                        <h1 className='text-base text-white font-Sansation'>Platform</h1>
                        <span className='w-full'>
                            <Select styles={selectStyles} options={platform} placeholder={'Select'} isSearchable={false} onChange={(opt) => request.plateform_id = opt.value} />
                        </span>
                    </div>

                </div>
                <textarea onChange={(e) => request.description = e.target.value} className='bg-primary-300 rounded-lg w-full h-[220px] outline-none resize-none text-white px-5 py-5' placeholder='Describe your order'></textarea>
                <Button2 title={"Request"} disabled={loading} />
            </form>
    )
}
