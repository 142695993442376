import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import en from './languages/en/global.json'
import arb from './languages/arb/global.json'
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import Provider from './state/Provider';
import { BrowserRouter } from 'react-router-dom';

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'en', // Set the default language
  fallbackLng: 'en', // Set the fallback language
  resources: {
    en: {
      global: en,
    },
    arb: {
      global: arb,
    },
  },
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <I18nextProvider i18n={i18next}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </I18nextProvider>
);

