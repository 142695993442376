import React, { useEffect, useState } from 'react'
import Input2 from './Input2'
import Button2 from './Button2'
import { toast } from 'react-toastify'
import axios from 'axios'
import { BACKEND_HOST } from '../constants'

function RankPoints({ rank_id }) {
    const [points, setPoints] = useState(null)
    const [loading, setLoading] = useState(false)
    const [wait, setWait] = useState(true)

    const onChange = (e) => {
        setPoints({ ...points, [e.target.name]: e.target.value })
    }


    const update = async () => {
        console.log(points);
        let data = JSON.stringify({
            'rank_id': rank_id,
            "price": points.price,
            "max_point": points.max_point,
            "min_point": points.min_point,
            "point_id": points.id
        })

        console.log('data to be updated', data);
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${BACKEND_HOST}/admin_update_rank_points`,
            headers: {
                'Content-Type': 'Application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            data: data
        };
        try {
            const response = await axios.request(config)
            if (response.status === 200 && response.data.status == true) {
                toast.success(response.data.message)
                fetchPoints()
            }
            else
                toast.error(response.data.error ? response.data.error : response.data.message)
        } catch (error) {
            console.log(error);
        }
    }

    const add = async () => {
        let data = JSON.stringify({
            'rank_id': rank_id,
            "price": points.price,
            "max_point": points.max_points,
            "min_point": points.min_points,
        })

        console.log('data to be added', data);
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${BACKEND_HOST}/admin_add_rank_points`,
            headers: {
                'Content-Type': 'Application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            data: data
        };
        try {
            const response = await axios.request(config)
            if (response.status === 200 && response.data.status == true) {
                toast.success(response.data.message)
                fetchPoints()
            }
            else
                toast.error(response.data.error ? response.data.error : response.data.message)
        } catch (error) {
            console.log(error);
        }
    }

    const fetchPoints = async () => {
        let data = JSON.stringify({ rank_id })
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${BACKEND_HOST}/admin_get_rank_points`,
            headers: {
                'Content-Type': 'Application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            data: data
        };
        try {
            const response = await axios.request(config)
            setWait(false)
            setLoading(false)
            if (response.status === 200 && response.data.status == true) {
                if (response.data.data.length > 0) {
                    console.log(response.data.data[0]);
                    setPoints(response.data.data[0])
                }
            }
            else
                toast.error(response.data.error ? response.data.error : response.data.message)
        } catch (error) {
            console.log(error);
        }
    }

    const deletePoint = async () => {
        const confirm = window.confirm('Are you sure deleting rank points?')
        if(!confirm)
            return;
        setLoading(true)
        let data = JSON.stringify({ point_id: points.id })

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${BACKEND_HOST}/admin_delete_rank_points`,
            headers: {
                'Content-Type': 'Application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            data: data
        };
        try {
            const response = await axios.request(config)
            setWait(true)
            if (response.status === 200 && response.data.status == true) {
                setPoints(null)
                toast.success(response.data.error ? response.data.error : response.data.message)
                fetchPoints()
            }
            else
                toast.error(response.data.error ? response.data.error : response.data.message)
        } catch (error) {
            console.log(error);
        }
        setLoading(false)
    }

    const submit = async (e) => {
        e.preventDefault()
        setLoading(true)
        if (points?.id)
            await update()
        else
            await add()
    }

    useEffect(() => {
        fetchPoints()
    }, [])

    useEffect(() => {
        if(points?.id){
            document.getElementById('min_points').defaultValue = points.min_point
            document.getElementById('max_points').defaultValue = points.max_point
            document.getElementById('price').defaultValue = points.price
        }
    }, [points])
    return (
        <div className='relative'>
            {wait &&  <img src={require('../assets/loading.gif')} alt='loader' className='w-4 h-4 mt-3'/>}
           {!wait && <>
                <form onSubmit={submit} className='flex flex-col gap-3 mt-3'>
                    <div className='flex gap-1'>
                        <Input2 placeholder={"Min Points"} value={points?.min_point} name={'min_points'} required={true} onChange={onChange} type={"number"} />
                        <Input2 placeholder={"Max Points"} value={points?.max_point} name={'max_points'} required={true} onChange={onChange} type={"number"} />
                        <Input2 placeholder={"Point Price"} value={points?.price} name={'price'} required={true} onChange={onChange} type={"number"} />
                    </div>
                    <div className='w-[30%] flex'>
                        <Button2 title={points?.id ? 'Update' : 'Submit'} disabled={loading} />
                    </div>
                </form>
                {points?.id && <button onClick={deletePoint} disabled={loading} className='bg-red/50 absolute bottom-0 right-0 hover:bg-red/100 disabled:cursor-wait disabled:bg-red/50 transition-all ease-in duration-100 rounded-md text-sm font-Sansation px-5 py-2 text-white sm:px-3 sm:py-2 sm:text-[12px] sm:w-[30%]'>Delete Points</button>}
            </>}
        </div>
    )
}

export default RankPoints
