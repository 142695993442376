import React, { useState } from 'react'
import { MdOutlineKeyboardArrowDown } from 'react-icons/md'
import { PiGameControllerFill } from 'react-icons/pi'
import Customizations from './Customizations'
import AddRanks from './AddRanks'

import Accordian from './Accordian'
import Dropdowns from './Dropdowns'
import NumberOfGames from './NumberOfGames'
import ServiceFaqs from './ServiceFaqs'

function Addons() {
    return (
        <div className='flex flex-col mt-10 gap-4'>

            <Accordian title={'Ranks'} hight={'h-[220px] sm:h-[350px]'}>
                <AddRanks />    
            </Accordian>
            <Accordian title={'Dropdowns'} hight={'h-[300px] sm:h-[500px]'}>
                <Dropdowns />    
            </Accordian>
            <Accordian title={'Number of Games'} hight={'h-[100px] sm:h-[200px]'}>
                <NumberOfGames />    
            </Accordian>
            <Accordian title={'Customizations'} hight={'h-[310px] sm:h-[500px]'}>
                <Customizations />    
            </Accordian>
            <Accordian title={'FAQs'} hight={'h-[350px] sm:h-[500px]'}>
                <ServiceFaqs />    
            </Accordian>

        </div>
    )
}

export default Addons
