import React from 'react'

function Button({ title, onClick, disabled }) {
    return (
        <button className='bg-[#2CB1E7] rounded-[51px] overflow-hidden p-2 sm:p-[5px] relative w-full flex drop-shadow-md transition-all ease-out duration-400 hover:bg-[#2cb2e7d0] mt-1 disabled:opacity-75 disabled:cursor-wait' onClick={onClick} disabled={disabled}>
            <span className='border-[1px] border-white/[.3] w-full rounded-[51px] p-1 sm:p-[3px] z-[1]'>
                <p className='font-Sansation text-white drop-shadow-sm text-lg sm:text-[12px]'>{title}</p>
            </span>

            <svg xmlns="http://www.w3.org/2000/svg" width="144" height="72" viewBox="0 0 144 72" fill="none" className='absolute -top-1 z-[0]'>
                <g opacity="0.5" filter="url(#filter0_f_207_693)">
                    <ellipse cx="72.4104" cy="-1.03943" rx="31.9277" ry="41.577" transform="rotate(-39.1613 72.4104 -1.03943)" fill="white" />
                </g>
                <defs>
                    <filter id="filter0_f_207_693" x="0.983768" y="-74.4085" width="142.854" height="146.738" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feGaussianBlur stdDeviation="17.6702" result="effect1_foregroundBlur_207_693" />
                    </filter>
                </defs>
            </svg>

            <svg xmlns="http://www.w3.org/2000/svg" width="134" height="66" viewBox="0 0 134 66" fill="none" className='absolute -bottom-1 right-0 z-[0]'>
                <g opacity="0.7" filter="url(#filter0_f_207_694)">
                    <ellipse cx="57.015" cy="22.7853" rx="57.015" ry="22.7853" transform="matrix(0.985261 -0.171057 0.101848 0.9948 34.0352 47.6092)" fill="#FF0000" />
                </g>
                <defs>
                    <filter id="filter0_f_207_694" x="0.96619" y="0.500523" width="183.128" height="120.045" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feGaussianBlur stdDeviation="17.6702" result="effect1_foregroundBlur_207_694" />
                    </filter>
                </defs>
            </svg>
        </button>
    )
}

export default Button
