import React from 'react'
import { PiInstagramLogoFill } from "react-icons/pi";
import { Link } from 'react-router-dom';
import { FaFacebookF, FaTiktok } from "react-icons/fa";
import { useTranslation } from 'react-i18next';

function Footer() {
  const [t, i18n] = useTranslation("global")
  return (
    <div className='flex flex-col bg-primary-300'>
      <div className='flex justify-between items-start pb-[58px] pl-[150px] pr-[140px] pt-[80px] sm:px-7 sm:flex-wrap sm:gap-2'>
        <div className='flex flex-col gap-11 justify-start sm:items-center sm:justify-center items-start sm:w-full w-[40%]'>
          <img src={require('../assets/logo.png')} alt='logo' className='w-[150px] sm:w-[120px] drop-shadow-[4.744px_3.795px_13.282px_#123698]' />
          <p className='text-[12px] text-white/75 font-Sansation sm:text-center'>Fwzny isn't endorsed or in any way affiliated with Activision Blizzard, Riot Games, Electronic Arts, Amazon Games, or Respawn Entertainment or Valve or Epic Games or Ubisoft and doesn’t reflect the views or opinions of anyone officially involved in producing or managing Rocket League, Valorant, Overwatch, Call of Duty, Counter Strike, and League of Legends, Apex Legends, Rainbow Six Siege All trademarks and logos belong to their respective owners. All submitted art content remains copyright of its original copyright holder.</p>
          <span className='sm:flex justify-start items-center gap-3 hidden'>
            <a href='#' target='_blank' className='rounded-full bg-primary-100 p-2 shadow-sm shadow-primary-100 flex justify-center items-center'>
              <PiInstagramLogoFill className='text-white text-[26px] sm:text-[20px]' />
            </a>
            <a href='#' target='_blank' className='rounded-full bg-primary-100 p-2 shadow-sm shadow-primary-100 flex justify-center items-center'>
              <FaFacebookF className='text-white text-[26px] sm:text-[20px]' />
            </a>
            <a href='#' target='_blank' className='rounded-full bg-primary-100 p-2 shadow-sm shadow-primary-100 flex justify-center items-center'>
              <FaTiktok className='text-white text-[26px] sm:text-[20px]' />
            </a>
          </span>
        </div>

        <div className='flex gap-7 flex-col justify-start items-start sm:w-[33%] sm:gap-4 sm:mt-7'>
          <h3 className='text-white font-Oswald font-bold text-[32px]  sm:text-lg'>{t('footer.main')}</h3>
          <nav className='flex flex-col gap-3 sm:gap-1'>
            <Link to='' className='text-white font-Sansation text-base hover:text-primary-100 transition-all ease-in duration-200 sm:text-[12px]'>{t("footer.home")}</Link>
            <Link to='' className='text-white font-Sansation text-base hover:text-primary-100 transition-all ease-in duration-200 sm:text-[12px]'>{t("footer.memmbership")}</Link>
            <Link to='' className='text-white font-Sansation text-base hover:text-primary-100 transition-all ease-in duration-200 sm:text-[12px]'>{t("footer.contact")}</Link>
            <Link to='' className='text-white font-Sansation text-base hover:text-primary-100 transition-all ease-in duration-200 sm:text-[12px]'>{t("footer.blog")}</Link>
          </nav>
        </div>
        <div className='flex gap-7 flex-col justify-start items-start sm:w-[33%] sm:gap-4 sm:mt-7'>
          <h3 className='text-white font-Oswald font-bold text-[32px]  sm:text-lg'>{t("footer.games")}</h3>
          <nav className='flex flex-col gap-3 sm:gap-1'>
            <Link to='' className='text-white font-Sansation text-base hover:text-primary-100 transition-all ease-in duration-200 sm:text-[12px]'>{t("footer.league")}</Link>
            <Link to='' className='text-white font-Sansation text-base hover:text-primary-100 transition-all ease-in duration-200 sm:text-[12px]'>{t("footer.valorant")}</Link>
            <Link to='' className='text-white font-Sansation text-base hover:text-primary-100 transition-all ease-in duration-200 sm:text-[12px]'>{t("footer.rainbow")}</Link>
            <Link to='' className='text-white font-Sansation text-base hover:text-primary-100 transition-all ease-in duration-200 sm:text-[12px]'>{t("footer.Rocket")}</Link>
            <Link to='' className='text-white font-Sansation text-base hover:text-primary-100 transition-all ease-in duration-200 sm:text-[12px]'>{t("footer.Overwatch")}</Link>
            <Link to='' className='text-primary-100 font-Sansation text-base hover:text-primary-100 transition-all ease-in duration-200 sm:text-[12px]'>{t("footer.More")}</Link>
          </nav>
        </div>
        <div className='flex gap-7 flex-col justify-start items-start sm:w-[28%] sm:gap-4 sm:mt-7'>
          <h3 className='text-white font-Oswald font-bold text-[32px]  sm:text-lg'>{t("footer.contact")}</h3>
          <nav className='flex flex-col gap-3 sm:gap-1'>
            <Link to='' className='text-white font-Sansation text-base hover:text-primary-100 transition-all ease-in duration-200 sm:text-[12px]'>{t("footer.chat")}</Link>
            <Link to='' className='text-white font-Sansation text-base hover:text-primary-100 transition-all ease-in duration-200 sm:text-[12px]'>{t("footer.mail")}</Link>
          </nav>
          <span className='flex justify-start items-center gap-3 sm:hidden'>
            <a href='#' target='_blank' className='rounded-full bg-primary-100 p-2 shadow-sm shadow-primary-100 flex justify-center items-center'>
              <PiInstagramLogoFill className='text-white text-[26px] sm:text-[20px]' />
            </a>
            <a href='#' target='_blank' className='rounded-full bg-primary-100 p-2 shadow-sm shadow-primary-100 flex justify-center items-center'>
              <FaFacebookF className='text-white text-[26px] sm:text-[20px]' />
            </a>
            <a href='#' target='_blank' className='rounded-full bg-primary-100 p-2 shadow-sm shadow-primary-100 flex justify-center items-center'>
              <FaTiktok className='text-white text-[26px] sm:text-[20px]' />
            </a>
          </span>
        </div>

      </div>
      <svg xmlns="http://www.w3.org/2000/svg" width="1249" height="2" viewBox="0 0 1249 2" fill="none" className='sm:w-[100%]'>
        <path opacity="0.5" d="M0.249023 0.885254H1248.84" stroke="url(#paint0_linear_207_656)" stroke-width="0.990553" />
        <defs>
          <linearGradient id="paint0_linear_207_656" x1="1302.33" y1="1.37562" x2="46.3097" y2="1.36234" gradientUnits="userSpaceOnUse">
            <stop stop-color="white" stop-opacity="0" />
            <stop offset="0.51" stop-color="white" />
            <stop offset="1" stop-color="white" stop-opacity="0" />
          </linearGradient>
        </defs>
      </svg>
      <p className='text-center text-white font-Sansation text-base py-5 sm:text-[12px]'>© {t('footer.copyright')} {new Date().getFullYear()}, {t("footer.all_right_reserved")}</p>
    </div>
  )
}

export default Footer
