import React from 'react'

function AdminNavbar() {
  return (
    <div className='flex justify-end items-center'>
      <img src={require('../assets/avator.png')} alt='profile' className='w-10 h-10 rounded-full drop-shadow-[4.744px_3.795px_13.282px_#123698] border-[1px] border-white/50'/>
    </div>
  )
}

export default AdminNavbar
