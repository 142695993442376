import React, { useState } from 'react'
import LoginHeader from '../components/LoginHeader'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import Input from '../components/Input'
import Button from '../components/Button'
import { BACKEND_HOST } from '../constants'
import { toast } from 'react-toastify'
import { motion } from 'framer-motion'

function SignUp() {
  const [t, i18n] = useTranslation("global")
  const [signup, setSignup] = useState(null)
  const [loading, setLoading] = useState(false)

  const onChange = (e) => {
    setSignup({ ...signup, [e.target.name]: e.target.value })
  }

  const navigate = useNavigate()

  const onSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      const response = await fetch(`${BACKEND_HOST}/user_register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'Application/json'
        },
        body: JSON.stringify(signup)
      })

      const res = await response.json()
      console.log(res);
      if (response.ok && res.status) {
          navigate('/login')
      }
      else{
        toast.error(res.error? res.error : res.message)
      }

    } catch (error) {
      console.log(error);
    }
    setLoading(false)
  }

  return (
    <motion.div initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0,transition:{duration:1}}}  className='relative overflow-hidden h-[900px] sm:h-screen w-full sm:bg-[url("./assets/signupMobile.jpg")] sm:bg-cover sm:bg-right-top sm:-mt-[0px] '>
      <img src={require('../assets/signup.jpg')} alt='bg' className='absolute left-0  -top-0 h-[900px] sm:hidden' />
      <div className='gradient w-full h-[900px] sm:h-screen absolute top-0 left-0 flex sm:mt-[0px]'>
        <div className='w-[950px] h-[950px] bg-primary-200 blur-[70px] rounded-full absolute -left-64 -top-44 sm:hidden'></div>
        <div className='w-[624px] h-[624px] bg-primary-100 blur-[137px] opacity-30 rounded-full absolute -right-44 -top-48 sm:opacity-100 sm:-top-20 sm:-right-20 sm:w-[300px] sm:h-[300px]'></div>
        <div className='w-[624px] h-[624px] bg-primary-100 blur-[137px] opacity-20 rounded-full absolute -left-60 -bottom-20 sm:opacity-100 sm:-left-20  sm:w-[300px] sm:h-[300px]'></div>
        <LoginHeader />

        <div className='z-10 mx-[88px] mt-[250px] w-[40%] sm:mx-7 sm:w-full sm:mt-[200px]'>
          <h3 className='font-Sansation text-lg text-white sm:text-[12px]'>{t('signupPage.subheading')}</h3>
          <h1 className='font-Oswald font-bold text-[68px] leading-[78px] mt-[50px] sm:text-[35px] sm:mt-0 sm:leading-[45px] text-white'>{t('signupPage.heading')} <span className='text-[#50C0F5]'>{t('signupPage.headingBlue')}</span></h1>
          <h3 className='font-Sansation text-lg text-white mt-2 sm:text-[12px] sm:mt-0'>{t('signupPage.text')} <Link to='/login' className='text-[#50C0F5] underline'>{t('signupPage.login')}</Link></h3>

          <form className='flex flex-col gap-5 w-full mt-8' onSubmit={onSubmit}>
            <Input name='username' type='text' value={signup?.username} onChange={onChange} required={true} placeholder={t("username")} />
            <Input name='email' type='email' value={signup?.email} onChange={onChange} required={true} placeholder={t("email")} />
            <Input name='password' type='password' value={signup?.password}  onChange={onChange} required={true} placeholder={t("password")} />
            <Button title={t('signupPage.signupButton')} disabled={loading}/>
          </form>

        </div>

      </div>
      <div className='hidden sm:block bg-primary-100 rounded-full absolute z-[1] sm:w-[300px] sm:h-[300px] sm:opacity-30 sm:-right-28 sm:blur-[50px] sm:-top-28'></div>
    </motion.div>
  )
}

export default SignUp
