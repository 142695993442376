import React from 'react'

function Card(props) {
  return (
    <div className='bg-primary-300/70 rounded-[20px] w-full shadow-2xl px-8 py-7 flex flex-col gap-4 sm:px-4 sm:py-4 sm:rounded-2xl sm:gap-2'>
     {props.children}
    </div>
  )
}

export default Card
