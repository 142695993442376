import React, { useContext } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { AiFillHome } from "react-icons/ai";
import { FaBlog, FaQuestionCircle, FaUsers } from 'react-icons/fa';
import { TbMessage } from "react-icons/tb";
import { HiMiniEnvelope } from "react-icons/hi2";
import { Context } from '../state/Provider';

function SideBar() {
  const { userLoggedIn } = useContext(Context)
  return (
    <div className='fixed top-0 z-50 sm:hidden  h-screen'>
      <div className='flex justify-between items-start pl-2 py-6 flex-1 h-screen'>
        <div className='w-[100px] overflow-hidden h-full bg-[#25467B] gradient-border shadow-[0px_4px_24px_10px_#1F3D6E_inset] rounded-[40px] hover:w-[220px] transition-all ease-in duration-200'>
          <div className='bg-[#25467B] w-full h-full py-[48px] flex flex-col justify-start items-start'>
            <img src={require('../assets/logo.png')} className='w-[70px] ml-3 drop-shadow-[4.744px_3.795px_13.282px_#123698]' alt='logo' />
            <nav className='flex flex-col justify-start items-start ml-7 mt-[100px] gap-[36px]'>
              <NavLink to='/' className='font-Sansation text-[#4B71AD] flex gap-10 justify-center items-center text-[17px] sidenav leading-0 transition-all ease-in duration-200'><AiFillHome className='text-[32px]' /> Home</NavLink>
              <NavLink to='/join-us' className='font-Sansation text-[#4B71AD] flex gap-10 justify-center items-center text-[17px] sidenav leading-0  transition-all ease-in duration-200 whitespace-nowrap'><FaUsers className='text-[32px]' /> Work with us</NavLink>
              <NavLink to='/blogs' className='font-Sansation text-[#4B71AD] flex gap-10 justify-center items-center text-[17px] sidenav leading-0 transition-all ease-in duration-200'><FaBlog className='text-[32px]' />Blogs</NavLink>
              <NavLink to='/contact' className='font-Sansation text-[#4B71AD] flex gap-10 justify-center items-center text-[17px] sidenav leading-0 transition-all ease-in duration-200'><TbMessage className='text-[32px]' /> Contact Us</NavLink>
              {userLoggedIn && <NavLink to='/dashboard' className='font-Sansation text-[#4B71AD] flex gap-10 justify-center items-center text-[17px] leading-0 transition-all ease-in duration-200'><HiMiniEnvelope className='text-[32px]' />Dashboard</NavLink>}
            </nav>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SideBar
