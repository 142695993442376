import React, { useContext, useEffect, useState } from 'react'
import Input2 from './Input2'
import Accordian from './Accordian'
import Dropdownoptions from './Dropdownoptions'
import Accordian2 from './Accordian2'
import Select from 'react-select'
import { selectStylesAdmin } from '../utils/selectStyles'
import { AdminContext } from '../state/AdminProvider'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { BACKEND_HOST } from '../constants'
import { toast } from 'react-toastify'
import { IoIosCloseCircleOutline } from 'react-icons/io'

function Dropdowns() {
    const { setDropDownPopup } = useContext(AdminContext)
    const [dropdownData, setDroprownData] = useState()
    const [dropdowns, setDroprowns] = useState(null)
    const [loading, setLoading] = useState()
    const [edit, setEdit] = useState()

    const { id, service_id } = useParams()

    const removeFromEdit = () => {
        setEdit(false)
        document.getElementById('title').value = ''
        setDroprownData(null)

    }

    const onChange = (e) => {
        setDroprownData({ ...dropdownData, [e.target.name]: e.target.value })
        console.log(dropdownData);
    }

    const addNew = async () => {
        let data = JSON.stringify({
            "game_id": id,
            "service_id": service_id,
            "title": dropdownData.title,
            "width": dropdownData?.width
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${BACKEND_HOST}/admin_add_dropdowns`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            data: data
        };

        const response = await axios.request(config)
        if (response.status === 200 && response.data.status == true) {
            setDroprownData(null)
            setDroprowns(null)
            document.getElementById('title').value = ''
            fetchDropDowns()
            toast.success(response.data.message)
        }
        else
            toast.error(response.data.error ? response.data.error : response.data.message)
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        if (edit)
            await editDrop()
        else
            await addNew()
        setLoading(false)
    }


    const editDrop = async () => {
        let data = JSON.stringify({
            "dropdown_id": dropdownData.id,
            "game_id": id,
            "service_id": service_id,
            "title": dropdownData.title,
            "width": dropdownData.width
        });

        console.log(data);
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${BACKEND_HOST}/admin_update_dropdowns`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            data: data
        };

        const response = await axios.request(config)
        if (response.status === 200 && response.data.status == true) {
            setEdit(false)
            document.getElementById('title').value = ''
            setDroprownData(null)
            setDroprowns(null)
            fetchDropDowns()
            toast.success(response.data.message)
        }
        else
            toast.error(response.data.error ? response.data.error : response.data.message)
    }

    const deleteDropdown = async (_id) => {
        const confirm = window.confirm('Are you sure deleting this?')
        if (!confirm)
            return;

        setLoading(true)

        let data = JSON.stringify({
            "dropdown_id": _id,
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${BACKEND_HOST}/admin_delete_dropdowns`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            data: data
        };

        const response = await axios.request(config)
        if (response.status === 200 && response.data.status == true) {
            setDroprowns(null)
            fetchDropDowns()
            toast.success(response.data.message)
        }
        else
            toast.error(response.data.error ? response.data.error : response.data.message)
        setLoading(false)
    }

    const fetchDropDowns = async () => {
        let data = JSON.stringify({
            "game_id": id,
            "service_id": service_id,
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${BACKEND_HOST}/admin_get_dropdowns`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            data: data
        };

        const response = await axios.request(config)
        if (response.status === 200 && response.data.status == true) {
            setDroprowns(response.data.data)
        }
        else
            toast.error(response.data.error ? response.data.error : response.data.message)
    }

    useEffect(() => {
    }, [dropdownData, edit])

    useEffect(() => {
        fetchDropDowns()
    }, [])
    return (
        <div className='flex gap-5 justify-start items-start sm:flex-col sm:gap-2'>

            <div className='flex flex-col gap-2 w-[70%] mt-3 overflow-y-scroll h-[290px] sm:h-auto sm:w-full'>
                {!dropdowns && <img src={require('../assets/loading.gif')} alt='loader' className='w-7 h-7' />}
                {dropdowns?.length === 0 && <p className='text-white text-sm sm:text-[12px] font-Sansation'>No data found.</p>}
                {dropdowns?.map((d, index) => {

                    return <div className='flex justify-between items-center px-5 py-3 glass rounded-lg sm:px-3 sm:py-1'>
                        <p className='text-white font-Sansation text-base sm:text-sm flex justify-start gap-2'>
                            {d.title}
                            <span className='text-primary-100'>{d.width}</span>
                        </p>
                        <span className='flex justify-end items-center gap-2 sm:gap-1'>
                            <button onClick={() => setDropDownPopup(d)} disabled={loading} className='font-Sansation text-base bg-primary-200/75 hover:bg-primary-200/100 transition-all ease-in duration-100 text-white px-4 py-1 rounded-md disabled:cursor-wait sm:px-2 sm:text-[12px]'>Options</button>
                            <button onClick={() => { setEdit(true); setDroprownData(d) }} disabled={loading} className='font-Sansation text-base bg-primary-100/50 hover:bg-primary-100/75 transition-all ease-in duration-100 text-white px-4 py-1 rounded-md  disabled:cursor-wait sm:px-2 sm:text-[12px]'>Edit</button>
                            <button onClick={() => deleteDropdown(d.id)} disabled={loading} className='font-Sansation text-base bg-red/50 hover:bg-red/100 transition-all ease-in duration-100 text-white px-4 py-1 rounded-md disabled:cursor-wait sm:px-2 sm:text-[12px]'>Delete</button>
                        </span>
                    </div>
                })}
            </div>

            <div className='flex flex-col gap-1 w-[30%] bg-primary-300 px-4 pt-3 pb-5 rounded-2xl mt-4 sm:w-full'>
                <h1 className='text-white font-Sansation text-base sm:text-sm"'>{edit ? <span className='flex gap-2 items-center'>Edit Dropdown  <IoIosCloseCircleOutline onClick={removeFromEdit} className='text-lg cursor-pointer' /></span> : 'Add New Dropdown'}</h1>
                <form onSubmit={onSubmit} className='flex gap-2 flex-col'>
                    <div className='flex flex-col gap-1 my-1'>
                        <p className='text-white/75 font-Sansation text-base'>Dropdown Width</p>
                        <div className='flex gap-x-3 gap-y-2 justify-start items-center flex-wrap'>
                            <span className='flex gap-1'>
                                <input type='radio' name='width' value={'25%'} checked={dropdownData?.width === '25%'} required className='cursor-pointer' onChange={onChange} />
                                <p className='text-white/75 font-Sansation text-sm'>25%</p>
                            </span>
                            <span className='flex gap-1'>
                                <input type='radio' name='width' value={'33%'} checked={dropdownData?.width === '33%'} required className='cursor-pointer' onChange={onChange} />
                                <p className='text-white/75 font-Sansation text-sm'>33%</p>
                            </span>
                            <span className='flex gap-1'>
                                <input type='radio' name='width' value={'50%'} checked={dropdownData?.width === '50%'} className='cursor-pointer' onChange={onChange} />
                                <p className='text-white/75 font-Sansation text-sm'>50%</p>
                            </span>
                            <span className='flex gap-1'>
                                <input type='radio' name='width' value={'75%'} checked={dropdownData?.width === '75%'} className='cursor-pointer' onChange={onChange} />
                                <p className='text-white/75 font-Sansation text-sm'>75%</p>
                            </span>
                            <span className='flex gap-1'>
                                <input type='radio' name='width' value={'100%'} checked={dropdownData?.width === '100%'} className='cursor-pointer' onChange={onChange} />
                                <p className='text-white/75 font-Sansation text-sm'>100%</p>
                            </span>
                        </div>
                    </div>
                    <Input2 type="text" placeholder={"Dropdown Name"} value={dropdownData?.title} name={"title"} required={true} onChange={onChange} />
                    <button className='bg-[#026CB7] text-white text-sm rounded-lg py-2 disabled:cursor-wait disabled:opacity-75' disabled={loading}>{edit ? 'Update' : 'Submit'}</button>
                </form>
            </div>

        </div>
    )
}

export default Dropdowns
