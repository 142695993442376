import React, { useEffect, useState } from 'react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Context } from '../state/Provider'
import { BsFillPatchQuestionFill } from "react-icons/bs";

function CustomizeBoosting({ customizes }) {
    const [t] = useTranslation('global')
    const { selectedService, setSelectedCustomization } = useContext(Context)

    const [localCustom, setLocalCustom] = useState();

    const handleClick = (c) => {
        const check = localCustom.filter(x => x.id === c.id);
        let updated = [];
    
        if (check.length > 0) {
            updated = localCustom.filter(x => x.id !== c.id);
        } else {
            updated = [...localCustom, c];
        }
    
        setLocalCustom(updated);
    }

    useEffect(() => {
        setLocalCustom([])
    }, [selectedService])
    useEffect(()=>{
        setSelectedCustomization(localCustom)
    }, [localCustom])
    return (
        <div className='flex flex-col gap-3'>
            <h1 className='text-white font-Oswald text-[38px] font-bold sm:text-[26px]'>{t('orderPage.cutomizeboosting')}</h1>
            <div className='flex justify-start items-stretch gap-3 flex-wrap  sm:gap-x-2 sm:gap-y-3'>
            {customizes?.map((c, index) =>{
                let active = localCustom?.some(x => x.id === c.id)
                return  <div  onClick={() => handleClick(c)} className={`relative rounded-xl sm:rounded-md px-4 cursor-pointer py-4 flex justify-start items-start gap-3 w-[220px] sm:gap-2 sm:px-3 sm:py-2 sm:w-[140px]  ${active? 'bg-gradient-to-r from-[#0091F7] to-[#0674C1] border-[1px] border-primary-100/75' : 'bg-[#ffffff1e] border-[1px] border-white'} transition-all ease-in duration-200`}>
                            <span className='flex flex-col gap-1 sm:gap-0'>
                                <p className='text-white font-Oswald text-[20px] leading-[25px] font-bold sm:text-base '>{c.custom_name}</p>
                                <p className='text-white font-Sansation text-base font-medium sm:text-[12px]'>{c.percentage == 0 ? 'FREE': c.percentage+'%'}</p>
                            </span>
                            {c.description?.length > 0 && <BsFillPatchQuestionFill className='question text-white absolute right-3 top-5 cursor-pointer sm:top-3 sm:right-2 sm:text-sm'/>}
                            <p className='hidden z-20 px-2 py-1 bg-white/95 text-sm sm:text-[12px] min-w-[100px] max-w-[170px] rounded-md absolute top-10 right-4 sm:top-7 sm:right-2'>
                                    {c.description}
                            </p>
                        </div>
            })}
            </div>
        </div>
    )
}

export default CustomizeBoosting

