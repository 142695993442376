import React, { useEffect, useState } from 'react'
import Card from './Card'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { BACKEND_HOST } from '../constants'
import axios from 'axios'
import ImageUploader from './ImageUploader'
import Input2 from './Input2'
import Button2 from './Button2'
import Addons from './Addons'

function AddService() {
    const [service, setService] = useState(null)
    const [img, setImg] = useState()
    const [edit, setEdit] = useState(null)
    const [loading, setLoading] = useState(false)
    const [editPage, setEditPage] = useState(true)

    const navigate = useNavigate()

    const { id , service_id } = useParams()

    const OnChange = (e) => {
        setService({ ...service, [e.target.name]: e.target.value })
    }

    const addNewservice = async () => {
        try {
            let data = new FormData();
            data.append('title', service.service_title);
            data.append('feature1', service.feature1);
            data.append('feature2', service.feature2);
            data.append('feature3', service.feature3);
            data.append('game_id', id);
            data.append('image', img);

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${BACKEND_HOST}/admin_add_game_services`,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                data: data
            };

            const response = await axios.request(config);
            if (response.status === 200 && response.data.status) {
                toast.success(response.data.error ? response.data.error : response.data.message)
            } else {
                toast.error(response.data.error ? response.data.error : response.data.message);
            }

        } catch (error) {
            console.log(error);
        }
    }

    const updateservice = async () => {
        try {
            let data = new FormData();
            data.append('title', service.service_title);
            data.append('feature1', service.feature1);
            data.append('feature2', service.feature2);
            data.append('feature3', service.feature3);
            data.append('game_id', id);
            data.append('image', img);
            data.append('game_service_id', service_id);

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${BACKEND_HOST}/admin_update_game_services`,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                data: data
            };


            const response = await axios.request(config);
            if (response.status === 200 && response.data.status) {
                toast.success(response.data.error ? response.data.error : response.data.message)
            } else {
                toast.error(response.data.error ? response.data.error : response.data.message);
            }
        } catch (error) {
            console.log(error);
        }
    }



    const onSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)

        if (window.location.pathname.includes('edit-service'))
            await updateservice()
        else
            await addNewservice()

        navigate(-1)

        setLoading(false)
    }

    const fetchService = async () => {
        try {
            const response = await axios.get(`${BACKEND_HOST}/admin_get_game_services`, {
                headers: {
                    'Content-Type': 'Application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })

            if (response.status === 200 && response.data.status) {
                const filter = response.data.data.filter((s) => s.id == service_id)[0]
                setService(filter)
                setEditPage(false)
            }
            else
                toast.error(response.data.error ? response.data.error : response.data.message)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (window.location.pathname.includes('edit-service')) {
            fetchService()
        }
        else
            setEditPage(false)
    }, [id])


    return (
        <Card>
            <h1 className="text-white font-Oswald text-3xl sm:text-2xl">{window.location.pathname.includes('edit-service') ? 'Edit Service' : 'Add New Service'}</h1>
            {editPage && !service && <img src={require('../assets/loading.gif')} alt='loader' className='w-7 h-7 sm:h-5 sm:w-5' />}
            {!editPage && <form onSubmit={onSubmit} className='flex flex-col gap-2 justify-start items-start'>
                <div className='flex justify-between items-start gap-4 w-full sm:flex-col-reverse'>
                    <div className='w-[65%] flex flex-col gap-4 sm:w-[100%]'>
                        <Input2 placeholder={"Title"} name={"service_title"} value={service?.service_title} required={true} onChange={OnChange} />
                        <Input2 placeholder={"Feature 1"} name={"feature1"} value={service?.feature1} required={true} onChange={OnChange} />
                        <Input2 placeholder={"Feature 2"} name={"feature2"} value={service?.feature2} required={true} onChange={OnChange} />
                        <Input2 placeholder={"Feature 3"} name={"feature3"} value={service?.feature3} required={true} onChange={OnChange} />
                    </div>
                    <div className='w-[35%] h-[235px] sm:w-[100%] sm:h-[150px]'>
                        <ImageUploader id={"service_img"} setImg={setImg} img={service?.service_image ? `${BACKEND_HOST.replace('/api', '')}/${service?.service_image}` : null} required={service?.service_image ? false : true} />
                    </div>
                </div>
                <Button2 title={"Submit"} disabled={loading} />
            </form>}

           {service_id && service && <Addons />}
        </Card>
    )
}

export default AddService
