import React, { useContext, useEffect, useState } from 'react'
import { BACKEND_HOST } from '../constants'
import { toast } from 'react-toastify'
import axios from 'axios'
import Card from '../components/Card'
import { AdminContext } from '../state/AdminProvider'

function Users() {
    const { refresh } = useContext(AdminContext)
    const [users, setUsers] = useState(null)
    const [allUsers, setAllUsers] = useState(null)
    const [loading, setLoading] = useState(false) 

    const { setBoosterPopup } = useContext(AdminContext)

    const searchUser = (e) => {
        const filtered = allUsers?.filter((u) => u.email.startsWith(e.target.value))
        setUsers(filtered)
    }

    const fetchUsers = async() => {
        try {
            const response = await axios.get(`${BACKEND_HOST}/admin_get_all_users`, {
                headers: {
                    'Content-Type': 'Application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })
    
            if(response.status === 200 && response.data.status){
                console.log(response.data.data);
                setAllUsers(response.data.data);
                setUsers(response.data.data);
            }
            else
                toast.error(response.data.error? response.data.error : response.data.message)
        } catch (error) {
            console.log(error);
        }
    }



    useEffect(()=>{
        setAllUsers(null)
        setUsers(null)
        fetchUsers()
    }, [refresh])
  return (
    <Card>
        <span className='flex justify-between items-center'>
            <h1 className="text-white font-Oswald text-3xl sm:text-2xl">Users</h1>
            <input type='text' className='bg-transparent sm:w-[50%] sm:text-[12px] sm:px-3 px-5 py-2 border-[1px] w-[20%] border-white/55 rounded-lg text-white outline-none text-sm font-Sansation' onChange={searchUser} placeholder={"Search user by email"}/>
        </span>
        <div className="overflow-x-auto">
            {!users && <img src={require('../assets/loading.gif')} alt='loader' className='w-7 h-7'/>}
            {users?.length === 0 && <p className='text-white text-sm sm:text-[12px] font-Sansation'>No data found.</p>}
            {users?.length > 0 && <table className="min-w-full sm:min-w-[700px]">
                <thead>
                    <tr>
                        <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-1 sm:py-1 text-grey">#</th>
                        <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey">Username</th>
                        <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey">Type</th>
                        <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey whitespace-nowrap">Email</th>
                        <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey whitespace-nowrap">Added On</th>
                        <th className="py-2 px-4 text-left font-Sansation text-base sm:text-sm sm:px-2 sm:py-1 text-grey">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {users?.map((b, index) => {
                        if(b.role !== 'admin')
                        return <tr className="bg-primary-300 border-b-[4px] border-primary-100/10" key={index}>
                                    <td className="py-2 px-4 text-left font-Sansation text-white text-sm border-b-white sm:px-1 sm:py-1 sm:text-[10px] sm:leading-[20px]">{index+1}</td>
                                    <td className="py-2 px-4 text-left font-Sansation text-white text-sm flex justify-start items-center gap-2  sm:px-2 sm:py-1 sm:text-[10px] sm:leading-[20px]">
                                        <img src={b.profile_image? `${BACKEND_HOST.replace('/api', '')}/${b?.profile_image}` : require('../assets/defaultAvatar.jpg')} alt="avatar" className="w-7 h-7 rounded-md border-[1px] border-white/50 sm:w-5 sm:h-5"/>
                                        {b.username}
                                    </td>
                                    <td className="py-2 px-4 text-left font-Sansation text-white text-sm sm:px-2 sm:py-1 sm:text-[10px] sm:leading-[20px]">{b.role === 'employee'? 'Booster': 'Customer'}</td>
                                    <td className="py-2 px-4 text-left font-Sansation text-white text-sm sm:px-2 sm:py-1 sm:text-[10px] sm:leading-[20px]">{b.email}</td>
                                    <td className="py-2 px-4 text-left font-Sansation text-white text-sm sm:px-2 sm:py-1 sm:text-[10px] sm:leading-[20px]">{b.created_at.substr(0,10)}</td>
                                    <td className="py-0 px-4 text-left font-Sansation text-white text-sm sm:px-2 sm:py-1 sm:text-[10px] sm:leading-[20px]">
                                        <span className='flex justify-start items-start gap-3'>
                                            <button className="text-primary-100" onClick={()=>setBoosterPopup(b) }>{b.role == 'user'? 'Upgrade to Booster' : 'Downgrade to Customer'}</button>
                                        </span>
                                    </td>
                                </tr>
                    })}
                    
                </tbody>
            </table>}
        </div>
    </Card>
  )
}

export default Users
