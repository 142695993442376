import React, { useContext, useEffect, useState } from 'react'
import { IoIosCloseCircleOutline } from 'react-icons/io'
import { AdminContext } from '../state/AdminProvider'
import Accordian2 from './Accordian2'
import ImageUploader from './ImageUploader'
import Input2 from './Input2'
import { BACKEND_HOST } from '../constants'
import { toast } from 'react-toastify'
import axios from 'axios'
import RankLevels from './RankLevels'
import RankPoints from './RankPoints'
import LevelPopup from './LevelPopup'

function AddRankPopup() {
    const { rankPopup, setRankPopup, rankMetadata, setRankMetadata, setRefresh } = useContext(AdminContext)
    const [img, setImg] = useState(null)
    const [rank, setRank] = useState(null)
    const [loading, setLoading] = useState(null)

    

    const onChange = (e) => {
        setRank({ ...rank, [e.target.name]: e.target.value })
    }

    const addNew = async() => {
        let data = new FormData();
        data.append('rank_type_id', rankMetadata.rank_type_id);
        data.append('rank_name', rank.rank_name);
        data.append('rank_number', rank.rank_number);
        data.append('service_id', rankMetadata.service_id);
        data.append('rank_icon', img);

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${BACKEND_HOST}/admin_add_ranks`,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            data: data
        };
        try {
            const response = await axios.request(config)
            if (response.status === 200 && response.data.status == true) {
              toast.success(response.data.message)
              setRefresh(state => !state)
            }
            else
              toast.error(response.data.error ? response.data.error : response.data.message)
          } catch (error) {
            console.log(error);
          }
    }

    const updateRank = async() => {
        let data = new FormData();
        data.append('rank_type_id', rankMetadata.rank_type_id);
        data.append('rank_id', rank.id);
        data.append('rank_name', rank.rank_name);
        data.append('rank_number', rank.rank_number);
        data.append('service_id', rankMetadata.service_id);
        if(img)
            data.append('rank_icon', img);

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${BACKEND_HOST}/admin_update_ranks`,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            data: data
        };
        try {
            const response = await axios.request(config)
            if (response.status === 200 && response.data.status == true) {
              toast.success(response.data.message)
              setRefresh(state => !state)
            }
            else
              toast.error(response.data.error ? response.data.error : response.data.message)
          } catch (error) {
            console.log(error);
          }
    }

    const deleteRank = async() => {
        const confirm = window.confirm('Are you sure deleting this?')
        if(!confirm)
            return;
        let data = JSON.stringify({
            'rank_id': rank.id
        })

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${BACKEND_HOST}/admin_delete_ranks`,
            headers: {
                'Content-Type': 'Application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            data: data
        };
        try {
            const response = await axios.request(config)
            if (response.status === 200 && response.data.status == true) {
              toast.success(response.data.message)
              setRankPopup(false)
              setRefresh(state => !state)
            }
            else
              toast.error(response.data.error ? response.data.error : response.data.message)
          } catch (error) {
            console.log(error);
          }
    }


    const onSubmit = async(e) => {
        e.preventDefault()
        setLoading(true)
        if(rankPopup?.id)
            await updateRank()
        else
            await addNew()
        setLoading(false)
    }

    useEffect(()=> {
        if(rankPopup?.id)
            setRank(rankPopup)
        console.log(rankPopup);
    }, [rankPopup])
    return (
        <div className={`fixed top-0 sm:w-full right-0 w-[80%] h-screen bg-[rgba(0,0,0,0.72)] z-50 flex justify-center items-center popupopens`}>
            <div className={`admin sm:px-4 sm:w-[90%] rounded-xl px-7 py-5 w-[55%] ${rankPopup?.id ? 'h-[650px] sm:h-[650px]': 'h-[270px]'} relative flex gap-2 flex-col transition-all ease-in duration-100`}>
                <IoIosCloseCircleOutline onClick={() => {setRankMetadata(null); setRankPopup(false)}} className='bg-red/50 text-white p-[2px] rounded-full text-[30px] absolute -top-2 right-2 cursor-pointer' />

                <div className='w-full sm:w-full flex flex-col gap-3'>
                    <h1 className="text-white font-Oswald text-2xl sm:text-2xl ">Add New</h1>
                    <form onSubmit={onSubmit} className='flex flex-col gap-2 w-full bg-primary-200/75 border-[1px] border-white/20 px-4 py-4 rounded-xl'>
                        <div className='flex justify-between items-start gap-2'>
                            <div className='w-[75%] sm:w-[65%] flex flex-col gap-3 sm:gap-2'>
                                <Input2 type='text' value={rankPopup?.rank_name} required={true} placeholder={"Rank name"} name='rank_name' onChange={onChange}/>
                                <Input2 type='number' value={rankPopup?.rank_number} required={true} placeholder={"Rank number"} name="rank_number" onChange={onChange}/>
                            </div>
                            <div className='w-[25%] sm:w-[35%] flex flex-col gap-3 h-[103px] sm:h-[95px]'>
                                <ImageUploader id={'rank_img'} setImg={setImg} img={rankPopup?.rank_icon ? `${BACKEND_HOST.replace('/api', '')}/${rankPopup?.rank_icon}` : null} required={rankPopup?.rank_icon ? false : true} />
                            </div>
                        </div>
                        <div className='flex justify-between items-center'>
                            <button disabled={loading} type='submit' className='bg-primary-100/75 text-white text-sm rounded-lg w-[20%] py-[9px] disabled:opacity-65 disabled:cursor-wait hover:bg-primary-100 transition-all ease-in duration-100 sm:w-[30%]'>{rankPopup?.id? 'Update': 'Add'}</button>
                        </div>
                    </form>
                </div>


               {rankPopup?.id && <div className='flex gap-2 flex-col justify-start items-start w-full h-[350px]'>
                                    <Accordian2 title={"Levels"} hight={'h-[210px] sm:h-[190px]'}>
                                                <RankLevels rank_id={rankPopup.id}/>
                                    </Accordian2>
                                    <Accordian2 title={"Points"} hight={'h-[100px]'}>
                                            <RankPoints rank_id={rankPopup.id}/>
                                    </Accordian2>
                                </div>}

                {rankPopup?.id && <button  onClick={deleteRank} disabled={loading} className='bg-red/50 text-white text-sm rounded-lg w-[20%] py-[9px] disabled:opacity-65 disabled:cursor-wait hover:bg-red/100 transition-all ease-in duration-100 self-end sm:px-3 sm:py-2 sm:text-[12px] sm:w-[30%]'>Delete Rank</button>}
            
            </div>

            
        </div>
    )
}

export default AddRankPopup
