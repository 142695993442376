import React from 'react'
import { AiFillHome } from 'react-icons/ai'
import { BsFillChatSquareTextFill } from 'react-icons/bs'
import { MdPermContactCalendar } from 'react-icons/md'
import { PiGameControllerFill } from 'react-icons/pi'
import { NavLink } from 'react-router-dom'
import { ImStatsBars } from "react-icons/im";
import { IoDisc } from 'react-icons/io5'
import { FaBlog, FaUsers, FaWallet } from 'react-icons/fa'
import { GiVibratingBall } from 'react-icons/gi'
import { FiPackage } from "react-icons/fi";

function AdminSidebar() {
    return (
        <div className='w-full h-full py-[48px] flex flex-col justify-start items-start px-7'>
            <img src={require('../assets/logo.png')} className='w-[76px] drop-shadow-[4.744px_3.795px_13.282px_#123698]' alt='logo' />
            <nav className='flex admin-nav flex-col justify-start items-start mt-[50px] gap-3'>
                <NavLink to='/admin/dashboard' className='font-Sansation text-[#afb5c0a1] flex gap-4 justify-center items-center text-[17px] sidenav leading-0 transition-all ease-in duration-200'>
                    <AiFillHome className='text-[32px]' />
                    Dashboard
                </NavLink>
                <NavLink to='/admin/affliate-marketing' className='font-Sansation text-[#afb5c0a1] flex gap-4 justify-center items-center text-[17px] sidenav leading-0 transition-all ease-in duration-200'>
                    <ImStatsBars className='text-[30px]'/>
                    Affliate Marketing
                </NavLink>
                <NavLink to='/admin/discount-codes' className='font-Sansation text-[#afb5c0a1] flex gap-4 justify-center items-center text-[17px] sidenav leading-0 transition-all ease-in duration-200'>
                    <IoDisc className='text-[30px]'/>
                    Discount Codes
                </NavLink>
                <NavLink to='/admin/games-management' className='font-Sansation text-[#afb5c0a1] flex gap-4 justify-center items-center text-[17px] sidenav leading-0 transition-all ease-in duration-200'>
                    <PiGameControllerFill className='text-[32px]' />
                    Games Management
                </NavLink>
                <NavLink to='/admin/users' className='font-Sansation text-[#afb5c0a1] flex gap-4 justify-center items-center text-[17px] sidenav leading-0 transition-all ease-in duration-200'>
                    <FaUsers className='text-[30px]'/>
                    Manage Users
                </NavLink>
                <NavLink to='/admin/faqs' className='font-Sansation text-[#afb5c0a1] flex gap-4 justify-center items-center text-[17px] sidenav leading-0 transition-all ease-in duration-200'>
                <GiVibratingBall className='text-[30px]'/>
                    Faqs
                </NavLink>
                <NavLink to='/admin/blogs' className='font-Sansation text-[#afb5c0a1] flex gap-4 justify-center items-center text-[17px] sidenav leading-0 transition-all ease-in duration-200'>
                    <FaBlog className='text-[30px]' />
                    Blogs
                </NavLink>
                <NavLink to='/admin/orders' className='font-Sansation text-[#afb5c0a1] flex gap-4 justify-center items-center text-[17px] sidenav leading-0 transition-all ease-in duration-200'>
                    <FiPackage className='text-[30px]' />
                    Orders
                </NavLink>
                <NavLink to='/admin/payments' className='font-Sansation text-[#afb5c0a1] flex gap-4 justify-center items-center text-[17px] sidenav leading-0 transition-all ease-in duration-200'>
                    <FaWallet className='text-[28px]' />
                    Payments
                </NavLink>
                <NavLink to='/admin/contact' className='font-Sansation text-[#afb5c0a1] flex gap-4 justify-center items-center text-[17px] sidenav leading-0 transition-all ease-in duration-200'>
                    <MdPermContactCalendar className='text-[32px]' />
                    Contact
                </NavLink>
            </nav>
        </div>
    )
}

export default AdminSidebar
