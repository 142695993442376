import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button2 from './Button2'
import { MdOutlineCloudUpload } from "react-icons/md";
import { toast } from 'react-toastify';
import { BACKEND_HOST } from '../constants';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function CompletePopup({ setcompletePopup, order_id }) {
    const [t] = useTranslation('global')
    const [image1, setImage1] = useState(null)
    const [image2, setImage2] = useState(null)
    const [screenShots, setScreenShots] = useState([])
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const complete = async (e) => {
        e.preventDefault()
        setLoading(true)

        const fd = new FormData()
        fd.append('order_id', order_id)
        fd.append('screenshots', screenShots)

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${BACKEND_HOST}/complete_order`,
            headers: {
            },
            data: fd
        };

        const res = await axios.request(config)
        if (res.data.status == true) {
            setcompletePopup(false)
            toast.success('Order Completed.')
            navigate(-1)
        }
        else
            toast.error(res.data?.message)

        setLoading(false)
    }

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result)
            }
        })
    }

    const handleImage = async (e, id) => {
        e.stopPropagation();

        if (e.target.files[0]?.size >= 1000000) {
            toast.error('Image size should be less than 1MB')
            return;
        }
        const base64 = await convertBase64(e.target.files[0])
        if (id == 1) {
            setImage1(base64)
            screenShots[0] = e.target.files[0]
        }
        else{
            setImage2(base64)
            screenShots[1] = e.target.files[0]
        }

    }

    useEffect(() => {
    }, [])

    return (
        <div className='fixed top-0 left-0 z-50 w-full h-screen flex justify-center items-center'>
            <div onClick={() => setcompletePopup(false)} className='fixed top-0 left-0 w-full h-screen bg-primary-300/70'></div>
            <form onSubmit={complete} className='bg-primary-300 rounded-2xl w-[40%] sm:w-[80%] shadow-2xl z-50 px-6 py-5 flex flex-col gap-3'>
                <h1 className='font-Oswald text-2xl text-white'>{t('userDashboard.upload')}</h1>
                <div className='flex  gap-2'>
                    <div className='w-[50%] flex justify-center flex-col items-center h-[170px] sm:h-[130px] rounded-xl border-[1px] border-white/50 border-dashed bg-primary-100/10' onClick={() => document.getElementById('file1').click()}>
                        {image1 ?
                            <div className='flex justify-center gap-2 items-center cursor-pointer'>
                                <img src={image1} className='object-contain h-[130px] sm:h-[90px]' />
                                <input type='file' id='file1' className='absolute opacity-0' accept='image/*' onClick={(e) => e.stopPropagation()} onChange={(e) => handleImage(e, 1)} />
                            </div>
                            :
                            <div className='flex flex-col justify-center items-center -mt-4'>
                                <input type='file' id='file1' required className='opacity-0' accept='image/*' onClick={(e) => e.stopPropagation()} onChange={(e) => handleImage(e, 1)} />
                                <MdOutlineCloudUpload className='text-white/70 text-[50px] cursor-pointer' />
                            </div>}
                    </div>
                    <div className='w-[50%] flex justify-center flex-col items-center h-[170px] sm:h-[130px] rounded-xl border-[1px] border-white/50 border-dashed bg-primary-100/10' onClick={() => document.getElementById('file2').click()}>
                        {image2 ?
                            <div className='flex justify-center gap-2 items-center'>
                                <img src={image2} className='object-contain h-[130px] sm:h-[90px]'/>
                                <input type='file' id='file2' className='absolute opacity-0' accept='image/*' onClick={(e) => e.stopPropagation()} onChange={(e) => handleImage(e, 2)} />
                            </div>
                            :
                            <div className='flex flex-col justify-center items-center -mt-4'>
                                <input type='file' id='file2' required className='opacity-0' accept='image/*' onClick={(e) => e.stopPropagation()} onChange={(e) => handleImage(e, 2)} />
                                <MdOutlineCloudUpload className='text-white/70 text-[50px] cursor-pointer' />
                            </div>}
                    </div>
                </div>
                <Button2 title={t('userDashboard.complete')} disabled={loading} />
            </form>
        </div>
    )
}

export default CompletePopup
