import React, { useEffect, useState } from 'react'
import Input2 from './Input2'
import Button2 from './Button2'
import { BACKEND_HOST } from '../constants'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'

function NumberOfGames() {
    const [range, setRange] = useState(null)
    const [loading, setLoading] = useState(false)

    const { service_id } = useParams()

    const onChange = (e) => {
        setRange({...range, [e.target.name]: e.target.value})
    }

    const deleteRange = async() => {
        const confirm = window.confirm('Are you sure deleting number of games?')
        if(!confirm)
            return;
        setLoading(true)
        let data = JSON.stringify({ range_id: range?.id})

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${BACKEND_HOST}/admin_delete_ranges`,
            headers: {
                'Content-Type': 'Application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            data: data
        };
        try {
            const response = await axios.request(config)
            if (response.status === 200 && response.data.status == true) {
                setRange(null)
                document.getElementById('min').value =''
                document.getElementById('max').value =''
                document.getElementById('price').value =''
                toast.success(response.data.error ? response.data.error : response.data.message)
                fetchRange()
            }
            else
                toast.error(response.data.error ? response.data.error : response.data.message)
        } catch (error) {
            console.log(error);
        }
        setLoading(false)
    }

    
    const update = async () => {
        let data = JSON.stringify({
            range_id: range.id,
            "min": range.min,
            "max": range.max,
            "price": range.price,
            "service_id": service_id
        })

        console.log('data to be updated', data);
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${BACKEND_HOST}/admin_update_ranges`,
            headers: {
                'Content-Type': 'Application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            data: data
        };
        try {
            const response = await axios.request(config)
            if (response.status === 200 && response.data.status == true) {
                toast.success(response.data.message)
                fetchRange()
            }
            else
                toast.error(response.data.error ? response.data.error : response.data.message)
        } catch (error) {
            console.log(error);
        }
    }

    const add = async () => {
        let data = JSON.stringify({
            "min": range.min,
            "max": range.max,
            "price": range.price,
            "service_id": service_id
        })

        console.log('data to be added', data);
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${BACKEND_HOST}/admin_add_ranges`,
            headers: {
                'Content-Type': 'Application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            data: data
        };
        try {
            const response = await axios.request(config)
            if (response.status === 200 && response.data.status == true) {
                toast.success(response.data.message)
                fetchRange()
            }
            else
                toast.error(response.data.error ? response.data.error : response.data.message)
        } catch (error) {
            console.log(error);
        }
    }


    const submit = async (e) => {
        e.preventDefault()
        setLoading(true)
        if (range?.id)
            await update()
        else
            await add()
    }


    const fetchRange = async() => {
        let data = JSON.stringify({ service_id })
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${BACKEND_HOST}/admin_get_ranges`,
            headers: {
                'Content-Type': 'Application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            data: data
        };
        try {
            const response = await axios.request(config)
            setLoading(false)
            if (response.status === 200 && response.data.status == true) {
                if (response.data.data.length > 0) {
                    console.log(response.data.data[0]);
                    setRange(response.data.data[0])
                }
            }
            else
                toast.error(response.data.error ? response.data.error : response.data.message)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(()=>{
        fetchRange()
    }, [])
  return (
    <div className='flex mt-4 relative'>
        <form onSubmit={submit} className='flex flex-col gap-3 w-full'>
            <div className='flex gap-2 sm:flex-col'>
                <Input2 type={"number"} placeholder={"Min Value"} name={'min'} required={"true"} value={range?.min} onChange={onChange} />
                <Input2 type={"number"} placeholder={"Max Value"} name={'max'} required={"true"} value={range?.max} onChange={onChange} />
                <Input2 type={"number"} placeholder={"Price for each"} name={'price'} required={"true"} value={range?.price} onChange={onChange} />
            </div>
            <div className='w-[20%]'>
                <Button2 title={range?.id? 'Update': 'Submit'} disabled={loading} />
            </div>
        </form>
        {range?.id && <button onClick={deleteRange} disabled={loading} className='bg-red/50 absolute bottom-0 right-0 hover:bg-red/100 disabled:cursor-wait disabled:bg-red/50 transition-all ease-in duration-100 rounded-md text-sm font-Sansation px-5 py-2 sm:px-3 sm:py-1 sm:text-[12px] text-white'>Delete</button>}
    </div>
  )
}

export default NumberOfGames
