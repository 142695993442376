import React, { useState } from 'react'
import { MdOutlineKeyboardArrowDown } from 'react-icons/md'

function Accordian2(props) {
    const [accordian, setAccordian] = useState(false)
    return (
        <div className='relative overflow-hidden border-[1px] border-white/40 px-3 py-4  sm:py-2 rounded-xl glass w-full'>
            <div className='flex justify-between items-center cursor-pointer' onClick={() => setAccordian(state => !state)}>
                <span className='flex justify-start items-center gap-3'>
                    <h2 className='text-white font-Sansation text-sm capitalize sm:text-[12px]'>{props.title} <span className='text-primary-100'>{props.dropdown_width}</span></h2>
                </span>
                <MdOutlineKeyboardArrowDown className={`text-white text-xl sm:text-lg ${accordian && 'rotate-180'} transition-all ease-in duration-200`} />
            </div>
            <div className={`${accordian ? props.hight : 'h-0 overflow-hidden'} transition-all ease-in duration-200`}>
                {props.children}
            </div>
        </div>
    )
}

export default Accordian2
