import React from 'react'
import { useTranslation } from 'react-i18next'

function OurBossterRequirements() {
    const [t, i18n] = useTranslation("global")
  return (
    <div className='pl-60 sm:px-7 sm:-mt-14  z-10'>
      <h1 className='text-white font-Oswald font-bold text-[50px] leading-[60px] mb-[50px] sm:text-[26px] sm:leading-[36px] sm:mb-7'>{t("joinPage.our_booster")} <span className='text-primary-100'>{t("joinPage.req")}</span></h1>
      <div className='flex flex-wrap justify-start gap-2 sm:gap-[12px] gap-y-[40px] sm:gap-y-4'>

        <div className='flex flex-col w-[272px] sm:w-[48%] scale-[0.95] sm:scale-100'>
            <img  src={require('../assets/legendoflwague.png')} alt='game' className='rounded-[28px] w-[272px] h-[378px]  sm:w-full sm:h-[243px] sm:rounded-[12.857px] object-cover hover:brightness-75 transition-all ease-in duration-200'/>
            <h2 className='text-white font-Oswald font-bold text-[24px] mt-3 mb-2 sm:mt-2 sm:mb-1 sm:text-base'>{t("joinPage.legend.name")}</h2>
            <p className='text-white font-Sansation text-[12px] sm:text-[8px] sm:leading-[18px]'>{t("joinPage.legend.text1")} <span className='text-primary-100'>{t("joinPage.legend.text1Blue")}</span><br/> {t("joinPage.legend.text2")} <span className='text-primary-100'>{t("joinPage.legend.text2Blue")}</span></p>
        </div>

        <div className='flex flex-col w-[272px] sm:w-[48%] scale-[0.95] sm:scale-100'>
            <img  src={require('../assets/vol.png')} alt='game' className='rounded-[28px] w-[272px] h-[378px]  sm:w-full sm:h-[243px] sm:rounded-[12.857px] object-cover hover:brightness-75 transition-all ease-in duration-200'/>
            <h2 className='text-white font-Oswald font-bold text-[24px] mt-3 mb-2 sm:mt-2 sm:mb-1 sm:text-base'>{t("joinPage.Valorant.name")}</h2>
            <p className='text-white font-Sansation text-[12px] sm:text-[8px] sm:leading-[18px]'>{t("joinPage.Valorant.text")} <span className='text-primary-100'>{t("joinPage.Valorant.textBlue")}</span></p>
        </div>

        <div className='flex flex-col w-[272px] sm:w-[48%] scale-[0.95] sm:scale-100'>
            <img  src={require('../assets/rain.png')} alt='game' className='rounded-[28px] w-[272px] h-[378px]  sm:w-full sm:h-[243px] sm:rounded-[12.857px] object-cover hover:brightness-75 transition-all ease-in duration-200'/>
            <h2 className='text-white font-Oswald font-bold text-[24px] mt-3 mb-2 sm:mt-2 sm:mb-1 sm:text-base'>{t("joinPage.Rainbow.name")}</h2>
            <p className='text-white font-Sansation text-[12px] sm:text-[8px] sm:leading-[18px]'>{t("joinPage.Rainbow.text")} <span className='text-primary-100'>{t("joinPage.Rainbow.textBlue")}</span></p>
        </div>

        <div className='flex flex-col w-[272px] sm:w-[48%] scale-[0.95] sm:scale-100'>
            <img  src={require('../assets/rock.png')} alt='game' className='rounded-[28px] w-[272px] h-[378px]  sm:w-full sm:h-[243px] sm:rounded-[12.857px] object-cover hover:brightness-75 transition-all ease-in duration-200'/>
            <h2 className='text-white font-Oswald font-bold text-[24px] mt-3 mb-2 sm:mt-2 sm:mb-1 sm:text-base'>{t("joinPage.Rocket.name")}</h2>
            <p className='text-white font-Sansation text-[12px] sm:text-[8px] sm:leading-[18px]'>{t("joinPage.Rocket.text")} <span className='text-primary-100'>{t("joinPage.Rocket.textBlue")}</span></p>
        </div>

        <div className='flex flex-col w-[272px] sm:w-[48%] scale-[0.95] sm:scale-100'>
            <img  src={require('../assets/over.png')} alt='game' className='rounded-[28px] w-[272px] h-[378px]  sm:w-full sm:h-[243px] sm:rounded-[12.857px] object-cover hover:brightness-75 transition-all ease-in duration-200'/>
            <h2 className='text-white font-Oswald font-bold text-[24px] mt-3 mb-2 sm:mt-2 sm:mb-1 sm:text-base'>{t("joinPage.Overwatch.name")}</h2>
            <p className='text-white font-Sansation text-[12px] sm:text-[8px] sm:leading-[18px]'>{t("joinPage.Overwatch.text")} <span className='text-primary-100'>{t("joinPage.Overwatch.textBlue")}</span></p>
        </div>

        <div className='flex flex-col w-[272px] sm:w-[48%] scale-[0.95] sm:scale-100'>
            <img  src={require('../assets/cs.png')} alt='game' className='rounded-[28px] w-[272px] h-[378px]  sm:w-full sm:h-[243px] sm:rounded-[12.857px] object-cover hover:brightness-75 transition-all ease-in duration-200'/>
            <h2 className='text-white font-Oswald font-bold text-[24px] mt-3 mb-2 sm:mt-2 sm:mb-1 sm:text-base'>{t("joinPage.cs.name")}</h2>
            <p className='text-white font-Sansation text-[12px] sm:text-[8px] sm:leading-[18px]'>{t("joinPage.cs.text")} <span className='text-primary-100'>{t("joinPage.cs.textBlue")}</span></p>
        </div>

        <div className='flex flex-col w-[272px] sm:w-[48%] scale-[0.95] sm:scale-100'>
            <img  src={require('../assets/war.png')} alt='game' className='rounded-[28px] w-[272px] h-[378px]  sm:w-full sm:h-[243px] sm:rounded-[12.857px] object-cover hover:brightness-75 transition-all ease-in duration-200'/>
            <h2 className='text-white font-Oswald font-bold text-[24px] mt-3 mb-2 sm:mt-2 sm:mb-1 sm:text-base'>{t("joinPage.Warfare.name")}</h2>
            <p className='text-white font-Sansation text-[12px] sm:text-[8px] sm:leading-[18px]'>{t("joinPage.Warfare.text")} <span className='text-primary-100'>{t("joinPage.Warfare.textBlue")}</span></p>
        </div>

        <div className='flex flex-col w-[272px] sm:w-[48%] scale-[0.95] sm:scale-100'>
            <img  src={require('../assets/apex.png')} alt='game' className='rounded-[28px] w-[272px] h-[378px]  sm:w-full sm:h-[243px] sm:rounded-[12.857px] object-cover hover:brightness-75 transition-all ease-in duration-200'/>
            <h2 className='text-white font-Oswald font-bold text-[24px] mt-3 mb-2 sm:mt-2 sm:mb-1 sm:text-base'>{t("joinPage.Apex.name")}</h2>
            <p className='text-white font-Sansation text-[12px] sm:text-[8px] sm:leading-[18px]'>{t("joinPage.Apex.text")} <span className='text-primary-100'>{t("joinPage.Apex.textBlue")}</span></p>
        </div>

        <div className='flex flex-col w-[272px] sm:w-[48%] scale-[0.95] sm:scale-100'>
            <img  src={require('../assets/call.png')} alt='game' className='rounded-[28px] w-[272px] h-[378px]  sm:w-full sm:h-[243px] sm:rounded-[12.857px] object-cover hover:brightness-75 transition-all ease-in duration-200'/>
            <h2 className='text-white font-Oswald font-bold text-[24px] mt-3 mb-2 sm:mt-2 sm:mb-1 sm:text-base'>{t("joinPage.call.name")}</h2>
            <p className='text-white font-Sansation text-[12px] sm:text-[8px] sm:leading-[18px]'>{t("joinPage.call.text")} <span className='text-primary-100'>{t("joinPage.call.textBlue")}</span></p>
        </div>

        

      </div>
    </div>
  )
}

export default OurBossterRequirements
