import React from 'react'
import { Link } from 'react-router-dom'

function Games() {
    return (
        <div className='flex flex-row justify-center items-center flex-wrap w-full mt-48 gap-10 sm:gap-3 gap-y-[50px] sm:gap-y-[80px] sm:mt-24' >

            <Link to={`/game-detail/${1}/${'league of legends'}`} className=' h-[390px] w-[320px] relative game sm:w-[48%] sm:h-[120px] scale-95 sm:scale-100'>
                <svg xmlns="http://www.w3.org/2000/svg" width="320" height="270" viewBox="0 0 380 293" fill="none" className='sm:w-[100%] sm:-mt-[75px]'>
                    <path d="M0 163.662C0 152.226 6.86956 141.91 17.4208 137.501L340.654 2.41835C359.334 -5.38818 379.94 8.33398 379.94 28.5795V263.867C379.94 279.526 367.246 292.221 351.587 292.221H28.3538C12.6944 292.221 0 279.526 0 263.867V163.662Z" fill="url(#paint0_linear_6_249)" />
                    <path d="M0.945126 163.662C0.945126 152.608 7.5857 142.635 17.7852 138.373L341.018 3.29039C359.075 -4.25592 378.995 9.00883 378.995 28.5795V263.867C378.995 279.004 366.724 291.276 351.587 291.276H28.3538C13.2164 291.276 0.945126 279.004 0.945126 263.867V163.662Z" stroke="url(#paint1_linear_6_249)" stroke-opacity="0.5" stroke-width="1.89025" />
                    <defs>
                        <linearGradient id="paint0_linear_6_249" x1="372.379" y1="10.5733" x2="15.122" y2="279.461" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#0AC8B9" />
                            <stop offset="1" stop-color="#089BBF" />
                        </linearGradient>
                        <linearGradient id="paint1_linear_6_249" x1="365.764" y1="269.065" x2="36.8599" y2="133.912" gradientUnits="userSpaceOnUse">
                            <stop stop-color="white" />
                            <stop offset="1" stop-color="white" stop-opacity="0" />
                        </linearGradient>
                    </defs>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="57" height="55" viewBox="0 0 57 55" fill="none" className='absolute right-5 bottom-36 z-30 sm:w-[30px] sm:bottom-[-4px] sm:right-2'>
                    <circle cx="28.5117" cy="27.2671" r="26.9361" fill="#08CAD0" />
                    <g opacity="0.8" filter="url(#filter0_f_6_284)">
                        <path d="M24.0462 27.7447L16.166 18.2884H19.8434L27.1983 27.7447L19.8434 36.6756H16.166L24.0462 27.7447Z" fill="white" />
                        <path d="M30.3504 27.7447L22.4702 18.2884H33.5025L40.8574 27.7447L33.5025 36.6756H22.4702L30.3504 27.7447Z" fill="white" />
                    </g>
                    <path d="M24.0462 27.7447L16.166 18.2884H19.8434L27.1983 27.7447L19.8434 36.6756H16.166L24.0462 27.7447Z" fill="white" />
                    <path d="M30.3504 27.7447L22.4702 18.2884H33.5025L40.8574 27.7447L33.5025 36.6756H22.4702L30.3504 27.7447Z" fill="white" />
                    <defs>
                        <filter id="filter0_f_6_284" x="0.453274" y="2.57568" width="56.1168" height="49.8126" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                            <feGaussianBlur stdDeviation="7.85636" result="effect1_foregroundBlur_6_284" />
                        </filter>
                    </defs>
                </svg>
                <div className='mask'></div>
                <img src={require('../assets/mask1.png')} alt='mask' className='absolute -top-1 hover:brightness-150 transition-all ease-in duration-200 select-none' />
                <img src={require('../assets/legendAvatar.png')} alt='mask' className='absolute -top-32 right-5 w-[203.202px] sm:w-[100px] sm:right-2 sm:-top-20' />
                <img src={require('../assets/leagueof (2).png')} alt='mask' className='absolute bottom-32 left-2 w-[180px] sm:w-[70px] sm:bottom-1 sm:left-1' />
            </Link>

            <Link to={`/game-detail/${2}/${'valorant'}`} className=' h-[390px] w-[320px] relative game  sm:w-[48%]  sm:h-[120px] scale-95 sm:scale-100'>
                <svg xmlns="http://www.w3.org/2000/svg" width="320" height="270"viewBox="0 0 381 293" fill="none"  className='sm:w-[100%] sm:-mt-[75px]'>
                    <path d="M0.320992 163.559C0.320992 152.124 7.19055 141.807 17.7417 137.398L340.975 2.31557C359.655 -5.49096 380.261 8.2312 380.261 28.4767V263.764C380.261 279.423 367.567 292.118 351.908 292.118H28.6748C13.0154 292.118 0.320992 279.423 0.320992 263.764V163.559Z" fill="#FD4556" />
                    <path d="M1.26612 163.559C1.26612 152.505 7.90669 142.533 18.1062 138.27L341.339 3.18761C359.396 -4.35871 379.316 8.90605 379.316 28.4767V263.764C379.316 278.902 367.045 291.173 351.908 291.173H28.6748C13.5374 291.173 1.26612 278.901 1.26612 263.764V163.559Z" stroke="url(#paint0_linear_6_289)" stroke-opacity="0.5" stroke-width="1.89025" />
                    <defs>
                        <linearGradient id="paint0_linear_6_289" x1="366.085" y1="268.962" x2="37.1809" y2="133.809" gradientUnits="userSpaceOnUse">
                            <stop stop-color="white" />
                            <stop offset="1" stop-color="white" stop-opacity="0" />
                        </linearGradient>
                    </defs>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="57" height="55" viewBox="0 0 57 55" fill="none" className='absolute right-5 bottom-36 z-30 sm:w-[30px] sm:bottom-[-4px] sm:right-2'>
                    <circle cx="28.6972" cy="27.267" r="26.9361" fill="#53212B" />
                    <g opacity="0.8" filter="url(#filter0_f_6_299)">
                        <path d="M24.2318 27.7446L16.3515 18.2883H20.029L27.3839 27.7446L20.029 36.6755H16.3515L24.2318 27.7446Z" fill="white" />
                        <path d="M30.5359 27.7446L22.6557 18.2883H33.688L41.0429 27.7446L33.688 36.6755H22.6557L30.5359 27.7446Z" fill="white" />
                    </g>
                    <path d="M24.2318 27.7446L16.3515 18.2883H20.029L27.3839 27.7446L20.029 36.6755H16.3515L24.2318 27.7446Z" fill="white" />
                    <path d="M30.5359 27.7446L22.6557 18.2883H33.688L41.0429 27.7446L33.688 36.6755H22.6557L30.5359 27.7446Z" fill="white" />
                    <defs>
                        <filter id="filter0_f_6_299" x="0.638821" y="2.57562" width="56.1168" height="49.8126" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                            <feGaussianBlur stdDeviation="7.85636" result="effect1_foregroundBlur_6_299" />
                        </filter>
                    </defs>
                </svg>
                <img src={require('../assets/volarantMask.png')} alt='mask' className='absolute -top-1 hover:brightness-150 transition-all ease-in duration-200 select-none' />
                <img src={require('../assets/volarantAva.png')} alt='mask' className='absolute -top-32 right-0 w-[183.354px] sm:w-[100px] sm:-right-2 sm:-top-20' />
                <img src={require('../assets/valorant.png')} alt='mask' className='absolute bottom-[120px] left-4 w-[170px] invert sm:w-[80px] sm:bottom-0 sm:left-2' />
            </Link>

            <Link to={`/game-detail/${3}/${'rainbow six'}`} className=' h-[390px] w-[320px] relative game  sm:w-[48%]  sm:h-[120px] scale-95 sm:scale-100'>
                <svg xmlns="http://www.w3.org/2000/svg" width="320" height="270"viewBox="0 0 381 293" fill="none" className='sm:w-[100%] sm:-mt-[75px]'>
                    <path d="M0.834961 163.559C0.834961 152.124 7.70452 141.807 18.2557 137.398L341.489 2.31557C360.169 -5.49096 380.775 8.2312 380.775 28.4767V263.764C380.775 279.423 368.081 292.118 352.422 292.118H29.1887C13.5294 292.118 0.834961 279.423 0.834961 263.764V163.559Z" fill="url(#paint0_linear_6_311)" />
                    <path d="M1.78009 163.559C1.78009 152.505 8.42066 142.533 18.6201 138.27L341.853 3.18761C359.91 -4.35871 379.83 8.90605 379.83 28.4767V263.764C379.83 278.902 367.559 291.173 352.422 291.173H29.1887C14.0514 291.173 1.78009 278.901 1.78009 263.764V163.559Z" stroke="url(#paint1_linear_6_311)" stroke-opacity="0.5" stroke-width="1.89025" />
                    <defs>
                        <linearGradient id="paint0_linear_6_311" x1="352.332" y1="34.5712" x2="14.922" y2="280.776" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#7C7C7C" />
                            <stop offset="1" stop-color="#BABABA" />
                        </linearGradient>
                        <linearGradient id="paint1_linear_6_311" x1="366.599" y1="268.962" x2="37.6949" y2="133.809" gradientUnits="userSpaceOnUse">
                            <stop stop-color="white" />
                            <stop offset="1" stop-color="white" stop-opacity="0" />
                        </linearGradient>
                    </defs>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="57" height="55" viewBox="0 0 57 55" fill="none" className='absolute right-5 bottom-36 z-30 sm:w-[30px] sm:bottom-[-4px] sm:right-2'>
                    <circle cx="28.211" cy="27.267" r="26.9361" fill="#535353" />
                    <g opacity="0.8" filter="url(#filter0_f_6_321)">
                        <path d="M23.7455 27.7446L15.8652 18.2883H19.5427L26.8976 27.7446L19.5427 36.6755H15.8652L23.7455 27.7446Z" fill="white" />
                        <path d="M30.0496 27.7446L22.1694 18.2883H33.2017L40.5566 27.7446L33.2017 36.6755H22.1694L30.0496 27.7446Z" fill="white" />
                    </g>
                    <path d="M23.7455 27.7446L15.8652 18.2883H19.5427L26.8976 27.7446L19.5427 36.6755H15.8652L23.7455 27.7446Z" fill="white" />
                    <path d="M30.0496 27.7446L22.1694 18.2883H33.2017L40.5566 27.7446L33.2017 36.6755H22.1694L30.0496 27.7446Z" fill="white" />
                    <defs>
                        <filter id="filter0_f_6_321" x="0.152523" y="2.57562" width="56.1168" height="49.8126" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                            <feGaussianBlur stdDeviation="7.85636" result="effect1_foregroundBlur_6_321" />
                        </filter>
                    </defs>
                </svg>
                <img src={require('../assets/rainMask.png')} alt='mask' className='absolute -top-1 hover:brightness-150 transition-all ease-in duration-200 select-none' />
                <img src={require('../assets/rainava.png')} alt='mask' className='absolute -top-24 right-0 w-[200.202px] select-none sm:w-[110px] sm:-right-3 sm:-top-16' />
                <img src={require('../assets/rainsix.png')} alt='mask' className='absolute bottom-32 left-4 w-[170px] invert sm:w-[80px] sm:bottom-0 sm:left-2' />
            </Link>

            <Link to={`/game-detail/${4}/${'rocket league'}`} className=' h-[390px] w-[320px] relative game sm:w-[48%] sm:h-[120px] scale-95 sm:scale-100'>
                <svg xmlns="http://www.w3.org/2000/svg" width="320" height="270"viewBox="0 0 381 293" fill="none" className='sm:w-[100%] sm:-mt-[75px]'>
                    <path d="M0.135498 164.232C0.135498 152.796 7.00506 142.48 17.5563 138.071L340.789 2.98818C359.469 -4.81835 380.076 8.90381 380.076 29.1493V264.437C380.076 280.096 367.382 292.79 351.722 292.79H28.4893C12.8299 292.79 0.135498 280.096 0.135498 264.437V164.232Z" fill="url(#paint0_linear_6_348)" />
                    <path d="M1.08062 164.232C1.08062 153.177 7.7212 143.205 17.9207 138.943L341.154 3.86021C359.211 -3.6861 379.131 9.57866 379.131 29.1493V264.437C379.131 279.574 366.86 291.845 351.722 291.845H28.4893C13.3519 291.845 1.08062 279.574 1.08062 264.437V164.232Z" stroke="url(#paint1_linear_6_348)" stroke-opacity="0.5" stroke-width="1.89025" />
                    <defs>
                        <linearGradient id="paint0_linear_6_348" x1="372.515" y1="11.1431" x2="15.2575" y2="280.031" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#009AED" />
                            <stop offset="1" stop-color="#00358D" />
                        </linearGradient>
                        <linearGradient id="paint1_linear_6_348" x1="365.899" y1="269.635" x2="36.9954" y2="134.482" gradientUnits="userSpaceOnUse">
                            <stop stop-color="white" />
                            <stop offset="1" stop-color="white" stop-opacity="0" />
                        </linearGradient>
                    </defs>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="57" height="54" viewBox="0 0 57 54" fill="none" className='absolute right-5 bottom-36 z-30 sm:w-[30px] sm:bottom-[-4px] sm:right-2'>
                    <circle cx="28.5117" cy="26.9396" r="26.9361" fill="#001E53" />
                    <g opacity="0.8" filter="url(#filter0_f_6_358)">
                        <path d="M24.0462 27.4172L16.166 17.9609H19.8434L27.1983 27.4172L19.8434 36.3482H16.166L24.0462 27.4172Z" fill="white" />
                        <path d="M30.3504 27.4172L22.4702 17.9609H33.5025L40.8574 27.4172L33.5025 36.3482H22.4702L30.3504 27.4172Z" fill="white" />
                    </g>
                    <path d="M24.0462 27.4172L16.166 17.9609H19.8434L27.1983 27.4172L19.8434 36.3482H16.166L24.0462 27.4172Z" fill="white" />
                    <path d="M30.3504 27.4172L22.4702 17.9609H33.5025L40.8574 27.4172L33.5025 36.3482H22.4702L30.3504 27.4172Z" fill="white" />
                    <defs>
                        <filter id="filter0_f_6_358" x="0.453274" y="2.24823" width="56.1168" height="49.8126" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                            <feGaussianBlur stdDeviation="7.85636" result="effect1_foregroundBlur_6_358" />
                        </filter>
                    </defs>
                </svg>
                <img src={require('../assets/maskrock.png')} alt='mask' className='absolute -top-1 hover:brightness-150 transition-all ease-in duration-200 select-none' />
                <img src={require('../assets/ROCKET.png')} alt='mask' className='absolute -top-40 -right-3 w-[296.769px] sm:w-[120px] sm:-right-2 sm:-top-16' />
                <img src={require('../assets/rocketlea.png')} alt='mask' className='absolute bottom-32 left-2 w-[190px] sm:w-[90px] sm:bottom-0 sm:left-1' />
            </Link>

            <Link to={`/game-detail/${5}/${'Overwatch 2'}`} className=' h-[390px] w-[320px] relative game sm:w-[48%] sm:h-[120px] scale-95 sm:scale-100'>
                <svg xmlns="http://www.w3.org/2000/svg" width="320" height="270"viewBox="0 0 381 293" fill="none" className='sm:w-[100%] sm:-mt-[75px]'>
                    <path d="M0.320992 164.232C0.320992 152.796 7.19055 142.48 17.7417 138.071L340.975 2.98818C359.655 -4.81835 380.261 8.90381 380.261 29.1493V264.437C380.261 280.096 367.567 292.79 351.908 292.79H28.6748C13.0154 292.79 0.320992 280.096 0.320992 264.437V164.232Z" fill="url(#paint0_linear_6_371)" />
                    <path d="M1.26612 164.232C1.26612 153.177 7.90669 143.205 18.1062 138.943L341.339 3.86021C359.396 -3.6861 379.316 9.57866 379.316 29.1493V264.437C379.316 279.574 367.045 291.845 351.908 291.845H28.6748C13.5374 291.845 1.26612 279.574 1.26612 264.437V164.232Z" stroke="url(#paint1_linear_6_371)" stroke-opacity="0.5" stroke-width="1.89025" />
                    <defs>
                        <linearGradient id="paint0_linear_6_371" x1="337.641" y1="19.6492" x2="25.7498" y2="285.229" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#F0640C" />
                            <stop offset="1" stop-color="#EF6209" />
                        </linearGradient>
                        <linearGradient id="paint1_linear_6_371" x1="366.085" y1="269.635" x2="37.1809" y2="134.482" gradientUnits="userSpaceOnUse">
                            <stop stop-color="white" />
                            <stop offset="1" stop-color="white" stop-opacity="0" />
                        </linearGradient>
                    </defs>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="57" height="54" viewBox="0 0 57 54" fill="none" className='absolute right-5 bottom-36 z-30 sm:w-[30px] sm:bottom-[-4px] sm:right-2'>
                    <circle cx="28.6972" cy="26.9396" r="26.9361" fill="#E0671C" />
                    <g opacity="0.8" filter="url(#filter0_f_6_377)">
                        <path d="M24.2318 27.4172L16.3515 17.9609H20.029L27.3839 27.4172L20.029 36.3482H16.3515L24.2318 27.4172Z" fill="white" />
                        <path d="M30.5359 27.4172L22.6557 17.9609H33.688L41.0429 27.4172L33.688 36.3482H22.6557L30.5359 27.4172Z" fill="white" />
                    </g>
                    <path d="M24.2318 27.4172L16.3515 17.9609H20.029L27.3839 27.4172L20.029 36.3482H16.3515L24.2318 27.4172Z" fill="white" />
                    <path d="M30.5359 27.4172L22.6557 17.9609H33.688L41.0429 27.4172L33.688 36.3482H22.6557L30.5359 27.4172Z" fill="white" />
                    <defs>
                        <filter id="filter0_f_6_377" x="0.638821" y="2.24823" width="56.1168" height="49.8126" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                            <feGaussianBlur stdDeviation="7.85636" result="effect1_foregroundBlur_6_377" />
                        </filter>
                    </defs>
                </svg>
                <img src={require('../assets/maskcs.png')} alt='mask' className='absolute -top-1 hover:brightness-150 transition-all ease-in duration-200 select-none' />
                <img src={require('../assets/css.png')} alt='mask' className='absolute -top-32 -right-2 w-[208.324px] sm:w-[100px] sm:-right-2 sm:-top-16' />
                <img src={require('../assets/overwatch.png')} alt='mask' className='absolute bottom-[120px] left-2 w-[120px] sm:w-[60px] sm:bottom-0 sm:left-1' />
            </Link>

            <Link to={`/game-detail/${6}/${'counter strike 2'}`} className=' h-[390px] w-[320px] relative game sm:w-[48%] sm:h-[120px] scale-95 sm:scale-100'>
                <svg xmlns="http://www.w3.org/2000/svg" width="320" height="270"viewBox="0 0 381 293" fill="none" className='sm:w-[100%] sm:-mt-[75px]'>
                    <path d="M0.834961 164.232C0.834961 152.796 7.70452 142.48 18.2557 138.071L341.489 2.98818C360.169 -4.81835 380.775 8.90381 380.775 29.1493V264.437C380.775 280.096 368.081 292.79 352.422 292.79H29.1887C13.5294 292.79 0.834961 280.096 0.834961 264.437V164.232Z" fill="#F2A121" />
                    <path d="M1.78009 164.232C1.78009 153.177 8.42066 143.205 18.6201 138.943L341.853 3.86021C359.91 -3.6861 379.83 9.57866 379.83 29.1493V264.437C379.83 279.574 367.559 291.845 352.422 291.845H29.1887C14.0514 291.845 1.78009 279.574 1.78009 264.437V164.232Z" stroke="url(#paint0_linear_6_394)" stroke-opacity="0.5" stroke-width="1.89025" />
                    <defs>
                        <linearGradient id="paint0_linear_6_394" x1="366.599" y1="269.635" x2="37.6949" y2="134.482" gradientUnits="userSpaceOnUse">
                            <stop stop-color="white" />
                            <stop offset="1" stop-color="white" stop-opacity="0" />
                        </linearGradient>
                    </defs>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="57" height="55" viewBox="0 0 57 55" fill="none" className='absolute right-5 bottom-36 z-30 sm:w-[30px] sm:bottom-[-4px] sm:right-2'>
                    <circle cx="28.211" cy="27.8848" r="26.9361" fill="#2B367C" />
                    <g opacity="0.8" filter="url(#filter0_f_6_400)">
                        <path d="M23.7455 28.3624L15.8652 18.9061H19.5427L26.8976 28.3624L19.5427 37.2933H15.8652L23.7455 28.3624Z" fill="white" />
                        <path d="M30.0496 28.3624L22.1694 18.9061H33.2017L40.5566 28.3624L33.2017 37.2933H22.1694L30.0496 28.3624Z" fill="white" />
                    </g>
                    <path d="M23.7455 28.3624L15.8652 18.9061H19.5427L26.8976 28.3624L19.5427 37.2933H15.8652L23.7455 28.3624Z" fill="white" />
                    <path d="M30.0496 28.3624L22.1694 18.9061H33.2017L40.5566 28.3624L33.2017 37.2933H22.1694L30.0496 28.3624Z" fill="white" />
                    <defs>
                        <filter id="filter0_f_6_400" x="0.152523" y="3.19342" width="56.1168" height="49.8126" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                            <feGaussianBlur stdDeviation="7.85636" result="effect1_foregroundBlur_6_400" />
                        </filter>
                    </defs>
                </svg>
                <img src={require('../assets/maskcounter.png')} alt='mask' className='absolute -top-1 hover:brightness-150 transition-all ease-in duration-200 select-none' />
                <img src={require('../assets/cs2.png')} alt='mask' className='absolute -top-32 right-0 w-[161.616px] sm:w-[80px] sm:-right-3 sm:-top-16' />
                <img src={require('../assets/counter.png')} alt='mask' className='absolute bottom-36 left-5 w-[200px] grayscale invert sm:w-[90px] sm:bottom-3 sm:left-2' />
            </Link>

            <Link to={`/game-detail/${7}/${'COD modern warfare |||'}`} className=' h-[390px] w-[320px] relative game sm:w-[48%] sm:h-[120px] scale-95 sm:scale-100'>
                <svg xmlns="http://www.w3.org/2000/svg" width="320" height="270"viewBox="0 0 381 293" fill="none" className='sm:w-[100%] sm:-mt-[75px]'>
                    <path d="M0.135498 163.904C0.135498 152.469 7.00506 142.153 17.5563 137.743L340.789 2.66066C359.469 -5.14587 380.076 8.57629 380.076 28.8218V264.109C380.076 279.769 367.382 292.463 351.722 292.463H28.4893C12.8299 292.463 0.135498 279.769 0.135498 264.109V163.904Z" fill="#D80000" />
                    <path d="M1.08062 163.904C1.08062 152.85 7.7212 142.878 17.9207 138.615L341.154 3.5327C359.211 -4.01361 379.131 9.25114 379.131 28.8218V264.109C379.131 279.247 366.86 291.518 351.722 291.518H28.4893C13.3519 291.518 1.08062 279.247 1.08062 264.109V163.904Z" stroke="url(#paint0_linear_70_138)" stroke-opacity="0.5" stroke-width="1.89025" />
                    <defs>
                        <linearGradient id="paint0_linear_70_138" x1="365.899" y1="269.307" x2="36.9954" y2="134.154" gradientUnits="userSpaceOnUse">
                            <stop stop-color="white" />
                            <stop offset="1" stop-color="white" stop-opacity="0" />
                        </linearGradient>
                    </defs>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="57" height="55" viewBox="0 0 57 55" fill="none" className='absolute right-5 bottom-36 z-30 sm:w-[30px] sm:bottom-[-4px] sm:right-2'>
                    <circle cx="28.5117" cy="27.6121" r="26.9361" fill="#920000" />
                    <g opacity="0.8" filter="url(#filter0_f_70_146)">
                        <path d="M24.0462 28.0897L16.166 18.6334H19.8434L27.1983 28.0897L19.8434 37.0206H16.166L24.0462 28.0897Z" fill="white" />
                        <path d="M30.3504 28.0897L22.4702 18.6334H33.5025L40.8574 28.0897L33.5025 37.0206H22.4702L30.3504 28.0897Z" fill="white" />
                    </g>
                    <path d="M24.0462 28.0897L16.166 18.6334H19.8434L27.1983 28.0897L19.8434 37.0206H16.166L24.0462 28.0897Z" fill="white" />
                    <path d="M30.3504 28.0897L22.4702 18.6334H33.5025L40.8574 28.0897L33.5025 37.0206H22.4702L30.3504 28.0897Z" fill="white" />
                    <defs>
                        <filter id="filter0_f_70_146" x="0.453274" y="2.92071" width="56.1168" height="49.8126" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                            <feGaussianBlur stdDeviation="7.85636" result="effect1_foregroundBlur_70_146" />
                        </filter>
                    </defs>
                </svg>
                <img src={require('../assets/maskModern.png')} alt='mask' className='absolute -top-1 hover:brightness-110 transition-all ease-in duration-200 select-none' />
                <img src={require('../assets/modernwar.png')} alt='mask' className='absolute -top-28 -right-9 w-[348.751px] sm:w-[220px] sm:-right-4 sm:-top-14' />
                <img src={require('../assets/call_of_duty_modern_warfare_iii-removebg-preview.png')} alt='mask' className='absolute bottom-28 left-3 w-[150px] sm:w-[60px] sm:-bottom-1 sm:left-1' />
            </Link>

            <Link to={`/game-detail/${8}/${'apex legends'}`} className=' h-[390px] w-[320px] relative game sm:w-[48%] sm:h-[120px] scale-95 sm:scale-100'>
                <svg xmlns="http://www.w3.org/2000/svg" width="320" height="270"viewBox="0 0 381 293" fill="none" className='sm:w-[100%] sm:-mt-[75px]'>
                    <path d="M0.320992 163.904C0.320992 152.469 7.19055 142.153 17.7417 137.743L340.975 2.66066C359.655 -5.14587 380.261 8.57629 380.261 28.8218V264.109C380.261 279.769 367.567 292.463 351.908 292.463H28.6748C13.0154 292.463 0.320992 279.769 0.320992 264.109V163.904Z" fill="#F70000" />
                    <path d="M1.26612 163.904C1.26612 152.85 7.90669 142.878 18.1062 138.615L341.339 3.5327C359.396 -4.01361 379.316 9.25114 379.316 28.8218V264.109C379.316 279.247 367.045 291.518 351.908 291.518H28.6748C13.5374 291.518 1.26612 279.247 1.26612 264.109V163.904Z" stroke="url(#paint0_linear_9_457)" stroke-opacity="0.5" stroke-width="1.89025" />
                    <defs>
                        <linearGradient id="paint0_linear_9_457" x1="366.085" y1="269.307" x2="37.1809" y2="134.154" gradientUnits="userSpaceOnUse">
                            <stop stop-color="white" />
                            <stop offset="1" stop-color="white" stop-opacity="0" />
                        </linearGradient>
                    </defs>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="57" height="55" viewBox="0 0 57 55" fill="none" className='absolute right-5 bottom-36 z-30 sm:w-[30px] sm:bottom-[-4px] sm:right-2'>
                    <circle cx="28.6972" cy="27.6121" r="26.9361" fill="#D90000" />
                    <g opacity="0.8" filter="url(#filter0_f_9_463)">
                        <path d="M24.2318 28.0897L16.3515 18.6334H20.029L27.3839 28.0897L20.029 37.0206H16.3515L24.2318 28.0897Z" fill="white" />
                        <path d="M30.5359 28.0897L22.6557 18.6334H33.688L41.0429 28.0897L33.688 37.0206H22.6557L30.5359 28.0897Z" fill="white" />
                    </g>
                    <path d="M24.2318 28.0897L16.3515 18.6334H20.029L27.3839 28.0897L20.029 37.0206H16.3515L24.2318 28.0897Z" fill="white" />
                    <path d="M30.5359 28.0897L22.6557 18.6334H33.688L41.0429 28.0897L33.688 37.0206H22.6557L30.5359 28.0897Z" fill="white" />
                    <defs>
                        <filter id="filter0_f_9_463" x="0.638821" y="2.92071" width="56.1168" height="49.8126" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                            <feGaussianBlur stdDeviation="7.85636" result="effect1_foregroundBlur_9_463" />
                        </filter>
                    </defs>
                </svg>
                <img src={require('../assets/maskapex.png')} alt='mask' className='absolute -top-1 hover:brightness-110 transition-all ease-in duration-200 select-none' />
                <img src={require('../assets/apexx.png')} alt='mask' className='absolute -top-32 right-5 w-[147.44px]  sm:w-[70px] sm:right-1 sm:-top-14' />
                <img src={require('../assets/apexlegends.png')} alt='mask' className='absolute bottom-32 left-3 w-[120px] grayscale invert sm:w-[50px] sm:bottom-2 sm:left-1' />
            </Link>

            <Link to={`/game-detail/${9}/${'COD warzone 2'}`} className=' h-[390px] w-[320px] relative game sm:w-[48%] sm:h-[120px] scale-95 sm:scale-100'>
                <svg xmlns="http://www.w3.org/2000/svg" width="320" height="270"viewBox="0 0 381 293" fill="none" className='sm:w-[100%] sm:-mt-[75px]'>
                    <g filter="url(#filter0_i_9_476)">
                        <path d="M0.834961 163.904C0.834961 152.469 7.70452 142.153 18.2557 137.743L341.489 2.66066C360.169 -5.14587 380.775 8.57629 380.775 28.8218V264.109C380.775 279.769 368.081 292.463 352.422 292.463H29.1887C13.5294 292.463 0.834961 279.769 0.834961 264.109V163.904Z" fill="#7EC92E" />
                    </g>
                    <defs>
                        <filter id="filter0_i_9_476" x="0.834961" y="0.438477" width="379.94" height="295.805" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset dy="3.7805" />
                            <feGaussianBlur stdDeviation="16.0671" />
                            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0.180392 0 0 0 0 0.47451 0 0 0 0 0 0 0 0 1 0" />
                            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_9_476" />
                        </filter>
                    </defs>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="57" height="55" viewBox="0 0 57 55" fill="none" className='absolute right-5 bottom-36 z-30 sm:w-[30px] sm:bottom-[-4px] sm:right-2'>
                    <circle cx="28.211" cy="27.5572" r="26.9361" fill="#57A218" />
                    <g opacity="0.8" filter="url(#filter0_f_9_482)">
                        <path d="M23.7455 28.0348L15.8652 18.5785H19.5427L26.8976 28.0348L19.5427 36.9657H15.8652L23.7455 28.0348Z" fill="white" />
                        <path d="M30.0496 28.0348L22.1694 18.5785H33.2017L40.5566 28.0348L33.2017 36.9657H22.1694L30.0496 28.0348Z" fill="white" />
                    </g>
                    <path d="M23.7455 28.0348L15.8652 18.5785H19.5427L26.8976 28.0348L19.5427 36.9657H15.8652L23.7455 28.0348Z" fill="white" />
                    <path d="M30.0496 28.0348L22.1694 18.5785H33.2017L40.5566 28.0348L33.2017 36.9657H22.1694L30.0496 28.0348Z" fill="white" />
                    <defs>
                        <filter id="filter0_f_9_482" x="0.152523" y="2.86578" width="56.1168" height="49.8126" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                            <feGaussianBlur stdDeviation="7.85636" result="effect1_foregroundBlur_9_482" />
                        </filter>
                    </defs>
                </svg>
                <img src={require('../assets/cod.png')} alt='mask' className='absolute -top-32 right-3 w-[172.866px]  sm:w-[80px] sm:right-1 sm:-top-16' />
                <img src={require('../assets/cod2.png')} alt='mask' className='absolute bottom-36 left-4 w-[170px] sm:w-[70px] sm:bottom-2 sm:left-2' />
            </Link>

        </div>
    )
}

export default Games
